<ul class="assessment-list" *ngIf="!(isLoading$ | async); else loadingAssessment">
    <li *ngFor="let assessment of (assessmentList$ | async); trackBy: trackByAssessmentId">
        <a
            class="nav-item"
            [routerLink]="['assess', assessment.mediaType | assessmentRoute, assessment.id]"
            (click)="onAssessmentClick(assessment.id)"
        >
            <span class="item__type">{{ assessment.mediaType | assessmentLabel | translate }}</span>
            <div class="assessment-content">
                <span aria-hidden="true" class="label-icon" [ngClass]="assessment.mediaType | assessmentIcon"></span>
                <span class="label-text">{{ assessment.name }}</span>
            </div>
        </a>
    </li>
</ul>
<ng-template #loadingAssessment>
    <ion-card-content class="card-content custom-skeleton">
        <ul class="assessment-list">
            <li *ngFor="let item of [0, 1, 2]" class="nav-item">
                <span class="item__type">
                    <ion-skeleton-text animated style="width: 40%;"></ion-skeleton-text>
                </span>
                <div class="assessment-content">
                    <span aria-hidden="true" class="label-icon">
                        <ion-icon class="list-item__icon" slot="start" name="square"></ion-icon>
                    </span>
                    <span class="label-text">
                        <ion-skeleton-text animated style="width: 80%;"></ion-skeleton-text>
                    </span>
                </div>
            </li>
        </ul>
    </ion-card-content>
</ng-template>
