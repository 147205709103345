import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { selectSelectedClassRosterId } from '../class-roster/store/class-roster.selectors';
import { AppState, selectRouterState } from '../core.state';
import {
    ActionCreateMediaDocument,
    ActionRemoveMedia,
    ActionSetMediaFilter,
    ActionUpdateMedia,
    ActionOpenMediaMenu,
    ActionCloseMediaMenu,
    ActionSendFileExceededTelemetry
} from './store/media.actions';
import { Media, MediaFilter } from './store/media.model';
import { MediaDataService } from './media.data.service';
import {
    ActionFetchSignedThumbnailUrls,
    ActionFetchSignedDownloadUrls,
    ActionUpdateMediaInStore
} from './store/media.actions';
import {
    selectFilteredMediaByClass,
    selectIsLoading,
    selectMediaFilter,
    selectFilteredMediaByStudent
} from './store/media.selectors';
import { AngularFireAuth } from '@angular/fire/auth';
import { selectMediaById } from './store/media.selectors';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MediaService {
    isLoading$ = this.store$.pipe(select(selectIsLoading));
    mediaFilter$ = this.store$.pipe(select(selectMediaFilter));
    filteredMedia$ = this.store$.pipe(
        select(selectSelectedClassRosterId),
        switchMap(classId => this.store$.pipe(select(selectFilteredMediaByClass(classId))))
    );

    filteredMediaByStudent$ = this.store$.pipe(
        select(selectRouterState),
        switchMap(({ state }) => this.store$.pipe(select(selectFilteredMediaByStudent(state.params.studentId))))
    );

    constructor(
        private store$: Store<AppState>,
        private mediaDataService: MediaDataService,
        private angularFireAuth: AngularFireAuth
    ) {}

    setMediaFilter(mediaFilter: MediaFilter) {
        this.store$.dispatch(new ActionSetMediaFilter({ filter: mediaFilter }));
    }

    getIsMediaInvalidAndSendTelemetry(file: File) {
        let isMediaInValid = false;
        const fileType = MediaDataService.mediaTypeFromMime(file.type);
        const maxSize = environment.mediaMaxUploadSize[fileType];
        if (file.size > maxSize) {
            isMediaInValid = true;
            this.store$.dispatch(new ActionSendFileExceededTelemetry({ type: fileType, size: file.size }));
        }
        return isMediaInValid;
    }

    addMediaDispatchAction(file: File) {
        if (!this.getIsMediaInvalidAndSendTelemetry(file)) {
            this.store$.dispatch(new ActionCreateMediaDocument({ file }));
        }
    }

    updateMediaDispatchAction(media: Media) {
        const newMedia = { ...media };
        delete newMedia.signedDownloadUrl;
        delete newMedia.signedThumbnailUrl;
        this.store$.dispatch(new ActionUpdateMedia({ media: newMedia }));
    }

    removeMediaDispatchAction(media: Media[]) {
        this.store$.dispatch(new ActionRemoveMedia({ media }));
    }

    openMediaMenu(mediaId?: string) {
        return this.store$.dispatch(new ActionOpenMediaMenu({ mediaId: mediaId ? mediaId : '' }));
    }

    closeMediaMenu() {
        return this.store$.dispatch(new ActionCloseMediaMenu());
    }

    fetchSignedThumbnailUrlsAndAddToMedia(medias: Media[]) {
        const mediaToRequest = [];
        medias.forEach(media => {
            if (!media.thumbnailLoading && media.thumbnails.h128) {
                if (!media.signedThumbnailUrl) {
                    mediaToRequest.push(media);
                } else if (media.signedThumbnailUrl && media.signedThumbnailUrl.expireAt - Date.now() < 0) {
                    mediaToRequest.push(media);

                    // remove the expired media in local store
                    const newMedia = { ...media };
                    newMedia.signedThumbnailUrl = undefined;
                    return this.store$.dispatch(new ActionUpdateMediaInStore({ media: newMedia }));
                }
            }
        });
        if (mediaToRequest.length > 0) {
            return this.store$.dispatch(new ActionFetchSignedThumbnailUrls({ medias: mediaToRequest }));
        }
    }

    fetchSignedDownloadUrlsAndAddToMedia(medias: Media[]) {
        const mediaToRequest = [];
        medias.forEach(media => {
            if (!media.mediaLoading) {
                if (!media.signedDownloadUrl) {
                    mediaToRequest.push(media);
                } else if (media.signedDownloadUrl && media.signedDownloadUrl.expireAt - Date.now() < 0) {
                    mediaToRequest.push(media);

                    // remove the expired media in local store
                    const newMedia = { ...media };
                    newMedia.signedDownloadUrl = undefined;
                    return this.store$.dispatch(new ActionUpdateMediaInStore({ media: newMedia }));
                }
            }
        });
        if (mediaToRequest.length > 0) {
            this.store$.dispatch(new ActionFetchSignedDownloadUrls({ medias: mediaToRequest }));
        }
    }

    fetchSignedUrls(urls: string[]) {
        return this.angularFireAuth.idToken.pipe(
            switchMap(token => {
                if (token) {
                    return this.mediaDataService.fetchSignedUrls(urls, token);
                }
                return of(null);
            })
        );
    }

    fetchMedia(classId: string, uid: string) {
        return this.mediaDataService.fetchMedia(classId, uid);
    }

    removeMedia(media: Media[]) {
        return this.mediaDataService.removeMedia(media);
    }

    updateMedia(changes: Media) {
        return this.mediaDataService.updateMedia(changes);
    }

    createMediaDocument(userId: string, classId: string, file: File, mediaId?: string) {
        return this.mediaDataService.createMediaDocument(userId, classId, file, mediaId);
    }

    uploadMedia(file: File, media: Media) {
        return this.mediaDataService.uploadMedia(file, media);
    }

    addMedia(media: Media) {
        return this.mediaDataService.addMedia(media);
    }

    selectMedia(mediaId: string) {
        return this.store$.pipe(select(selectMediaById(mediaId)));
    }
}
