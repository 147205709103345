import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/comments.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('comments', reducer)
    ]
})
export class CommentsModule {}
