import { Component } from '@angular/core';
import { MediaService } from 'src/app/core/media/media.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

    constructor(private mediaService: MediaService) {}

    onMediaCaptured(event) {
        const files: FileList = event.target.files;
        if (files && files.length > 0) {
            this.mediaService.addMediaDispatchAction(files[0]);
            event.target.value = '';
        }
    }
}
