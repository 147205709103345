import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Note, NotesFilter } from './notes.model';

export enum NotesActionTypes {
    LoadNotesEnd = '[Notes] Load Notes End',
    LoadNotesStart = '[Notes] Start loading notes',
    RemoveNoteSuccess = '[Notes] Remove Note Success',
    RemoveNotesFailed = '[Notes] Remove Note Failed',
    RemoveNotes = '[Notes] Remove Notes',
    FilterNotes = '[Notes] Filter',
    ShowEditorMenu = '[Notes] Show Editor Menu',
    HideEditorMenu = '[Notes] Hide Editor Menu',
    UpdateNote = '[Notes] Update Note',
    UpdateNoteSuccess = '[Notes] Update Note Success',
    UpdateNoteFailed = '[Notes] Update Note Failed',
    CreateNote = '[Notes] Create Note',
    CreateNoteSuccess = '[Notes] Create Note Success',
    AddNoteToStore = '[Note] Add Note To Store',
    RemoveNoteFromStore = '[Note] Remove Note From Store',
    UpdateNoteInStore = '[Note] Update Note In Store',
}

export class ActionLoadNotesStart implements Action {
    readonly type = NotesActionTypes.LoadNotesStart;
}

export class ActionLoadNotesEnd implements Action {
    readonly type = NotesActionTypes.LoadNotesEnd;
}

export class ActionRemoveNoteSuccess implements Action {
    readonly type = NotesActionTypes.RemoveNoteSuccess;

    constructor(public payload: { ids: string[] }) {}
}

export class ActionRemoveNotesFailed implements Action {
    readonly type = NotesActionTypes.RemoveNotesFailed;

    constructor(public payload: { error: any }) {}
}

export class ActionRemoveNotes implements Action {
    readonly type = NotesActionTypes.RemoveNotes;

    constructor(public payload: { ids: string[] }) {}
}

export class ActionFilterNotes implements Action {
    readonly type = NotesActionTypes.FilterNotes;

    constructor(public payload: { filter: NotesFilter }) {}
}

export class ActionShowEditorMenu implements Action {
    readonly type = NotesActionTypes.ShowEditorMenu;

    constructor(public payload?: { noteId?: string }) {}
}

export class ActionHideEditorMenu implements Action {
    readonly type = NotesActionTypes.HideEditorMenu;
}

export class ActionUpdateNote implements Action {
    readonly type = NotesActionTypes.UpdateNote;

    constructor(public payload: { update: Update<Note> }) {}
}

export class ActionUpdateNoteSuccess implements Action {
    readonly type = NotesActionTypes.UpdateNoteSuccess;

    constructor(public payload: { update: Update<Note> }) {}
}

export class ActionUpdateNoteFailed implements Action {
    readonly type = NotesActionTypes.UpdateNoteFailed;
    constructor(public payload: { error: any }) {}
}

export class ActionCreateNote implements Action {
    readonly type = NotesActionTypes.CreateNote;

    constructor(public payload: { note: Partial<Note> }) {}
}

export class ActionCreateNoteSuccess implements Action {
    readonly type = NotesActionTypes.CreateNoteSuccess;

    constructor(public payload: { note: Note }) {}
}

export class ActionAddNoteToStore implements Action {
    readonly type = NotesActionTypes.AddNoteToStore;
    constructor(public payload: { note: Note }) {}
}

export class ActionRemoveNoteFromStore implements Action {
    readonly type = NotesActionTypes.RemoveNoteFromStore;
    constructor(public payload: { note: Note }) {}
}

export class ActionUpdateNoteInStore implements Action {
    readonly type = NotesActionTypes.UpdateNoteInStore;
    constructor(public payload: { note: Note }) {}
}

export type NotesActions =
    | ActionLoadNotesEnd
    | ActionLoadNotesStart
    | ActionCreateNoteSuccess
    | ActionRemoveNoteSuccess
    | ActionRemoveNotes
    | ActionRemoveNotesFailed
    | ActionFilterNotes
    | ActionShowEditorMenu
    | ActionHideEditorMenu
    | ActionUpdateNote
    | ActionUpdateNoteSuccess
    | ActionUpdateNoteFailed
    | ActionCreateNote
    | ActionAddNoteToStore
    | ActionRemoveNoteFromStore
    | ActionUpdateNoteInStore;
