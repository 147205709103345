import { Action } from '@ngrx/store';
import {
    Assessment,
    AssessmentViewPayload,
    AssessmentNavigationType,
    AssessmentNavigationSubType
} from './assessment.model';

export enum AssessmentActionTypes {
    SetSelectedAssessmentId = '[Assessment] Set Selected AssessmentId',
    SetSelectedCriterionId = '[Assessment] Set Selected CriterionId',
    LoadAssessment = '[Assessment] Load Assessment',
    LoadAssessmentSuccess = '[Assessment] Load Assessment Success',
    LoadAssessmentFailed = '[Assessment] Load Assessment Error',
    NavigateToAssessmentView = '[Assessment] Navigate To Assessment View',
    NavigateToSelectedAssessmentZeroState = '[Assessment] Navigate To Selected Assessment ZeroState',
    SendAssessmentOpenedTelemetryEvent = '[Assessment] Send Assessment Opened Telemetry Event',
}

export class ActionSetSelectedAssessmentId implements Action {
    readonly type = AssessmentActionTypes.SetSelectedAssessmentId;
    constructor(public  payload: { assessmentId: string }) {}
}

export class ActionSetSelectedCriterionId implements Action {
    readonly type = AssessmentActionTypes.SetSelectedCriterionId;
    constructor(public  payload: { criterionId: string }) {}
}

export class ActionLoadAssessment implements Action {
    readonly type = AssessmentActionTypes.LoadAssessment;
    constructor(public payload: { assessmentId: string, programId?: string }) {}
}

export class ActionLoadAssessmentSuccess implements Action {
    readonly type = AssessmentActionTypes.LoadAssessmentSuccess;
    constructor(public payload: { assessment: Assessment }) {}
}

export class ActionLoadAssessmentFailed implements Action {
    readonly type = AssessmentActionTypes.LoadAssessmentFailed;
    constructor(public payload: { error: any }) {}
}

export class ActionNavigateToAssessmentView implements Action {
    readonly type = AssessmentActionTypes.NavigateToAssessmentView;
    constructor(public payload: { assessment: AssessmentViewPayload }) {}
}

export class ActionNavigateToSelectedAssessmentZeroState implements Action {
    readonly type = AssessmentActionTypes.NavigateToSelectedAssessmentZeroState;
}

export class ActionSendAssessmentOpenedTelemetryEvent implements Action {
    readonly type = AssessmentActionTypes.SendAssessmentOpenedTelemetryEvent;
    constructor(public payload: {
        assessmentId: string,
        navigationType: AssessmentNavigationType,
        navigationSubType?: AssessmentNavigationSubType
    }) {}
}

export type AssessmentActions =
    | ActionSetSelectedAssessmentId
    | ActionSetSelectedCriterionId
    | ActionLoadAssessment
    | ActionLoadAssessmentSuccess
    | ActionLoadAssessmentFailed
    | ActionNavigateToSelectedAssessmentZeroState
    | ActionNavigateToAssessmentView
    | ActionSendAssessmentOpenedTelemetryEvent;
