import { Pipe, PipeTransform } from '@angular/core';
import { SearchResultCategory } from 'src/app/core/search/store/search.model';

@Pipe({ name: 'searchResultIcon' })
export class SearchResultIconPipe implements PipeTransform {
    transform(searchResultType: SearchResultCategory) {
        switch (searchResultType) {
            case SearchResultCategory.Media:
                return 'icon-media_library';
            case SearchResultCategory.Note:
                return 'icon-PDF_alt';
            default:
                return '';
        }
    }
}
