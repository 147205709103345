import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouterState } from '../../core.state';
import { Media, MediaFilter, MediaState, MediaType, State } from './media.model';
import { selectAll } from './media.reducer';

export const selectMediaState = createFeatureSelector<State, MediaState>('media');

export const selectMediaFilter = createSelector(
    selectMediaState,
    state => state.filter
);

export const selectAllMedia = createSelector(
    selectMediaState,
    selectAll
);

export const selectIsLoading = createSelector(
    selectMediaState,
    state => state.isLoading
);

export const selectAllMediaByClass = (classId: string) =>
    createSelector(
        selectAllMedia,
        allMedia => allMedia.filter(media => media.classId === classId)
            .sort((a, b) => b.updatedAt - a.updatedAt)
    );

export const selectFilteredMediaByClass = (classId: string) =>
    createSelector(
        selectAllMediaByClass(classId),
        selectMediaFilter,
        (allMedia, filter) => {
            let result: Media[];
            switch (filter) {
                case MediaFilter.Images:
                    result = allMedia.filter(media => media.type === MediaType.Image);
                    break;
                case MediaFilter.Videos:
                    result = allMedia.filter(media => media.type === MediaType.Video);
                    break;
                case MediaFilter.AllMedia:
                default:
                    result = allMedia;
            }
            return result;
        }
    );

export const selectMediaById = (id: string) =>
    createSelector(
        selectAllMedia,
        allMedia => allMedia.find(media => media.id === id && !media.deleted)
    );

export const selectSelectedMediaId = createSelector(
    selectMediaState,
    state => state.selectedMediaId
);

export const selectMediaIdFromRouterState = createSelector(
    selectRouterState,
    router => router && router.state.params.mediaId
);

export const selectFilteredMediaByStudent = (studentId: string) =>
    createSelector(
        selectAllMedia,
        selectMediaFilter,
        (allMedia, filter) => {
            let result: Media[];
            switch (filter) {
                case MediaFilter.StudentMedia:
                    result = allMedia.filter(media => {
                        return media.taggedStudents.includes(studentId);
                    });
                    break;
                case MediaFilter.AllMedia:
                default:
                    result = allMedia;
            }
            return result.sort((a, b) => b.updatedAt - a.updatedAt);
        }
    );

export const selectMediaForSearchTerm = (searchTerm: string) =>
    createSelector(
        selectAllMedia,
        allMedia => allMedia.filter(media => media.title.toLowerCase().includes(searchTerm.toLowerCase()))
    );

export const selectMediaForRecentActivity = (classId: string, numberOfResults: number) =>
    createSelector(
        selectAllMediaByClass(classId),
        allMedia => {
            allMedia.sort((a, b) => b.updatedAt - a.updatedAt);
            return allMedia.slice(0, numberOfResults);
        }
    );
