import { Pipe, PipeTransform } from '@angular/core';
import { ObservationType } from '../../core/observations/store/observations.model';

@Pipe({ name: 'observationLabel' })
export class ObservationLabelPipe implements PipeTransform {
    transform(observationType: ObservationType) {
        switch (observationType) {
            case ObservationType.Checklist:
                return 'dashboard-assessment-list.assessmentTypeLabels.checklist';
            case ObservationType.Fluency:
                return 'dashboard-assessment-list.assessmentTypeLabels.fluency';
            case ObservationType.Ongoing:
                return 'dashboard-assessment-list.assessmentTypeLabels.ongoing';
            default:
                return '';
        }
    }
}
