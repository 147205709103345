import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MenuController } from '@ionic/angular';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import sha256 from 'crypto-js/sha256';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentUser } from '../../auth/store/auth.selectors';
import { AppState, selectRouterState } from '../../core.state';
import { SearchDataService } from '../search.data.service';
import {
    ActionCloseSearchMenu,
    ActionLoadSearchRecordsStart,
    ActionOpenSearchMenu,
    ActionUpsertSearchRecord,
    ActionUpsertSearchRecordFailed,
    ActionUpsertSearchRecordSuccess,
    SearchActionTypes
} from './search.actions';
import { SearchRecord } from './search.model';
import { selectIsSearchMenuOpen } from './search.selectors';

@Injectable()
export class SearchEffects {

    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private afsAuth: AngularFireAuth,
        private afsDb: AngularFirestore,
        private searchDataService: SearchDataService,
        private menuController: MenuController,
    ) {}

    @Effect()
    loadSearches$ = this.afsAuth.authState.pipe(
        filter(user => !!user),
        map(user => user.uid),
        tap(() => this.store$.dispatch(new ActionLoadSearchRecordsStart())),
        switchMap(userId => this.searchDataService.fetchSearches(userId))
    );

    @Effect()
    upsertSearch$ = this.actions$.pipe(
        ofType<ActionUpsertSearchRecord>(SearchActionTypes.UpsertSearchRecord),
        map(action => action.payload.searchTerm),
        withLatestFrom(
            this.store$.pipe(
                select(selectCurrentUser),
                filter(currentUser => !!currentUser),
                map(currentUser => currentUser.userId),
            )
        ),
        switchMap(([searchTerm, userId]) => {
            const timestamp = Date.now();
            const searchRecord: SearchRecord = {
                id: sha256(userId + searchTerm).toString(),
                createdBy: userId,
                updatedAt: timestamp,
                searchTerm,
            };
            return this.searchDataService.upsertSearch(searchRecord).pipe(
                map(() => new ActionUpsertSearchRecordSuccess({ searchRecord })),
                catchError(error => of(new ActionUpsertSearchRecordFailed({ error })))
            );
        })
    );

    @Effect({ dispatch: false })
    openSearchMenu$ = this.actions$.pipe(
        ofType<ActionOpenSearchMenu>(SearchActionTypes.OpenSearchMenu),
        tap(() => {
            this.menuController.enable(true, 'searchMenu').then(() => {
                this.menuController.open('searchMenu');
            });
        })
    );

    @Effect({ dispatch: false })
    closeSearchMenu$ = this.actions$.pipe(
        ofType<ActionCloseSearchMenu>(SearchActionTypes.CloseSearchMenu),
        tap(() => {
            this.menuController.close('searchMenu');
        })
    );

    @Effect({ dispatch: false })
    closeSearchMenuOnRouteChange$ = this.store$.pipe(
        select(selectRouterState),
        withLatestFrom(
            this.store$.pipe(
                select(selectIsSearchMenuOpen),
                filter(isSearchMenuOpen => !!isSearchMenuOpen)
            )
        ),
        tap(([, isSearchMenuOpen]) => {
            if (isSearchMenuOpen) {
                this.store$.dispatch(new ActionCloseSearchMenu());
            }
        })
    );
}
