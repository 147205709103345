import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { CommentsActions, CommentsActionTypes } from './comments.actions';
import { Comment, CommentsState } from './comments.model';

export const adapter: EntityAdapter<Comment> = createEntityAdapter<Comment>();
export const initialState: CommentsState = adapter.getInitialState({
    isLoading: false
});
export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

export function reducer(state = initialState, action: CommentsActions) {
    switch (action.type) {
        case CommentsActionTypes.LoadComments:
            return adapter.upsertMany(action.payload.comments, { ...state, isLoading: false });
        case CommentsActionTypes.LoadCommentsStart:
            return { ...state, isLoading: true };
        case CommentsActionTypes.AddCommentToStore:
            return adapter.addOne(action.payload.comment, state);
        case CommentsActionTypes.DeleteCommentFromStore:
            return adapter.removeOne(action.payload.comment.id, state);
        default:
            return state;
    }
}
