import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { ClassExistsGuard } from './classExists.guard';
import { AuthDataService } from './auth.data.service';

@NgModule({
    declarations: [],
    imports: [AngularFireAuthModule, CommonModule],
    providers: [AngularFireAuth, AuthGuard, ClassExistsGuard, AuthService, AuthDataService]
})
export class AuthModule {}
