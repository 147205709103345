import { Component, Input, OnInit } from '@angular/core';
import { AssessmentService } from 'src/app/core/assessment/assessment.service';
import { AssessmentNavigationType, AssessmentNavigationSubType } from 'src/app/core/assessment/store/assessment.model';
import { DashboardService } from 'src/app/core/dashboard/dashboard.service';
import { ProgramItem } from 'src/app/core/program/store/program.model';
import { HIERARCHY_PADDING } from '../../common/constants';

@Component({
    selector: 'app-dashboard-assessment-hierarchy-node',
    templateUrl: './dashboard-assessment-hierarchy-node.component.html',
    styleUrls: ['./dashboard-assessment-hierarchy-node.component.scss']
})
export class DashboardAssessmentHierarchyNodeComponent implements OnInit {
    @Input() assessment: ProgramItem;
    @Input() hierarchyLevel: number;

    constructor(
        private assessmentService: AssessmentService,
        private dashboardService: DashboardService,
    ) {}

    ngOnInit() {}

    get hierarchyPadding() {
        return this.hierarchyLevel * HIERARCHY_PADDING + 'em'; // having a constant value of 10px for padding
    }

    onAssessmentClick(assessmentId: string) {
        this.dashboardService.closeDashboard();
        this.assessmentService.sendAssessmentOpenedTelemetryEvent(
            assessmentId,
            AssessmentNavigationType.AssessmentMenu,
            AssessmentNavigationSubType.ViewByTOC,
        );
    }
}
