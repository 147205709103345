import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'media-root',
    templateUrl: './media-root.component.html',
    styleUrls: ['./media-root.component.scss']
})
export class MediaRootComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
