import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const APP_KEY = `${environment.appId}-`;

@Injectable()
export class SessionStorageService {
    constructor() {}

    static loadAllState() {
        return Object.keys(sessionStorage).reduce((state: any, storageKey) => {
            if (storageKey.includes(APP_KEY)) {
                const stateKeys = storageKey
                    .replace(APP_KEY, '')
                    .toLowerCase()
                    .split('.')
                    .map(key =>
                        key
                            .split('-')
                            .map((token, index) =>
                                index === 0
                                    ? token
                                    : token.charAt(0).toUpperCase() +
                                    token.slice(1)
                            )
                            .join('')
                    );
                let currentStateRef = state;
                stateKeys.forEach((key, index) => {
                    if (index === stateKeys.length - 1) {
                        currentStateRef[key] = JSON.parse(
                            sessionStorage.getItem(storageKey)
                        );
                        return;
                    }
                    currentStateRef[key] = currentStateRef[key] || {};
                    currentStateRef = currentStateRef[key];
                });
            }
            return state;
        }, {});
    }

    setItem(key: string, value: any) {
        sessionStorage.setItem(`${APP_KEY}${key}`, JSON.stringify(value));
    }

    getItem(key: string) {
        return JSON.parse(sessionStorage.getItem(`${APP_KEY}${key}`));
    }

    removeItem(key: string) {
        sessionStorage.removeItem(`${APP_KEY}${key}`);
    }

    clear() {
        Object.keys(sessionStorage).forEach(key => {
            if (key.includes(APP_KEY)) {
                sessionStorage.removeItem(key);
            }
        });
    }
}
