import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { ErrorService } from 'src/app/core/error/error.service';
import { Logger } from 'src/app/core/logging/logger';
import { selectRbsCredentials } from '../auth/store/auth.selectors';
import { selectCurrentClassRoster, selectSelectedClassRosterId } from '../class-roster/store/class-roster.selectors';
import { AppState, selectRouterState } from '../core.state';
import { ObservationsService } from '../observations/observations.service';
import { ProgramService } from '../program/program.service';
import { selectCurrentProgram } from '../program/store/program.selectors';
import { AssessmentDataService } from './assessment.data.service';
import {
    ActionLoadAssessment,
    ActionNavigateToSelectedAssessmentZeroState,
    ActionSendAssessmentOpenedTelemetryEvent,
    ActionSetSelectedAssessmentId,
    ActionSetSelectedCriterionId
} from './store/assessment.actions';
import {
    selectAssessmentById,
    selectSelectedAssessmentId,
    selectSelectedCriterionId
} from './store/assessment.selectors';
import { AssessmentItem, AssessmentNavigationSubType, AssessmentNavigationType } from './store/assessment.model';

@Injectable({ providedIn: 'root' })
export class AssessmentService {
    assessmentIsValid$ = combineLatest(
        this.store.pipe(select(selectSelectedAssessmentId)),
        this.store.pipe(select(selectCurrentClassRoster)),
        this.store.pipe(select(selectCurrentProgram))
    ).pipe(
        filter(
            ([assessmentId, selectedClass, selectedProgram]) => !!assessmentId && !!selectedClass && !!selectedProgram
        ),
        map(([assessmentId, , selectedProgram]) =>
            this.programService.assessmentExistInProgram(assessmentId, selectedProgram)
        )
    );

    selectSelectedCriterionId$ = this.store.pipe(select(selectSelectedCriterionId));

    selectAssessmentIdFromRouterState$ = this.store.pipe(
        select(selectRouterState),
        map(router => router.state.params.assessmentId)
    );

    selectedCriterionIdFromRouterState$ = this.store.pipe(
        select(selectRouterState),
        map(router => router.state.queryParams.criterionId)
    );

    constructor(
        private store: Store<AppState>,
        private programService: ProgramService,
        private assessmentDataService: AssessmentDataService,
        private observationsService: ObservationsService,
        private errorService: ErrorService,
    ) {}

    loadAssessment(assessmentId: string) {
        this.store.dispatch(new ActionLoadAssessment({ assessmentId }));
    }

    getAssessmentsForSearchTerm(searchTerm: string): Observable<AssessmentItem[]> {
        return combineLatest(
            this.store.pipe(select(selectRbsCredentials)),
            this.store.pipe(select(selectSelectedClassRosterId))
        ).pipe(
            filter(([creds, classId]) => !!creds && !!classId),
            switchMap(([{ userId, token }, classId]) =>
                this.assessmentDataService.fetchAssessmentItems(searchTerm, classId, userId, token)),
            switchMap(assessments => forkJoin(
                assessments.map(assessment =>
                    this.observationsService.fetchLatestObservationForAssessment(assessment))
                )
            ),
            switchMap(assessments => forkJoin(
                assessments.map((assessment: AssessmentItem) =>
                    this.programService.fetchProgramItemForAssessment(assessment))
            )),
            map(assessments => assessments.filter(assessment => !!assessment)),
            catchError(error => {
                if (!(error.status && error.status === 404)) {
                    const description = {
                        origin: 'Search Assessments',
                        payload: { searchTerm },
                        message: error,
                    };
                    this.errorService.processError(description, false);
                }
                return of([]);
            }),
            first()
        );
    }

    getAssessment(assessmentId: string, programId: string) {
        return this.store.pipe(
            select(selectAssessmentById(assessmentId)),
            tap((assessment) => {
                if (!assessment) {
                    this.store.dispatch(new ActionLoadAssessment({ assessmentId, programId }));
                }
            }),
            filter((assessment) => !!assessment));
    }

    navigateToSelectedAssessmentZeroState() {
        this.store.dispatch(new ActionNavigateToSelectedAssessmentZeroState());
    }

    setSelectedAssessmentId(assessmentId = '') {
        this.store.dispatch(new ActionSetSelectedAssessmentId({ assessmentId }));
    }

    setSelectedCriterionId(criterionId?: string) {
        this.store.dispatch(new ActionSetSelectedCriterionId({ criterionId }));
    }

    getAssessmentRoute(type: string, assessmentId: string, studentId?: string, criterionId?: string) {
        if (criterionId) {
            return studentId ? `/assess/${type}/${assessmentId}/student/${studentId}?criterionId=${criterionId}` :
                `/assess/${type}/${assessmentId}`;
        } else {
            return studentId ? `/assess/${type}/${assessmentId}/student/${studentId}` :
                `/assess/${type}/${assessmentId}`;
        }
    }

    sendAssessmentOpenedTelemetryEvent(
        assessmentId: string,
        navigationType: AssessmentNavigationType,
        navigationSubType?: AssessmentNavigationSubType
    ) {
        this.store.dispatch(new ActionSendAssessmentOpenedTelemetryEvent({
            assessmentId,
            navigationType,
            navigationSubType
        }));
    }
}
