import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import {
    Action,
    ActionReducerMap,
    createFeatureSelector,
    MetaReducer
} from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from 'src/environments/environment';
import { authReducer } from './auth/store/auth.reducer';
import { AuthState } from './auth/store/auth.model';
import { debug } from './meta-reducers/debug.reducer';
import { initStateFromSessionStorage } from './meta-reducers/init-state-from-storage.reducer';
import { RouterStateUrl } from './router/router.state';

export const reducers: ActionReducerMap<AppState> = {
    auth: authReducer,
    router: routerReducer
};

export const metaReducers: MetaReducer<AppState>[] = [
    initStateFromSessionStorage
];
if (!environment.production) {
    metaReducers.unshift(storeFreeze);
    if (!environment.test) {
        metaReducers.unshift(debug);
    }
}

export const selectAuthState = createFeatureSelector<AppState, AuthState>(
    'auth'
);

export const selectRouterState = createFeatureSelector<
    AppState,
    RouterReducerState<RouterStateUrl>
>('router');

export interface AppState {
    auth: AuthState;
    router: RouterReducerState<RouterStateUrl>;
}

export class ActionDoNothing implements Action {
    readonly type = 'DO_NOTHING';
}
