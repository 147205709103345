import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { RbsTokenResponse, JwtResponse, AuthenticationResponse } from './store/auth.model';
import { environment } from 'src/environments/environment';
import { Logger } from '../logging/logger';
import { map, catchError } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({ providedIn: 'root' })
export class AuthDataService {
    constructor(private http: HttpClient, private logger: Logger, private angularFireAuth: AngularFireAuth) {}

    authenticateTicket(ticket: string, serviceUrl: string): Observable<AuthenticationResponse> {
        this.logger.info('authenticate ticket', { ticket, serviceUrl });

        const requestParams = {
            serviceUrl,
            includeLicensedProducts: 'true',
            authContextId: environment.authContextId,
            token: ticket
        };
        const loginServiceUrl = `${environment.authGatewayUrl}/account/v2/login`;
        const request = this.http.post<{ data: AuthenticationResponse }>(loginServiceUrl, requestParams).pipe(
            map(({ data }) => data),
            catchError(err => throwError(err))
        );

        return request;
    }

    getRbsToken(userId: string, castgc: string): Observable<RbsTokenResponse> {
        const tokenSvcUrl = `${environment.authGatewayUrl}/oauth/token`;
        const params = {
            userId,
            ...environment.rbsConfig
        };
        const headers = new HttpHeaders({
            castgc
        });

        return this.http.post<RbsTokenResponse>(tokenSvcUrl, params, {
            headers
        });
    }

    checkRbsTtl(rbsToken: string): Observable<RbsTokenResponse> {
        const url = `${environment.authGatewayUrl}/oauth/token/ttl`;
        const headers = new HttpHeaders({
            access_token: rbsToken
        });

        return this.http.get<RbsTokenResponse>(url, { headers });
    }

    resetRbsTtl(rbsToken: string): Observable<RbsTokenResponse> {
        const url = `${environment.authGatewayUrl}/oauth/token/reset_ttl`;
        const headers = new HttpHeaders({
            access_token: rbsToken
        });

        return this.http.put<RbsTokenResponse>(url, null, { headers });
    }

    getJwt(userId: string, rbsToken: string): Observable<JwtResponse> {
        const url = `${environment.api.jwt}`;
        const headers = new HttpHeaders({
            userId,
            Authorization: rbsToken
        });
        return this.http.get<JwtResponse>(url, { headers });
    }

    firebaseLogin(jwt: string): Observable<firebase.auth.UserCredential> {
        const promise = this.angularFireAuth.auth.setPersistence('session')
            .then(() => this.angularFireAuth.auth.signInWithCustomToken(jwt));
        return from(promise);
    }

    firebaseLogout(): Promise<void> {
        return this.angularFireAuth.auth.signOut();
    }
}
