import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MediaListComponent } from './pages/media-list/media-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MediaMenuComponent } from './components/media-menu/media-menu.component';
import { MediaViewComponent } from './pages/media-view/media-view.component';
import { MediaRootComponent } from './pages/media-root/media-root.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        MediaListComponent,
        MediaRootComponent,
        MediaMenuComponent,
        MediaViewComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        IonicModule,
        RouterModule
    ],
    exports: [MediaMenuComponent]
})
export class MediaModule {}
