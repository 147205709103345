import { Action } from '@ngrx/store';
import { Comment } from './comments.model';

export enum CommentsActionTypes {
    LoadCommentsStart = '[Comments] Start Loading Comments',
    LoadComments = '[Comments] Load Comments',

    AddCommentToStore = '[Comments] Add Comment To Store',
    DeleteCommentFromStore = '[Comments] Delete Comment From Store'
}

export class ActionLoadCommentsStart implements Action {
    readonly type = CommentsActionTypes.LoadCommentsStart;
}

export class ActionLoadComments implements Action {
    readonly type = CommentsActionTypes.LoadComments;
    constructor(public payload: { comments: Comment[] }) {}
}

export class ActionAddCommentToStore implements Action {
    readonly type = CommentsActionTypes.AddCommentToStore;
    constructor(public payload: { comment: Comment }) {}
}

export class ActionDeleteCommentFromStore implements Action {
    readonly type = CommentsActionTypes.DeleteCommentFromStore;
    constructor(public payload: { comment: Comment }) {}
}

export type CommentsActions =
    | ActionLoadCommentsStart
    | ActionLoadComments
    | ActionAddCommentToStore
    | ActionDeleteCommentFromStore;
