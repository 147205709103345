import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UserProfile } from './user-profile.model';
import {
    UserProfileActions,
    UserProfileActionTypes
} from './user-profile.actions';

export interface State extends EntityState<UserProfile> {
    selectedUserId: string;
    isLoading: boolean;
}

export function sortByLastName(a: UserProfile, b: UserProfile): number {
    return a.rumbaUser.lastName.localeCompare(b.rumbaUser.lastName);
}

export function selectUserId(u: UserProfile): string {
    return u.rumbaUser.userId;
}

export const adapter: EntityAdapter<UserProfile> = createEntityAdapter<
    UserProfile
>({
    selectId: selectUserId,
    sortComparer: sortByLastName
});

export const initialState: State = adapter.getInitialState({
    selectedUserId: null,
    isLoading: false,
});

export function reducer(
    state: State = initialState,
    action: UserProfileActions
): State {
    switch (action.type) {
        case UserProfileActionTypes.BULK_LOAD:
        case UserProfileActionTypes.LOAD_PROFILE:
            return { ...state, isLoading: true };
        case UserProfileActionTypes.BULK_LOAD_SUCCESS:
            return adapter.addMany(action.payload.profiles, { ...state, isLoading: false });
        case UserProfileActionTypes.LOAD_PROFILE_SUCCESS:
            return adapter.addOne(action.payload.profile, { ...state, isLoading: false });
        default:
            return state;
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
