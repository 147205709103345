<div class="flex-container-col">
    <div class="card-header">
        <a
            routerLink="/reports/observation"
            (click)="closeFilterMenu()"
            class="link-back"
            [title]="'global.navigation-back' | translate">
            <span class="label-icon icon-chevron" aria-hidden="true"></span>
        </a>
        <ion-buttons class="buttons-container">
            <ion-label class="filter-label">{{ 'reports-observation.toolbar-filter-title' | translate }}</ion-label>
            <ion-button
                [disabled]="isApplyButtonDisabled"
                mode="ios"
                class="button--apply"
                (click)="applyFilters()">
                <ion-label>{{ 'reports-observation.toolbar-apply-title' | translate }}</ion-label>
            </ion-button>
        </ion-buttons>
    </div>
    <app-observation-filter-form
        class="filter-options"
        [observationReportFilters]="selectedFilters"
        (notifyFilterChange)="onFilterChange($event)">
    </app-observation-filter-form>
</div>
