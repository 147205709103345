import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SearchService } from './search.service';
import { SearchTelemetryEffects } from './store/search-telemetry.effects';
import { SearchEffects } from './store/search.effects';
import { reducer } from './store/search.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('searches', reducer),
        EffectsModule.forFeature([SearchEffects, SearchTelemetryEffects])
    ],
    providers: [SearchService]
})
export class SearchModule {}
