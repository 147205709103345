import { Component, OnInit } from '@angular/core';
import { MediaService } from 'src/app/core/media/media.service';

@Component({
    selector: 'media-view',
    templateUrl: './media-view.component.html',
    styleUrls: ['./media-view.component.scss']
})
export class MediaViewComponent implements OnInit {

    constructor(private mediaService: MediaService) {
    }

    ngOnInit() {
        this.mediaService.openMediaMenu();
    }

}
