import { LogEntry, LogLevel } from './logEntry';

export interface ILogWriter {
    writeLog(entry: LogEntry): void;
}

export class ConsoleLogWriter implements ILogWriter {
    writeLog(entry: LogEntry) {
        entry.stringifyParams = false;
        const consoleMethod =
            entry.level === LogLevel.ALL
                ? 'log'
                : LogLevel[entry.level].toLowerCase();
        console[consoleMethod](entry.toString(), ...entry.params);
    }
}
