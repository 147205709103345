import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/dashboard.reducer';
import { DashboardEffects } from './store/dashboard.effects';
import { DashboardService } from './dashboard.service';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('dashboard', reducer), EffectsModule.forFeature([DashboardEffects])],
    providers: [DashboardService]
})
export class DashboardModuleNgrx {}
