import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { selectSelectedClassRosterId } from 'src/app/core/class-roster/store/class-roster.selectors';
import { AppState } from 'src/app/core/core.state';
import { ActionSendReportOpenedTelemetryEvent, ReportsActionTypes } from 'src/app/core/reports/store/reports.actions';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';

@Injectable()
export class ReportsTelemetryEffects {

    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private telemetryService: TelemetryService,
    ) {}

    @Effect({ dispatch: false })
    openedReport = this.actions$.pipe(
        ofType<ActionSendReportOpenedTelemetryEvent>(ReportsActionTypes.SendReportOpenedTelemetryEvent),
        map(action => action.payload.reportType),
        withLatestFrom(this.store$.pipe(select(selectSelectedClassRosterId))),
        tap(([reportType, classId]) => {
             const details = {
                 section: { id: classId },
                 reportType,
             };
             this.telemetryService.sendActivity('opened', 'Reports', details);
        })
    );
}
