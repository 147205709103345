import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StudentsTelemetryEffects } from './store/students-telemetry.effects';
import { StudentsEffects } from './store/students.effects';
import { StudentsService } from './students.service';
import { reducer } from './store/students.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('students', reducer),
        EffectsModule.forFeature([StudentsEffects, StudentsTelemetryEffects])
    ],
    providers: [StudentsService, AngularFireStorage]
})
export class StudentsModule {}
