import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, ProductState, ProgramSkeleton } from './product.model';
import { selectAll } from './product.reducer';

export const selectProductState = createFeatureSelector<State, ProductState>('product');

export const selectProductsByIds = (productIds: string[]) => {
    return createSelector(
        selectProductState,
        state => {
            return productIds.map(productId => {
                return state.entities[productId];
            });
        }
    );
};

export const selectAllProducts = createSelector(
    selectProductState,
    selectAll
);

export const selectIsLoadingProducts = createSelector(
    selectProductState,
    state => state.isLoadingProducts
);

export const getProductFromProgramId = (programId: string) => {
    return createSelector(
        selectAllProducts,
        products => {
            return products.find(product => {
                const programExists = product.programs.find(programSkeleton => {
                    return programSkeleton.rootProgramIdentifier === programId;
                });
                return !!programExists;
            });
        }
    );
};

export const getProgramSkeleton = (programId: string) => {
    return createSelector(
        selectAllProducts,
        products => {
            let programSelected: ProgramSkeleton;
            products.forEach(element => {
                const programExist = element.programs.find(program => {
                    return program.rootProgramIdentifier === programId;
                });
                if (programExist) {
                    programSelected = programExist;
                }
                return !!programExist;
            });
            return programSelected;
        }
    );
};
