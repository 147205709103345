import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/service-worker.reducers';
import { ServiceWorkerEffects } from './store/service-worker.effects';
import { ServiceWorkerService } from './service-worker.service';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('serviceWorker', reducer),
        EffectsModule.forFeature([ServiceWorkerEffects])
    ],
    providers: [ServiceWorkerService]
})
export class ServiceWorkerModule {}
