<form>
    <ul class="observation-filter-form">
        <li class="form__item">
            <div class="form__item--content">
                <label class="form__label">
                    {{ 'reports-observation.form.assessment-filter-label' | translate }}
                </label>
                <lst-dropdown
                    class="dropdown"
                    [valueToDisplay]="selectedObservationTypeFilter"
                    has-preselected-value="true"
                    [values]="observationTypeFilterOptions"
                    (onDropdownItemSelected)="onFilterChange($event.detail.item, FilterType.Observation)"
                    #viewDropdown
                >
                </lst-dropdown>
            </div>
        </li>
        <li class="form__item">
            <div class="form__item--content">
                <label class="form__label">
                    {{ 'reports-observation.form.range-filter-label' | translate }}
                </label>
                <lst-dropdown
                    class="dropdown"
                    [valueToDisplay]="selectedRangeFilter"
                    has-preselected-value="true"
                    [values]="rangeFilterOptions"
                    (onDropdownItemSelected)="onFilterChange($event.detail.item, FilterType.Range)"
                    #rangeDropdown
                >
                </lst-dropdown>
            </div>
        </li>
        <li class="form__item">
            <div class="form__item--content">
                <label class="form__label">
                    {{ 'reports-observation.form.from-filter-label' | translate }}
                </label>
                <ion-item lines="none" class="date-picker">
                    <ion-datetime
                        class="date-input"
                        display-format="DDDD, MMM DD, YYYY"
                        min="{{ minDate }}"
                        max="{{ maxDate }}"
                        [value]="fromFilterISO"
                        disabled="{{ !isCustomRangeSelected }}"
                        [placeholder]="'reports-observation.datetime.placeholder' | translate"
                        (ionChange)="onFilterChange($event, FilterType.From)"
                    >
                    </ion-datetime>
                </ion-item>
            </div>
        </li>
        <li class="form__item" [ngClass]="{ 'form__item--alert': showDateAlert }">
            <div class="form__item--content">
                <label class="form__label">
                    {{ 'reports-observation.form.to-filter-label' | translate }}
                </label>
                <ion-item lines="none" class="date-picker">
                    <ion-datetime
                        class="date-input"
                        display-format="DDDD, MMM DD, YYYY"
                        min="{{ minDate }}"
                        max="{{ maxDate }}"
                        [value]="toFilterISO"
                        disabled="{{ !isCustomRangeSelected }}"
                        [placeholder]="'reports-observation.datetime.placeholder' | translate"
                        (ionChange)="onFilterChange($event, FilterType.To)"
                    >
                    </ion-datetime>
                </ion-item>
            </div>
        </li>
        <li class="form__item form__item--date-alert" *ngIf="showDateAlert">
            <div class="form__item--content form__item--content-alert">
                <ion-label>{{ 'reports-observation.form.invalid-date' | translate }}</ion-label>
            </div>
        </li>
        <li class="form__item">
            <div class="form__item--content">
                <label class="form__label">
                    {{ 'reports-observation.form.sort-by-filter-label' | translate }}
                </label>
                <lst-dropdown
                    class="dropdown"
                    [valueToDisplay]="selectedSortByFilter"
                    has-preselected-value="true"
                    [values]="sortByFilterOptions"
                    (onDropdownItemSelected)="onFilterChange($event.detail.item, FilterType.SortBy)"
                    #sortByDropdown
                >
                </lst-dropdown>
            </div>
        </li>
    </ul>
</form>
