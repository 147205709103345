<ion-header>
    <ion-toolbar class="header__toolbar" mode="md">
        <div class="header__logo" slot="start">
            <a
                [routerLink]="['/dashboard']"
                class="header__logo__img-link"
                [title]="'app-header.logo-button' | translate"
            >
                <picture>
                    <source [srcset]="scoutLogoUrl" media="(min-width: 601px)">
                    <source [srcset]="scoutLogoUrlSmall">
                    <img [src]="scoutLogoUrl" [alt]="'app-header.logo' | translate">
                </picture>
            </a>
        </div>
        <div class="toolbar__content">
            <ion-title class="header__title"><h1>{{ title$ | async }}</h1></ion-title>
            <app-class-select
                class="class-select"
                (selectionChange)="onClassSelectionChange($event)"
                [selectedClassId]="selectedClassId$ | async"
                [classList]="classList$ | async"
                *ngIf="!!(selectedClassId$ | async) && !(isUnrecoverableErrorMode$ | async)"
                cssClassName="class-select--header"
                slot="secondary">
            </app-class-select>
            <ion-buttons *ngIf="!(isAuthenticating$ | async)" slot="end">
                <input class="hidden" type="file" accept="image/*, video/*" capture #captureImage (change)="onMediaCaptured($event)">
                <ion-button
                    class="header__capture-btn header__icon"
                    (click)="captureImage.click()"
                    [title]="'app-header.capture' | translate"
                    lstA11yIonButton [lstA11yLabel]="'app-header.capture' | translate"
                    *ngIf="!!(selectedClassId$ | async) && !(isUnrecoverableErrorMode$ | async)"
                >
                    <div class="label-icon icon-camera" slot="icon-only" aria-hidden="true"></div>
                </ion-button>
                <ion-button
                    class="header__search-btn header__icon"
                    [title]="'app-header.search' | translate"
                    lstA11yIonButton [lstA11yLabel]="'app-header.search' | translate"
                    (click)="presentSearchMenu()"
                    *ngIf="!!(selectedClassId$ | async) && !(isUnrecoverableErrorMode$ | async)"
                >
                    <div class="label-icon icon-search" slot="icon-only" aria-hidden="true"></div>
                </ion-button>
                <ion-button
                    class="header__user-btn header__icon"
                    [title]="'app-header.menu' | translate"
                    lstA11yIonButton [lstA11yLabel]="'app-header.menu' | translate"
                    (click)="presentUserMenu($event)"
                >
                    <div class="user-btn__icon--desktop label-icon icon-assessments" slot="icon-only" aria-hidden="true"></div>
                    <ion-icon class="user-btn__icon--mobile" slot="icon-only" name="more" aria-hidden="true"></ion-icon>
                </ion-button>
            </ion-buttons>
        </div>
    </ion-toolbar>
</ion-header>
