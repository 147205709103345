import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/core.state';
import {
    ActionNavigateToErrorPage,
    ActionSendTelemetryEventForError,
    ActionShowErrorModal
} from 'src/app/core/error/store/error.actions';
import { ErrorDescription } from 'src/app/core/error/store/error.model';
import { selectIsUnrecoverableErrorMode, selectErrorType } from './store/error.selectors';

@Injectable({ providedIn: 'root' })
export class ErrorService {

    isUnrecoverableErrorMode$ = this.store$.pipe(select(selectIsUnrecoverableErrorMode));
    errorType$ = this.store$.pipe(select(selectErrorType));

    constructor(private store$: Store<AppState>) {}

    navigateToErrorPage(description: ErrorDescription) {
        this.store$.dispatch(new ActionNavigateToErrorPage({ description }));
    }

    processError(description: ErrorDescription, showErrorModal = true) {
        this.store$.dispatch(new ActionSendTelemetryEventForError({ description }));
        if (showErrorModal) {
            this.store$.dispatch(new ActionShowErrorModal());
        }
    }
}
