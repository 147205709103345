import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { NavigationService } from './navigation.service';
import { reducer } from './store/navigation.reducer';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature('navigation', reducer)],
    providers: [NavigationService]
})
export class NavigationModule {}
