import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ClassRoster, ClassRosterState } from './class-roster.model';
import {
    ClassRosterActions,
    ClassRosterActionTypes
} from './class-roster.actions';

export const adapter: EntityAdapter<ClassRoster> = createEntityAdapter<
    ClassRoster
>({
    selectId: (c: ClassRoster) => c.classId
});

export const initialState: ClassRosterState = adapter.getInitialState({
    // additional entity state properties
    currentRoster: null,
    selectedStudentId: null,
    isLoading: true
});

export function reducer(
    state = initialState,
    action: ClassRosterActions
): ClassRosterState {
    switch (action.type) {
        case ClassRosterActionTypes.AddClassRoster: {
            return adapter.addOne(action.payload.classRoster, state);
        }

        case ClassRosterActionTypes.UpsertClassRoster: {
            return adapter.upsertOne(action.payload.classRoster, state);
        }

        case ClassRosterActionTypes.AddClassRosters: {
            return adapter.addMany(action.payload.classRosters, state);
        }

        case ClassRosterActionTypes.UpsertClassRosters: {
            return adapter.upsertMany(action.payload.classRosters, state);
        }

        case ClassRosterActionTypes.UpdateClassRoster: {
            return adapter.updateOne(action.payload.classRoster, state);
        }

        case ClassRosterActionTypes.UpdateClassRosters: {
            return adapter.updateMany(action.payload.classRosters, state);
        }

        case ClassRosterActionTypes.DeleteClassRoster: {
            return adapter.removeOne(action.payload.id, state);
        }

        case ClassRosterActionTypes.DeleteClassRosters: {
            return adapter.removeMany(action.payload.ids, state);
        }

        case ClassRosterActionTypes.LoadClassRosters: {
            const newState = adapter.addMany(action.payload.classRosters, state);
            return { ...newState, isLoading: false };
        }

        case ClassRosterActionTypes.ClearClassRosters: {
            return adapter.removeAll(state);
        }

        case ClassRosterActionTypes.SetSelectedStudentId: {
            return { ...state, selectedStudentId: action.payload.studentId };
        }

        case ClassRosterActionTypes.SetSelectedClassRoster: {
            const newClassId = action.payload.classId;
            return newClassId === state.currentRoster
                ? state
                : { ...state, currentRoster: action.payload.classId };
        }

        default: {
            return state;
        }
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();
