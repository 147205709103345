import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClassRosterTelemetryEffects } from './store/class-roster-telemetry.effects';
import { ClassRosterEffects } from './store/class-roster.effects';
import * as fromClassRoster from './store/class-roster.reducer';
import { ClassRosterService } from './class-roster.service';
import { ClassSelectComponent } from './class-select/class-select.component';

@NgModule({
    declarations: [ClassSelectComponent],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        StoreModule.forFeature('classRoster', fromClassRoster.reducer),
        EffectsModule.forFeature([ClassRosterEffects, ClassRosterTelemetryEffects]),
        NgSelectModule,
        FormsModule,
        SharedModule
    ],
    providers: [ClassRosterService],
    exports: [ClassSelectComponent]
})
export class ClassRosterModule {}
