import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductEntity } from './store/product.model';

@Injectable({ providedIn: 'root' })
export class ProductDataService {
    constructor(private http: HttpClient) {}

    loadProducts(productIds: string[], userId: string, accessToken: string): Observable<ProductEntity[]> {
        const url = environment.api.rootPrograms;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            userId,
            'Content-Type': 'application/json'
        };
        const body = JSON.stringify({ products: productIds });

        return this.http.post(url, body, { headers }).pipe(
            map(({ rootPrograms }: { rootPrograms: ProductEntity[] }) => rootPrograms),
            catchError(error => throwError(error))
        );
    }
}
