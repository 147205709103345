import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[lstA11yIonButton]'
})

export class LstA11yIonButtonDirective {
    @Input() lstA11yLabel: string;

    constructor(
        private el: ElementRef
    ) {
        el.nativeElement.componentOnReady().then(() => {
            this.onShadowReady();
        });
    }

    onShadowReady() {
        const button = this.el.nativeElement.shadowRoot.querySelector('button');
        button.setAttribute('aria-label', this.lstA11yLabel);
    }
}
