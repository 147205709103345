import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DateService } from 'src/app/shared/utils/date.service';
import { ObservationFilterFormComponent } from './components/observation-filter-form/observation-filter-form.component';
import { LstA11yIonButtonDirective } from './directives/lst-a11y-ion-button.directive';
import { TruncatePipe } from './pipe/TruncatePipe';
import { MinutesToSecondsPipe } from './pipe/MinutesToSecondsPipe';
import { AssessmentIconPipe } from './pipe/AssessmentIconPipe';
import { AssessmentLabelPipe } from './pipe/AssessmentLabelPipe';
import { AssessmentRoutePipe } from './pipe/AssessmentRoutePipe';
import { SearchResultIconPipe } from './pipe/SearchResultIconPipe';
import { NameSkeletonComponent } from './components/name-skeleton/name-skeleton.component';
import { MediaGridComponent } from './components/media-grid/media-grid.component';
import { ObservationLabelPipe } from './pipe/ObservationLabelPipe';
import { ObservationIconPipe } from './pipe/ObservationIconPipe';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule
    ],
    declarations: [
        LstA11yIonButtonDirective,
        TruncatePipe,
        MinutesToSecondsPipe,
        AssessmentIconPipe,
        AssessmentLabelPipe,
        AssessmentRoutePipe,
        SearchResultIconPipe,
        ObservationFilterFormComponent,
        ObservationLabelPipe,
        ObservationIconPipe,
        NameSkeletonComponent,
        MediaGridComponent
    ],
    exports: [
        LstA11yIonButtonDirective,
        CommonModule,
        TruncatePipe,
        MinutesToSecondsPipe,
        AssessmentIconPipe,
        AssessmentLabelPipe,
        AssessmentRoutePipe,
        SearchResultIconPipe,
        ObservationFilterFormComponent,
        ObservationLabelPipe,
        ObservationIconPipe,
        NameSkeletonComponent,
        MediaGridComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        TruncatePipe,
        AssessmentRoutePipe,
        DateService,
        ObservationLabelPipe
    ]
})
export class SharedModule {}
