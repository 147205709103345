import { Action } from '@ngrx/store';
import { SearchRecord } from './search.model';

export enum SearchActionTypes {
    LoadRecentSearchesStart = '[Search] Start Loading Recent Searches',
    LoadRecentSearches = '[Search] Load Recent Searches',

    SetIsLoadingSearchResults = '[Search] Set Is Loading Search Results',

    UpsertSearchRecord = '[Search] Upsert Search',
    UpsertSearchRecordSuccess = '[Search] Upsert Search Success',
    UpsertSearchRecordFailed = '[Search] Upsert Search Failed',

    OpenSearchMenu = '[Search] Open Search Menu',
    CloseSearchMenu = '[Search] Close Search Menu',
    SetIsSearchMenuOpen = '[Search] Set Is Search Menu Open',

    SendSearchTelemetryEvent = '[Search] Send SearchTelemetry Event',
}

export class ActionLoadSearchRecordsStart implements Action {
    readonly type = SearchActionTypes.LoadRecentSearchesStart;
}

export class ActionLoadSearchRecords implements Action {
    readonly type = SearchActionTypes.LoadRecentSearches;
    constructor(public payload: { searchRecords: SearchRecord[] }) {}
}

export class ActionSetIsLoadingSearchResults implements Action {
    readonly type = SearchActionTypes.SetIsLoadingSearchResults;
    constructor(public payload: { isLoadingSearchResults: boolean }) {}
}

export class ActionOpenSearchMenu implements Action {
    readonly type = SearchActionTypes.OpenSearchMenu;
}

export class ActionCloseSearchMenu implements Action {
    readonly type = SearchActionTypes.CloseSearchMenu;
}

export class ActionSetIsSearchMenuOpen implements Action {
    readonly type = SearchActionTypes.SetIsSearchMenuOpen;
    constructor(public payload: { isSearchMenuOpen: boolean }) {}
}

export class ActionUpsertSearchRecord implements Action {
    readonly type = SearchActionTypes.UpsertSearchRecord;
    constructor(public payload: { searchTerm: string }) {}
}

export class ActionUpsertSearchRecordSuccess implements Action {
    readonly type = SearchActionTypes.UpsertSearchRecordSuccess;
    constructor(public payload: { searchRecord: SearchRecord }) {}
}

export class ActionUpsertSearchRecordFailed implements Action {
    readonly type = SearchActionTypes.UpsertSearchRecordFailed;
    constructor(public payload: { error: any }) {}
}

export class ActionSendSearchTelemetryEvent implements Action {
    readonly type = SearchActionTypes.SendSearchTelemetryEvent;
    constructor(public payload: { searchTerm: string, fromSearchHistory: boolean }) {}
}

export type SearchActions =
    | ActionLoadSearchRecordsStart
    | ActionLoadSearchRecords
    | ActionSetIsLoadingSearchResults
    | ActionOpenSearchMenu
    | ActionCloseSearchMenu
    | ActionUpsertSearchRecord
    | ActionUpsertSearchRecordSuccess
    | ActionUpsertSearchRecordFailed
    | ActionSetIsSearchMenuOpen
    | ActionSendSearchTelemetryEvent;
