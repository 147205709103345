import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BulkProfilesResponse } from 'src/app/core/user-profile/user-profile.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserProfileDataService {

    constructor(private http: HttpClient) {}

    getProfiles(userIds: string[], accessToken: string, currentUserId: string) {
        const url = environment.api.userProfile;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            listofuserids: userIds.join(','),
            userIds: currentUserId
        };
        return this.http.get(url, { headers });
    }
}
