import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ObservationsState, State } from './observations.model';
import { selectAll } from './observations.reducer';

export const selectObservationsState = createFeatureSelector<State, ObservationsState>('observations');

export const selectAllObservations = createSelector(
    selectObservationsState,
    selectAll,
);

export const selectObservation = (
    classId: string,
    programId: string,
    assessmentId: string,
    studentId: string,
    createdBy: string
) => {
    return createSelector(
        selectAllObservations,
        observations => observations.find(
            observation =>
                observation.classId === classId &&
                observation.programId === programId &&
                observation.assessmentId === assessmentId &&
                observation.studentId === studentId &&
                observation.createdBy === createdBy
        )
    );
};

export const selectSelectedObservationId = createSelector(
    selectObservationsState,
    state => state.selectedObservationId,
);

export const selectIsLoadingObservations = createSelector(
    selectObservationsState,
    state => state.isLoading,
);

export const selectAssessmentObservations = (
    classId: string,
    programId: string,
    assessmentId: string
) => {
    return createSelector(
        selectAllObservations,
        observations => observations.filter(
            observation =>
                observation.classId === classId &&
                observation.programId === programId &&
                observation.assessmentId === assessmentId
        )
    );
};
