import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { Logger } from './logger';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { ConsoleLogWriter } from './logWriter';

@Injectable()
export class LoggingEffects {
    private consoleWriter: ConsoleLogWriter = new ConsoleLogWriter();

    constructor(private logger: Logger) {}

    @Effect({ dispatch: false })
    writeLog$ = this.logger.logs$.pipe(
        tap(logEntry => {
            environment.logging.writers.forEach(logWriter => {
                if (logWriter === 'console') {
                    this.consoleWriter.writeLog(logEntry);
                }
            });
        })
    );
}
