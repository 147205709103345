import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UserDetails } from '../../auth/store/auth.model';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
    currentUser$: Observable<UserDetails>;
    helpUrl = environment.helpUrl;
    constructor(
        private authService: AuthService,
        public popoverCtrl: PopoverController
    ) {}

    ngOnInit() {
        this.currentUser$ = this.authService.currentUser$;
    }

    search() {
        const onSearchData = 'search';
        this.popoverCtrl.dismiss(onSearchData);
    }

    support() {
        const onSupportData = 'support';
        this.popoverCtrl.dismiss(onSupportData);
    }

    signOut() {
        const onSignOutData = 'signout';
        this.popoverCtrl.dismiss(onSignOutData);
    }
}
