import { createSelector } from '@ngrx/store';
import { selectAuthState } from '../../core.state';
import { AuthState } from './auth.model';

export const selectIsAuthenticated = createSelector(
    selectAuthState,
    (state: AuthState) => state.isAuthenticated
);

export const selectIsAuthenticating = createSelector(
    selectAuthState,
    (state: AuthState) => state.isAuthenticating
);

export const selectIsValidating = createSelector(
    selectAuthState,
    (state: AuthState) => state.isValidating
);

export const selectCurrentUser = createSelector(
    selectAuthState,
    (state: AuthState) => state.currentUser
);

export const selectRbs = createSelector(
    selectAuthState,
    (state: AuthState) => state.rbs
);

export const selectAuthorizedProducts = createSelector(
    selectAuthState,
    (state: AuthState) => state.authorizedScoutProducts
);

export const selectRbsCredentials = createSelector(
    selectCurrentUser,
    selectRbs,
    (currentUser, rbs) => {
        if (currentUser && rbs) {
            const { userId } = currentUser;
            const { token } = rbs;
            return { userId, token };
        }

        return undefined;
    }
);

export const selectFirebaseJwt = createSelector(
    selectAuthState,
    (state: AuthState) => state.jwt
);
