import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom, first } from 'rxjs/operators';
import { ActionSendTelemetryEventForError, ActionShowErrorModal } from 'src/app/core/error/store/error.actions';
import { ActionSetPreviousURL } from 'src/app/core/navigation/store/navigation.actions';
import { selectRbsCredentials } from '../../auth/store/auth.selectors';
import { selectCurrentClassRoster } from '../../class-roster/store/class-roster.selectors';
import { AppState, selectRouterState } from '../../core.state';
import { getProductFromProgramId } from '../../product/store/product.selectors';
import { ProgramService } from '../../program/program.service';
import { ActionSetSelectedProgram } from '../../program/store/program.actions';
import {
    selectCurrentProgram,
    selectSelectedProgramId,
    selectProgramById
} from '../../program/store/program.selectors';
import { AssessmentDataService } from '../assessment.data.service';
import { AssessmentService } from '../assessment.service';
import {
    ActionLoadAssessment,
    ActionLoadAssessmentFailed,
    ActionLoadAssessmentSuccess,
    ActionNavigateToAssessmentView,
    AssessmentActionTypes,
    ActionNavigateToSelectedAssessmentZeroState
} from './assessment.actions';
import { selectAssessmentById } from './assessment.selectors';

@Injectable()
export class AssessmentEffects {

    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private assessmentDataService: AssessmentDataService,
        private assessmentService: AssessmentService,
        private router: Router,
        private programService: ProgramService
    ) {}

    @Effect()
    loadAssessment$ = this.actions$.pipe(
        ofType<ActionLoadAssessment>(AssessmentActionTypes.LoadAssessment),
        map(action => action.payload),
        mergeMap(({ assessmentId, programId }) =>
            this.store$.pipe(
                select(selectAssessmentById(assessmentId)),
                map(assessment => ({ assessmentId, assessment, programId })),
                first()
            )
        ),
        filter(({ assessment }) => !assessment),
        mergeMap(({ assessmentId, programId }) => {
            if (programId) {
                return this.store$.pipe(
                    select(selectProgramById(programId)),
                    filter(program => !!program),
                    map(program => ({ program, assessmentId })),
                    first()
                );
            }
            return this.store$.pipe(
                select(selectCurrentProgram),
                filter(currentProgram => !!currentProgram),
                map(currentProgram => ({ program: currentProgram, assessmentId })),
                first()
            );
        }),
        filter(
            ({ assessmentId, program }) =>
                program && this.programService.assessmentExistInProgram(assessmentId, program)
        ),
        mergeMap(({ assessmentId, program }) =>
            this.store$.pipe(
                select(getProductFromProgramId(program.id)),
                withLatestFrom(this.store$.pipe(select(selectRbsCredentials))),
                map(([product, { userId, token }]) => ({
                    assessmentId,
                    productId: product.id,
                    programId: program.id,
                    userId,
                    token
                })),
                first()
            )
        ),
        mergeMap(({ productId, programId, assessmentId, userId, token }) =>
            this.assessmentDataService.fetchAssessment(productId, programId, assessmentId, userId, token).pipe(
                map(assessment => new ActionLoadAssessmentSuccess({ assessment })),
                catchError(error => {
                    const description = {
                        origin: 'Fetch Assessment',
                        payload: { assessmentId, programId },
                        message: error,
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionLoadAssessmentFailed({ error }),
                        new ActionShowErrorModal({ goToPreviousURL: true }),
                    ];
                })
            )
        )
    );

    @Effect({ dispatch: false })
    navigateToSelectedAssessmentZeroState$ = this.actions$.pipe(
        ofType<ActionNavigateToSelectedAssessmentZeroState>
        (AssessmentActionTypes.NavigateToSelectedAssessmentZeroState),
        withLatestFrom(this.store$.pipe(select(selectRouterState))),
        map(([, router]) => {
            const { params: { assessmentId, studentId }, url } = router.state;
            return { assessmentId, studentId, url };
        }),
        filter(({ assessmentId, studentId }) => !!assessmentId && !!studentId),
        tap(({ url }) => {
            const [toNavigateUrl] = url.split('student');
            this.router.navigateByUrl(toNavigateUrl);
        })
    );

    @Effect({ dispatch: false })
    redirectToZeroStateOnClassChange$ = this.store$.pipe(
        select(selectCurrentClassRoster),
        withLatestFrom(this.store$.select(selectRouterState)),
        filter(([classRoster, router]) => !!classRoster && !!router),
        map(([, router]) => {
            const { params: { assessmentId, studentId }, url } = router.state;
            return { assessmentId, studentId, url };
        }),
        filter(({ assessmentId, studentId }) => !!assessmentId && !!studentId),
        tap(({ url }) => {
            const [toNavigateUrl] = url.split('student');
            this.router.navigateByUrl(toNavigateUrl);
        })
    );

    @Effect({ dispatch: false })
    navigateToAssessmentView$ = this.actions$.pipe(
        ofType<ActionNavigateToAssessmentView>(AssessmentActionTypes.NavigateToAssessmentView),
        map(action => action.payload.assessment),
        withLatestFrom(
            this.store$.pipe(select(selectSelectedProgramId)),
            this.store$.pipe(select(selectRouterState))
        ),
        tap(([assessment, currentProgramId, { state }]) => {
            const { type, programId, assessmentId, studentId, criterionId } = assessment;
            if (studentId) {
                this.store$.dispatch(new ActionSetPreviousURL({ previousURL: state.url }));
            }
            if (currentProgramId === programId) {
                const route = this.assessmentService.getAssessmentRoute(type, assessmentId, studentId, criterionId);
                this.router.navigateByUrl(route);
            }
        }),
        filter(([assessment, currentProgramId]) => assessment.programId !== currentProgramId),
        switchMap(([assessment]) => this.store$.pipe(
            select(getProductFromProgramId(assessment.programId)),
            map(product => product.id),
            tap(productId => {
                const { type, programId, assessmentId, studentId, criterionId } = assessment;
                this.assessmentService.setSelectedAssessmentId();
                if (productId) {
                    this.store$.dispatch(new ActionSetSelectedProgram({ rootProgramIdentifier: programId, productId }));
                }
                const route = this.assessmentService.getAssessmentRoute(type, assessmentId, studentId, criterionId);
                this.router.navigateByUrl(route);
            })
        ))
    );
}
