import { Action } from '@ngrx/store';
import { Locale } from './settings.model';

export enum SettingsActionTypes {
    CHANGE_LOCALE = '[Settings] Change Locale'
}

export class ActionSettingsChangeLocale implements Action {
    readonly type = SettingsActionTypes.CHANGE_LOCALE;

    constructor(readonly payload: { locale: Locale }) {}
}

export type SettingsActions = ActionSettingsChangeLocale;
