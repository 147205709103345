import { AppState } from 'src/app/core/core.state';

export enum ErrorType {
    TIMEOUT = 'timeout'
}

export interface ErrorDescription {
    origin: string;
    payload?: any;
    message: any;
}

export interface ErrorState {
    isErrorModalOpen: boolean;
    isUnrecoverableErrorMode: boolean;
    errorType: ErrorType | undefined;
}

export interface State extends AppState {
    error: ErrorState;
}
