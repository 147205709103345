import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateUrl } from './router.state';

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;

        let { params } = route;
        while (route.firstChild) {
            route = route.firstChild;
            params = { ...params, ...route.params };
        }

        const {
            url,
            root: { queryParams }
        } = routerState;
        const [plainUrl] = url.split('?');

        return { url: plainUrl, params, queryParams };
    }
}
