import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAllUsers } from '../../user-profile/store/user-profile.selectors';
import { NotesState, NoteType, NoteVM, State } from './notes.model';
import { selectAll } from './notes.reducer';

export const selectNotesState = createFeatureSelector<State, NotesState>(
    'notes'
);

export const selectNotesFilter = createSelector(
    selectNotesState,
    state => state.filter
);

export const selectIsLoadingNotes = createSelector(
    selectNotesState,
    state => state.isLoadingNotes
);

export const selectEditorNote = createSelector(
    selectNotesState,
    state => state.editorNote
);

export const selectAllNotes = createSelector(
    selectNotesState,
    selectAll
);

export const selectNoteById = (noteId: string) =>
    createSelector(
        selectNotesState,
        state => state.entities[noteId]
    );

export const selectAllNotesByClass = (classId: string) =>
    createSelector(
        selectAllNotes,
        notes => notes.filter(note => note.classId === classId)
    );

export const selectAllNotesVmByClass = (classId: string) =>
    createSelector(
        selectAllNotesByClass(classId),
        selectAllUsers,
        (notes, users) => {
            return notes.map(note => {
                const isTodo = note.type === NoteType.todo;
                const vm: NoteVM = {
                    id: note.id,
                    type: note.type,
                    body: note.body,
                    completed: note.completed,
                    updatedDate: note.updatedDate,
                    dueDateISO: note.dueDate && note.dueDate > 0
                        ? new Date(note.dueDate).toISOString() : null,
                    isTodo,
                    sortDueDateOrder: note.dueDate || Number.MAX_SAFE_INTEGER,
                    sortUpdateDateOrder: note.updatedDate || note.createdDate,
                    students: note.studentIds.reduce((result, studentId) => {
                        const user = users.find(
                            u => u.rumbaUser.userId === studentId
                        );
                        if (user) {
                            result.push({
                                id: user.rumbaUser.userId,
                                name: user.rumbaUser.firstAndLastName,
                                initials:
                                    user.rumbaUser.firstName[0].toUpperCase() +
                                    user.rumbaUser.lastName[0].toUpperCase()
                            });
                        }
                        return result;
                    }, [])
                };
                return vm;
            });
        }
    );

export const selectFilteredNotesVmByClass = (classId: string) =>
    createSelector(
        selectAllNotesVmByClass(classId),
        selectNotesFilter,
        (notes, filter) => {
            let result = [];
            if (filter === 'ALL') {
                result = [...notes].sort((a, b) => b.sortUpdateDateOrder - a.sortUpdateDateOrder);
            }
            if (filter === 'TODO') {
                result = notes.filter(note => note.isTodo)
                    .sort((a, b) => a.sortDueDateOrder - b.sortDueDateOrder);
            }
            return result;
        }
    );

export const selectCountOfAllNotesByClass = (classId: string) =>
    createSelector(
        selectAllNotesByClass(classId),
        notes => notes.length
    );

export const selectCountOfFilteredNotesByClass = (classId: string) =>
    createSelector(
        selectFilteredNotesVmByClass(classId),
        notes => notes.length
    );

export const selectAllTodosByClass = (classId: string) =>
    createSelector(
        selectAllNotesByClass(classId),
        notes => notes.filter(note => note.type === NoteType.todo)
    );

export const selectTodoVmByClass = (classId: string) =>
    createSelector(
        selectAllNotesVmByClass(classId),
        notes => notes.filter(note => note.type === NoteType.todo)
    );

export const selectAllNotesByStudent = (classId: string, studentId: string) =>
    createSelector(
        selectAllNotesVmByClass(classId),
        notes => notes.filter((note) => {
            const index =  note.students.findIndex(student => student.id === studentId);
            return index !== -1;
        }).sort((a, b) => b.sortUpdateDateOrder - a.sortUpdateDateOrder)
    );

export const selectNotesForSearchTerm = (searchTerm: string) =>
    createSelector(
        selectAllNotes,
        allNotes => allNotes.filter(note => note.body.toLowerCase().includes(searchTerm.toLowerCase()))
    );

export const selectNotesForRecentActivity = (classId: string, numberOfResults: number) =>
    createSelector(
        selectAllNotesByClass(classId),
        notes => notes
            .filter(note => note.type === NoteType.note)
            .sort((a, b) => b.updatedDate - a.updatedDate)
            .slice(0, numberOfResults)
    );

export const selectTodosForDasboard = (classId: string, numberOfResults: number) =>
    createSelector(
        selectTodoVmByClass(classId),
        todos => {
            todos.sort((a, b) => a.sortDueDateOrder - b.sortDueDateOrder);
            return todos.slice(0, numberOfResults);
        }
    );

