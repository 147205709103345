import { Action } from '@ngrx/store';
import { UserProfile } from './user-profile.model';

export enum UserProfileActionTypes {
    BULK_LOAD = '[UserProfile] Bulk Load',
    BULK_LOAD_SUCCESS = '[UserProfile] Bulk Load Success',
    LOAD_PROFILE = '[UserProfile] Load Profile',
    LOAD_PROFILE_SUCCESS = '[UserProfile] Load Profile Success',
}

export class ActionUserProfileBulkLoad implements Action {
    readonly type = UserProfileActionTypes.BULK_LOAD;

    constructor(readonly payload: { userIds: string[] }) {}
}

export class ActionUserProfileBulkLoadSuccess implements Action {
    readonly type = UserProfileActionTypes.BULK_LOAD_SUCCESS;

    constructor(readonly payload: { profiles: UserProfile[] }) {}
}

export class ActionUserProfileLoad implements Action {
    readonly type = UserProfileActionTypes.LOAD_PROFILE;

    constructor(readonly payload: { userId: string }) {}
}

export class ActionUserProfileLoadSuccess implements Action {
    readonly type = UserProfileActionTypes.LOAD_PROFILE_SUCCESS;

    constructor(readonly payload: { profile: UserProfile }) {}
}

export type UserProfileActions =
    | ActionUserProfileBulkLoad
    | ActionUserProfileBulkLoadSuccess
    | ActionUserProfileLoad
    | ActionUserProfileLoadSuccess;
