import { createSelector, createFeatureSelector } from '@ngrx/store';
import { State, StudentMetadataForClassState } from './students.model';
import { selectAll } from './students.reducer';

export const selectStudentsState = createFeatureSelector<State, StudentMetadataForClassState>('students');

export const selectAllStudentMetadataForClass = createSelector(
    selectStudentsState,
    selectAll
);

export const selectStudentMetadataForClass = (
    classId: string,
    studentId: string,
    createdBy: string
) => {
    return createSelector(
        selectAllStudentMetadataForClass,
        studentMetadataForClass => studentMetadataForClass.find(
            studentMetadataFor =>
                studentMetadataFor.classId === classId &&
                studentMetadataFor.studentId === studentId &&
                studentMetadataFor.createdBy === createdBy
        )
    );
};

export const selectSelectedDetailsType = createSelector(
    selectStudentsState,
    state => state.selectedDetailsType
);

export const selectIsLoadingStudentMetadataForClass = createSelector(
    selectStudentsState,
    state => state.isLoading
);
