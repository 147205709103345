import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { map, mergeMap, tap, filter, withLatestFrom } from 'rxjs/operators';
import {
    ActionNavigateToErrorPage,
    ActionSendTelemetryEventForError,
    ErrorActionTypes,
    ActionShowErrorModal,
    ActionSetIsUnrecoverableErrorMode,
    ActionSetIsErrorModalOpen
} from 'src/app/core/error/store/error.actions';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';
import { AppState } from '../../core.state';
import { selectIsUnrecoverableErrorMode, selectIsErrorModalOpen, selectErrorState } from './error.selectors';
import { ErrorModalComponent } from '../../components/error-modal/error-modal.component';
import { NavigationService } from 'src/app/core/navigation/navigation.service';
import { selectIsAuthenticated } from '../../auth/store/auth.selectors';
import { AuthActionTypes } from '../../auth/store/auth.actions';
import { SessionStorageService } from '../../session-storage/session-storage.service';

const STATE_STORAGE_KEY = 'ERROR';

@Injectable()
export class ErrorEffects {
    constructor(
        private actions$: Actions<Action>,
        private telemetryService: TelemetryService,
        private router: Router,
        private modalController: ModalController,
        private store$: Store<AppState>,
        private navigationService: NavigationService,
        private sessionStorageService: SessionStorageService
    ) {}

    @Effect({ dispatch: false })
    checkErrorMode = this.store$.pipe(
        select(selectIsUnrecoverableErrorMode),
        filter(errorMode => !!errorMode),
        tap(() => this.router.navigate(['/error']))
    );

    @Effect()
    navigateToErrorPage$ = this.actions$.pipe(
        ofType<ActionNavigateToErrorPage>(ErrorActionTypes.NavigateToErrorPage),
        map(action => action.payload.description),
        tap(() => this.router.navigate(['/error'])),
        mergeMap(description => [
            new ActionSendTelemetryEventForError({ description }),
            new ActionSetIsUnrecoverableErrorMode({ isUnrecoverableErrorMode: true })
        ])
    );

    @Effect({ dispatch: false })
    sendTelemetryEventForError$ = this.actions$.pipe(
        ofType<ActionSendTelemetryEventForError>(ErrorActionTypes.SendTelemetryEventForError),
        map(action => action.payload.description),
        tap(({ origin, ...details }) => this.telemetryService.sendActivity('error', 'Application', details, origin))
    );

    @Effect({ dispatch: false })
    showErrorModal$ = this.actions$.pipe(
        ofType<ActionShowErrorModal>(ErrorActionTypes.ShowErrorModal),
        map(action => action.payload || {}),
        withLatestFrom(
            this.store$.pipe(select(selectIsUnrecoverableErrorMode)),
            this.store$.pipe(select(selectIsErrorModalOpen)),
            this.store$.pipe(select(selectIsAuthenticated))
        ),
        tap(
            ([
                {
                    translatedTitle = 'error-modal.title',
                    translatedDescription = 'error.message',
                    goToPreviousURL = false
                },
                isUnrecoverableErrorMode,
                isErrorModalOpen,
                isAuthenticated
            ]) => {
                if (!isUnrecoverableErrorMode && !isErrorModalOpen && isAuthenticated) {
                    this.store$.dispatch(new ActionSetIsErrorModalOpen({ isErrorModalOpen: true }));
                    this.modalController
                        .create({
                            component: ErrorModalComponent,
                            cssClass: 'error-modal',
                            componentProps: {
                                translatedTitle,
                                translatedDescription
                            }
                        })
                        .then(modal => {
                            modal.present();
                            modal.onDidDismiss().then(() => {
                                this.store$.dispatch(new ActionSetIsErrorModalOpen({ isErrorModalOpen: false }));
                                if (goToPreviousURL) {
                                    this.navigationService.goToPreviousURL();
                                }
                            });
                        });
                }
            }
        )
    );

    @Effect({ dispatch: false })
    persistState = this.actions$.pipe(
        ofType(
            ErrorActionTypes.SetErrorType,
            ErrorActionTypes.SetIsUnrecoverableErrorMode,
            ErrorActionTypes.SetIsErrorModalOpen
        ),
        withLatestFrom(this.store$.pipe(select(selectErrorState))),
        tap(([, state]) => {
            this.sessionStorageService.setItem(STATE_STORAGE_KEY, state);
        })
    );

    @Effect({ dispatch: false })
    clearPersistedState = this.actions$.pipe(
        ofType(AuthActionTypes.LOGOUT),
        tap(() => {
            this.sessionStorageService.removeItem(STATE_STORAGE_KEY);
        })
    );
}
