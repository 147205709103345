import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../core.state';
import {
    ActionOpenOralReadingChartMenu,
    ActionCloseOralReadingChartMenu
} from './store/oral-reading-chart.actions';

@Injectable({ providedIn: 'root' })
export class OralReadingChartService {
    constructor(private store: Store<AppState>) {}

    openOralReadingChartMenu() {
        this.store.dispatch(new ActionOpenOralReadingChartMenu());
    }

    closeOralReadingChartMenu() {
        this.store.dispatch(new ActionCloseOralReadingChartMenu());
    }
}
