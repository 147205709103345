import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { AppState } from 'src/app/core/core.state';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';
import {
    ActionAuthLogout,
    ActionSendTelemetryEventAndLogout,
    ActionValidateTicketSuccess,
    AuthActionTypes
} from './auth.actions';

@Injectable()
export class AuthTelemetryEffects {
    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private telemetryService: TelemetryService,
    ) {}

    @Effect({ dispatch: false })
    login = this.actions$.pipe(
        ofType<ActionValidateTicketSuccess>(
            AuthActionTypes.VALIDATE_TICKET_SUCCESS
        ),
        tap(() => {
            this.telemetryService.sendActivity('loggedIn', 'Auth');
        })
    );

    @Effect({ dispatch: false })
    logout = this.actions$.pipe(
        ofType<ActionSendTelemetryEventAndLogout>(AuthActionTypes.SEND_TELEMETRY_EVENT_AND_LOGOUT),
        switchMap(() => this.telemetryService.sendActivity$('loggedOut', 'Auth')
            .pipe(finalize(() => this.store$.dispatch(new ActionAuthLogout())))
        )
    );
}
