import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { StudentMetadataForClass, StudentMetadataForClassSnapShot } from './store/students.model';
import { ActionLoadStudentMetadataForClass } from './store/students.actions';
import { map, switchMap } from 'rxjs/operators';
import { of, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StudentsDataService {

    constructor(private afsDb: AngularFirestore) { }

    createStudentMetadataForClassDocument(
            studentMetadataForClass: StudentMetadataForClassSnapShot
        ): StudentMetadataForClass {
        return {
            ...studentMetadataForClass as any,
            id: this.afsDb.createId()
        };
    }

    insertStudentMetadataForClass(studentMetadataForClass: StudentMetadataForClass) {
        return from(this.afsDb.collection('students').doc(studentMetadataForClass.id).set(studentMetadataForClass));
    }

    updateStudentMetadataForClass(updatedStudentMetadataForClass: StudentMetadataForClassSnapShot) {
        return from(this.afsDb.collection('students').doc(updatedStudentMetadataForClass.id)
            .update(updatedStudentMetadataForClass));
    }

    fetchStudentMetadataForClass(classId: string, createdBy: string) {
        const query = ref => ref
            .where('classId', '==', classId)
            .where('createdBy', '==', createdBy);
        return this.afsDb.collection('students', query)
            .snapshotChanges()
            .pipe(
                map(actions => actions.map(action => {
                    const doc = action.payload.doc.data() as StudentMetadataForClass;
                    const id = action.payload.doc.id;
                    return { ...doc, id };
                })),
                switchMap((studentMetadataForClass) => {
                    return of(new ActionLoadStudentMetadataForClass({ studentMetadataForClass }));
                })
            );
    }
}
