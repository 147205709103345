import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NotesService } from './notes.service';
import { NotesEffects } from './store/notes.effects';
import { reducer } from './store/notes.reducer';
import { NotesTelemetryEffects } from './store/notes-telemetry.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('notes', reducer),
        EffectsModule.forFeature([NotesEffects, NotesTelemetryEffects])
    ],
    providers: [NotesService]
})
export class NotesModule {}
