import { EntityState } from '@ngrx/entity';
import { AssessmentItem } from '../../assessment/store/assessment.model';
import { AppState } from '../../core.state';
import { Media } from '../../media/store/media.model';
import { Note } from '../../notes/store/notes.model';

export interface SearchRecord {
    id: string;
    createdBy: string;
    updatedAt: number;
    searchTerm: string;
}

export interface SearchVM {
    today: SearchRecord[];
    thisWeek: SearchRecord[];
}

export interface SearchResult {
    id: string;
    category: SearchResultCategory;
    title: string;
    updatedAt: number;
    object: Media | Note | AssessmentItem;
}

export enum SearchResultCategory {
    Note = 'note',
    Media = 'media',
    Assessment = 'assessment',
}

export interface SearchState extends EntityState<SearchRecord> {
    isLoadingRecentSearches: boolean;
    isLoadingSearchResults: boolean;
    isSearchMenuOpen: boolean;
}

export interface State extends AppState {
    searches: SearchState;
}
