export const oralReadingData = [
    {
        grade: 1,
        benchMarkRecord: [
            {
                ILE: 90,
                winterWcpm: 97,
                springWcpm: 116
            },
            {
                ILE: 75,
                winterWcpm: 59,
                springWcpm: 91
            },
            {
                ILE: 50,
                winterWcpm: 29,
                springWcpm: 60
            },
            {
                ILE: 25,
                winterWcpm: 16,
                springWcpm: 34
            },
            {
                ILE: 10,
                winterWcpm: 9,
                springWcpm: 18
            }
        ]
    },
    {
        grade: 2,
        benchMarkRecord: [
            {
                ILE: 90,
                fallWcpm: 111,
                winterWcpm: 131,
                springWcpm: 148
            },
            {
                ILE: 75,
                fallWcpm: 84,
                winterWcpm: 109,
                springWcpm: 124
            },
            {
                ILE: 50,
                fallWcpm: 50,
                winterWcpm: 84,
                springWcpm: 100
            },
            {
                ILE: 25,
                fallWcpm: 36,
                winterWcpm: 59,
                springWcpm: 72
            },
            {
                ILE: 10,
                fallWcpm: 23,
                winterWcpm: 35,
                springWcpm: 43
            }
        ]
    },
    {
        grade: 3,
        benchMarkRecord: [
            {
                ILE: 90,
                fallWcpm: 134,
                winterWcpm: 161,
                springWcpm: 166
            },
            {
                ILE: 75,
                fallWcpm: 104,
                winterWcpm: 137,
                springWcpm: 139
            },
            {
                ILE: 50,
                fallWcpm: 83,
                winterWcpm: 97,
                springWcpm: 112
            },
            {
                ILE: 25,
                fallWcpm: 59,
                winterWcpm: 79,
                springWcpm: 91
            },
            {
                ILE: 10,
                fallWcpm: 40,
                winterWcpm: 62,
                springWcpm: 63
            }
        ]
    },
    {
        grade: 4,
        benchMarkRecord: [
            {
                ILE: 90,
                fallWcpm: 153,
                winterWcpm: 168,
                springWcpm: 184
            },
            {
                ILE: 75,
                fallWcpm: 125,
                winterWcpm: 143,
                springWcpm: 160
            },
            {
                ILE: 50,
                fallWcpm: 94,
                winterWcpm: 120,
                springWcpm: 133
            },
            {
                ILE: 25,
                fallWcpm: 75,
                winterWcpm: 95,
                springWcpm: 105
            },
            {
                ILE: 10,
                fallWcpm: 60,
                winterWcpm: 71,
                springWcpm: 83
            }
        ]
    },
    {
        grade: 5,
        benchMarkRecord: [
            {
                ILE: 90,
                fallWcpm: 179,
                winterWcpm: 183,
                springWcpm: 195
            },
            {
                ILE: 75,
                fallWcpm: 153,
                winterWcpm: 160,
                springWcpm: 169
            },
            {
                ILE: 50,
                fallWcpm: 121,
                winterWcpm: 133,
                springWcpm: 146
            },
            {
                ILE: 25,
                fallWcpm: 87,
                winterWcpm: 109,
                springWcpm: 119
            },
            {
                ILE: 10,
                fallWcpm: 64,
                winterWcpm: 84,
                springWcpm: 102
            }
        ]
    }
];
