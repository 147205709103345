import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from 'src/app/core/error/error.service';
import { selectIsLoadingUsers } from 'src/app/core/user-profile/store/user-profile.selectors';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../user-profile/user-profile.service';
import { ClassRosterResponse, ClassRoster } from './store/class-roster.model';
import { Store, select } from '@ngrx/store';
import { AppState } from '../core.state';
import { Observable, of, combineLatest } from 'rxjs';
import { selectRbsCredentials, selectAuthorizedProducts } from '../auth/store/auth.selectors';
import { tap, switchMap, map, filter, catchError } from 'rxjs/operators';
import { ActionSetSelectedClassRoster, LoadClassRosters } from './store/class-roster.actions';
import { selectClassRosterIsLoading } from './store/class-roster.selectors';
import {
    selectAllClassRosters,
    selectSelectedClassRosterId,
    selectCurrentClassRoster,
    selectSelectedStudentId
} from './store/class-roster.selectors';

@Injectable({
    providedIn: 'root'
})
export class ClassRosterService {
    selectedClassId$: Observable<string>;

    classList$: Observable<ClassRoster[]>;

    isLoading$: Observable<boolean>;

    selectedStudentNotInSelectedClassRoster$ = combineLatest(
        this.store.select(selectCurrentClassRoster),
        this.store.select(selectSelectedStudentId)
    ).pipe(
        filter(([classRoster, studentId]) => !!classRoster && !!studentId),
        map(([classRoster, studentId]) => {
            const { studentIds } = classRoster;
            return { studentIds, studentId };
        }),
        map(({ studentId, studentIds }) => studentIds.indexOf(studentId) < 0)
    );

    constructor(
        private http: HttpClient,
        private store: Store<AppState>,
        private ups: UserProfileService,
        private errorService: ErrorService
    ) {
        this.classList$ = combineLatest(
            this.store.pipe(select(selectRbsCredentials)),
            this.store.pipe(select(selectAllClassRosters)),
            this.store.pipe(select(selectAuthorizedProducts))
        ).pipe(
            filter(([creds]) => !!creds),
            switchMap(([{ userId, token }, rosters, products]) => {
                const rostersLoaded = rosters && rosters.length;
                if (rostersLoaded) {
                    return of(rosters);
                }
                return this.getSections(userId, token).pipe(
                    map(classRosters => {
                        // select all active class
                        const activeClass = classRosters.filter(classRoster => classRoster.status === 'ACTIVE');

                        // filter class with atleast one hasAccess productId
                        const filteredClassRoster = activeClass.filter(classRoster => {
                            return classRoster.productIds.reduce((acc, productId) => {
                                return acc || products.indexOf(productId) >= 0;
                            }, false);
                        });

                        // remove productIds from class whose user don't have access
                        const filteredProductIdsClass = filteredClassRoster.map(classRoster => {
                            return {
                                ...classRoster,
                                productIds: classRoster.productIds.filter(productId => {
                                    return products.indexOf(productId) >= 0;
                                })
                            };
                        });
                        return filteredProductIdsClass;
                    }),
                    tap(classRosters => this.store.dispatch(new LoadClassRosters({ classRosters })))
                );
            }),
            map(classRosters => {
                classRosters.sort((classA, classB) => classA.className.localeCompare(classB.className));
                return classRosters;
            })
        );

        this.selectedClassId$ = this.store.pipe(select(selectSelectedClassRosterId));

        this.isLoading$ = this.store.pipe(select(selectClassRosterIsLoading));
    }

    currentRoster$: Observable<ClassRoster> = this.store.pipe(select(selectCurrentClassRoster));

    currentRosterStudents$ = this.currentRoster$.pipe(
        filter(roster => !!roster),
        switchMap(roster => {
            return this.ups.users$(roster.studentIds).pipe(
                switchMap(students => {
                    students.sort((a, b) =>
                        a.rumbaUser.fullName.toLowerCase().localeCompare(b.rumbaUser.fullName.toLowerCase())
                    );
                    return of(students);
                })
            );
        })
    );

    showZeroState$ = combineLatest(this.currentRosterStudents$, this.store.pipe(select(selectIsLoadingUsers))).pipe(
        map(([currentRosterStudents, isLoadingUsers]) => currentRosterStudents.length !== 0 && !isLoadingUsers)
    );

    setSelectedClassRosterId(classId: string) {
        this.store.dispatch(new ActionSetSelectedClassRoster({ classId }));
    }

    private getSections(userId: string, accessToken: string) {
        const url = environment.api.rosterSections;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            userId
        };
        return this.http.get<ClassRosterResponse>(url, { headers }).pipe(
            map(({ rosters }) => rosters),

            catchError(error => {
                // case when no class exists
                if (error.status === 404) {
                    return of([]);
                }

                // send error if other than 404
                const description = {
                    origin: 'Fetch Class Rosters',
                    payload: { userId },
                    message: error
                };
                this.errorService.navigateToErrorPage(description);
                return of([]);
            })
        );
    }
}
