import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { ActionNavigateToAssessmentView } from 'src/app/core/assessment/store/assessment.actions';
import { AssessmentItem } from 'src/app/core/assessment/store/assessment.model';
import { AppState } from 'src/app/core/core.state';
import { ActionShowEditorMenu } from 'src/app/core/notes/store/notes.actions';
import { SearchService } from 'src/app/core/search/search.service';
import { SearchRecord, SearchResult, SearchResultCategory, SearchVM } from 'src/app/core/search/store/search.model';

@Component({
    selector: 'app-search-menu',
    templateUrl: './search-menu.component.html',
    styleUrls: ['./search-menu.component.scss']
})
export class SearchMenuComponent implements OnInit, OnDestroy {

    recentSearches: SearchVM;
    recentSearchesSubscription: Subscription;

    searchTerm = '';
    searchInput = '';
    showRecentSearches = true;
    searchResults: SearchResult[] = [];

    isSearchMenuOpen: boolean;
    isSearchMenuOpenSubscription: Subscription;

    isLoadingRecentSearches$: Observable<boolean>;
    isLoadingSearchResults$: Observable<boolean>;
    assessmentType = SearchResultCategory.Assessment;
    constructor(
        private store: Store<AppState>,
        private router: Router,
        private searchService: SearchService,
    ) {}

    ngOnInit() {
        this.isSearchMenuOpenSubscription = this.searchService.isSearchMenuOpen$
            .subscribe(isSearchMenuOpen => this.onSearchMenuToggle(isSearchMenuOpen));
        this.isLoadingRecentSearches$ = this.searchService.isLoadingRecentSearches$;
        this.isLoadingSearchResults$ = this.searchService.isLoadingSearchResults$;
    }

    ngOnDestroy() {
        this.isSearchMenuOpenSubscription.unsubscribe();
    }

    onKeyUpHandler(event) {
        if (event.keyCode === 13) { // keyCode for Enter
            this.onSearch(false);
        }
     }

    onSearchMenuToggle(isSearchMenuOpen: boolean) {
        this.isSearchMenuOpen = isSearchMenuOpen;
        if (isSearchMenuOpen) {
            this.recentSearchesSubscription = this.searchService.getRecentSearches()
                .subscribe(recentSearches => this.recentSearches = recentSearches);
        } else {
            this.clearSearch();
            if (this.recentSearchesSubscription) {
                this.recentSearchesSubscription.unsubscribe();
            }
        }
    }

    onSearch(fromSearchHistory: boolean) {
        this.showRecentSearches = false;
        this.searchResults = [];
        this.searchTerm = this.searchInput;
        if (this.searchTerm.trim().length === 0) {
            this.clearSearch();
            return;
        }

        this.searchService.getSearchResultsForSearchTerm(this.searchTerm)
            .pipe(first())
            .subscribe(searchResults =>
                this.searchResults = this.isSearchMenuOpen ? searchResults : this.searchResults);

        this.searchService.upsertSearchRecord(this.searchTerm);
        this.searchService.sendSearchTelemetryEvent(this.searchTerm, fromSearchHistory);
    }

    clearSearch() {
        this.searchInput = '';
        this.showRecentSearches = true;
        this.searchTerm = '';
        this.searchResults = [];
    }

    navigateToSearchResult(searchResult: SearchResult) {
        this.clearSearch();
        this.searchService.closeSearchMenu();
        switch (searchResult.category) {
            case SearchResultCategory.Note:
                this.router.navigate(['/notes']);
                this.store.dispatch(new ActionShowEditorMenu({ noteId: searchResult.id }));
                break;
            case SearchResultCategory.Media:
                this.router.navigate(['/media', searchResult.id]);
                break;
            case SearchResultCategory.Assessment:
                const assessmentItem = searchResult.object as AssessmentItem;
                const assessment = {
                    type: assessmentItem.type,
                    programId: assessmentItem.rootProgramIdentifier,
                    assessmentId: assessmentItem.identifier,
                };
                this.store.dispatch(new ActionNavigateToAssessmentView({ assessment }));
                break;
            default:
                break;
        }
    }

    onClickRecentSearch(recentSearch: SearchRecord) {
        this.showRecentSearches = false;
        this.searchInput = recentSearch.searchTerm;
        this.onSearch(true);
    }

    closeSearchMenu() {
        this.clearSearch();
        this.searchService.closeSearchMenu();
    }

    trackByRecentSearchRecordId(index, recentSearchRecord: SearchRecord) {
        return recentSearchRecord.id;
    }

    trackBySearchResultRecordId(index, searchResult: SearchResult) {
        return searchResult.id;
    }
}
