import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { ReportsService } from 'src/app/core/reports/reports.service';
import {
    FilterChange,
    FilterType,
    ObservationReportFilters,
    ObservationTypeFilterOptions,
    RangeFilterOptions,
    SortByOptions
} from 'src/app/core/reports/store/reports.model';
import { DateService } from 'src/app/shared/utils/date.service';
import { ApplyCustomStyle } from '../lst-dropdown/style';

interface ILstDropdownOption {
    name: string;
    code: ObservationTypeFilterOptions | RangeFilterOptions | SortByOptions;
}

@Component({
    selector: 'app-observation-filter-form',
    templateUrl: './observation-filter-form.component.html',
    styleUrls: ['./observation-filter-form.component.scss']
})
export class ObservationFilterFormComponent implements AfterViewInit, OnChanges {

    selectedObservationTypeFilter: string;
    observationTypeFilterOptions: ILstDropdownOption[];

    selectedRangeFilter: string;
    rangeFilterOptions: ILstDropdownOption[];

    selectedSortByFilter: string;
    sortByFilterOptions: ILstDropdownOption[];

    selectedFromFilter: number;
    fromFilterISO = undefined;
    toFilterISO = undefined;
    selectedToFilter: number;
    showDateAlert = false;
    minDate = (new Date().getFullYear() - 1).toString();
    maxDate = (new Date().getFullYear() + 1).toString();

    @ViewChild('viewDropdown') viewDropdown;
    @ViewChild('rangeDropdown') rangeDropdown;
    @ViewChild('sortByDropdown') sortByDropdown;
    @Input() observationReportFilters: Partial<ObservationReportFilters>;
    @Output() notifyFilterChange = new EventEmitter<FilterChange>();

    constructor(
        private translate: TranslateService,
        private reportsService: ReportsService,
        private dateService: DateService,
    ) {
        const assessmentFilterOptionsLabels = [
            ObservationTypeFilterOptions.Ongoing,
            ObservationTypeFilterOptions.Checklists,
            ObservationTypeFilterOptions.Fluency
        ];
        this.translate
            .get(assessmentFilterOptionsLabels)
            .pipe(first())
            .subscribe(values => {
                this.observationTypeFilterOptions = [
                    {
                        name: values[ObservationTypeFilterOptions.Ongoing],
                        code: ObservationTypeFilterOptions.Ongoing
                    },
                    {
                        name: values[ObservationTypeFilterOptions.Checklists],
                        code: ObservationTypeFilterOptions.Checklists
                    },
                    {
                        name: values[ObservationTypeFilterOptions.Fluency],
                        code: ObservationTypeFilterOptions.Fluency
                    }
                ];
                this.selectedObservationTypeFilter = this.observationTypeFilterOptions[0].name;
                this.reportsService.setObservationReportFilters({
                    observationTypeFilter: ObservationTypeFilterOptions.Ongoing
                });
            });

        const rangeFilterOptionsLabels = [
            RangeFilterOptions.Last7Days,
            RangeFilterOptions.Last14Days,
            RangeFilterOptions.Last30Days,
            RangeFilterOptions.Last60Days,
            RangeFilterOptions.CustomRange
        ];
        this.translate
            .get(rangeFilterOptionsLabels)
            .pipe(first())
            .subscribe(values => {
                this.rangeFilterOptions = [
                    {
                        name: values[RangeFilterOptions.Last7Days],
                        code: RangeFilterOptions.Last7Days
                    },
                    {
                        name: values[RangeFilterOptions.Last14Days],
                        code: RangeFilterOptions.Last14Days
                    },
                    {
                        name: values[RangeFilterOptions.Last30Days],
                        code: RangeFilterOptions.Last30Days
                    },
                    {
                        name: values[RangeFilterOptions.Last60Days],
                        code: RangeFilterOptions.Last60Days
                    },
                    {
                        name: values[RangeFilterOptions.CustomRange],
                        code: RangeFilterOptions.CustomRange
                    }
                ];
                this.selectedRangeFilter = this.rangeFilterOptions[0].name;
                this.reportsService.setObservationReportFilters({ rangeFilter: RangeFilterOptions.Last7Days });
            });

        const sortByFilterOptionsLabels = [SortByOptions.Students, SortByOptions.Observations];
        this.translate
            .get(sortByFilterOptionsLabels)
            .pipe(first())
            .subscribe(values => {
                this.sortByFilterOptions = [
                    {
                        name: values[SortByOptions.Students],
                        code: SortByOptions.Students
                    },
                    {
                        name: values[SortByOptions.Observations],
                        code: SortByOptions.Observations
                    }
                ];
                this.selectedSortByFilter = this.sortByFilterOptions[0].name;
                this.reportsService.setObservationReportFilters({ sortByFilter: SortByOptions.Students });
            });

        this.reportsService.setObservationReportFilters({ fromFilter: undefined, toFilter: undefined });
    }

    get FilterType() {
        return FilterType;
    }

    get isCustomRangeSelected(): boolean {
        if (this.rangeFilterOptions) {
            const customRangeFilter = this.rangeFilterOptions
                .find(filter => filter.code === RangeFilterOptions.CustomRange);
            return this.selectedRangeFilter === customRangeFilter.name;
        }
        return false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.observationReportFilters && changes.observationReportFilters.currentValue) {
            const {
                observationTypeFilter,
                rangeFilter,
                sortByFilter,
                fromFilter,
                toFilter
            } = changes.observationReportFilters.currentValue;

            this.selectedObservationTypeFilter = this.selectedObservationTypeFilter
                ? this.observationTypeFilterOptions.find(filter => filter.code === observationTypeFilter).name
                : undefined;

            this.selectedRangeFilter = this.selectedRangeFilter
                ? this.rangeFilterOptions.find(filter => filter.code === rangeFilter).name
                : undefined;

            this.selectedSortByFilter = this.selectedSortByFilter
                ? this.sortByFilterOptions.find(filter => filter.code === sortByFilter).name
                : undefined;

            this.selectedFromFilter = fromFilter;
            this.fromFilterISO = fromFilter ? new Date(fromFilter).toISOString().split('T')[0] : undefined;
            this.toFilterISO = toFilter ? new Date(toFilter).toISOString().split('T')[0] : undefined;
            this.selectedToFilter = toFilter;
        }
    }

    ngAfterViewInit() {
        ApplyCustomStyle(this.viewDropdown);
        ApplyCustomStyle(this.rangeDropdown);
        ApplyCustomStyle(this.sortByDropdown);
    }

    getTimestampForValue(value: string | undefined) {
      if (!value) {
        return undefined;
      }
      const date = value.split('T')[0];
      return new Date(date).getTime();
    }

    onFilterChange(selectedValue, filterType: FilterType) {
        let value;
        // (24 * 60 * 60 * 1000) - 1
        const millisInADays = 86399999;
        switch (filterType) {
            case FilterType.Observation:
            case FilterType.Range:
            case FilterType.SortBy:
                value = selectedValue.code;
                this.showDateAlert = (value !== RangeFilterOptions.CustomRange) ? false :
                (this.selectedToFilter - millisInADays) < this.selectedFromFilter;
                break;
            case FilterType.From:
                value = this.getTimestampForValue(selectedValue.target.value);
                this.showDateAlert = this.selectedToFilter && (value > (this.selectedToFilter - millisInADays));
                break;
            case FilterType.To:
                // add an additional day for correct value of [from-to] range
                value = this.getTimestampForValue(selectedValue.target.value);
                this.showDateAlert = this.selectedFromFilter && (value < this.selectedFromFilter);
                value = value + millisInADays;
                break;
            default:
                throw new Error(`unknown observation report filter type: ${filterType}`);
        }
        this.notifyFilterChange.emit({ value, filterType });
    }
}
