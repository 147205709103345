import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { StudentAssessmentVM } from 'src/app/students/components/assessments/components/students-assessments.component';
import { AssessmentRoutePipe } from '../../shared/pipe/AssessmentRoutePipe';
import { AssessmentItem, AssessmentType } from '../assessment/store/assessment.model';
import { AppState } from '../core.state';
import { Observation } from '../observations/store/observations.model';
import { ProgramSkeleton } from '../product/store/product.model';
import { getProductFromProgramId, getProgramSkeleton, selectProductsByIds } from '../product/store/product.selectors';
import { ProgramDataService } from './program.data.service';
import { ActionCheckAndDispatchLoadProgram, ActionSetSelectedProgram } from './store/program.actions';
import { Program, ProgramItem } from './store/program.model';
import {
    selectCurrentProgram,
    selectProgramById,
    selectProgramState,
    selectSelectedProgramId
} from './store/program.selectors';

@Injectable({
    providedIn: 'root'
})
export class ProgramService {
    selectedProgramId$: Observable<string>;
    currentProgram$: Observable<Program>;
    currentProgramName$: Observable<string>;

    constructor(
        private store: Store<AppState>,
        private programDataService: ProgramDataService,
        private assessmentRoutePipe: AssessmentRoutePipe
    ) {
        this.currentProgram$ = combineLatest(
            this.store.pipe(select(selectProgramState)),
            this.store.pipe(select(selectCurrentProgram))
        ).pipe(
            filter(([, selectedProgram]) => !!selectedProgram),
            map(([, selectedProgram]) => selectedProgram)
        );

        this.currentProgramName$ = this.store.pipe(
            withLatestFrom(this.store.pipe(select(selectCurrentProgram))),
            mergeMap(([, program]) => {
                if (program) {
                    return this.store.pipe(
                        select(getProgramSkeleton(program.id)),
                        map((programSkeleton: ProgramSkeleton) => {
                            return programSkeleton.programName;
                        })
                    );
                } else {
                    return of(undefined);
                }
            })
        );

        this.selectedProgramId$ = this.store.pipe(select(selectSelectedProgramId));
    }

    loadProgram(
        productId: string,
        programId: string,
        userId: string,
        accessToken: string,
        programName: string
    ): Observable<Program> {
        return this.programDataService.loadProgram(productId, programId, userId, accessToken, programName).pipe(
            map(programHierarchyResponse => {
                const programJson = programHierarchyResponse.hierarchy;
                const programStruct = this.makeProgramFromHierarchy(programJson, programName);
                return programStruct;
            }),
            catchError(error => throwError(error))
        );
    }

    assessmentExistInProgram(assessmentId: string, program: Program): boolean {
        const flattenedProgram = this.getFlatHierarchy(program);
        const assessment = flattenedProgram.find(programItem => {
            return programItem.id === assessmentId;
        });
        return !!assessment;
    }

    getFilteredHierarchy(program: Program): Program {
        return { ...program, hierarchy: this.filterProgramHierarchy(cloneDeep(program.hierarchy)) };
    }

    private filterProgramHierarchy(hierarchy: ProgramItem[], hierarchyLevel: number = 0): ProgramItem[] {
        return hierarchy.filter(h => {
            if (h.children && h.children.length > 0) {
                h.children = this.filterProgramHierarchy(h.children, hierarchyLevel + 1);
                return h.children.length > 0;
            }
            // to remove hierarchy at root level
            return h.contentType === 'Observational Assessment' && hierarchyLevel > 0;
        });
    }

    getFlatHierarchy(program: Program): ProgramItem[] {
        return this.programFlatList(cloneDeep(program.hierarchy));
    }

    private programFlatList(hierarchy: ProgramItem[]): ProgramItem[] {
        return hierarchy.reduce((accu, h) => {
            if (h.children && h.children.length > 0) {
                return accu.concat(this.programFlatList(h.children));
            } else if (h.contentType === 'Observational Assessment') {
                return accu.concat(h);
            }
        }, []);
    }

    updateSelectedProgram(rootProgramIdentifier: string, productId: string) {
        return this.store.dispatch(new ActionSetSelectedProgram({ rootProgramIdentifier, productId }));
    }

    getProgramItem(assessmentId: string): Observable<ProgramItem> {
        return this.store.pipe(
            select(selectCurrentProgram),
            filter(program => !!program),
            map(program => this.getProgramItemForAssessment(program, assessmentId))
        );
    }

    getProgramItemForAssessment(program: Program, assessmentId: string): ProgramItem {
        const flatHierarchy = this.getFlatHierarchy(program);
        return flatHierarchy.find(programItem => programItem.id === assessmentId);
    }

    sortAndSelectPrograms(productIds: string[]) {
        return this.store.pipe(
            select(selectProductsByIds(productIds)),
            map(products => products.filter(product => !!product)),
            map(products => {
                // make flat structure for sorting
                const programArray: {
                    productId: string;
                    programName: string;
                    rootProgramIdentifier: string;
                }[] = [];
                products.forEach(product => {
                    const productId = product.id;
                    product.programs.forEach(program => {
                        programArray.push({ ...program, productId });
                    });
                });
                // sorting array
                programArray.sort((a, b) => {
                    return a.programName.localeCompare(b.programName);
                });
                return new ActionSetSelectedProgram({
                    rootProgramIdentifier: programArray[0].rootProgramIdentifier,
                    productId: programArray[0].productId
                });
            })
        );
    }

    getProgramItemForObservation(observation: Observation): Observable<StudentAssessmentVM> {
        const { programId, assessmentId } = observation;
        return this.getProgram(programId).pipe(
            switchMap(program =>
                of({
                    observation,
                    programItem: program.id && this.getProgramItemForAssessment(program, assessmentId)
                })
            ),
            first()
        );
    }

    getProgram(programId: string): Observable<Program | any> {
        return this.store.pipe(
            select(selectProgramById(programId)),
            switchMap((program) => {
                if (!program) {
                    return this.store.pipe(
                        select(getProductFromProgramId(programId)),
                        tap(product => {
                            if (product) {
                                this.store.dispatch(
                                    new ActionCheckAndDispatchLoadProgram(
                                        { productId: product.id, rootProgramIdentifier: programId }
                                    )
                                );
                            }
                        }),
                        filter(product => !product),
                        switchMap(() => of({}))
                    );
                }
                return of(program);
            }),
            filter(program => !!program)
        );
    }

    fetchProgramItemForAssessment(assessment: AssessmentItem): Observable<AssessmentItem> {
        return this.getProgram(assessment.rootProgramIdentifier).pipe(
            switchMap(program => of(this.mapProgramItemForAssessment(assessment, program))),
            first()
        );
    }

    mapProgramItemForAssessment(assessment: AssessmentItem, program: Program): AssessmentItem {
        let programItem = null;
        if (program.id) {
            programItem = this.getProgramItemForAssessment(program, assessment.identifier);
        }
        return programItem
            ? {
                  ...assessment,
                  type: this.assessmentRoutePipe.transform(programItem.mediaType as AssessmentType),
                  path: programItem.path.join(' > ')
              }
            : undefined;
    }

    private makeProgramFromHierarchy(hierarchyJson, programName): Program {
        const { identifier } = hierarchyJson;
        const hierarchy: ProgramItem[] =
            hierarchyJson &&
            hierarchyJson.children &&
            this.makeProgramHierarchyStructure(hierarchyJson.children, [programName]);
        return {
            id: identifier,
            hierarchy
        };
    }

    private makeProgramHierarchyStructure(hierarchyChildren, path: string[]): ProgramItem[] {
        if (!hierarchyChildren || hierarchyChildren.length === 0) {
            return [];
        } else {
            hierarchyChildren.sort((a, b) => a.displayOrder - b.displayOrder);
            const programItem: ProgramItem[] = hierarchyChildren.map(child => {
                const { identifier, titleInSequence, mediaType, contentType, displayOrder } = child;
                const children = this.makeProgramHierarchyStructure(child.children, [...path, titleInSequence]);
                return {
                    id: identifier,
                    displayOrder,
                    name: titleInSequence,
                    mediaType,
                    path,
                    contentType,
                    children
                };
            });
            return programItem;
        }
    }
}
