import { Action } from '@ngrx/store';
import { Comment } from '../../comments/store/comments.model';
import { Media } from '../../media/store/media.model';
import { Observation, ObservationsSnapshot } from './observations.model';

export enum ObservationsActionTypes {
    CreateObservationDocument = '[Observations] Create Observations Document',

    SetSelectedObservationId = '[Observations] Set Selected Observation Id',

    LoadObservationsStart = '[Observations] Start Loading Observations',
    LoadObservations = '[Observations] Load Observations For Assessment',

    DeleteObservationComments = '[Observations] Delete Observation Comments',
    DeleteObservationCommentsSuccess = '[Observations] Delete Observation Comments Success',
    DeleteObservationCommentsFailed = '[Observations] Delete Observation Comments Failed',

    UpsertObservation = '[Observations] Upsert Observation',
    UpsertObservationSuccess = '[Observations] Upsert Observation Success',
    UpsertObservationFailed = '[Observations] Upsert Observation Failed',

    UploadCommentMedia = '[Observations] Upload Comment Media',
    AddCommentMedia = '[Observations] Add Comment Media',

    SendStartedTimerTelemetryEvent = '[Observations] Send Started Timer Telemetry Event',
}

export class ActionCreateObservationDocument implements Action {
    readonly type = ObservationsActionTypes.CreateObservationDocument;
    constructor(public payload: { snapshot: ObservationsSnapshot }) {}
}

export class ActionSetSelectedObservationId implements Action {
    readonly type = ObservationsActionTypes.SetSelectedObservationId;
    constructor(public payload: { observationId: string }) {}
}

export class ActionLoadObservationsStart implements Action {
    readonly type = ObservationsActionTypes.LoadObservationsStart;
}

export class ActionLoadObservations implements Action {
    readonly type = ObservationsActionTypes.LoadObservations;
    constructor(public payload: { observations: Observation[] }) {}
}

export class ActionUpsertObservation implements Action {
    readonly type = ObservationsActionTypes.UpsertObservation;
    constructor(public payload: { observation: Observation, comment?: Comment }) {}
}

export class ActionUpsertObservationSuccess implements Action {
    readonly type = ObservationsActionTypes.UpsertObservationSuccess;
    constructor(public payload: { observation: Observation, comment: Comment }) {}
}

export class ActionUpsertObservationFailed implements Action {
    readonly type = ObservationsActionTypes.UpsertObservationFailed;
    constructor(public payload: {}) {}
}

export class ActionDeleteObservationComments implements Action {
    readonly type = ObservationsActionTypes.DeleteObservationComments;
    constructor(public payload: { comments: Comment[] }) {}
}

export class ActionDeleteObservationCommentsSuccess implements Action {
    readonly type = ObservationsActionTypes.DeleteObservationCommentsSuccess;
}

export class ActionDeleteObservationCommentsFailed implements Action {
    readonly type = ObservationsActionTypes.DeleteObservationCommentsFailed;
    constructor(public payload: { error: any }) {}
}

export class ActionUploadCommentMedia implements Action {
    readonly type = ObservationsActionTypes.UploadCommentMedia;
    constructor(public payload: { observation: Observation, comment: Comment, media: Media, file: File }) {}
}

export class ActionAddCommentMedia implements Action {
    readonly type = ObservationsActionTypes.AddCommentMedia;
    constructor(public payload: { observation: Observation, comment: Comment, media: Media }) {}
}

export class ActionSendStartedTimerTelemetryEvent implements Action {
    readonly type = ObservationsActionTypes.SendStartedTimerTelemetryEvent;
}

export type ObservationsActions =
    | ActionCreateObservationDocument
    | ActionSetSelectedObservationId
    | ActionLoadObservationsStart
    | ActionLoadObservations
    | ActionUpsertObservation
    | ActionUpsertObservationSuccess
    | ActionUpsertObservationFailed
    | ActionDeleteObservationComments
    | ActionDeleteObservationCommentsSuccess
    | ActionDeleteObservationCommentsFailed
    | ActionAddCommentMedia
    | ActionUploadCommentMedia
    | ActionSendStartedTimerTelemetryEvent;
