import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../core.state';
import { ActionShowRefreshModal } from './store/service-worker.actions';

@Injectable({ providedIn: 'root' })
export class ServiceWorkerService {
    constructor(private store$: Store<AppState>) {}

    showRefreshModal() {
        this.store$.dispatch(new ActionShowRefreshModal());
    }
}
