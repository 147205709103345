import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Program, ProgramItem } from '../../../../program/store/program.model';
import { ProgramService } from '../../../../program/program.service';
import { filter, map } from 'rxjs/operators';
import { DashboardService } from '../../../../dashboard/dashboard.service';

@Component({
    selector: 'app-dashboard-assessment-hierarchy',
    templateUrl: './dashboard-assessment-hierarchy.component.html',
    styleUrls: ['./dashboard-assessment-hierarchy.component.scss']
})
export class DashboardAssessmentHierarchyComponent implements OnInit {
    programHierarchy$: Observable<ProgramItem[]>;
    isLoading$: Observable<boolean>;
    constructor(private programService: ProgramService, private dashboardService: DashboardService) {}

    ngOnInit() {
        this.isLoading$ = this.dashboardService.isSelectedProgramLoading$;
        this.programHierarchy$ = this.programService.currentProgram$.pipe(
            map(program => {
                return program ? this.programService.getFilteredHierarchy(program).hierarchy : [];
            })
        );
    }
}
