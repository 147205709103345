import { Action } from '@ngrx/store';
import { ProgramItem } from 'src/app/core/program/store/program.model';
import { CriteriaTag, ObservationReportFilters, ReportType } from './reports.model';

export enum ReportsActionTypes {
    SetObservationReportFilters = '[Report] Set Observation Report Filters',
    OpenObservationFilterMenu = '[Report] Open Observation Filter Menu',
    CloseObservationFilterMenu = '[Report] Close Observation Filter Menu',

    SetSelectedProductProgramSkeleton = '[Report] Set Selected ProductProgramSkeleton In Checklist Report',
    SetSelectedContentItem = '[Report] Set Selected Content Item In Checklist Report',
    SetSelectedCriteriaTag = '[Report] Set Selected Criteria Tag In Checklist Report',

    LoadTagListForSelectedProgram = '[Report] Load Tag List For Selected Program and Content Item',
    LoadTagListForSelectedProgramFailed = '[Report] Load Tag List For Selected Program and Content Item Failed',
    SendReportOpenedTelemetryEvent = '[Report] Send Report Opened Telemetry Event',

    SetIsChecklistReportLoading = '[Report] Set Is Checklist Report Loading'
}

export class ActionSetObservationReportFilters implements Action {
    readonly type = ReportsActionTypes.SetObservationReportFilters;
    constructor(public payload: { filters: Partial<ObservationReportFilters> }) {}
}

export class ActionOpenObservationFilterMenu implements Action {
    readonly type = ReportsActionTypes.OpenObservationFilterMenu;
}

export class ActionCloseObservationFilterMenu implements Action {
    readonly type = ReportsActionTypes.CloseObservationFilterMenu;
}

export class ActionSetSelectedProductProgramSkeleton implements Action {
    readonly type = ReportsActionTypes.SetSelectedProductProgramSkeleton;
    constructor(public payload: { selectedProductProgramSkeleton: any }) {}
}

export class ActionSetSelectedContentItem implements Action {
    readonly type = ReportsActionTypes.SetSelectedContentItem;
    constructor(public payload: { selectedContentItem: ProgramItem }) {}
}

export class ActionSetSelectedCriteriaTag implements Action {
    readonly type = ReportsActionTypes.SetSelectedCriteriaTag;
    constructor(public payload: { selectedCriteriaTag: CriteriaTag }) {}
}

export class ActionLoadTagListForSelectedProgram implements Action {
    readonly type = ReportsActionTypes.LoadTagListForSelectedProgram;
    constructor(public payload: { tagsList: CriteriaTag[] }) {}
}

export class ActionLoadTagListForSelectedProgramFailed implements Action {
    readonly type = ReportsActionTypes.LoadTagListForSelectedProgramFailed;
    constructor(public payload: { error: any }) {}
}

export class ActionSetIsChecklistReportLoading implements Action {
    readonly type = ReportsActionTypes.SetIsChecklistReportLoading;
    constructor(public payload: { isChecklistReportDataLoading: boolean }) {}
}

export class ActionSendReportOpenedTelemetryEvent implements Action {
    readonly type = ReportsActionTypes.SendReportOpenedTelemetryEvent;
    constructor(public payload: { reportType: ReportType }) {}
}

export type ReportsActions =
    | ActionSetObservationReportFilters
    | ActionOpenObservationFilterMenu
    | ActionCloseObservationFilterMenu
    | ActionSetSelectedProductProgramSkeleton
    | ActionSetSelectedContentItem
    | ActionSetSelectedCriteriaTag
    | ActionLoadTagListForSelectedProgram
    | ActionLoadTagListForSelectedProgramFailed
    | ActionSendReportOpenedTelemetryEvent
    | ActionSetIsChecklistReportLoading;
