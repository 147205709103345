import { Pipe, PipeTransform } from '@angular/core';
import { AssessmentType } from 'src/app/core/assessment/store/assessment.model';

@Pipe({ name: 'assessmentLabel' })
export class AssessmentLabelPipe implements PipeTransform {
    transform(assessmentType: AssessmentType) {
        switch (assessmentType) {
            case AssessmentType.Checklist:
                return 'dashboard-assessment-list.assessmentTypeLabels.checklist';
            case AssessmentType.Fluency:
                return 'dashboard-assessment-list.assessmentTypeLabels.fluency';
            case AssessmentType.Ongoing:
                return 'dashboard-assessment-list.assessmentTypeLabels.ongoing';
            default:
                return '';
        }
    }
}
