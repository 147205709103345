<ion-grid class="media-grid">
    <ion-row>
        <ion-col size="auto" class="media-col" *ngFor="let media of allMedia; trackBy: trackByMediaId">
            <div *ngIf="isMediaThumbnailLoaded(media); else thumbnailSkeleton">
                <div class="media-container">
                    <ion-checkbox
                        mode="md"
                        *ngIf="editMode"
                        (ionChange)="onMediaEdit($event, media)"
                        class="checkbox"
                        [checked]="checkedSelection.has(media)"
                        slot="start">
                    </ion-checkbox>
                    <ion-button class="media-content" fill="clear" no-padding (click)="onMediaClick(media)">
                        <img class="img-content" *ngIf="media.type === 'Image'" [src]="media.signedThumbnailUrl?.url" [alt]="'media-list.media-thumbnail.description' | translate"/>
                        <img class="img-content" *ngIf="media.type === 'Video'" src="/assets/images/video-graphic.png" [alt]="'media-list.media-thumbnail.description' | translate"/>
                        <span *ngIf="media.type === 'Video'" class="media-duration">
                            {{ media.metadata.duration | minutesToSeconds }}
                        </span>
                    </ion-button>
                    <ion-label class="media-name"> {{ media.title }} </ion-label>
                    <div class="media-detail">
                        <ion-label class="media-creationdate"> {{ media.updatedAt | date: 'MM/dd/yy' }} </ion-label>
                        <ion-button [disabled]="media.taggedStudents.length === 0" fill="clear" mode="ios" class="media-tagged-student" (click)="presentStudentList(media)">
                            <div
                                [ngClass]="{'icon-small-groups-color': media.taggedStudents.length > 0}"
                                class="action-button__icon label-icon icon-small_groups" aria-hidden="true">
                            </div>
                        </ion-button>
                        <div
                            *ngIf="media.id === currentMedia?.id"
                            class="student-list-container"
                            [ngClass]="{'empty-student-list-container': studentList?.length === 0 }"
                        >
                            <ng-container *ngTemplateOutlet="studentListTemplate; context:{ studentsToShow: 4, students: studentList, class: 'student-list' }"></ng-container>
                            <ng-container *ngTemplateOutlet="studentListTemplate; context:{ studentsToShow: 3, students: studentList, class: 'student-list student-list--x-small' }"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #thumbnailSkeleton>
                <div class="media-container">
                    <ion-checkbox
                        mode="md"
                        *ngIf="editMode"
                        (ionChange)="onMediaEdit($event, media)"
                        class="checkbox"
                        slot="start">
                    </ion-checkbox>
                    <ion-button class="media-content media-content--skeleton" fill="clear" no-padding></ion-button>
                    <ion-spinner class="media-loader"></ion-spinner>
                    <ion-label class="media-name">
                        <ion-skeleton-text animated style="width: 50%;"></ion-skeleton-text>
                    </ion-label>
                    <ion-label class="media-creationdate">
                        <ion-skeleton-text animated style="width: 50%;"></ion-skeleton-text>
                    </ion-label>
                </div>
            </ng-template>
        </ion-col>
    </ion-row>
</ion-grid>

<ng-template #studentListTemplate let-studentsToShow="studentsToShow" let-students="students" let-class="class">
    <ul [ngClass]="[class]">
        <li
            *ngFor="let student of students; let mediaIndex=index"
            ngClass="{'student-list-item': mediaIndex < studentsToShow}"
        >
            <div class="item-content" *ngIf="mediaIndex < studentsToShow">
                <ion-avatar class="avatar">
                    <span class="avatar__initials">{{getStudentInitials(student)}}</span>
                </ion-avatar>
                <ion-label class="item-content__label">{{student.rumbaUser.firstName + ' ' + student.rumbaUser.lastName}}</ion-label>
            </div>
        </li>
        <li *ngIf="students.length > studentsToShow" class="media-grid-more-student">
            {{ 'media-grid-list.more-students-title' | translate: {studentCount: (studentList.length - studentsToShow)} }}
        </li>
    </ul>
</ng-template>
