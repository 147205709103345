import { StudentMetadataForClass, StudentMetadataForClassState } from './students.model';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StudentMetadataForClassActions, StudentsActionTypes } from './students.actions';

export const adapter: EntityAdapter<StudentMetadataForClass> = createEntityAdapter<StudentMetadataForClass>();
export const initialState: StudentMetadataForClassState = adapter.getInitialState({
    isLoading: false,
    selectedDetailsType: undefined
});

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

export function reducer(state = initialState, action: StudentMetadataForClassActions): StudentMetadataForClassState {
    switch (action.type) {
        case StudentsActionTypes.SetSelectedDetailsType:
            return { ...state, selectedDetailsType: action.payload.detailsType };
        case StudentsActionTypes.LoadStudentMetadataForClassStart:
            return { ...state, isLoading: true };
        case StudentsActionTypes.LoadStudentMetadataForClass:
            return adapter.addAll(action.payload.studentMetadataForClass, { ...state, isLoading: false });
        default:
            return state;
    }
}
