import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { selectSelectedClassRosterId } from '../class-roster/store/class-roster.selectors';
import { AppState } from '../core.state';

@Injectable({
    providedIn: 'root'
})
export class ClassExistsGuard implements CanActivate {
    constructor(private store: Store<AppState>) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.pipe(
            withLatestFrom(this.store.pipe(select(selectSelectedClassRosterId))),
            map(([, selectedClass]) => !!selectedClass)
        );
    }
}
