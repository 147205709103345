import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {
    @Input() translatedTitle: string;
    @Input() translatedDescription: string;
    @Input() showHeader = true;
    @Input() translatedButtonText = 'alert-header.ok';

    imageMaxUploadSize = environment.mediaMaxUploadSize.Image / 1048576;
    videoMaxUploadSize = environment.mediaMaxUploadSize.Video / 1048576;

    constructor(private modalController: ModalController) {}

    closeModal() {
        this.modalController.dismiss();
    }
}
