import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { selectRouterState } from '../../core.state';
import { SessionStorageService } from '../../session-storage/session-storage.service';
import { TitleService } from '../../title/title.service';
import {
    ActionSettingsChangeLocale,
    SettingsActions,
    SettingsActionTypes
} from './settings.actions';
import { State } from './settings.model';
import { selectSettingsState } from './settings.selectors';
import { Logger } from '../../logging/logger';

export const SETTINGS_KEY = 'SETTINGS';

@Injectable()
export class SettingsEffects {
    constructor(
        private actions$: Actions<SettingsActions>,
        private store: Store<State>,
        private storageService: SessionStorageService,
        private translateService: TranslateService,
        private titleService: TitleService,
        private router: Router,
        private logger: Logger
    ) {}

    @Effect({ dispatch: false })
    persistSettings = this.actions$.pipe(
        ofType(SettingsActionTypes.CHANGE_LOCALE),
        withLatestFrom(this.store.pipe(select(selectSettingsState))),
        tap(([action, settings]) =>
            // should this be local instead of session?
            this.storageService.setItem(SETTINGS_KEY, settings)
        )
    );

    @Effect({ dispatch: false })
    setTranslateServiceLanguage = this.store.pipe(
        select(selectSettingsState),
        map(settings => settings.locale),
        distinctUntilChanged(),
        tap(locale => this.translateService.use(locale))
    );

    @Effect({ dispatch: false })
    changeLocaleViaQueryParam = this.actions$.pipe(
        ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
        withLatestFrom(this.store.pipe(select(selectRouterState))),
        tap(([action, routerState]) => {
            const { queryParams } = routerState.state;
            if (queryParams) {
                const { locale } = queryParams;
                if (locale) {
                    this.logger.info('got locale request', locale);
                    this.store.dispatch(
                        new ActionSettingsChangeLocale({ locale })
                    );
                    const newTree = this.router.parseUrl(
                        this.router.routerState.snapshot.url
                    );
                    this.router.navigateByUrl(newTree);
                }
            }
        })
    );

    @Effect({ dispatch: false })
    setTitle = merge(
        this.actions$.pipe(ofType(SettingsActionTypes.CHANGE_LOCALE)),
        this.router.events.pipe(filter(event => event instanceof ActivationEnd))
    ).pipe(
        tap(() => {
            this.titleService.setTitle(
                this.router.routerState.snapshot.root,
                this.translateService
            );
        })
    );
}
