<div class="notes-container">
    <!-- Loading notes - Show skeletons -->
    <ion-list class="list" *ngIf="isLoadingNotes$ | async" no-padding>
        <ion-item *ngFor="let item of [0,1,2]" class="list-item list-item--todo-item" lines="full">
            <ion-chip class="chip" slot="start" outline color="dark">
                <ion-label class="chip__label">
                    <ion-skeleton-text animated style="min-width: 70px;"></ion-skeleton-text>
                </ion-label>
            </ion-chip>
            <ion-label>
                <h3 class="list-item__title list-item__title--ellipsis">
                    <ion-skeleton-text animated style="width: 90%;"></ion-skeleton-text>
                </h3>
                <h4 class="list-item__subtitle">
                    <ion-chip class="inline-avatar">
                        <ion-avatar class="avatar">
                            <span class="avatar__initials">
                                <ion-skeleton-text animated style="min-width: 20px;"></ion-skeleton-text>
                            </span>
                        </ion-avatar>
                        <ion-label class="avatar__label">
                            <ion-skeleton-text animated style="width: 40%;"></ion-skeleton-text>
                        </ion-label>
                    </ion-chip>
                </h4>
            </ion-label>
        </ion-item>
    </ion-list>

    <!-- Notes are loaded. Show real content -->
    <ion-list class="list" *ngIf="!(isLoadingNotes$ | async)" no-padding>

        <!-- zero state when there are no notes -->
        <ion-item class="list-item list-item--zero-state" *ngIf="allNotes?.length === 0" lines="none" text-wrap>
            <span class="zero-state zero-state__title" [innerHtml]="zeroStateText"></span> 
        </ion-item>

        <!-- has notes state -->
        <div *ngIf="count > 0">
            <ion-item *ngFor="let note of allNotes; trackBy: trackByNoteId" class="list-item" lines="full" (click)="presentNotesMenuForEdit(note.id)">
                <div class='content-wrapper'>
                    <div class='content-wrapper-item'>
                        <ion-checkbox *ngIf="editMode" (ionChange)="toggleSelected($event, note)" class="checkbox" slot="start">
                        </ion-checkbox>
                        <ion-chip *ngIf="note.type === 'todo'" class="todo-chip"
                            [ngClass]="{ 'todo-chip--no-due-date': !note.dueDateISO }" slot="start" outline color="dark">
                            <div class="todo-chip-label">
                                <div class="todo-chip-label__title">
                                    {{ 'notes-page.todo-chip.title' | translate }}
                                </div>
                                <div class="todo-chip-label__date">
                                    <span class="todo-chip-label__due">
                                        {{ 'notes-page.todo-chip.date' | translate }}
                                    </span>
                                    <span>{{ note.dueDateISO | date: 'MM/dd' }}</span>
                                </div>
                            </div>
                        </ion-chip>
                        <ion-label class="list-item__label">
                            <h3 class="list-item__title">
                                <ion-button class="list-item-label__button"
                                    fill="clear" [title]="'notes-page.editNote' | translate">
                                    {{ note.body }}
                                </ion-button>
                            </h3>
                            <h4 *ngIf="note.students.length > 0" class="list-item__subtitle">

                                <ion-button *ngIf="note.students.length > 2" (click)="showStudents()"
                                    class="content-expandable__button action-button action-button--link"
                                    [ngClass]="{ 'content-expandable__button--expanded': isExpanded }"
                                    [title]="'notes-page.show-all-students' | translate">
                                    <div class="action-button__icon label-icon icon-chevron" slot="end" aria-hidden="true">
                                    </div>
                                    <ion-label class="action-button__label">{{ note.students.length }} Students
                                    </ion-label>
                                </ion-button>

                                <span class="content-expandable"
                                    [ngClass]="{ 'content-expandable--collapsed': !isExpanded && note.students.length > 2 }">
                                    <ion-chip *ngFor="let student of note.students" class="inline-avatar">
                                        <ion-avatar class="avatar">
                                            <span class="avatar__initials">{{ student.initials }}</span>
                                        </ion-avatar>
                                        <ion-label class="avatar__label">{{ student.name }}</ion-label>
                                    </ion-chip>
                                </span>
                            </h4>
                        </ion-label>
                    </div>
                    <ion-note class="list-item__info" slot="end" color="default">
                        {{ note.updatedDate | date: 'MM/dd' }}
                    </ion-note>
                </div>
            </ion-item>
        </div>
    </ion-list>
</div>
