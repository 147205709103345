import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgramState, State } from './program.model';

export const selectProgramState = createFeatureSelector<
    State,
    ProgramState
>('program');

export const selectProgramById = (programId: string) =>
    createSelector(
        selectProgramState,
        state => state.entities[programId]
    );

export const selectSelectedProgramId = createSelector(
    selectProgramState,
    state => state.currentRootProgramIdentifier
);

export const selectCurrentProgram = createSelector(
    selectProgramState,
    state => state.entities[state.currentRootProgramIdentifier]
);

export const selectLoadingRootProgramIdentifierIds = createSelector(
    selectProgramState,
    state => state.loadingRootProgramIdentifierIds
);

export const selectHierarchyForSelectedProgram = (programId: string) => createSelector(
    selectProgramById(programId),
    state => state ? state.hierarchy : undefined
);
