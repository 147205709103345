<ng-select
    [items]="classList"
    bindLabel="className"
    bindValue="classId"
    [(ngModel)]="selectedClassId"
    (change)="rosterChanged($event)"
    class="class-select"
    [ngClass]="[cssClassName]"
    appendTo="body"
    [clearable]="false"
    [searchable]="false"
    [isOpen]="isDropdownOpen"
    (click)="toggleDropdown()"
>
</ng-select>
