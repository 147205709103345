import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
    ShowFooter = '[Layout] Show Footer',
    HideFooter = '[Layout] Hide Footer'
}

export class ActionShowFooter implements Action {
    readonly type = LayoutActionTypes.ShowFooter;
}

export class ActionHideFooter implements Action {
    readonly type = LayoutActionTypes.HideFooter;
}

export type LayoutActions =
    | ActionShowFooter
    | ActionHideFooter;
