import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { ActionNavigateToErrorPage } from 'src/app/core/error/store/error.actions';
import { AppState } from '../../core.state';
import { Injectable } from '@angular/core';
import {
    ActionLoadProducts,
    ProductActionTypes,
    ActionLoadProductsSuccess,
    ActionLoadProductFailure
} from './product.actions';
import { map, switchMap, withLatestFrom, filter, catchError, tap, first } from 'rxjs/operators';
import { selectRbsCredentials } from '../../auth/store/auth.selectors';
import { ProductService } from '../product.service';
import { of } from 'rxjs';
import { selectProductState, selectProductsByIds } from './product.selectors';
import { SessionStorageService } from '../../session-storage/session-storage.service';
import { AuthActionTypes } from '../../auth/store/auth.actions';
import { ActionStartSetSelectProgram } from '../../program/store/program.actions';

const STATE_STORAGE_KEY = 'PRODUCT';
@Injectable()
export class ProductEffects {
    constructor(
        private actions$: Actions<Action>,
        private store: Store<AppState>,
        private productService: ProductService,
        private sessionStorageService: SessionStorageService
    ) {}

    @Effect()
    loadProducts$ = this.actions$.pipe(
        ofType<ActionLoadProducts>(ProductActionTypes.LoadProducts),
        map(action => action.payload),
        withLatestFrom(this.store.pipe(select(selectRbsCredentials))),
        filter(([, cred]) => !!cred),
        switchMap(([{ productIds }, { userId, token }]) => this.store.pipe(
            select(selectProductsByIds(productIds)),
            map((products) => {
                const productIdsToFetch = productIds.filter((_, index) => !products[index]);
                const hasProductsInStore = (productIds.length - productIdsToFetch.length) > 0;
                return { productIdsToFetch, userId, token, hasProductsInStore };
            }),
            first()
        )),
        switchMap(({ productIdsToFetch, userId, token, hasProductsInStore }) => {
            if (productIdsToFetch.length > 0) {
                // Throw error on product load failure only if no valid products in store
                const throwErrorOnProductLoadFailure = !hasProductsInStore;
                return this.productService.loadProducts(
                    productIdsToFetch,
                    userId,
                    token,
                    throwErrorOnProductLoadFailure
                );
            }
            return of([]);
        }),
        map(products => new ActionLoadProductsSuccess({ products })),
        catchError(error => {
            const description = {
                origin: 'Load Products',
                message: error,
            };
            return [
                new ActionNavigateToErrorPage({ description }),
                new ActionLoadProductFailure({ error }),
            ];
        })
    );

    @Effect()
    loadProductsSuccess$ = this.actions$.pipe(
        ofType<ActionLoadProductsSuccess>(ProductActionTypes.LoadProductsSuccess),
        map(() => new ActionStartSetSelectProgram()),
    );

    @Effect({ dispatch: false })
    persistState = this.actions$.pipe(
        ofType(ProductActionTypes.LoadProductsSuccess),
        withLatestFrom(this.store.pipe(select(selectProductState))),
        tap(([, state]) => {
            this.sessionStorageService.setItem(STATE_STORAGE_KEY, state);
        })
    );

    @Effect({ dispatch: false })
    clearPersistedState = this.actions$.pipe(
        ofType(AuthActionTypes.LOGOUT),
        tap(() => {
            this.sessionStorageService.removeItem(STATE_STORAGE_KEY);
        })
    );
}
