import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActionSendTelemetryEventAndLogout } from 'src/app/core/auth/store/auth.actions';
import { ClassRoster } from 'src/app/core/class-roster/store/class-roster.model';
import { ClassRosterService } from 'src/app/core/class-roster/class-roster.service';
import { AppState } from 'src/app/core/core.state';
import { SearchService } from 'src/app/core/search/search.service';
import { UserMenuComponent } from 'src/app/core/components/user-menu/user-menu.component';
import { TitleService } from 'src/app/core/title/title.service';
import { Logger } from 'src/app/core/logging/logger';
import { MediaService } from 'src/app/core/media/media.service';
import { ProgramService } from 'src/app/core/program/program.service';
import { selectIsAuthenticating } from 'src/app/core/auth/store/auth.selectors';
import { ErrorService } from '../../error/error.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    readonly scoutLogoUrl = '/assets/images/logos/scout-logo.svg';
    classList$: Observable<ClassRoster[]>;
    selectedClassId$: Observable<string>;
    isUnrecoverableErrorMode$: Observable<boolean>;
    readonly scoutLogoUrlSmall = '/assets/images/logos/rs-rev.svg';

    title$ = this.titleService.sectionTitle;

    program$ = this.programService.currentProgram$;
    isAuthenticating$ = this.store.pipe(select(selectIsAuthenticating));

    constructor(
        private store: Store<AppState>,
        public popoverController: PopoverController,
        private classRosterService: ClassRosterService,
        private titleService: TitleService,
        private mediaService: MediaService,
        private logger: Logger,
        private programService: ProgramService,
        private searchService: SearchService,
        private errorService: ErrorService
    ) {}

    ngOnInit() {
        this.classList$ = this.classRosterService.classList$;
        this.selectedClassId$ = this.classRosterService.selectedClassId$;
        this.isUnrecoverableErrorMode$ = this.errorService.isUnrecoverableErrorMode$;

        this.program$.subscribe(program => {
            this.logger.info('program updated', program);
        });
    }

    onClassSelectionChange(classId: string) {
        this.logger.info('onClassSelectionChange', classId);
        this.classRosterService.setSelectedClassRosterId(classId);
    }

    async presentSearchMenu() {
        this.searchService.openSearchMenu();
    }

    async presentUserMenu(ev: any) {
        const popover = await this.popoverController.create({
            component: UserMenuComponent,
            cssClass: 'user-menu__popover',
            event: ev,
            translucent: true,
            showBackdrop: false
        });

        popover.onDidDismiss().then(dataReturned => {
            if (dataReturned.data === 'search') {
                this.presentSearchMenu();
            }

            if (dataReturned.data === 'support') {
                // TODO: call the open support (FAQs) page method
            }

            if (dataReturned.data === 'signout') {
                this.logout();
            }
        });

        return await popover.present();
    }

    logout() {
        this.store.dispatch(new ActionSendTelemetryEventAndLogout());
    }

    onMediaCaptured(event) {
        const files: FileList = event.target.files;
        if (files && files.length > 0) {
            this.mediaService.addMediaDispatchAction(files[0]);
            event.target.value = '';
        }
    }
}
