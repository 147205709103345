import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MenuController } from '@ionic/angular';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { ErrorService } from 'src/app/core/error/error.service';
import { selectCurrentClassRoster } from '../../class-roster/store/class-roster.selectors';
import { AppState, selectRouterState } from '../../core.state';
import { ObservationsDataService } from '../../observations/observations.data.service';
import { ProgramService } from '../../program/program.service';
import { Program } from '../../program/store/program.model';
import { selectCurrentProgram } from '../../program/store/program.selectors';
import {
    ActionCloseAssessmentMenu,
    ActionLoadAssessmentStats,
    ActionLoadAssessmentStatsStart,
    ActionOpenAssessmentMenu,
    DashBoardActionTypes
} from './dashboard.actions';

@Injectable()
export class DashboardEffects {

    constructor(
        private actions$: Actions<Action>,
        private menuController: MenuController,
        private store$: Store<AppState>,
        private programService: ProgramService,
        private observationsDataService: ObservationsDataService,
        private afsAuth: AngularFireAuth,
        private errorService: ErrorService,
    ) {}

    @Effect()
    loadAssessmentStats$ = combineLatest(
        this.store$.pipe(select(selectCurrentClassRoster)),
        this.store$.pipe(select(selectCurrentProgram)),
        this.store$.pipe(
            select(selectRouterState),
            filter(router => !!router && router.state.url.endsWith('dashboard')),
        )
    ).pipe(
        filter(([classRoster, program]) => !!classRoster && !!program),
        map(([classRoster, program]) => ({
            classRoster,
            programId: program.id,
            programItems: this.programService.getFlatHierarchy(program)
        })),
        tap(() => this.store$.dispatch(new ActionLoadAssessmentStatsStart())),
        switchMap(({ classRoster, programId, programItems }) => this.afsAuth.authState.pipe(
            filter(user => !!user),
            switchMap(user => forkJoin(
                programItems.map(programItem => this.observationsDataService
                    .fetchObservationStats(classRoster, programId, programItem, user.uid)
                    .pipe(
                        map(observations => observations
                            .filter(observation => classRoster.studentIds.includes(observation.studentId))),
                        map(observations => {
                            const percentObserved = observations.length / classRoster.studentIds.length * 100;
                            const updatedAt = percentObserved > 0 ? observations[0].updatedAt : 0;
                            return {
                                ...programItem,
                                programId,
                                percentObserved,
                                updatedAt
                            };
                        }),
                        catchError(error => {
                            const description = {
                                origin: 'Load Dashboard Assessment Stats',
                                payload: { classId: classRoster.classId, programId },
                                message: error
                            };
                            this.errorService.processError(description);
                            return of([] as any);
                        })
                    )
                )
            ))
        )),
        map(assessmentStats => new ActionLoadAssessmentStats({ assessmentStats }))
    );

    @Effect({ dispatch: false })
    openDashboard$ = this.actions$.pipe(
        ofType<ActionOpenAssessmentMenu>(DashBoardActionTypes.OpenAssessmentMenu),
        tap(() => {
            this.menuController.enable(true, 'allAssessmentsMenu').then(() => {
                this.menuController.open('allAssessmentsMenu');
            });
        })
    );

    @Effect({ dispatch: false })
    closeDashboard$ = this.actions$.pipe(
        ofType<ActionCloseAssessmentMenu>(DashBoardActionTypes.CloseAssessmentMenu),
        tap(() => {
            this.menuController.close('allAssessmentsMenu');
        })
    );
}
