import { EntityState } from '@ngrx/entity';
import { AppState } from '../../core.state';

export enum MediaType {
    Image = 'Image',
    Video = 'Video'
}

export interface SignedUrl {
    url: string;
    expireAt: number;
}

export interface Media {
    id: string;
    type: MediaType;
    title: string;
    description: string;
    downloadUrl: string;
    thumbnails: Thumbnails;
    classId: string;
    createdBy: string;
    createdAt: number;
    updatedAt: number;
    taggedStudents: string[];
    metadata: Metadata;
    deleted: boolean;

    signedDownloadUrl?: SignedUrl;
    signedThumbnailUrl?: SignedUrl;

    thumbnailLoading: boolean;
    mediaLoading: boolean;
}

export interface Metadata {
    name: string;
    contentType: string;
    ref: string;
    duration?: number;
}

// TODO: Confirm sizes
export interface Thumbnails {
    h128: string;
    h256: string;
}

export enum MediaFilter {
    AllMedia = 'All Media',
    Images = 'Images',
    Videos = 'Videos',
    StudentMedia = 'Student Media'
}

export interface MediaState extends EntityState<Media> {
    filter: MediaFilter;
    isLoading: boolean;
    isMediaMenuOpen: boolean;
    selectedMediaId: string;
}

export interface State extends AppState {
    media: MediaState;
}

export interface SignedUrlVM {
    mediaRef: string;
    signedUrl: string;
    expiresIn: number;
 }
