import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, forkJoin } from 'rxjs';
import { filter, map, switchMap, first, tap, catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/core/error/error.service';
import { selectCurrentUser } from '../auth/store/auth.selectors';
import { selectCurrentClassRoster, selectSelectedClassRosterId } from '../class-roster/store/class-roster.selectors';
import {
    selectNotesForRecentActivity,
    selectIsLoadingNotes,
    selectTodosForDasboard
} from '../notes/store/notes.selectors';
import { selectMediaForRecentActivity, selectIsLoading } from '../media/store/media.selectors';
import { AppState } from '../core.state';
import { ObservationsDataService } from '../observations/observations.data.service';
import { Observation } from '../observations/store/observations.model';
import { selectProductsByIds, selectIsLoadingProducts } from '../product/store/product.selectors';
import { ProgramService } from '../program/program.service';
import {
    ActionCloseAssessmentMenu,
    ActionOpenAssessmentMenu,
    ActionSetRecentActivityLoading,
    ActionSetIsAllAssessmentsMenuOpen,
} from './store/dashboard.actions';
import { ProductProgramSkeleton, RecentActivityCategory } from './store/dashboard.model';
import { selectSelectedProgramId, selectLoadingRootProgramIdentifierIds } from '../program/store/program.selectors';
import {
    selectDashboardAssessmentStats,
    selectIsLoadingDashboardStats,
    selectIsLoadingRecentActivity,
    selectIsAllAssessmentsMenuOpen,
} from './store/dashboard.selectors';

@Injectable({ providedIn: 'root' })
export class DashboardService {

    isLoadingDashboardStats$ = this.store.pipe(select(selectIsLoadingDashboardStats));
    isLoadingRecentActivity$ = this.store.pipe(select(selectIsLoadingRecentActivity));
    assessmentStats$ = this.store.pipe(select(selectDashboardAssessmentStats));
    isAllAssessmentsMenuOpen$ = this.store.pipe(select(selectIsAllAssessmentsMenuOpen));
    allClassPrograms$: Observable<ProductProgramSkeleton[]>;

    isSelectedProgramLoading$ = combineLatest(
        this.store.pipe(select(selectSelectedProgramId)),
        this.store.pipe(select(selectLoadingRootProgramIdentifierIds))
    ).pipe(map(([selectedProgramId, programLoading]) => {
        const isProgramLoading = selectedProgramId && programLoading.includes(selectedProgramId) || false;
        return !selectedProgramId || isProgramLoading;
    }));

    numberOfResults = 20;
    recentActivitiesForClass$ = this.store.pipe(
        select(selectSelectedClassRosterId),
        filter(classId => !!classId),
        tap(() => this.setIsRecentActivityLoading(true)),
        switchMap(classId => combineLatest(
            this.store.pipe(select(selectCurrentUser)),
            this.afsAuth.authState,
            this.store.pipe(select(selectIsLoadingProducts))
        ).pipe(
            filter(([currentUser, firebaseUser, isLoadingProducts]) =>
                !!currentUser && !!firebaseUser && !isLoadingProducts
            ),
            map(([currentUser]) => ({ classId, currentUser })),
            first()
        )),
        switchMap(({ classId, currentUser }) =>
            forkJoin(
                this.store.pipe(
                    select(selectIsLoadingNotes),
                    filter(isLoading => !isLoading),
                    switchMap(() =>
                        this.store.pipe(select(selectNotesForRecentActivity(classId, this.numberOfResults)))),
                    first()
                ),
                this.store.pipe(
                    select(selectIsLoading),
                    filter(isLoading => !isLoading),
                    switchMap(() =>
                        this.store.pipe(select(selectMediaForRecentActivity(classId, this.numberOfResults)))),
                    first()
                ),
                this.getAssessmentsForRecentActivity(classId, currentUser.userId).pipe(first())
            ).pipe(
                map(([notesResult, mediaResult, assessmentsResult]) => {
                    const mediaResults = mediaResult.map(media => ({
                        category: RecentActivityCategory.Media,
                        title: media.title,
                        updatedAt: media.updatedAt,
                        id: media.id,
                        object: media,
                    }));
                    const notesResults = notesResult.map(note => ({
                        category: RecentActivityCategory.Note,
                        title: note.body,
                        updatedAt: note.updatedDate,
                        id: note.id,
                        object: note,
                    }));
                    const assessmentsResults = [];
                    assessmentsResult.forEach(assessment => {
                        const { observation, programItem } = assessment;
                        if (programItem) {
                            assessmentsResults.push({
                                category: RecentActivityCategory.Assessment,
                                title: programItem.name,
                                updatedAt: observation.updatedAt,
                                id: observation.id,
                                object: programItem,
                                observation: observation
                            });
                        }
                    });
                    return [
                        ...mediaResults,
                        ...notesResults,
                        ...assessmentsResults
                    ].sort((a, b) => b.updatedAt - a.updatedAt).slice(0, this.numberOfResults);
                }),
                tap(() => this.setIsRecentActivityLoading(false)),
                catchError(error => {
                    const description = {
                        origin: 'Load Recent Activities For Class',
                        payload: { classId, userId: currentUser.userId },
                        message: error,
                    };
                    this.errorService.processError(description);
                    return of([]);
                }),
            )
        ),
    );

    dashboardTodos$ = this.store.pipe(
        select(selectSelectedClassRosterId),
        filter(classId => !!classId),
        switchMap(classId => this.store.pipe(select(selectTodosForDasboard(classId, this.numberOfResults))))
    );

    constructor(
        private store: Store<AppState>,
        private afsAuth: AngularFireAuth,
        private observationsDataService: ObservationsDataService,
        private programService: ProgramService,
        private errorService: ErrorService,
    ) {
        this.allClassPrograms$ = this.store.pipe(
            select(selectCurrentClassRoster),
            filter(classRoster => {
                return !!classRoster;
            }),
            switchMap(({ productIds }) => {
                return this.store.pipe(
                    select(selectProductsByIds(productIds)),
                    map(products => products.filter(product => !!product)),
                    map(products => {
                        const productProgramArray: ProductProgramSkeleton[] = [];
                        const arrayProductProgram: ProductProgramSkeleton[] = products.reduce((accu, product) => {
                            return accu.concat(
                                product.programs.map(program => {
                                    return { ...program, productId: product.id };
                                })
                            );
                        }, productProgramArray);
                        return arrayProductProgram.sort((a, b) => {
                            return a.programName.localeCompare(b.programName);
                        });
                    })
                );
            })
        );
    }

    openDashboard() {
        return this.store.dispatch(new ActionOpenAssessmentMenu());
    }

    closeDashboard() {
        return this.store.dispatch(new ActionCloseAssessmentMenu());
    }

    setIsAllAssessmentsMenuOpen(isAllAssessmentsMenuOpen: boolean) {
        this.store.dispatch(new ActionSetIsAllAssessmentsMenuOpen({ isAllAssessmentsMenuOpen }));
    }

    setIsRecentActivityLoading(loading: boolean) {
        this.store.dispatch(new ActionSetRecentActivityLoading({ loading }));
    }

    getAssessmentsForRecentActivity(classId: string, createdBy: string) {
        return this.observationsDataService.getObservationsForRecentActivity(classId, createdBy, this.numberOfResults)
            .pipe(
                map(querySnapshot => querySnapshot.docs.map(snapshot => snapshot.data() as Observation)),
                switchMap((observations) => {
                    const filteredObservation = [];
                    if (observations.length > 0) {
                        observations.sort((a, b) => b.updatedAt - a.updatedAt);
                        observations.forEach((observation, i) => {
                            const isAssessmentIdExist = filteredObservation.find(
                                data => data.assessmentId === observation.assessmentId
                            );
                            if (!isAssessmentIdExist) {
                                filteredObservation.push(observation);
                            }
                        });
                        return combineLatest(filteredObservation.map(observation =>
                            this.programService.getProgramItemForObservation(observation)
                        ));
                    }
                    return of([]);
                })
            );
    }
}
