import { ServiceWorkerState } from './service-worker.model';
import { ServiceWorkerActions, ServiceWorkerActionTypes } from './service-worker.actions';

export const initialState: ServiceWorkerState = {
    isServiceWorkerUpdateModalOpen: false
};

export function reducer(state = initialState, action: ServiceWorkerActions) {
    switch (action.type) {
        case ServiceWorkerActionTypes.SetIsServiceWorkerUpdateModalOpen:
            return {...state, isServiceWorkerUpdateModalOpen: action.payload.isServiceWorkerUpdateModalOpen};
        default:
            return state;
    }
}
