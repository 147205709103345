<app-dashboard-assessment-hierarchy-group
    [programHierarchy]="programHierarchy$ | async"
    *ngIf="!(isLoading$ | async); else loadingProgram"
    [hierarchyLevel]="0"
></app-dashboard-assessment-hierarchy-group>

<ng-template #loadingProgram>
    <ion-card-content class="card-content custom-skeleton">
        <ion-list class="list">
            <ion-item *ngFor="let item of [0, 1, 2]" class="list-item" lines="full">
                <ion-label>
                    <h3 class="list-item__title list-item__title--ellipsis">
                        <ion-skeleton-text animated style="width: 80%;"></ion-skeleton-text>
                    </h3>
                </ion-label>
            </ion-item>
        </ion-list>
    </ion-card-content>
</ng-template>
