import { Injectable } from '@angular/core';

@Injectable()
export class DateService {
    convertDateISOToNumber(dateString: string) {
        // TODO: Ionic uses ISO date format in the picker, doesn't parse right for some reason.
        const [year, month, day] = dateString.split('-');
        const timeStartIndex = day.indexOf('T');
        const trimmedDay = timeStartIndex > 0 ? day.substring(0, timeStartIndex) : day; // remove time
        return new Date(`${month}/${trimmedDay}/${year}`).getTime();
    }
}
