<ion-app class="app-main flex-container-col" [ngClass]="{ 'hide-footer-class-select': hideFooter$ | async }">
    <header class="header bar" role="banner" [ngClass]="{ 'hide': (classRoasterIsLoading$ | async) }">
        <app-header class="bar-header"></app-header>
        <nav
            *ngIf="!isAuthenticating"
            class="bar-class-select"
            role="navigation"
            aria-label="Class Selection"
        >
            <app-class-select
                class="class-select"
                (selectionChange)="onClassSelectionChange($event)"
                [selectedClassId]="selectedClassId$ | async"
                [classList]="classList$ | async"
                slot="secondary"
                *ngIf="!!(selectedClassId$ | async)"
                cssClassName="class-select--app"
            ></app-class-select>
        </nav>
    </header>
    <!-- app level loading indicator -->
    <ion-spinner *ngIf="isAuthenticating" class="status-spinner"></ion-spinner>
    <main *ngIf="!isAuthenticating" class="flex-container-row" role="main">
        <app-navbar *ngIf="!!(selectedClassId$ | async) && !(isUnrecoverableErrorMode$ | async)" class="nav-bar left vertical"></app-navbar>
        <section class="flex-container flex-container-row page-area">
            <ion-content class="content-area flex-item">
                <router-outlet id="contentOutlet"></router-outlet>
            </ion-content>
        </section>
    </main>
    <footer *ngIf="!isAuthenticating && !!(selectedClassId$ | async)" class="bar footer" role="contentinfo">
        <app-navbar class="nav-bar horizontal"></app-navbar>
    </footer>
    <ion-menu
        *ngIf="!isAuthenticating"
        class="app-menu app-menu__notes"
        menuId="notesMenu"
        side="end"
        type="overlay"
        swipeGesture="false"
        contentId="contentOutlet"
    >
        <notes-menu class="notes-menu"></notes-menu>
    </ion-menu>
    <ion-menu
        *ngIf="!isAuthenticating"
        (ionDidOpen)="onAllAssessmentsMenuOpen()"
        (ionDidClose)="onAllAssessmentsMenuClose()"
        class="app-menu app-menu__dashboard-menu"
        menuId="allAssessmentsMenu"
        side="end"
        type="overlay"
        swipeGesture="false"
        contentId="contentOutlet"
    >
        <app-dashboard-menu class="dashboard-menu"></app-dashboard-menu>
    </ion-menu>
    <ion-menu
        *ngIf="!isAuthenticating"
        class="app-menu app-menu__media"
        menuId="mediaMenu"
        side="end"
        type="overlay"
        swipeGesture="false"
        contentId="contentOutlet"
    >
        <media-menu class="media-menu"></media-menu>
    </ion-menu>
    <ion-menu
        *ngIf="!isAuthenticating"
        class="app-menu app-menu__oral-chart"
        menuId="oralReadingChartMenu"
        side="end"
        type="overlay"
        swipeGesture="false"
        contentId="contentOutlet"
    >
        <app-oral-reading-chart-menu class="oral-chart"></app-oral-reading-chart-menu>
    </ion-menu>
    <ion-menu
        *ngIf="!isAuthenticating"
        class="app-menu app-menu__observation-report"
        menuId="observationReportFilterMenu"
        side="end"
        type="overlay"
        swipeGesture="false"
        contentId="contentOutlet"
    >
        <app-observation-filter-menu></app-observation-filter-menu>
    </ion-menu>
    <ion-menu
        *ngIf="!isAuthenticating"
        (ionDidOpen)="onSearchMenuOpen()"
        (ionDidClose)="onSearchMenuClose()"
        class="app-menu app-menu__search"
        menuId="searchMenu"
        side="end"
        type="overlay"
        swipeGesture="false"
        contentId="contentOutlet"
    >
        <app-search-menu class="search-menu"></app-search-menu>
    </ion-menu>
</ion-app>
