import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RumbaUserDetails } from 'src/app/core/user-profile/store/user-profile.model';
import { AssessmentService } from '../assessment/assessment.service';
import { selectCurrentUser, selectRbsCredentials } from '../auth/store/auth.selectors';
import { selectSelectedClassRosterId, selectSelectedStudentId } from '../class-roster/store/class-roster.selectors';
import { Comment } from '../comments/store/comments.model';
import { AppState, selectRouterState } from '../core.state';
import { selectMediaById } from '../media/store/media.selectors';
import { ObservationsService } from '../observations/observations.service';
import {
    ActionInsertStudentMetadataForClass,
    ActionSendTelemetryEventForExportStudentCSV,
    ActionSetSelectedDetailsType,
    ActionUpdateStudentMetadataForClass
} from './store/students.actions';
import { StudentMetadataForClass } from './store/students.model';
import { Observation } from '../observations/store/observations.model';
import { StudentAssessmentVM } from '../../students/components/assessments/components/students-assessments.component';
import { ProgramService } from '../program/program.service';
import { ActionNavigateToAssessmentView } from '../assessment/store/assessment.actions';
import { selectUserById } from '../user-profile/store/user-profile.selectors';
import {
    selectIsLoadingStudentMetadataForClass,
    selectSelectedDetailsType,
    selectStudentMetadataForClass
} from './store/students.selectors';

@Injectable({ providedIn: 'root' })
export class StudentsService {
    getDetailTypeFromRouterState$ = this.store$.pipe(
        select(selectRouterState),
        map(router => router.state.params.detailsType)
    );

    isLoadingStudentsInfo$ = this.store$.pipe(select(selectIsLoadingStudentMetadataForClass));

    detailsType$ = this.store$.pipe(
        select(selectSelectedDetailsType),
        filter(detailsType => !!detailsType)
    );

    selectedStudentId$ = this.store$.pipe(
        select(selectRouterState),
        map(({ state }) => state.params.studentId),
        filter(studentId => !!studentId)
    );

    constructor(
        private store$: Store<AppState>,
        private assessmentService: AssessmentService,
        private observationService: ObservationsService,
        private programService: ProgramService
    ) {}

    selectedStudent$ = this.store$.pipe(
        select(selectSelectedStudentId),
        switchMap(studentId =>
            this.store$.pipe(select(selectUserById(studentId)))
        )
    );

    studentMetadataForClass$ = combineLatest(
        this.store$.pipe(select(selectSelectedClassRosterId)),
        this.store$.pipe(select(selectSelectedStudentId))
    ).pipe(
        withLatestFrom(this.store$.pipe(select(selectCurrentUser))),
        filter(([, currentUser]) => !!currentUser),
        switchMap(([[classId, studentId], currentUser]) =>
            this.store$.pipe(select(selectStudentMetadataForClass(classId, studentId, currentUser.userId)))
        )
    );

    upsertStudentMetadataForClass(studentMetadataForClass: StudentMetadataForClass) {
        studentMetadataForClass.updatedAt = new Date().getTime();
        if (!studentMetadataForClass.createdAt) {
            studentMetadataForClass.createdAt = new Date().getTime();
        }
        let dataAction;
        if (studentMetadataForClass.id) {
            dataAction = new ActionUpdateStudentMetadataForClass({ studentMetadataForClass });
        } else {
            dataAction = new ActionInsertStudentMetadataForClass({ studentMetadataForClass });
        }
        this.store$.dispatch(dataAction);
    }

    setSelectedDetailType(detailsType: string) {
        this.store$.dispatch(new ActionSetSelectedDetailsType({ detailsType }));
    }

    getAllAssessmentAttachments(assessmentProgram: Array<{ assessmentId: string; programId: string }>) {
        return combineLatest(
            assessmentProgram.map(({ assessmentId, programId }) => {
                return this.assessmentService.getAssessment(assessmentId, programId);
            })
        );
    }

    getLatestCommentForAllObservations(observations: string[]) {
        return this.store$.pipe(
            select(selectRbsCredentials),
            // get latest comment for the observation
            switchMap(({ userId }) => {
                return combineLatest(
                    observations.map(observationId => {
                        return this.observationService.getLatestCommentByObservationId(observationId, userId).pipe(
                            map(querySnapshot => {
                                return querySnapshot.docs.map(document => {
                                    return document.data() as Comment;
                                });
                            })
                        );
                    })
                );
            }),
            map(commentArray => {
                return commentArray.map(comment => comment[0]);
            })
        );
    }

    getAllMediaDocuments(mediaIds: string[]) {
        return combineLatest(
            mediaIds.map(mediaId => {
                return this.store$.pipe(select(selectMediaById(mediaId)));
            })
        );
    }

    makeStudentAssessmentVM(observation: Observation): Observable<StudentAssessmentVM> {
        return this.programService.getProgramItemForObservation(observation);
    }

    getAllValidObservationsForStudent(studentId: string): Observable<Observation[]> {
        return combineLatest(
            this.store$.pipe(select(selectSelectedClassRosterId)),
            this.store$.pipe(select(selectCurrentUser))
        ).pipe(
            filter(([classId, userDetails]) => !!classId && !!userDetails),
            map(([classId, userDetails]) => {
                const { userId } = userDetails;
                return { classId, currentUserId: userId, studentId };
            }),
            switchMap(({ classId, currentUserId }) => {
                return this.observationService
                    .getAllValidObservationsForStudent(classId, currentUserId, studentId)
                    .pipe(map(querySnapshot => querySnapshot.docs.map(snapshot => snapshot.data() as Observation)));
            })
        );
    }

    navigateToObservation(observation: Observation) {
        const assessment = {
            type: observation.type.toString(),
            programId: observation.programId,
            assessmentId: observation.assessmentId,
            studentId: observation.studentId
        };
        this.store$.dispatch(new ActionNavigateToAssessmentView({ assessment }));
    }

    sendTelemetryEventForExportStudentCSV(student: RumbaUserDetails) {
        this.store$.dispatch(new ActionSendTelemetryEventForExportStudentCSV({ student }));
    }
}
