import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { LayoutService } from './layout.service';
import { reducer } from './store/layout.reducer';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature('layout', reducer)],
    providers: [LayoutService]
})
export class LayoutModule {}
