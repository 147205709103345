import { Action } from '@ngrx/store';
import { AssessmentStat } from './dashboard.model';

export enum DashBoardActionTypes {
    LoadAssessmentStatsStart = '[Dashboard] Load Assessment Stats Start',
    LoadAssessmentStats = '[Dashboard] Load Assessment Stats',
    SetRecentActivityLoading = '[Dashboard] Set Recent Activity Loading',
    OpenAssessmentMenu = '[Dashboard] Open Assessment Menu',
    CloseAssessmentMenu = '[Dashboard] Close Assessment Menu',
    SetIsAllAssessmentsMenuOpen = '[Dashboard] Set Is AllAssessments Menu Open'
}

export class ActionOpenAssessmentMenu implements Action {
    readonly type = DashBoardActionTypes.OpenAssessmentMenu;
}

export class ActionCloseAssessmentMenu implements Action {
    readonly type = DashBoardActionTypes.CloseAssessmentMenu;
}

export class ActionLoadAssessmentStatsStart implements Action {
    readonly type = DashBoardActionTypes.LoadAssessmentStatsStart;
}

export class ActionSetRecentActivityLoading implements Action {
    readonly type = DashBoardActionTypes.SetRecentActivityLoading;
    constructor(public payload: { loading: boolean }) {}
}

export class ActionLoadAssessmentStats implements Action {
    readonly type = DashBoardActionTypes.LoadAssessmentStats;
    constructor(public payload: { assessmentStats: AssessmentStat[] }) {}
}
export class ActionSetIsAllAssessmentsMenuOpen implements Action {
    readonly type = DashBoardActionTypes.SetIsAllAssessmentsMenuOpen;
    constructor(public payload: { isAllAssessmentsMenuOpen: boolean }) {}
}

export type DashboardActions =
    | ActionLoadAssessmentStatsStart
    | ActionLoadAssessmentStats
    | ActionOpenAssessmentMenu
    | ActionCloseAssessmentMenu
    | ActionSetRecentActivityLoading
    | ActionSetIsAllAssessmentsMenuOpen;
