import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssessmentService } from 'src/app/core/assessment/assessment.service';
import { AssessmentNavigationSubType, AssessmentNavigationType } from 'src/app/core/assessment/store/assessment.model';
import { DashboardService } from 'src/app/core/dashboard/dashboard.service';
import { ProgramService } from 'src/app/core/program/program.service';
import { ProgramItem } from 'src/app/core/program/store/program.model';

@Component({
    selector: 'app-dashboard-assessment-list',
    templateUrl: './dashboard-assessment-list.component.html',
    styleUrls: ['./dashboard-assessment-list.component.scss']
})
export class DashboardAssessmentListComponent implements OnInit {
    assessmentList$: Observable<ProgramItem[]>;
    isLoading$: Observable<boolean>;

    constructor(
        private assessmentService: AssessmentService,
        private programService: ProgramService,
        private dashboardService: DashboardService
    ) {}

    ngOnInit() {
        this.isLoading$ = this.dashboardService.isSelectedProgramLoading$;
        this.assessmentList$ = this.programService.currentProgram$.pipe(
            map(program => {
                return program ? this.programService.getFlatHierarchy(program) : [];
            })
        );
    }

    onAssessmentClick(assessmentId: string) {
        this.dashboardService.closeDashboard();
        this.assessmentService.sendAssessmentOpenedTelemetryEvent(
            assessmentId,
            AssessmentNavigationType.AssessmentMenu,
            AssessmentNavigationSubType.ViewAll
        );
    }

    trackByAssessmentId(index, assessment: ProgramItem) {
        return assessment.id;
    }
}
