import { Action } from '@ngrx/store';

export enum NavigationActionTypes {
    SetPreviousURL = '[Navigation] Set Previous URL'
}

export class ActionSetPreviousURL implements Action {
    readonly type = NavigationActionTypes.SetPreviousURL;
    constructor(public payload: { previousURL: string }) {}
}

export type NavigationActions = ActionSetPreviousURL;
