import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ModuleWithProviders } from '@angular/core';
import { defineCustomElements } from 'lst-dropdown/dist/loader';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LstDropdownModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: LstDropdownModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    multi: true,
                    useFactory: appInitialize
                }
            ]
        };
    }
}

export function appInitialize() {
    return async () => {
        const win = window as any;
        if (typeof win !== 'undefined') {
            // Define all of our custom elements
            return defineCustomElements(win);
        }
    };
}
