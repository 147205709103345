import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OralReadingChartService } from './oral-reading-chart.service';
import { reducer } from './store/oral-reading-chart.reducer';
import { OralReadingChartEffects } from './store/oral-reading-chart.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('oralReadingChart', reducer),
        EffectsModule.forFeature([OralReadingChartEffects])
    ],
    providers: [OralReadingChartService]
})
export class OralReadingChartModule {}
