<ion-content>
    <div class="flex-container-col">
        <ion-toolbar class="menu-header__toolbar menu-header__toolbar--media-content" *ngIf="newMediaModel.media">
            <ion-buttons class="toolbar__start" slot="start" *ngIf="!editMode">
                <ion-button fill="clear" (click)="onCloseMenu()" class="action-button">
                    <span class="icon-chevron icon-chevron--left"></span>
                </ion-button>
                <h4 class="toolbar__title">{{ newMediaModel.media.title | truncate:10 }}</h4>
            </ion-buttons>
            <ion-buttons slot="start">
                <ion-button *ngIf="formDirtyMode"
                    (click)="cancelMenuUpdate()"
                    class="menu-header__cancel-button action-button">
                    <ion-label class="action-button__label">{{ 'media-menu.cancel' | translate }}</ion-label>
                </ion-button>
            </ion-buttons>
            <ion-buttons slot="end">
                <ion-button *ngIf="formDirtyMode"
                    (click)="doMenuUpdate()"
                    class="menu-header__save-button action-button">
                    <ion-label class="action-button__label">{{ 'media-menu.save' | translate }}</ion-label>
                </ion-button>
                <ion-button *ngIf="!editMode"
                    (click)="editMode=true"
                    class="menu-header__edit-button action-button">
                    <div class="action-button__icon label-icon icon-edit" aria-hidden="true"></div>
                    <ion-label class="action-button__label">{{ 'media-menu.edit' | translate }}</ion-label>
                </ion-button>
                <ion-button
                    (click)="onCloseMenu()"
                    *ngIf="editMode && !formDirtyMode"
                    class="menu-header__close-button action-button">
                    <span class="icon-exit" aria-hidden="true"></span>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
        <div class="flex-container-row view-content"
            *ngIf="newMediaModel.media"
            [ngClass]="{
                'media-content--edit-view': !editMode,
                'menu-content--edit-view': editMode
            }">
            <div class="media-content">
                <div class="image-content middle" *ngIf="newMediaModel.media.type === 'Image'; else videoPlay">
                    <img *ngIf="newMediaModel.media.signedDownloadUrl?.url" style="object-fit: fill; height: auto; max-height: 100vh;" [src]="newMediaModel.media.signedDownloadUrl?.url" [alt]="'media-menu.image.description' | translate">
                </div>
                <ng-template #videoPlay>
                    <div class="video-content middle">
                        <video *ngIf="newMediaModel.media.signedDownloadUrl?.url" controls disablePictureInPicture controlsList="nodownload" style="height: 100%; width: 100%;" [src]="newMediaModel.media.signedDownloadUrl?.url" #videoClip></video>
                    </div>
                </ng-template>
            </div>
            <div class="menu-content">
                <ion-toolbar class="menu-header__toolbar menu-header__toolbar--menu-content">
                    <ion-buttons slot="start">
                        <ion-button
                            *ngIf="formDirtyMode"
                            (click)="cancelMenuUpdate()"
                            class="menu-header__cancel-button action-button">
                            <ion-label class="action-button__label">{{ 'media-menu.cancel' | translate }}</ion-label>
                        </ion-button>
                    </ion-buttons>
                    <ion-buttons slot="end">
                        <ion-button
                            *ngIf="formDirtyMode"
                            (click)="doMenuUpdate()"
                            class="menu-header__save-button action-button">
                            <ion-label class="action-button__label">{{ 'media-menu.save' | translate }}</ion-label>
                        </ion-button>
                        <ion-button
                            (click)="onCloseMenu()"
                            *ngIf="!formDirtyMode"
                            class="menu-header__close-button action-button">
                            <span class="icon-exit" aria-hidden="true"></span>
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
                <ion-list class="menu-container" (ionChange)="onMenuDirty()" *ngIf="newMediaModel">
                    <ion-item-group class="form-group">
                        <ion-item lines="none">
                            <ion-input
                                class="textarea textarea--title"
                                [(ngModel)]="newMediaModel.media.title"
                                [placeholder]="'media-menu.title-placeholder' | translate"
                                maxlength="88">
                            </ion-input>
                        </ion-item>
                        <ion-item lines="none">
                            <textarea
                                (input)="onMenuDirty()"
                                class="textarea textarea--description"
                                [(ngModel)]="newMediaModel.media.description"
                                [placeholder]="'media-menu.description-placeholder' | translate"
                                maxlength="200">
                            </textarea>
                        </ion-item>
                    </ion-item-group>
                    <ion-item-group class="form-group">
                        <ion-item lines="none">
                            <ion-label>
                                {{ 'media-menu.tag-students.label' | translate }}
                            </ion-label>
                        </ion-item>
                        <ul class="student-list">
                            <li class="student-list__item" lines="none" *ngFor="let student of newMediaModel.students; let i=index; trackBy: trackByStudentId">
                                <ion-checkbox mode="md" [(ngModel)]="student.selected" class="checkbox" slot="start">
                                </ion-checkbox>
                                <div class="list-item__container">
                                    <ion-avatar class="avatar" [title]="student.initials">
                                        <span class="avatar__initials">{{ student.initials }}</span>
                                    </ion-avatar>
                                    <ion-label class="list-item__label">{{ student.name }}</ion-label>
                                </div>
                            </li>
                        </ul>
                    </ion-item-group>
                </ion-list>
            </div>
        </div>
    </div>
</ion-content>
