import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';
import { ErrorModalComponent } from '../../components/error-modal/error-modal.component';
import {
    ServiceWorkerActionTypes,
    ActionShowRefreshModal,
    ActionSetIsServiceWorkerUpdateModalOpen
} from './service-worker.actions';
import { SwUpdate } from '@angular/service-worker';
import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { AppState } from '../../core.state';
import { selectIsServiceWorkerUpdateModalOpen } from './service-worker.selectors';

@Injectable()
export class ServiceWorkerEffects {
    constructor(
        private actions$: Actions<Action>,
        private modalController: ModalController,
        private swUpdate: SwUpdate,
        private store$: Store<AppState>
    ) {}

    @Effect({ dispatch: false })
    checkForUpdates$ = this.actions$.pipe(
        ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
        tap(() => {
            if (this.swUpdate.isEnabled) {
                this.swUpdate.checkForUpdate();
            }
        })
    );

    @Effect({ dispatch: false })
    showRefreshModal$ = this.actions$.pipe(
        ofType<ActionShowRefreshModal>(ServiceWorkerActionTypes.ShowRefreshModal),
        withLatestFrom(this.store$.pipe(select(selectIsServiceWorkerUpdateModalOpen))),
        tap(([_, isServiceWorkerUpdateModalOpen]) => {
            if (document.hidden) {
                window.location.reload();
            } else {
                if (!isServiceWorkerUpdateModalOpen) {
                    this.store$.dispatch(
                        new ActionSetIsServiceWorkerUpdateModalOpen({
                            isServiceWorkerUpdateModalOpen: true
                        })
                    );
                    this.modalController
                        .create({
                            component: ErrorModalComponent,
                            cssClass: 'service-worker-modal',
                            backdropDismiss: false,
                            componentProps: {
                                translatedTitle: '',
                                translatedDescription:
                                    'service-worker.update-modal.description',
                                showHeader: false,
                                translatedButtonText:
                                    'service-worker.update-modal.refresh'
                            }
                        })
                        .then(modal => {
                            modal.present();
                            modal.onDidDismiss().then(() => {
                                this.store$.dispatch(
                                    new ActionSetIsServiceWorkerUpdateModalOpen({
                                        isServiceWorkerUpdateModalOpen: false
                                    })
                                );
                                window.location.reload();
                            });
                        });
                }
            }
        })
    );
}
