import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CriteriaTag } from './store/reports.model';

@Injectable({ providedIn: 'root' })
export class ReportsDataService {

    constructor(private http: HttpClient) {}

    fetchTagsList(productId, rootProgramId, contentItemId, userId, accessToken): Observable<CriteriaTag[]> | any {
        const url = environment.api.itemAttachment +
            `product/${productId}/rootProgram/${rootProgramId}/contentItem/${contentItemId}/tags`;

        const headers = {
            Authorization: `Bearer ${accessToken}`,
            userId
        };
        return this.http.get<any>(url, { headers }).pipe(
            map((tagsListResponse: { tags: CriteriaTag[] }) => {
                return tagsListResponse.tags.reduce((acc, tag) => {
                    if (tag.title && tag.description && tag.assessments) {
                        tag['id'] = tag.title.split(' ').join('_') + Math.random().toString(36).substring(2);
                        acc.push(tag);
                    }
                    return acc;
                }, []);
            }),
            catchError(error => throwError(error))
        );
    }
}
