import { Action } from '@ngrx/store';
import { RumbaUserDetails } from 'src/app/core/user-profile/store/user-profile.model';
import { StudentMetadataForClassSnapShot, StudentMetadataForClass } from './students.model';

export enum  StudentsActionTypes {
    SetSelectedDetailsType = '[Students] Set Selected Details Type',

    LoadStudentMetadataForClassStart = '[Students] Start Loading StudentMetadataForClass',
    LoadStudentMetadataForClass = '[Students] Load StudentMetadataForClass',

    InsertStudentMetadataForClass = '[Students] Insert StudentMetadataForClass',
    InsertStudentMetadataForClassSuccess = '[Students] Insert StudentMetadataForClass Success',
    InsertStudentMetadataForClassFailed = '[Students] Insert StudentMetadataForClass Failed',

    UpdateStudentMetadataForClass = '[Students] Update StudentMetadataForClass',
    UpdateStudentMetadataForClassSuccess = '[Students] Update StudentMetadataForClass Success',
    UpdateStudentMetadataForClassFailed = '[Students] Update StudentMetadataForClass Failed',

    SendTelemetryEventForExportStudentCSV = '[Students] Send Telemetry Event For Export Student CSV',
}


export class ActionSetSelectedDetailsType implements Action {
    readonly type = StudentsActionTypes.SetSelectedDetailsType;
    constructor(public payload: { detailsType: string }) {}
}

export class ActionLoadStudentMetadataForClassStart implements Action {
    readonly type = StudentsActionTypes.LoadStudentMetadataForClassStart;
}

export class ActionLoadStudentMetadataForClass implements Action {
    readonly type = StudentsActionTypes.LoadStudentMetadataForClass;
    constructor(public payload: { studentMetadataForClass: StudentMetadataForClass[] }) {}
}

export class ActionInsertStudentMetadataForClass implements Action {
    readonly type = StudentsActionTypes.InsertStudentMetadataForClass;
    constructor(public payload: { studentMetadataForClass: StudentMetadataForClass }) {}
}

export class ActionInsertStudentMetadataForClassSuccess implements Action {
    readonly type = StudentsActionTypes.InsertStudentMetadataForClassSuccess;
}

export class ActionInsertStudentMetadataForClassFailed implements Action {
    readonly type = StudentsActionTypes.InsertStudentMetadataForClassFailed;
    constructor(public payload: { error: any }) {}
}

export class ActionUpdateStudentMetadataForClass implements Action {
    readonly type = StudentsActionTypes.UpdateStudentMetadataForClass;
    constructor(public payload: { studentMetadataForClass: StudentMetadataForClassSnapShot }) {}
}

export class ActionUpdateStudentMetadataForClassSuccess implements Action {
    readonly type = StudentsActionTypes.UpdateStudentMetadataForClassSuccess;
    constructor(public payload: { studentId: string }) {}
}

export class ActionUpdateStudentMetadataForClassFailed implements Action {
    readonly type = StudentsActionTypes.UpdateStudentMetadataForClassFailed;
    constructor(public payload: { error: any }) {}
}

export class ActionSendTelemetryEventForExportStudentCSV implements Action {
    readonly type = StudentsActionTypes.SendTelemetryEventForExportStudentCSV;
    constructor(public payload: { student: RumbaUserDetails }) {}
}

export type StudentMetadataForClassActions =
    | ActionSetSelectedDetailsType
    | ActionLoadStudentMetadataForClassStart
    | ActionLoadStudentMetadataForClass
    | ActionInsertStudentMetadataForClass
    | ActionInsertStudentMetadataForClassSuccess
    | ActionInsertStudentMetadataForClassFailed
    | ActionUpdateStudentMetadataForClass
    | ActionUpdateStudentMetadataForClassSuccess
    | ActionUpdateStudentMetadataForClassFailed
    | ActionSendTelemetryEventForExportStudentCSV;
