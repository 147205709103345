import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchState, State } from './search.model';
import { selectAll } from './search.reducer';

export const selectSearchState = createFeatureSelector<State, SearchState>('searches');

export const selectAllSearches = createSelector(
    selectSearchState,
    selectAll
);

export const selectIsSearchMenuOpen = createSelector(
    selectSearchState,
    state => state.isSearchMenuOpen
);

export const selectRecentSearchesOrderedByDesc = createSelector(
    selectAllSearches,
    allSearch => {
        allSearch.sort((a, b) => b.updatedAt - a.updatedAt);
        return allSearch.slice(0, 25);
    }
);

export const selectIsLoadingRecentSearches = createSelector(
    selectSearchState,
    state => state.isLoadingRecentSearches,
);

export const selectIsLoadingSearchResults = createSelector(
    selectSearchState,
    state => state.isLoadingSearchResults,
);
