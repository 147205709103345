import { AuthState, UserRole } from './auth.model';
import { AuthActions, AuthActionTypes } from './auth.actions';

export const initialState: AuthState = {
    isAuthenticated: false,
    isAuthenticating: false,
    isValidating: false,
    currentUser: null,
    rbs: null,
    jwt: null,
    authorizedScoutProducts: [],
};

export function authReducer(
    state: AuthState = initialState,
    action: AuthActions
): AuthState {
    switch (action.type) {
        case AuthActionTypes.VALIDATE_TICKET:
            return { ...state, isAuthenticating: true, isValidating: true };

        case AuthActionTypes.RBS_TOKEN_REQUEST:
        case AuthActionTypes.RBS_TOKEN_TTL_CHECK:
            return { ...state, isValidating: true };

        case AuthActionTypes.SET_AUTHORIZED_SCOUT_PRODUCTS:
            const { products } = action.payload;
            return {...state, authorizedScoutProducts: products };

        case AuthActionTypes.VALIDATE_TICKET_SUCCESS:
            const userRole = action.payload.response.idpResponse.data.assertion.attributes.OrgRole1 as UserRole;
            const {
                identityId: userId,
                firstName,
                lastName,
                name,
                title,
                userName
            } = action.payload.response;
            const currentUser = {
                userId,
                firstName,
                lastName,
                name,
                title,
                userName,
                role: userRole
            };
            return {
                ...state,
                isAuthenticated: true,
                currentUser,
                isAuthenticating: false
            };

        case AuthActionTypes.RBS_TOKEN_TTL_SUCCESS:
        case AuthActionTypes.RBS_TOKEN_REQUEST_SUCCESS:
            const { access_token, expires_in } = action.payload;
            const rbsToken = {
                token: access_token,
                expires: Date.now() + parseInt(expires_in, 10)
            };
            return {
                ...state,
                rbs: rbsToken,
                isValidating: false
            };

        case AuthActionTypes.RBS_TOKEN_TTL_FAILURE:
        case AuthActionTypes.RBS_TOKEN_REQUEST_FAILURE:
        case AuthActionTypes.VALIDATE_TICKET_FAILURE:
        case AuthActionTypes.LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                currentUser: null,
                isValidating: false,
                isAuthenticating: false,
                rbs: null
            };

        case AuthActionTypes.JWT_REQUEST_SUCCESS:
            const { jwt } = action.payload;
            return {
                ...state,
                jwt,
            };

        default:
            return state;
    }
}
