import { SettingsState } from './settings.model';
import { SettingsActionTypes, SettingsActions } from './settings.actions';

export const initialState: SettingsState = {
    locale: 'en'
};

export function reducer(
    state: SettingsState = initialState,
    action: SettingsActions
): SettingsState {
    if (action.type === SettingsActionTypes.CHANGE_LOCALE) {
        return { ...state, ...action.payload };
    } else {
        return state;
    }
}
