import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom, mergeMap, filter } from 'rxjs/operators';
import { ActionNavigateToErrorPage } from 'src/app/core/error/store/error.actions';
import { ActionDoNothing, AppState, selectAuthState, selectRouterState } from '../../core.state';
import { Logger } from '../../logging/logger';
import { SessionStorageService } from '../../session-storage/session-storage.service';
import {
    ActionAuthLogout,
    ActionFirebaseLogin,
    ActionFirebaseLoginFailure,
    ActionFirebaseLoginSuccess,
    ActionJwtRequest,
    ActionJwtRequestFailure,
    ActionJwtRequestSuccess,
    ActionRbsTokenRequest,
    ActionRbsTokenRequestFailure,
    ActionRbsTokenRequestSuccess,
    ActionRbsTokenTtlCheck,
    ActionRbsTtlFailure,
    ActionRbsTtlSuccess,
    ActionRedirectSso,
    ActionValidateTicket,
    ActionValidateTicketFailure,
    ActionValidateTicketSuccess,
    AuthActionTypes
} from './auth.actions';
import { selectIsAuthenticated, selectIsAuthenticating, selectRbs, selectCurrentUser } from './auth.selectors';
import { AuthService } from '../auth.service';
import { ActionSetAuthorizedScoutProducts } from './auth.actions';
import { selectSelectedClassRosterId } from '../../class-roster/store/class-roster.selectors';
import { selectIsUnrecoverableErrorMode } from '../../error/store/error.selectors';
import { UserRole } from './auth.model';

export const AUTH_KEY = 'AUTH';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions<Action>,
        private store: Store<AppState>,
        private sessionStorageService: SessionStorageService,
        private router: Router,
        private authService: AuthService,
        private logger: Logger
    ) {}

    @Effect({ dispatch: false })
    logout = this.actions$.pipe(
        ofType<ActionAuthLogout>(AuthActionTypes.LOGOUT),
        tap(() => {
            this.logger.info('LOGOUT EFFECT: ', '');
            this.authService.firebaseLogout().then(() => {
                this.logger.info('Firebase logged out');
            });
            this.authService.ssoLogout();
            this.sessionStorageService.removeItem(AUTH_KEY);
        })
    );

    @Effect({ dispatch: false })
    persistAuthState = this.actions$.pipe(
        ofType(
            AuthActionTypes.LOGIN,
            AuthActionTypes.RBS_TOKEN_REQUEST_SUCCESS,
            AuthActionTypes.RBS_TOKEN_TTL_SUCCESS,
            AuthActionTypes.VALIDATE_TICKET_SUCCESS,
            AuthActionTypes.LOGOUT,
            AuthActionTypes.RBS_TOKEN_REQUEST_FAILURE,
            AuthActionTypes.RBS_TOKEN_TTL_FAILURE,
            AuthActionTypes.VALIDATE_TICKET_FAILURE
        ),
        withLatestFrom(this.store.pipe(select(selectAuthState))),
        tap(([, state]) => {
            this.sessionStorageService.setItem(AUTH_KEY, state);
        })
    );

    @Effect({ dispatch: false })
    redirectSso = this.actions$.pipe(
        ofType<ActionRedirectSso>(AuthActionTypes.REDIRECT_SSO),
        map(action => action.payload),
        withLatestFrom(
            this.store.pipe(select(selectSelectedClassRosterId)),
            this.store.pipe(select(selectIsAuthenticated))
        ),
        tap(([{ returnUrl }, selectedClassId, isAuthenticated]) => {
            let toRedirectUrl = returnUrl;
            if (!selectedClassId && !returnUrl.includes('/init')) {
                toRedirectUrl = `/init?redirect=${toRedirectUrl}`;
            }
            const redirectUrl = this.authService.getSsoRedirectUrl(toRedirectUrl);
            window.open(redirectUrl, '_self');
        })
    );

    @Effect()
    validateTicket$ = this.actions$.pipe(
        ofType<ActionValidateTicket>(AuthActionTypes.VALIDATE_TICKET),
        map(action => action.payload),
        switchMap(({ ticket, returnUrl }) => {
            const serviceUrl = this.authService.getReturnServiceUrl(returnUrl);
            return this.authService.authenticateTicket(ticket, serviceUrl).pipe(
                concatMap(response => {
                    const {
                        castgc,
                        identityId: userId,
                        idpResponse: {
                            data: { authorizedResource: authorizedResource }
                        }
                    } = response;

                    const products = authorizedResource.map(resource => {
                        return resource.productId;
                    });

                    return [
                        new ActionRbsTokenRequest({ castgc, userId }),
                        new ActionValidateTicketSuccess({ returnUrl, response }),
                        new ActionSetAuthorizedScoutProducts({ products })
                    ];
                }),
                catchError(() => of(new ActionValidateTicketFailure({ returnUrl })))
            );
        })
    );

    @Effect()
    validateTicketFailed$ = this.actions$.pipe(
        ofType<ActionRbsTtlFailure | ActionValidateTicketFailure>(
            AuthActionTypes.VALIDATE_TICKET_FAILURE,
            AuthActionTypes.RBS_TOKEN_TTL_FAILURE
        ),
        mergeMap(({ payload }) => [new ActionRedirectSso({ returnUrl: payload.returnUrl }), new ActionAuthLogout()])
    );

    @Effect()
    rbsTokenRequest$ = this.actions$.pipe(
        ofType<ActionRbsTokenRequest>(AuthActionTypes.RBS_TOKEN_REQUEST),
        map(action => action.payload),
        switchMap(({ castgc, userId }) =>
            this.authService.getRbsToken(userId, castgc).pipe(
                map(response => new ActionRbsTokenRequestSuccess(response)),
                catchError(() => of(new ActionRbsTokenRequestFailure()))
            )
        )
    );

    @Effect()
    rbsTokenCheckTtl$ = this.actions$.pipe(
        ofType<ActionRbsTokenTtlCheck>(AuthActionTypes.RBS_TOKEN_TTL_CHECK),
        withLatestFrom(this.store.pipe(select(selectRbs))),
        switchMap(([action, rbsToken]) => {
            const { returnUrl } = action.payload;
            if (!rbsToken || Date.now() > rbsToken.expires) {
                return of(new ActionRbsTtlFailure({ returnUrl }));
            }
            // TODO: for now use reset, later utilize check for idle timeouts
            return this.authService.resetRbsTtl(rbsToken.token).pipe(
                map(response => new ActionRbsTtlSuccess(response)),
                catchError(() => of(new ActionRbsTtlFailure({ returnUrl })))
            );
        })
    );

    @Effect()
    locationUpdate$ = this.actions$.pipe(
        ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
        withLatestFrom(
            this.store.pipe(select(selectIsAuthenticating)),
            this.store.pipe(select(selectIsAuthenticated)),
            this.store.pipe(select(selectRouterState)),
            this.store.pipe(select(selectSelectedClassRosterId)),
            this.store.pipe(select(selectIsUnrecoverableErrorMode))
        ),
        map(([, isAuthenticating, isAuthenticated, router, classSelectedId, isUnrecoverableErrorMode]) => {
            const { url: returnUrl, queryParams } = router.state;
            const { ticket, ...rest } = queryParams;

            if (ticket) {
                let queryParamsString: string;
                if (rest) {
                    const params = Object.keys(rest);
                    queryParamsString = params.reduce((acc, key) => {
                        return acc ? `${acc}&${key}=${queryParams[key]}` : `${key}=${queryParams[key]}`;
                    }, null);
                }
                const newUrl = queryParamsString ? `${returnUrl}?${queryParamsString}` : `${returnUrl}`;
                // we NEED to get rid of the ticket in the url now or we'll loop forever
                this.router.navigateByUrl(newUrl);
                return new ActionValidateTicket({ ticket, returnUrl: newUrl });
            }

            if (!isAuthenticating && !isAuthenticated) {
                return new ActionRedirectSso({ returnUrl });
            }
            if (isAuthenticating) {
                // IF we're busy authenticating currently then the authguard should take care of it
                // we NEED to dispatch something however or ngrx will break
                return new ActionDoNothing();
            }

            if (
                isAuthenticated &&
                !classSelectedId &&
                !returnUrl.includes('init') &&
                !returnUrl.includes('error') &&
                !isUnrecoverableErrorMode
            ) {
                const redirectUrl = `/init?redirect=${returnUrl}`;
                window.open(redirectUrl, '_self');
                return;
            }

            return new ActionRbsTokenTtlCheck({ returnUrl });
        })
    );

    @Effect()
    rbsTokenRequestSuccess$ = this.actions$.pipe(
        ofType<ActionRbsTokenRequestSuccess>(AuthActionTypes.RBS_TOKEN_REQUEST_SUCCESS),
        map(({ payload }) => {
            const rbsPayload = {
                rbsToken: 'Bearer ' + payload.access_token,
                userId: payload.userId
            };
            return new ActionJwtRequest(rbsPayload);
        })
    );

    @Effect()
    jwtRequest$ = this.actions$.pipe(
        ofType<ActionJwtRequest>(AuthActionTypes.JWT_REQUEST),
        map(action => action.payload),
        withLatestFrom(this.store.pipe(select(selectCurrentUser))),
        filter(([, userDetail]) => userDetail.role !== UserRole.STUDENT),
        switchMap(([{ userId, rbsToken }]) =>
            this.authService.getJwt(userId, rbsToken).pipe(
                map(response => new ActionJwtRequestSuccess(response)),
                catchError(error => {
                    const description = {
                        origin: 'Auth Jwt Request',
                        payload: { userId },
                        message: error
                    };
                    return [
                        new ActionNavigateToErrorPage({ description }),
                        new ActionJwtRequestFailure(error),
                    ];
                })
            )
        )
    );

    @Effect()
    jwtRequestSuccess$ = this.actions$.pipe(
        ofType<ActionJwtRequestSuccess>(AuthActionTypes.JWT_REQUEST_SUCCESS),
        map(({ payload }) => {
            return new ActionFirebaseLogin(payload);
        })
    );

    @Effect()
    firebaseLogin$ = this.actions$.pipe(
        ofType<ActionFirebaseLogin>(AuthActionTypes.FIREBASE_LOGIN),
        map(action => action.payload),
        switchMap(({ jwt }) =>
            this.authService.firebaseLogin(jwt).pipe(
                map(() => new ActionFirebaseLoginSuccess()),
                catchError(error => {
                    const description = {
                        origin: 'Auth Firebase Login',
                        message: error,
                    };
                    return [
                        new ActionNavigateToErrorPage({ description }),
                        new ActionFirebaseLoginFailure(error),
                    ];
                })
            )
        )
    );
}
