import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { UserProfile } from '../../user-profile/store/user-profile.model';
import { ClassRoster } from './class-roster.model';

export enum ClassRosterActionTypes {
    LoadClassRosters = '[ClassRoster] Load ClassRosters',
    AddClassRoster = '[ClassRoster] Add ClassRoster',
    UpsertClassRoster = '[ClassRoster] Upsert ClassRoster',
    AddClassRosters = '[ClassRoster] Add ClassRosters',
    UpsertClassRosters = '[ClassRoster] Upsert ClassRosters',
    UpdateClassRoster = '[ClassRoster] Update ClassRoster',
    UpdateClassRosters = '[ClassRoster] Update ClassRosters',
    DeleteClassRoster = '[ClassRoster] Delete ClassRoster',
    DeleteClassRosters = '[ClassRoster] Delete ClassRosters',
    ClearClassRosters = '[ClassRoster] Clear ClassRosters',
    GetClassRostersForUser = '[ClassRoster] Get Class Rosters For User',
    GetClassRostersForUserFailed = '[ClassRoster] Get Class Rosters For User Failed',
    SetSelectedClassRoster = '[ClassRoster] Set Selected Class Roster',
    PopulateStudentsForRoster = '[ClassRoster] Populate Students for Roster',
    SetSelectedStudentId = '[ClassRoster] Set Selected Student Id',
}

export class LoadClassRosters implements Action {
    readonly type = ClassRosterActionTypes.LoadClassRosters;

    constructor(public payload: { classRosters: ClassRoster[] }) {}
}

export class AddClassRoster implements Action {
    readonly type = ClassRosterActionTypes.AddClassRoster;

    constructor(public payload: { classRoster: ClassRoster }) {}
}

export class UpsertClassRoster implements Action {
    readonly type = ClassRosterActionTypes.UpsertClassRoster;

    constructor(public payload: { classRoster: ClassRoster }) {}
}

export class AddClassRosters implements Action {
    readonly type = ClassRosterActionTypes.AddClassRosters;

    constructor(public payload: { classRosters: ClassRoster[] }) {}
}

export class UpsertClassRosters implements Action {
    readonly type = ClassRosterActionTypes.UpsertClassRosters;

    constructor(public payload: { classRosters: ClassRoster[] }) {}
}

export class UpdateClassRoster implements Action {
    readonly type = ClassRosterActionTypes.UpdateClassRoster;

    constructor(public payload: { classRoster: Update<ClassRoster> }) {}
}

export class UpdateClassRosters implements Action {
    readonly type = ClassRosterActionTypes.UpdateClassRosters;

    constructor(public payload: { classRosters: Update<ClassRoster>[] }) {}
}

export class DeleteClassRoster implements Action {
    readonly type = ClassRosterActionTypes.DeleteClassRoster;

    constructor(public payload: { id: string }) {}
}

export class DeleteClassRosters implements Action {
    readonly type = ClassRosterActionTypes.DeleteClassRosters;

    constructor(public payload: { ids: string[] }) {}
}

export class ClearClassRosters implements Action {
    readonly type = ClassRosterActionTypes.ClearClassRosters;
}

export class ActionGetClassRostersForUser implements Action {
    readonly type = ClassRosterActionTypes.GetClassRostersForUser;
}

export class ActionGetClassRostersForUserFailed implements Action {
    readonly type = ClassRosterActionTypes.GetClassRostersForUserFailed;
}

export class ActionSetSelectedClassRoster implements Action {
    readonly type = ClassRosterActionTypes.SetSelectedClassRoster;

    constructor(public payload: { classId: string }) {}
}

export class ActionPopulateStudentsForRoster implements Action {
    readonly type = ClassRosterActionTypes.PopulateStudentsForRoster;

    constructor(public payload: { students: UserProfile[] }) {}
}

export class ActionSetSelectedStudentId implements Action {
    readonly type = ClassRosterActionTypes.SetSelectedStudentId;

    constructor(public payload: { studentId: string }) {}
}

export type ClassRosterActions =
    | LoadClassRosters
    | AddClassRoster
    | UpsertClassRoster
    | AddClassRosters
    | UpsertClassRosters
    | UpdateClassRoster
    | UpdateClassRosters
    | DeleteClassRoster
    | DeleteClassRosters
    | ClearClassRosters
    | ActionGetClassRostersForUser
    | ActionGetClassRostersForUserFailed
    | ActionSetSelectedClassRoster
    | ActionPopulateStudentsForRoster
    | ActionSetSelectedStudentId;
