import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { selectSelectedClassRosterId } from 'src/app/core/class-roster/store/class-roster.selectors';
import { AppState } from 'src/app/core/core.state';
import {
    ActionSendTelemetryEventForExportStudentCSV,
    ActionUpdateStudentMetadataForClassSuccess,
    StudentsActionTypes
} from 'src/app/core/students/store/students.actions';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';

@Injectable()
export class StudentsTelemetryEffects {

    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private telemetryService: TelemetryService,
    ) {}

    @Effect({ dispatch: false })
    editStudentContactInfo = this.actions$.pipe(
        ofType<ActionUpdateStudentMetadataForClassSuccess>(StudentsActionTypes.UpdateStudentMetadataForClassSuccess),
        map(action => action.payload.studentId),
        withLatestFrom(this.store$.pipe(select(selectSelectedClassRosterId))),
        tap(([studentId, classId]) => {
            const details = {
                section: { id: classId },
                studentIDs: [studentId]
            };
            this.telemetryService.sendActivity('modified', 'ContactInfo', details);
        })
    );

    @Effect({ dispatch: false })
    sendTelemetryForStudentExportCSV$ = this.actions$.pipe(
        ofType<ActionSendTelemetryEventForExportStudentCSV>(StudentsActionTypes.SendTelemetryEventForExportStudentCSV),
        map(action => action.payload.student),
        withLatestFrom(this.store$.pipe(select(selectSelectedClassRosterId))),
        tap(([student, classId]) => {
            const details = {
                section: { id: classId },
                studentIDs: [student.userId]
            };
            this.telemetryService.sendActivity('exported', 'StudentAssessments', details);
        })
    );
}
