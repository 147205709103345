import { Component, OnInit } from '@angular/core';
import { oralReadingData } from './oral-reading.data';

@Component({
    selector: 'app-oral-reading-chart-menu',
    templateUrl: './oral-reading-chart-menu.component.html',
    styleUrls: ['./oral-reading-chart-menu.component.scss']
})
export class OralReadingChartMenuComponent implements OnInit {
    oralReadingChartData = oralReadingData;
    constructor() {}

    ngOnInit() {}
}
