import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Product, ProductState } from './product.model';
import { ProductActions, ProductActionTypes } from './product.actions';

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>({});

export const initialState: ProductState = adapter.getInitialState({
    isLoadingProducts: false
});

export function reducer(state = initialState, action: ProductActions): ProductState {
    switch (action.type) {
        case ProductActionTypes.LoadProducts:
            return { ...state, isLoadingProducts: true };
        case ProductActionTypes.LoadProductsSuccess:
                return adapter.upsertMany(action.payload.products, { ...state, isLoadingProducts: false });
        case ProductActionTypes.LoadProductFailure:
            return { ...state, isLoadingProducts: false };
        default: {
            return state;
        }
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();
