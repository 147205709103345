import { Action } from '@ngrx/store';
import { AuthenticationResponse } from './auth.model';
import { JwtResponse } from './auth.model';
import { RbsTokenResponse } from './auth.model';

export enum AuthActionTypes {
    LOGIN = '[Auth] Login',
    LOGOUT = '[Auth] Logout',
    REDIRECT_SSO = '[Auth] Redirect SSO',
    VALIDATE_TICKET = '[Auth] Validate Ticket',
    VALIDATE_TICKET_SUCCESS = '[Auth] Validate Ticket Success',
    VALIDATE_TICKET_FAILURE = '[Auth] Validate Ticket Failure',
    RBS_TOKEN_REQUEST = '[Auth] RBS Token Request',
    RBS_TOKEN_REQUEST_SUCCESS = '[Auth] RBS Token Request Success',
    RBS_TOKEN_REQUEST_FAILURE = '[Auth] RBS Token Request Failure',
    RBS_TOKEN_TTL_CHECK = '[Auth] RBS Token TTL Check',
    RBS_TOKEN_TTL_SUCCESS = '[Auth] RBS Token TTL Success',
    RBS_TOKEN_TTL_FAILURE = '[Auth] RBS Token TTL Failure',
    JWT_REQUEST = '[Auth] JWT Request',
    JWT_REQUEST_SUCCESS = '[Auth] JWT Request Success',
    JWT_REQUEST_FAILURE = '[Auth] JWT Request Failure',
    FIREBASE_LOGIN = '[Auth] Firebase Login',
    FIREBASE_LOGIN_SUCCESS = '[Auth] Firebase Login Success',
    FIREBASE_LOGIN_FAILURE = '[Auth] Firebase Login Failure',
    SET_AUTHORIZED_SCOUT_PRODUCTS = '[Auth] Set Access Product',
    SEND_TELEMETRY_EVENT_AND_LOGOUT = '[Auth] Send Telemetry Event And Logout',
}

export class ActionAuthLogin implements Action {
    readonly type = AuthActionTypes.LOGIN;
}

export class ActionAuthLogout implements Action {
    readonly type = AuthActionTypes.LOGOUT;
}

export class ActionRedirectSso implements Action {
    readonly type = AuthActionTypes.REDIRECT_SSO;

    constructor(readonly payload: { returnUrl: string }) {}
}

export class ActionValidateTicket implements Action {
    readonly type = AuthActionTypes.VALIDATE_TICKET;

    constructor(readonly payload: { ticket: string; returnUrl: string }) {}
}

export class ActionValidateTicketSuccess implements Action {
    readonly type = AuthActionTypes.VALIDATE_TICKET_SUCCESS;

    constructor(readonly payload: { returnUrl: string, response: AuthenticationResponse }) {}
}

export class ActionSetAuthorizedScoutProducts implements Action {
    readonly type = AuthActionTypes.SET_AUTHORIZED_SCOUT_PRODUCTS;

    constructor(readonly payload: { products: string[] }) {}
}

export class ActionValidateTicketFailure implements Action {
    readonly type = AuthActionTypes.VALIDATE_TICKET_FAILURE;

    constructor(readonly payload: { returnUrl: string }) {}
}

export class ActionRbsTokenRequest implements Action {
    readonly type = AuthActionTypes.RBS_TOKEN_REQUEST;

    constructor(readonly payload: { castgc: string; userId: string }) {}
}

export class ActionRbsTokenRequestSuccess implements Action {
    readonly type = AuthActionTypes.RBS_TOKEN_REQUEST_SUCCESS;

    constructor(readonly payload: RbsTokenResponse) {}
}

export class ActionRbsTokenRequestFailure implements Action {
    readonly type = AuthActionTypes.RBS_TOKEN_REQUEST_FAILURE;
}

export class ActionRbsTokenTtlCheck implements Action {
    readonly type = AuthActionTypes.RBS_TOKEN_TTL_CHECK;

    constructor(readonly payload: { returnUrl: string }) {}
}

export class ActionRbsTtlSuccess implements Action {
    readonly type = AuthActionTypes.RBS_TOKEN_TTL_SUCCESS;

    constructor(readonly payload: RbsTokenResponse) {}
}

export class ActionRbsTtlFailure implements Action {
    readonly type = AuthActionTypes.RBS_TOKEN_TTL_FAILURE;

    constructor(readonly payload: { returnUrl: string }) {}
}

export class ActionJwtRequest implements Action {
    readonly type = AuthActionTypes.JWT_REQUEST;

    constructor(readonly payload: { rbsToken: string; userId: string }) {}
}

export class ActionJwtRequestSuccess implements Action {
    readonly type = AuthActionTypes.JWT_REQUEST_SUCCESS;

    constructor(readonly payload: JwtResponse) {}
}

export class ActionJwtRequestFailure implements Action {
    readonly type = AuthActionTypes.JWT_REQUEST_FAILURE;

    constructor(readonly payload: { error: string }) {}
}

export class ActionFirebaseLogin implements Action {
    readonly type = AuthActionTypes.FIREBASE_LOGIN;

    constructor(readonly payload: { jwt: string }) {}
}

export class ActionFirebaseLoginSuccess implements Action {
    readonly type = AuthActionTypes.FIREBASE_LOGIN_SUCCESS;
}

export class ActionFirebaseLoginFailure implements Action {
    readonly type = AuthActionTypes.FIREBASE_LOGIN_FAILURE;

    constructor(readonly payload: { error: string }) {}
}

export class ActionSendTelemetryEventAndLogout implements Action {
    readonly type = AuthActionTypes.SEND_TELEMETRY_EVENT_AND_LOGOUT;
}

export type AuthActions =
    | ActionAuthLogin
    | ActionAuthLogout
    | ActionRedirectSso
    | ActionValidateTicket
    | ActionValidateTicketSuccess
    | ActionSetAuthorizedScoutProducts
    | ActionValidateTicketFailure
    | ActionRbsTokenRequest
    | ActionRbsTokenRequestSuccess
    | ActionRbsTokenRequestFailure
    | ActionRbsTokenTtlCheck
    | ActionRbsTtlSuccess
    | ActionRbsTtlFailure
    | ActionJwtRequest
    | ActionJwtRequestSuccess
    | ActionJwtRequestFailure
    | ActionFirebaseLogin
    | ActionFirebaseLoginSuccess
    | ActionFirebaseLoginFailure
    | ActionSendTelemetryEventAndLogout;
