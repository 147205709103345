import { Action } from '@ngrx/store';
import { ErrorDescription } from 'src/app/core/error/store/error.model';
import { ErrorType } from './error.model';

export enum ErrorActionTypes {
    NavigateToErrorPage = '[Error] Navigate To Error Page',
    SendTelemetryEventForError = '[Error] Send Telemetry For Error',
    ShowErrorModal = '[Error] Show Error Modal',

    SetIsUnrecoverableErrorMode = '[Error] Set Unrecoverable Error Mode',
    SetIsErrorModalOpen = '[Error] Set Error Modal Open',
    SetErrorType = '[Error] Set error type',
}

export class ActionNavigateToErrorPage implements Action {
    readonly type = ErrorActionTypes.NavigateToErrorPage;
    constructor(public payload: { description: ErrorDescription }) {}
}

export class ActionSendTelemetryEventForError implements Action {
    readonly type = ErrorActionTypes.SendTelemetryEventForError;
    constructor(public payload: { description: ErrorDescription }) {}
}

export class ActionShowErrorModal implements Action {
    readonly type = ErrorActionTypes.ShowErrorModal;
    constructor(
        public payload?: {
            translatedTitle?: string;
            translatedDescription?: string;
            goToPreviousURL?: boolean;
        }
    ) {}
}

export class ActionSetIsUnrecoverableErrorMode implements Action {
    readonly type = ErrorActionTypes.SetIsUnrecoverableErrorMode;
    constructor(public payload: { isUnrecoverableErrorMode: boolean }) {}
}

export class ActionSetIsErrorModalOpen implements Action {
    readonly type = ErrorActionTypes.SetIsErrorModalOpen;
    constructor(public payload: { isErrorModalOpen: boolean }) {}
}

export class ActionSetErrorType implements Action {
    readonly type = ErrorActionTypes.SetErrorType;
    constructor(public payload: { errorType: ErrorType | undefined }) {}
}

export type ErrorActions =
    | ActionNavigateToErrorPage
    | ActionSendTelemetryEventForError
    | ActionShowErrorModal
    | ActionSetIsErrorModalOpen
    | ActionSetIsUnrecoverableErrorMode
    | ActionSetErrorType;
