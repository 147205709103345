import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentUser } from '../../auth/store/auth.selectors';
import { selectCurrentClassRoster, selectSelectedClassRosterId } from '../../class-roster/store/class-roster.selectors';
import { AppState, selectRouterState } from '../../core.state';
import { StudentsDataService } from '../students.data.service';
import {
    ActionInsertStudentMetadataForClass,
    ActionInsertStudentMetadataForClassFailed,
    ActionInsertStudentMetadataForClassSuccess,
    ActionLoadStudentMetadataForClassStart,
    ActionUpdateStudentMetadataForClass,
    ActionUpdateStudentMetadataForClassFailed,
    ActionUpdateStudentMetadataForClassSuccess,
    StudentsActionTypes,
} from './students.actions';
import { ActionSendTelemetryEventForError, ActionShowErrorModal } from '../../error/store/error.actions';

@Injectable()
export class StudentsEffects {

    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private afsAuth: AngularFireAuth,
        private studentsDataService: StudentsDataService,
        private router: Router,
    ) {}

    @Effect()
    loadStudentMetadataForClass$ = this.store$.pipe(
        select(selectSelectedClassRosterId),
        filter(classId => !!classId),
        tap(() => this.store$.dispatch(new ActionLoadStudentMetadataForClassStart())),
        switchMap(classId => this.afsAuth.authState.pipe(
            filter(user => !!user),
            switchMap(user => this.studentsDataService.fetchStudentMetadataForClass(classId, user.uid)),
            catchError(error => {
                const description = {
                    origin: 'Load Student Metadata',
                    payload: classId,
                    message: error
                };
                return [
                    new ActionSendTelemetryEventForError({ description }),
                    new ActionShowErrorModal(),
                ];
            })
        ))
    );

    @Effect()
    insertStudentMetadataForClass$ = this.actions$.pipe(
        ofType<ActionInsertStudentMetadataForClass>(StudentsActionTypes.InsertStudentMetadataForClass),
        map(action => action.payload),
        withLatestFrom(
            this.store$.pipe(select(selectSelectedClassRosterId)),
            this.store$.pipe(
                select(selectCurrentUser),
                filter(currentUser => !!currentUser),
                map(currentUser => currentUser.userId)
            )
        ),
        switchMap(([payload, classId, userId]) => {
            const studentMetadataForClassDoc = {
                ...payload.studentMetadataForClass as any,
                classId,
                createdBy: userId
            };
            const studentMetadataForClass =
                this.studentsDataService.createStudentMetadataForClassDocument(studentMetadataForClassDoc);
            return [studentMetadataForClass];
        }),
        switchMap(studentMetadataForClass =>
            this.studentsDataService.insertStudentMetadataForClass(studentMetadataForClass)
                .pipe(
                    map(() => new ActionInsertStudentMetadataForClassSuccess()),
                    catchError(error => {
                        const description = {
                            origin: 'Add Student Metadata',
                            payload: studentMetadataForClass,
                            message: error
                        };
                        return [
                            new ActionSendTelemetryEventForError({ description }),
                            new ActionInsertStudentMetadataForClassFailed({ error }),
                            new ActionShowErrorModal(),
                        ];
                    })
                )
        )
    );

    @Effect()
    updateStudentMetadataForClass$ = this.actions$.pipe(
        ofType<ActionUpdateStudentMetadataForClass>(StudentsActionTypes.UpdateStudentMetadataForClass),
        map(action => action.payload.studentMetadataForClass),
        switchMap(studentMetadataForClass =>
            this.studentsDataService.updateStudentMetadataForClass(studentMetadataForClass)
                .pipe(
                    map(() => new ActionUpdateStudentMetadataForClassSuccess({
                        studentId: studentMetadataForClass.studentId
                    })),
                    catchError(error => {
                        const description = {
                            origin: 'Update Student MetaData',
                            payload: studentMetadataForClass,
                            message: error
                        };
                        return [
                            new ActionSendTelemetryEventForError({ description }),
                            new ActionUpdateStudentMetadataForClassFailed({ error }),
                            new ActionShowErrorModal(),
                        ];
                    })
                ))
    );

    @Effect({ dispatch: false })
    redirectToZeroStateOnClassChange$ = this.store$.pipe(
        select(selectCurrentClassRoster),
        withLatestFrom(this.store$.select(selectRouterState)),
        filter(([classRoster, router]) => !!classRoster && !!router),
        map(([, router]) => {
            const { params: { detailsType, studentId }, url } = router.state;
            return { detailsType, studentId, url };
        }),
        filter(({ detailsType, studentId }) => !!detailsType && !!studentId),
        tap(() => {
            this.router.navigateByUrl('/students');
        })
    );
}
