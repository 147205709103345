import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssessmentState, State } from './assessment.model';
import { selectAll } from './assessment.reducer';

export const selectAssessmentState = createFeatureSelector<State, AssessmentState>('assessments');

export const selectAllAssessments = createSelector(
    selectAssessmentState,
    selectAll
);

export const selectAssessmentById = (assessmentId: string) => {
    return createSelector(
        selectAssessmentState,
        assessments => assessments.entities[assessmentId]
    );
};

export const selectSelectedAssessmentId = createSelector(
    selectAssessmentState,
    state => state.selectedAssessmentId
);

export const selectSelectedCriterionId = createSelector(
    selectAssessmentState,
    state => state.selectedCriterionId
);
