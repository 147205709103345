<nav class="menu" role="navigation" [attr.aria-label]="'app-navbar.aria.nav' | translate">
    <ul class="nav list space-evenly" [attr.aria-label]="'app-navbar.aria.ul' | translate">
        <li class="list-item dashboard">
            <a class="nav-item" routerLinkActive #dashboard="routerLinkActive" [routerLink]="['/dashboard']"
                [title]="'app-navbar.dashboard' | translate" [ngClass]="{ 'selected': assessment.isActive || dashboard.isActive }">
                <div class="label-icon icon-overview" aria-hidden="true"></div>
                <div class="label-text">{{ 'app-navbar.dashboard' | translate }}</div>
            </a>
        </li>
        <li class="list-item notes">
            <a class="nav-item" routerLinkActive="selected" [routerLink]="['/notes']"
                [title]="'app-navbar.notes' | translate">
                <div class="label-icon icon-PDF_alt" aria-hidden="true"></div>
                <div class="label-text">{{ 'app-navbar.notes' | translate }}</div>
            </a>
        </li>
        <li class="list-item media">
            <a class="nav-item" routerLinkActive="selected" [routerLink]="['/media']"
                [title]="'app-navbar.media' | translate">
                <div class="label-icon icon-media_library" aria-hidden="true"></div>
                <div class="label-text">{{ 'app-navbar.media' | translate }}</div>
            </a>
        </li>
        <li class="list-item reports">
            <a class="nav-item" routerLinkActive="selected" routerLink="/reports"
                [title]="'app-navbar.reports' | translate">
                <div class="label-icon icon-poll" aria-hidden="true"></div>
                <div class="label-text">{{ 'app-navbar.reports' | translate }}</div>
            </a>
        </li>
        <li class="list-item students">
            <a class="nav-item" routerLinkActive="selected" [routerLink]="['/students']"
                [title]="'app-navbar.students' | translate">
                <div class="label-icon icon-small_groups" aria-hidden="true"></div>
                <div class="label-text">{{ 'app-navbar.students' | translate }}</div>
            </a>
        </li>
        <li class="list-item navbar__capture">
            <input class="hidden" type="file" accept="image/*, video/*" capture #captureImage (change)="onMediaCaptured($event)">
            <a class="nav-item"
                (click)="captureImage.click()"
                [title]="'app-navbar.capture' | translate">
                <div class="label-icon icon-camera" aria-hidden="true"></div>
                <div class="label-text">{{ 'app-navbar.capture' | translate }}</div>
            </a>
        </li>
        <li class="list-item assessment">
            <a class="nav-item" routerLinkActive #assessment="routerLinkActive" [routerLink]="['/assess']"></a>
        </li>
    </ul>
</nav>
