import { Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'src/app/core/logging/logger';
import { NotesService } from '../../core/notes/notes.service';
import { AppState } from 'src/app/core/core.state';
import { Store } from '@ngrx/store';
import { ActionShowEditorMenu, ActionHideEditorMenu } from 'src/app/core/notes/store/notes.actions';
import { Note } from 'src/app/core/notes/store/notes.model';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'notes-page',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotesComponent implements OnInit, OnDestroy {
    allNotes$ = this.notesService.currentClassNotes$.pipe(
        tap(() => {
            this.cancelEditMode();
        })
    );
    notesFilter$ = this.notesService.filter$;
    isLoadingNotes$ = this.notesService.isLoadingNotes$;

    editMode = false;
    isExpanded = false;
    deleteConfirmText = {
        header: '',
        message: '',
        buttonCancel: '',
        buttonDelete: '',
    };

    editSelections = new Set<Note>();

    constructor(
        public alertController: AlertController,
        private notesService: NotesService,
        private logger: Logger,
        private translate: TranslateService,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.translate.get('notes-page.deleteConfirm.header').subscribe((result: string) => {
            this.deleteConfirmText.header = result;
        });
        this.translate.get('notes-page.deleteConfirm.message').subscribe((result: string) => {
            this.deleteConfirmText.message = result;
        });
        this.translate.get('notes-page.deleteConfirm.buttonCancel').subscribe((result: string) => {
            this.deleteConfirmText.buttonCancel = result;
        });
        this.translate.get('notes-page.deleteConfirm.buttonDelete').subscribe((result: string) => {
            this.deleteConfirmText.buttonDelete = result;
        });
    }

    ngOnDestroy() {
        this.store.dispatch(new ActionHideEditorMenu());
    }

    async presentNotesMenu() {
        this.store.dispatch(new ActionShowEditorMenu());
    }

    filterChanged(evt) {
        this.logger.info('segment changed, whatever that means ;)', evt);
        const filter = evt.detail.value;
        this.notesService.filterNotes(filter);
    }

    enableEditMode() {
        this.editMode = true;
    }

    cancelEditMode() {
        // TODO: other things
        this.editMode = false;
        this.editSelections.clear();
    }

    async presentDeleteConfirm() {
        const alert = await this.alertController.create({
            header: this.deleteConfirmText.header,
            message: this.deleteConfirmText.message,
            buttons: [
                {
                    text: this.deleteConfirmText.buttonCancel,
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        this.logger.info('Confirm Cancel');
                    }
                }, {
                    text: this.deleteConfirmText.buttonDelete,
                    handler: () => {
                        this.logger.info('Confirm Delete');
                        this.removeSelected();
                    }
                }
            ]
        });

        await alert.present();
    }

    removeSelected() {
        this.logger.info('selected items to remove: ', this.editSelections);
        // TODO: remove selected items
        const ids = Array.from(this.editSelections).map(note => note.id);
        this.notesService.removeNotes(ids);
        this.editSelections.clear();
        this.editMode = false;
    }

    updateCheckedNotesList(event) {
        const { checked, note } = event;
        if (checked) {
            this.editSelections.add(note);
        } else {
            this.editSelections.delete(note);
        }
    }
}
