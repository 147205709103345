import {
    Component,
    Output,
    EventEmitter,
    Input,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    AfterViewChecked,
    OnInit,
    HostListener
} from '@angular/core';
import { ClassRoster } from '../store/class-roster.model';
import { ScreenWidthBreakpoints } from 'src/app/shared/constants/media-queries';

@Component({
    selector: 'app-class-select',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './class-select.component.html',
    styleUrls: ['./class-select.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ClassSelectComponent implements OnInit, AfterViewChecked {
    @Input() classList: ClassRoster[];
    @Input() selectedClassId: string;
    @Input() cssClassName: string;
    @Output() selectionChange = new EventEmitter<string>();

    isDropdownOpen = false;
    isDropDownContentOpen = false;
    matcher: MediaQueryList;
    constructor() {}

    ngOnInit() {
        if (matchMedia) {
            this.matcher = window.matchMedia(ScreenWidthBreakpoints.small);
        }
    }

    ngAfterViewChecked() {
        if (this.isDropdownOpen && !this.isDropDownContentOpen && this.matcher && !this.matcher.matches) {
            const dropdown: any = document.querySelector('.ng-dropdown-panel.class-select');
            dropdown.style.width = 'auto';
            const dropdownContent = document.querySelector('.ng-dropdown-panel.class-select .ng-dropdown-panel-items');
            const currentDropdownWidth = parseInt(dropdown.style['min-width'], 10);
            const currentDropdownLeft = parseInt(dropdown.style.left, 10);
            const expectedWidth = 450;
            const offsetWidth = expectedWidth - currentDropdownWidth;
            const expectedLeft = currentDropdownLeft - offsetWidth;
            if (dropdownContent && !isNaN(expectedLeft)) {
                const currentWidth = dropdownContent.getBoundingClientRect().width;
                this.isDropDownContentOpen = true;
                const styleWidth = 1;
                if (currentWidth > expectedWidth) {
                    dropdown.style.left = `${expectedLeft}px`;
                    dropdown.style['max-width'] = `${expectedWidth}px`;
                } else if (currentWidth > (currentDropdownWidth + styleWidth)) {
                    const expectedLeftShift = currentDropdownLeft - (currentWidth - currentDropdownWidth);
                    dropdown.style.left = `${expectedLeftShift}px`;
                }
            }
        }
    }

    rosterChanged(evt) {
        this.isDropdownOpen = false;
        this.selectionChange.emit(evt.classId);
    }

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
        this.isDropDownContentOpen = !this.isDropdownOpen;
    }

    isSelectClassClicked(event): boolean {
        const dropdown = document.querySelector(`.${this.cssClassName}.class-select.ng-select`);
        return dropdown.contains(event.target);
    }

    @HostListener('document:click', ['$event'])
    onClick(event) {
        if (this.isDropdownOpen && !this.isSelectClassClicked(event)) {
            this.isDropdownOpen = false;
        }
    }
}
