import { Injectable } from '@angular/core';
import { LogLevel, LogEntry } from './logEntry';

import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class Logger {
    constructor() {}

    readonly logs$: Subject<LogEntry> = new Subject<LogEntry>();

    info(message: string, ...params) {
        this.writeToLog(message, LogLevel.INFO, ...params);
    }

    debug(message: string, ...params) {
        this.writeToLog(message, LogLevel.DEBUG, ...params);
    }

    warn(message: string, ...params) {
        this.writeToLog(message, LogLevel.WARN, ...params);
    }

    error(message: string, ...params) {
        this.writeToLog(message, LogLevel.ERROR, ...params);
    }

    private writeToLog(message: string, level: LogLevel, ...params) {
        const entry = new LogEntry();
        entry.message = message;
        entry.level = level;
        entry.params = params;

        if (level < environment.logging.level) {
            this.logs$.next(entry);
        }
    }
}
