import { EntityState } from '@ngrx/entity';
import { AppState } from '../../core.state';
import { Media } from '../../media/store/media.model';

export interface Comment {
    id: string;
    type: CommentType;
    observationId: string;
    criterionId?: string;
    createdAt: number;
    updatedAt: number;
    createdBy: string;
    value?: string;
    mediaId?: string;
    deleted: boolean;
}

export interface CommentSnapshot {
    id?: string;
    type: CommentType;
    observationId?: string;
    criterionId?: string;
    createdAt?: number;
    updatedAt?: number;
    createdBy?: string;
    value?: string;
    file?: File;
    media?: Media;
}

export enum CommentType {
    Text = 'text',
    Media = 'media',
}

export interface CommentsState extends EntityState<Comment> {
    isLoading: boolean;
}

export interface State extends AppState {
    comments: CommentsState;
}
