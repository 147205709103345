import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorModule } from './error/error.module';
import { environment } from 'src/environments/environment';
import { AuthEffects } from './auth/store/auth.effects';
import { AuthModule } from './auth/auth.module';
import { ClassRosterModule } from './class-roster/class-roster.module';
import { ClassSelectComponent } from './class-roster/class-select/class-select.component';
import { CommentsModule } from './comments/comments.module';
import { metaReducers, reducers } from './core.state';
import { HeaderComponent } from './components/header/header.component';
import { MediaModule } from './media/media.module';
import { StudentsModule } from './students/students.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotesModule } from './notes/notes.module';
import { ObservationsModule } from './observations/observations.module';
import { ReportsModule } from './reports/reports.module';
import { CustomSerializer } from './router/custom-serializer';
import { SessionStorageService } from './session-storage/session-storage.service';
import { SettingsModule } from './settings/settings.module';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { UserProfileModule } from './user-profile/user-profile.module';
import { TitleService } from './title/title.service';
import { LoggingModule } from './logging/logging.module';
import { TelemetryModule } from './telemetry/telemetry.module';
import { AuthTelemetryEffects } from './auth/store/auth-telemetry.effects';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ProgramModule } from './program/program.module';
import { ProductModule } from './product/product.module';
import { AssessmentModule } from './assessment/assessment.module';
import { SharedModule } from '../shared/shared.module';
import { DashboardMenuComponent } from './components/dashboard-menu/dashboard-menu.component';
// tslint:disable-next-line: max-line-length
import { DashboardAssessmentListComponent } from './components/dashboard-menu/components/dashboard-assessment-list/dashboard-assessment-list.component';
// tslint:disable-next-line: max-line-length
import { DashboardAssessmentHierarchyComponent } from './components/dashboard-menu/components/dashboard-assessment-hierarchy/dashboard-assessment-hierarchy.component';
// tslint:disable-next-line: max-line-length
import { DashboardAssessmentHierarchyGroupComponent } from './components/dashboard-menu/components/dashboard-assessment-hierarchy-group/dashboard-assessment-hierarchy-group.component';
// tslint:disable-next-line: max-line-length
import { DashboardAssessmentHierarchyNodeComponent } from './components/dashboard-menu/components/dashboard-assessment-hierarchy-node/dashboard-assessment-hierarchy-node.component';
import { DashboardModuleNgrx } from './dashboard/dashboard.module';
import { FormsModule } from '@angular/forms';
import { LstDropdownModule } from './components/lst-dropdown/lst-dropdown.module';
import { LayoutModule } from './layout/layout.module';
import { NavigationModule } from './navigation/navigation.module';
import { OralReadingChartMenuComponent } from './components/oral-reading-chart-menu/oral-reading-chart-menu.component';
import { SearchMenuComponent } from './components/search-menu/search-menu.component';
import { SearchModule } from './search/search.module';
import { ObservationFilterMenuComponent } from './components/observation-filter-menu/observation-filter-menu.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { OralReadingChartModule } from './oral-reading-chart/oral-reading-chart.module';
import { ServiceWorkerModule } from './service-worker/service-worker.module';

@NgModule({
    declarations: [
        HeaderComponent,
        UserMenuComponent,
        NavbarComponent,
        DashboardMenuComponent,
        DashboardAssessmentListComponent,
        DashboardAssessmentHierarchyComponent,
        DashboardAssessmentHierarchyGroupComponent,
        DashboardAssessmentHierarchyNodeComponent,
        SearchMenuComponent,
        OralReadingChartMenuComponent,
        ObservationFilterMenuComponent,
        ErrorModalComponent
    ],
    entryComponents: [
        UserMenuComponent,
        ErrorModalComponent
    ],
    imports: [
        // angular
        CommonModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        LstDropdownModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        // ngrx
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreRouterConnectingModule.forRoot(),
        EffectsModule.forRoot([AuthEffects, AuthTelemetryEffects]),
        environment.production
            ? []
            : StoreDevtoolsModule.instrument({
                  name: environment.appId
              }),
        // ionic
        IonicModule,
        // features
        AuthModule,
        SettingsModule,
        LoggingModule,
        TelemetryModule,
        UserProfileModule,
        ClassRosterModule,
        NotesModule,
        ProgramModule,
        ProductModule,
        AssessmentModule,
        ObservationsModule,
        CommentsModule,
        SharedModule,
        MediaModule,
        StudentsModule,
        SearchModule,
        LayoutModule,
        NavigationModule,
        ReportsModule,
        OralReadingChartModule,
        DashboardModuleNgrx,
        ErrorModule,
        ServiceWorkerModule
    ],
    providers: [
        TitleService,
        SessionStorageService,
        { provide: 'SESSION_STORAGE', useValue: sessionStorage },
        { provide: 'LOCATION', useValue: window.location },
        { provide: 'WINDOW', useValue: window },
        { provide: RouterStateSerializer, useClass: CustomSerializer }
    ],
    exports: [
        TranslateModule,
        HeaderComponent,
        UserMenuComponent,
        NavbarComponent,
        DashboardMenuComponent,
        // need to re-export at the moment due to usage in app component
        ClassSelectComponent,
        SearchMenuComponent,
        OralReadingChartMenuComponent,
        ObservationFilterMenuComponent
    ],
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
