import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUserProfiles from './user-profile.reducer';

export const selectUserProfilesState = createFeatureSelector<fromUserProfiles.State>(
    'users'
);

export const selectUserById = (userId: string) =>
    createSelector(
        selectUserProfilesState,
        state => state.entities[userId]
    );

export const selectAllUsers = createSelector(
    selectUserProfilesState,
    fromUserProfiles.selectAll
);

export const selectUsersByIds = (userIds: string[]) =>
    createSelector(
        selectAllUsers,
        users => users.filter(user => userIds.includes(user.rumbaUser.userId))
    );

export const selectIsLoadingUsers = createSelector(
    selectUserProfilesState,
    state => state.isLoading,
);
