import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, tap } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class TitleService {
    constructor(
        private translateService: TranslateService,
        private title: Title
    ) {
        this.sectionTitle = new Subject();
    }

    public sectionTitle: Subject<string>;

    setTitle(
        snapshot: ActivatedRouteSnapshot,
        lazyTranslateService?: TranslateService
    ) {
        let lastChild = snapshot;
        while (lastChild.children.length) {
            lastChild = lastChild.children[0];
        }
        const { title } = lastChild.data;
        const translate = lazyTranslateService || this.translateService;
        if (title) {
            translate
                .get(title)
                .pipe(
                    tap(translatedTitle => {
                        this.sectionTitle.next(translatedTitle);
                    }),
                    filter(translatedTitle => translatedTitle !== title)
                )
                .subscribe(translatedTitle =>
                    this.title.setTitle(`${translatedTitle} - ${env.appName}`)
                );
        } else {
            this.title.setTitle(env.appName);
        }
    }
}
