import { Injectable } from '@angular/core';
import { Query } from '@angular/fire/firestore';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ChecklistReportVM, AssessmentVM } from 'src/app/core/reports/store/reports.model';
import { UserProfile } from 'src/app/core/user-profile/store/user-profile.model';
import { AssessmentItem, ContentCriterion } from '../assessment/store/assessment.model';
import { selectSelectedAssessmentId } from '../assessment/store/assessment.selectors';
import { selectCurrentUser } from '../auth/store/auth.selectors';
import { ClassRosterService } from '../class-roster/class-roster.service';
import { selectSelectedClassRosterId, selectSelectedStudentId } from '../class-roster/store/class-roster.selectors';
import { Comment, CommentType } from '../comments/store/comments.model';
import { selectObservationComments, selectIsLoadingComments } from '../comments/store/comments.selectors';
import { AppState } from '../core.state';
import { ObservationsDataService } from '../observations/observations.data.service';
import { selectSelectedProgramId } from '../program/store/program.selectors';
import {
    ObservationReportFilters,
    ObservationTypeFilterOptions,
    RangeFilterOptions
} from '../reports/store/reports.model';
import {
    ActionCreateObservationDocument,
    ActionDeleteObservationComments,
    ActionSendStartedTimerTelemetryEvent
} from './store/observations.actions';
import {
    ChecklistObservation,
    ChecklistStudentViewVM,
    ObservationsSnapshot,
    ObservationType,
    FluencyReadingLevel
} from './store/observations.model';
import {
    selectAssessmentObservations,
    selectIsLoadingObservations,
    selectObservation,
    selectSelectedObservationId
} from './store/observations.selectors';

@Injectable({ providedIn: 'root' })
export class ObservationsService {
    isLoadingComments$ = combineLatest(
        this.store$.pipe(select(selectIsLoadingComments)),
        this.store$.pipe(select(selectIsLoadingObservations))
    ).pipe(
        map(([isLoadingComments, isLoadingObservations]) => {
            return (isLoadingComments || isLoadingObservations);
        })
    );

    studentObservation$ = combineLatest(
        this.store$.pipe(select(selectSelectedClassRosterId)),
        this.store$.pipe(select(selectSelectedStudentId))
    ).pipe(
        withLatestFrom(
            this.store$.pipe(select(selectSelectedProgramId)),
            this.store$.pipe(select(selectSelectedAssessmentId)),
            this.store$.pipe(select(selectCurrentUser))
        ),
        filter(([, programId, assessmentId, currentUser]) => !!programId && !!assessmentId && !!currentUser),
        switchMap(([[classId, studentId], programId, assessmentId, currentUser]) =>
            this.store$.pipe(select(selectObservation(classId, programId, assessmentId, studentId, currentUser.userId)))
        )
    );

    assessmentObservations$ = combineLatest(
        this.store$.pipe(select(selectSelectedClassRosterId)),
        this.store$.pipe(select(selectSelectedProgramId)),
        this.store$.pipe(select(selectSelectedAssessmentId))
    ).pipe(
        switchMap(([classId, programId, assessmentId]) =>
            this.store$.pipe(select(selectAssessmentObservations(classId, programId, assessmentId)))
        )
    );

    studentsObservationsVM$ = combineLatest(
        this.rosterService.currentRosterStudents$,
        this.assessmentObservations$
    ).pipe(
        filter(([students, observations]) => !!students && !!observations),
        map(([students, observations]) => {
            const observationsMap = observations.reduce((accumulator, observation) => {
                accumulator[observation.studentId] = observation;
                return accumulator;
            }, {});
            return { students, observations: observationsMap };
        }),
        map(({ students, observations }) => {
            const studentsData = [];
            students.forEach(student => {
                const studentId = student.rumbaUser.userId;
                const isStudentObservationComplete = !!observations[studentId] && observations[studentId].isComplete;
                const studentData = { student, isStudentObservationComplete };
                studentsData.push(studentData);
            });
            return studentsData;
        })
    );

    studentObservationComments$ = this.store$.pipe(
        select(selectSelectedObservationId),
        switchMap(observationId =>
            observationId ? this.store$.pipe(select(selectObservationComments(observationId))) : of([])
        )
    );

    selectedStudentId$ = this.store$.pipe(select(selectSelectedStudentId));
    selectedAssessmentId$ = this.store$.pipe(select(selectSelectedAssessmentId));

    constructor(
        private store$: Store<AppState>,
        private rosterService: ClassRosterService,
        private observationsDataService: ObservationsDataService
    ) {}

    upsertObservations(snapshot: ObservationsSnapshot) {
        this.store$.dispatch(new ActionCreateObservationDocument({ snapshot }));
    }

    deleteComments(comments: Comment[]) {
        this.store$.dispatch(new ActionDeleteObservationComments({ comments }));
    }

    getChecklistStudentViewVM(
        criteria: ContentCriterion,
        observation: ChecklistObservation,
        comments: any[]
    ): ChecklistStudentViewVM {
        let textCommentsCount = 0;
        let mediaCommentsCount = 0;
        let lastComment;
        const filteredComments = comments.filter(commentVM => {
            if (commentVM.comment.criterionId === criteria.id) {
                if (commentVM.comment.type === CommentType.Media) {
                    mediaCommentsCount += 1;
                } else {
                    textCommentsCount += 1;
                }
                lastComment = commentVM;
                return true;
            }
            return false;
        });
        return {
            criteria,
            comments: filteredComments,
            textCommentsCount,
            mediaCommentsCount,
            isChecked: !!(observation && observation.criteriaIsChecked && observation.criteriaIsChecked[criteria.id]),
            lastComment
        };
    }

    sendStartedTimerTelemetryEvent() {
        this.store$.dispatch(new ActionSendStartedTimerTelemetryEvent());
    }

    fetchLatestObservationForAssessment(assessment: AssessmentItem) {
        return combineLatest(
            this.store$.pipe(select(selectSelectedClassRosterId)),
            this.store$.pipe(select(selectCurrentUser))
        ).pipe(
            filter(([classId, currentUser]) => !!classId && !!currentUser),
            switchMap(([classId, currentUser]) =>
                this.observationsDataService.fetchLatestObservationForAssessment(
                    classId,
                    assessment.rootProgramIdentifier,
                    assessment.identifier,
                    currentUser.userId
                )
            ),
            map(querySnapshot =>
                querySnapshot.docs.length === 0
                    ? { ...assessment, updatedAt: 0 }
                    : { ...assessment, updatedAt: querySnapshot.docs[0].data().updatedAt }
            ),
            first()
        );
    }

    getLatestValidObservationForStudentByType(
        student: UserProfile,
        createdBy: string,
        classId: string,
        assessmentType: ObservationType
    ) {
        return this.observationsDataService.getLatestValidObservationForStudentByType(
            student,
            createdBy,
            classId,
            assessmentType
        );
    }

    getLatestCommentByObservationId(observationId: string, createdBy: string) {
        return this.observationsDataService.getLatestCommentByObservationId(observationId, createdBy);
    }

    getAllValidObservationsForStudent(
        classId: string,
        createdBy: string,
        studentId: string,
        assessmentType?: ObservationType
    ) {
        return this.observationsDataService.getAllValidObservationsForStudent(
            classId,
            createdBy,
            studentId,
            assessmentType
        );
    }

    fetchObservationsWithComments(
        classId: string,
        programId: string,
        assessmentVM: AssessmentVM,
        createdBy: string
    ): Observable<ChecklistReportVM> {
        return this.observationsDataService
            .fetchObservationsWithComments(classId, programId, assessmentVM.id, createdBy)
            .pipe(map(observations => ({ assessmentVM, programId, observations })));
    }

    fetchObservationsForClassByFilter(
        classId: string,
        createdBy: string,
        withComments: boolean,
        filters: ObservationReportFilters,
    ): Observable<any> {
        const { observationTypeFilter, rangeFilter, fromFilter, toFilter } = filters;
        const queryFn = ref => {
            let query = ref.where('classId', '==', classId)
                .where('createdBy', '==', createdBy);
            query = !!observationTypeFilter ? this.applyObservationTypeFilter(observationTypeFilter, query) : query;
            query = !!rangeFilter ? this.applyRangeFilter(rangeFilter, fromFilter, toFilter, query) : query;
            return query;
        };
        return this.observationsDataService.fetchObservationsForClass(withComments, createdBy, queryFn);
    }

    private applyObservationTypeFilter(observationTypeFilter: ObservationTypeFilterOptions, query: Query) {
        switch (observationTypeFilter) {
            case ObservationTypeFilterOptions.Checklists:
                return query.where('type', '==', ObservationType.Checklist);
            case ObservationTypeFilterOptions.Ongoing:
                return query.where('type', '==', ObservationType.Ongoing);
            case ObservationTypeFilterOptions.Fluency:
                return query.where('type', '==', ObservationType.Fluency);
            default:
                throw new Error(`unknown observation type filter: ${observationTypeFilter}`);
        }
    }

    private applyRangeFilter(rangeFilter: RangeFilterOptions, fromFilter: number, toFilter: number, query: Query) {
        switch (rangeFilter) {
            case RangeFilterOptions.CustomRange:
                query = !!fromFilter ? query.where('updatedAt', '>=', fromFilter) : query;
                query = !!toFilter ? query.where('updatedAt', '<', toFilter) : query;
                return query;
            case RangeFilterOptions.Last7Days:
                const millisInAWeek = 7 * 24 * 60 * 60 * 1000;
                const last7Days = Date.now() - millisInAWeek;
                return query.where('updatedAt', '>=', last7Days);
            case RangeFilterOptions.Last14Days:
                const millisInTwoWeeks = 2 * 7 * 24 * 60 * 60 * 1000;
                const last14Days = Date.now() - millisInTwoWeeks;
                return query.where('updatedAt', '>=', last14Days);
            case RangeFilterOptions.Last30Days:
                const millisInAMonth = 30 * 24 * 60 * 60 * 1000;
                const last30Days = Date.now() - millisInAMonth;
                return query.where('updatedAt', '>=', last30Days);
            case RangeFilterOptions.Last60Days:
                const millisInTwoMonths = 2 * 30 * 24 * 60 * 60 * 1000;
                const last60Days = Date.now() - millisInTwoMonths;
                return query.where('updatedAt', '>=', last60Days);
            default:
                throw new Error(`unknown range filter type: ${rangeFilter}`);
        }
    }
}
