import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TelemetryEffects } from './telemetry.effects';
import { TelemetryService } from './telemetry.service';

@NgModule({
    imports: [CommonModule, EffectsModule.forFeature([TelemetryEffects])],
    providers: [TelemetryService]
})
export class TelemetryModule {}
