import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReportsState, State } from './reports.model';

export const selectReportsState = createFeatureSelector<State, ReportsState>('reports');

export const selectObservationReportFilters = createSelector(
    selectReportsState,
    state => state.observationReportFilters,
);

export const selectCheckListReportStates = createSelector(
    selectReportsState,
    state => state.checklistReportStates
);

export const selectSelectedProductProgramSkeleton = createSelector(
    selectCheckListReportStates,
    state => state.selectedProductProgramSkeleton
);

export const selectSelectedContentItem = createSelector(
    selectCheckListReportStates,
    state => state.selectedContentItem
);

export const selectSelectedCriteriaTag = createSelector(
    selectCheckListReportStates,
    state => state.selectedCriteriaTag
);

export const selectTagsList = createSelector(
    selectCheckListReportStates,
    state => state.tagsList
);

export const selectIsTagsListLoading = createSelector(
    selectCheckListReportStates,
    state => state.isTagsListLoading
);

export const selectIsChecklistReportDataLoading = createSelector(
    selectCheckListReportStates,
    state => state.isChecklistReportDataLoading
);
