<ion-content>
    <div class="flex-container-col">
        <div class="card-header">
            <h4 class="card-header__title">{{ 'app-oral-reading-chart-menu.heading' | translate }}</h4>
            <ion-menu-toggle>
                <button class="menu-header__close-button" [title]="'global.menu.close-menu' | translate">
                    <span class="icon-exit" aria-hidden="true"></span>
                </button>
            </ion-menu-toggle>
        </div>
        <div class="report-container">
            <table class="oral-reading-chart__table">
                <thead class="table__header">
                    <tr>
                        <th>{{ 'app-oral-reading-chart-menu.table-header.grade' | translate }}</th>
                        <th>{{ 'app-oral-reading-chart-menu.table-header.ILE' | translate }}</th>
                        <th>{{ 'app-oral-reading-chart-menu.table-header.fallwcpm' | translate }}</th>
                        <th>{{ 'app-oral-reading-chart-menu.table-header.winterwcpm' | translate }}</th>
                        <th>{{ 'app-oral-reading-chart-menu.table-header.springwcpm' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let gradeRecord of oralReadingChartData">
                        <tr *ngFor="let record of gradeRecord.benchMarkRecord; let recordIndex = index">
                            <td *ngIf="recordIndex === 0" rowspan="5">{{ gradeRecord.grade }}</td>
                            <td>{{ record.ILE }}</td>
                            <td [ngClass]="{ 'table__row--nodata': !record.fallWcpm }">{{ record.fallWcpm }}</td>
                            <td>{{ record.winterWcpm }}</td>
                            <td>{{ record.springWcpm }}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</ion-content>

