<ion-list class="user-menu" lines="none">
    <span *ngIf="currentUser$ | async as currentUser">
        <ion-list-header class="user-menu__header">
            <ion-label>
                <h3 class="user-menu__header__title">
                    {{ currentUser.firstName }} {{ currentUser.lastName }}
                </h3>
            </ion-label>
        </ion-list-header>
        <ion-list-header class="user-menu__header user-menu__header--subtitle">
            <span class="user-menu__header__subtitle">{{ currentUser.userName }}</span>
        </ion-list-header>
    </span>
    <ion-item class="user-menu__item menu-item__search" (click)="search()">
        <ion-label>
            {{ 'app-user-menu.search' | translate }}
        </ion-label>
    </ion-item>
    <a *ngIf="helpUrl" href="{{ helpUrl }}" target="_blank" class="support-link">
        <ion-item class="user-menu__item menu-item__support" (click)="support()">
            <ion-label>
                {{ 'app-user-menu.help' | translate }}
            </ion-label>
        </ion-item>
    </a>
    <ion-item class="user-menu__item menu-item__signOut" (click)="signOut()">
        <ion-label>
            {{ 'app-user-menu.signout' | translate }}
        </ion-label>
    </ion-item>
</ion-list>
