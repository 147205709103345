import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { MediaService } from 'src/app/core/media/media.service';
import { Media, MediaFilter } from 'src/app/core/media/store/media.model';
import { Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'media-list',
    templateUrl: './media-list.component.html',
    styleUrls: ['./media-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaListComponent implements OnInit, OnDestroy {
    _editMode = false;
    filteredBy: string;
    deleteConfirmText = {
        header: '',
        message: '',
        buttonCancel: '',
        buttonDelete: '',
    };
    checkedSelection = new Set<Media>();

    private filterSub: Subscription;

    constructor(
        public alertController: AlertController,
        private mediaService: MediaService,
        private translate: TranslateService
    ) {}

    allMedia$ = this.mediaService.filteredMedia$;
    mediaFilter$ = this.mediaService.mediaFilter$;
    isLoading$ = this.mediaService.isLoading$;

    ngOnInit() {
        this.filterSub = this.mediaFilter$.subscribe(filteredBy => {
            this.filteredBy = (filteredBy === MediaFilter.StudentMedia) ? MediaFilter.AllMedia : filteredBy;
        });

        this.translate.get('media-list.deleteConfirm.header').subscribe((result: string) => {
            this.deleteConfirmText.header = result;
        });
        this.translate.get('media-list.deleteConfirm.message').subscribe((result: string) => {
            this.deleteConfirmText.message = result;
        });
        this.translate.get('media-list.deleteConfirm.buttonCancel').subscribe((result: string) => {
            this.deleteConfirmText.buttonCancel = result;
        });
        this.translate.get('media-list.deleteConfirm.buttonDelete').subscribe((result: string) => {
            this.deleteConfirmText.buttonDelete = result;
        });
    }

    ngOnDestroy() {
        this.filterSub.unsubscribe();
    }

    set editMode(value: boolean) {
        this._editMode = value;
        this.checkedSelection.clear();
    }

    get editMode() {
        return this._editMode;
    }

    filterChanged(event) {
        const currentFilter = event.detail.value;
        this.mediaService.setMediaFilter(currentFilter);
    }

    toggleSelected(eventData) {
        const { event, media } = eventData;
        const { checked } = event.detail;
        if (checked) {
            this.checkedSelection.add(media);
        } else {
            this.checkedSelection.delete(media);
        }
    }

    async presentDeleteConfirm() {
        const alert = await this.alertController.create({
            header: this.deleteConfirmText.header,
            message: this.deleteConfirmText.message,
            buttons: [
                {
                    text: this.deleteConfirmText.buttonCancel,
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: this.deleteConfirmText.buttonDelete,
                    handler: () => {
                        this.deleteMedia();
                    }
                }
            ]
        });

        await alert.present();
    }

    deleteMedia() {
        this.mediaService.removeMediaDispatchAction(Array.from(this.checkedSelection));
        this.checkedSelection.clear();
        this._editMode = false;
    }

    onMediaCaptured(event) {
        const files: FileList = event.target.files;
        if (files && files.length > 0) {
            this.mediaService.addMediaDispatchAction(files[0]);
            event.target.value = '';
        }
    }

    onImageClick(media: Media) {
        if (this._editMode) {
            const checked = this.checkedSelection.has(media);
            if (checked) {
                this.checkedSelection.delete(media);
            } else {
                this.checkedSelection.add(media);
            }
        } else {
            this.mediaService.openMediaMenu(media.id);
        }
    }
}
