<div class="node-container">
    <a
        class="nav-item"
        [routerLink]="[
            'assess',
            assessment?.mediaType ? (assessment?.mediaType | assessmentRoute) : '',
            assessment?.id ? assessment?.id : ''
        ]"
        [ngStyle]="{ 'padding-left': hierarchyPadding }"
        (click)="onAssessmentClick(assessment?.id)"
    >
        <span class="item__type">{{ assessment?.mediaType | assessmentLabel | translate }}</span>
        <div class="assessment-content">
            <span aria-hidden="true" class="label-icon" [ngClass]="assessment?.mediaType | assessmentIcon"></span>
            <span class="label-text">{{ assessment?.name }}</span>
        </div>
    </a>
</div>
