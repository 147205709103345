import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ActionLoadSearchRecords } from './store/search.actions';
import { SearchRecord } from './store/search.model';

@Injectable({ providedIn: 'root' })
export class SearchDataService {

    constructor(private afsDb: AngularFirestore) {}

    upsertSearch(searchRecord: SearchRecord) {
        return from(this.afsDb.collection('searches').doc(searchRecord.id).set(searchRecord, { merge: true }));
    }

    fetchSearches(createdBy: string) {
        const millisInAWeek = 7 * 24 * 60 * 60 * 1000;
        const previousWeek = Date.now() - millisInAWeek;
        const query = ref => ref
            .where('createdBy', '==', createdBy)
            .where('updatedAt', '>', previousWeek)
            .orderBy('updatedAt', 'desc')
            .limit(25);
        return this.afsDb.collection('searches', query)
            .get()
            .pipe(
                map(snapshot => snapshot.docs.map(documentSnapshot => documentSnapshot.data() as SearchRecord)),
                switchMap(searchRecords => of(new ActionLoadSearchRecords({ searchRecords })))
            );
    }
}
