import { EntityState } from '@ngrx/entity';
import { AppState } from '../../core.state';
import { ObservationType } from '../../observations/store/observations.model';

// resembles `mediaType` in program hierarchy
export enum AssessmentType {
    Ongoing = 'Ongoing Assessment',
    Fluency = 'Fluency Assessment',
    Checklist = 'Assessment Checklist'
}

export interface Assessment {
    id: string;
    type: ObservationType;
    title: string;
    keywords?: string[];
    criteria?: ContentCriterion[];
}

export interface ContentCriterion {
    id: string;
    title: string;
    sequence: number;
    tags: CriterionTag[];
}

export interface CriterionTag {
    title: string;
    description: string;
}

export interface AssessmentViewPayload {
    type: string;
    programId: string;
    assessmentId: string;
    studentId?: string;
    criterionId?: string;
}

export interface AssessmentState extends EntityState<Assessment> {
    selectedAssessmentId: string;
    selectedCriterionId?: string;
}

export interface State extends AppState {
    assessments: AssessmentState;
}

export interface ItemAttachmentResponse {
    identifier: string;
    titleInSequence: string;
    attachment: {
        type: ObservationType;
        title: string;
        id: string;
        keywords: string[];
        criteria: ContentCriterion[];
    };
}

export interface AssessmentItem {
    productIds: string[];
    rootProgramIdentifier: string;
    identifier: string;
    titleInSequence: string;
    path?: string;
    updatedAt?: number;
    type?: string;
}

export enum AssessmentNavigationType {
    Carousel = 'carousel',
    AssessmentMenu = 'assessmentMenu'
}

export enum AssessmentNavigationSubType {
    ViewByTOC = 'viewByTOC',
    ViewAll = 'viewAll'
}
