import { Pipe, PipeTransform } from '@angular/core';
import { AssessmentType } from 'src/app/core/assessment/store/assessment.model';

@Pipe({ name: 'assessmentIcon' })
export class AssessmentIconPipe implements PipeTransform {
    transform(assessmentType: AssessmentType) {
        switch (assessmentType) {
            case AssessmentType.Checklist:
                return 'icon-observational_assessment-inverted';
            case AssessmentType.Fluency:
                return 'icon-timer';
            case AssessmentType.Ongoing:
                return 'icon-assessment';
            default:
                return '';
        }
    }
}
