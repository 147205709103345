import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { switchMap, filter } from 'rxjs/operators';
import { selectSelectedClassRosterId } from '../class-roster/store/class-roster.selectors';
import { AppState, selectRouterState } from '../core.state';
import {
    ActionCreateNote,
    ActionFilterNotes,
    ActionRemoveNotes,
    ActionUpdateNote
} from './store/notes.actions';
import { Note, NotesFilter, NoteType } from './store/notes.model';
import {
    selectCountOfFilteredNotesByClass,
    selectFilteredNotesVmByClass,
    selectIsLoadingNotes,
    selectNoteById,
    selectNotesFilter,
    selectAllNotesByStudent
} from './store/notes.selectors';
import { combineLatest } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotesService {
    constructor(private store: Store<AppState>) {}

    isLoadingNotes$ = this.store.pipe(select(selectIsLoadingNotes));

    currentClassNotes$ = this.store.pipe(
        select(selectSelectedClassRosterId),
        switchMap(classId =>
            this.store.pipe(select(selectFilteredNotesVmByClass(classId)))
        )
    );

    currentClassNotesCount$ = this.store.pipe(
        select(selectSelectedClassRosterId),
        switchMap(classId =>
            this.store.pipe(select(selectCountOfFilteredNotesByClass(classId)))
        )
    );

    selectedStudentsNotes$ = combineLatest(
        this.store.pipe(select(selectSelectedClassRosterId)),
        this.store.pipe(select(selectRouterState)),
    ).pipe(
        filter(([classId, {state}]) => classId && state && state.params && state.params.studentId),
        switchMap(([classId, { state }]) =>
            this.store.pipe(select(selectAllNotesByStudent(classId, state.params.studentId)))
        )
    );

    filter$ = this.store.pipe(select(selectNotesFilter));

    public filterNotes(filterType: NotesFilter) {
        this.store.dispatch(new ActionFilterNotes({ filter: filterType }));
    }

    getNoteById(noteId: string) {
        return this.store.pipe(select(selectNoteById(noteId)));
    }

    createNote(newNote: {
        title?: string;
        body?: string;
        type: NoteType;
        dueDate?: number;
        studentIds?: string[];
        classId: string;
        createdBy: string;
    }) {
        const note: Partial<Note> = {
            body: newNote.body,
            type: newNote.type,
            createdDate: Date.now(),
            updatedDate: Date.now(),
            dueDate: newNote.dueDate,
            createdBy: newNote.createdBy,
            classId: newNote.classId,
            completed: false,
            deleted: false,
            completedDate: null,
            studentIds: newNote.studentIds || []
        };
        this.store.dispatch(new ActionCreateNote({ note }));
    }

    updateNote(updateNote: Update<Note>) {
        this.store.dispatch(new ActionUpdateNote({ update: updateNote }));
    }

    removeNotes(ids: string[]) {
        this.store.dispatch(new ActionRemoveNotes({ ids }));
    }
}
