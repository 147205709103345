import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AssessmentService } from './assessment.service';
import { AssessmentTelemetryEffects } from './store/assessment-telemetry.effects';
import { AssessmentEffects } from './store/assessment.effects';
import { assessmentReducer } from './store/assessment.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('assessments', assessmentReducer),
        EffectsModule.forFeature([AssessmentEffects, AssessmentTelemetryEffects])
    ],
    providers: [AssessmentService]
})
export class AssessmentModule {}
