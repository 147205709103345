import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { NotesActions, NotesActionTypes } from './notes.actions';
import { Note, NotesFilter, NotesState, NoteType } from './notes.model';

export const adapter: EntityAdapter<Note> = createEntityAdapter<Note>();

const initialFilter: NotesFilter = 'ALL';

export const initialState: NotesState = adapter.getInitialState({
    filter: initialFilter,
    editorNote: null,
    isLoadingNotes: true
});

export function reducer(
    state: NotesState = initialState,
    action: NotesActions
): NotesState {
    switch (action.type) {
        case NotesActionTypes.LoadNotesStart:
            return { ...state, isLoadingNotes: true };
        case NotesActionTypes.LoadNotesEnd:
            return { ...state, isLoadingNotes: false };
        case NotesActionTypes.AddNoteToStore: {
            return adapter.addOne(action.payload.note, state);
        }
        case NotesActionTypes.RemoveNoteFromStore: {
            const id = action.payload.note.id;
            return adapter.removeOne(id, state);
        }
        case NotesActionTypes.UpdateNoteInStore: {
            const id = action.payload.note.id;
            const changes = action.payload.note;
            return adapter.updateOne({ id, changes }, state);
        }
        case NotesActionTypes.RemoveNoteSuccess:
            return adapter.removeMany(action.payload.ids, state);
        case NotesActionTypes.FilterNotes:
            return { ...state, filter: action.payload.filter };
        case NotesActionTypes.ShowEditorMenu:
            let noteStartState = null;
            if (action.payload && action.payload.noteId) {
                noteStartState = { ...state.entities[action.payload.noteId] };
                // TODO: check if for some reason this is undefined?
            } else {
                noteStartState = {
                    id: '',
                    body: '',
                    createdDate: 0,
                    updatedDate: 0,
                    createdBy: '',
                    type: NoteType.note,
                    classId: '',
                    completed: false,
                    dueDate: null,
                    completedDate: 0,
                    studentIds: []
                };
            }
            return { ...state, editorNote: noteStartState };
        case NotesActionTypes.UpdateNoteSuccess:
            return adapter.updateOne(action.payload.update, state);
        default:
            return state;
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();
