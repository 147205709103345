import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, ClassRosterState } from './class-roster.model';
import { selectAll } from './class-roster.reducer';

export const selectClassRosterState = createFeatureSelector<
    State,
    ClassRosterState
>('classRoster');

export const selectClassRosterById = (classId: string) =>
    createSelector(
        selectClassRosterState,
        state => state.entities[classId]
    );

export const selectAllClassRosters = createSelector(
    selectClassRosterState,
    selectAll
);

export const selectSelectedClassRosterId = createSelector(
    selectClassRosterState,
    state => state.currentRoster
);

export const selectSelectedStudentId = createSelector(
    selectClassRosterState,
    state => state.selectedStudentId
);

export const selectCurrentClassRoster = createSelector(
    selectClassRosterState,
    state => state.entities[state.currentRoster]
);

export const selectClassRosterIsLoading = createSelector(
    selectClassRosterState,
    state => state.isLoading
);
