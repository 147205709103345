import { Action } from '@ngrx/store';
import { Product } from './product.model';

export enum ProductActionTypes {
    LoadProducts = '[Products] Load Products',
    LoadProductsSuccess = '[Products] Load Products Success',
    LoadProductFailure = '[Products] Load Products Failure'
}

export class ActionLoadProducts implements Action {
    readonly type = ProductActionTypes.LoadProducts;
    constructor(public payload: { productIds: string[] }) {}
}

export class ActionLoadProductsSuccess implements Action {
    readonly type = ProductActionTypes.LoadProductsSuccess;
    constructor(public payload: { products: Product[] }) {}
}

export class ActionLoadProductFailure implements Action {
    readonly type = ProductActionTypes.LoadProductFailure;
    constructor(public payload: { error: any }) {}
}

export type ProductActions = ActionLoadProducts | ActionLoadProductsSuccess | ActionLoadProductFailure;
