import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ClassRoster } from '../class-roster/store/class-roster.model';
import { ActionLoadComments } from '../comments/store/comments.actions';
import { Comment } from '../comments/store/comments.model';
import { ProgramItem } from '../program/store/program.model';
import { UserProfile } from '../user-profile/store/user-profile.model';
import { ActionLoadObservations } from './store/observations.actions';
import { Observation, ObservationType } from './store/observations.model';

@Injectable({ providedIn: 'root' })
export class ObservationsDataService {

    constructor(private afsDb: AngularFirestore) {}

    upsertObservation(observation: Observation, comment?: Comment) {
        const batch = this.afsDb.firestore.batch();
        const observationRef = this.afsDb.firestore.collection('observations').doc(observation.id);
        batch.set(observationRef, observation, { merge: true });
        if (comment) {
            const commentRef = observationRef.collection('comments').doc(comment.id);
            batch.set(commentRef, comment);
        }
        return from(batch.commit());
    }

    deleteObservationComments(comments: Comment[]) {
        const batch = this.afsDb.firestore.batch();
        comments.forEach(comment => {
            const observationRef = this.afsDb.firestore.collection('observations').doc(comment.observationId);
            const commentRef = observationRef.collection('comments').doc(comment.id);
            batch.update(observationRef, { updatedAt: Date.now() });
            batch.update(commentRef, { ...comment, deleted: true });
        });
        return from(batch.commit());
    }

    fetchObservations(classId: string, programId: string, assessmentId: string, createdBy: string) {
        const query = ref => ref
            .where('classId', '==', classId)
            .where('programId', '==', programId)
            .where('assessmentId', '==', assessmentId)
            .where('createdBy', '==', createdBy);
        return this.afsDb.collection('observations', query)
            .snapshotChanges()
            .pipe(
                map(actions => {
                    return actions.map(action => {
                        const doc = action.payload.doc.data() as Observation;
                        const id = action.payload.doc.id;
                        return { ...doc, id };
                    });
                }),
                switchMap(observations => of(new ActionLoadObservations({ observations })))
            );
    }

    fetchObservationComments(observationId: string, createdBy: string) {
        const query = ref => ref.where('createdBy', '==', createdBy);
        return this.afsDb.collection('observations', query)
            .doc(observationId)
            .collection('comments', query)
            .snapshotChanges()
            .pipe(
                map(actions => {
                    return actions.map(action => {
                        const doc = action.payload.doc.data() as Comment;
                        const id = action.payload.doc.id;
                        return { ...doc, id };
                    });
                }),
                switchMap(comments => of(new ActionLoadComments({ comments })))
            );
    }

    getAllValidObservationsForStudent(
        classId: string,
        createdBy: string,
        studentId: string,
        assessmentType?: ObservationType
    ) {
        const queryFn = ref => {
            let query = ref
                .where('classId', '==', classId)
                .where('createdBy', '==', createdBy)
                .where('isComplete', '==', true)
                .where('studentId', '==', studentId);
            query = !!assessmentType ? query.where('type', '==', assessmentType) : query;
            return query;
        };
        return this.afsDb.collection('observations', queryFn).get();
    }

    getLatestValidObservationForStudentByType(
        student: UserProfile,
        createdBy: string,
        classId: string,
        assessmentType: ObservationType
    ) {
        const studentId = student.rumbaUser.userId;
        const query = ref => ref
            .where('classId', '==', classId)
            .where('createdBy', '==', createdBy)
            .where('type', '==', assessmentType)
            .where('studentId', '==', studentId)
            .where('isObservationValid', '==', true)
            .orderBy('updatedAt', 'desc')
            .limit(1);
        return this.afsDb.collection('observations', query).get();
    }

    getObservationsForRecentActivity(classId: string, createdBy: string, numberOfResults: number) {
        const query = ref => ref
            .where('classId', '==', classId)
            .where('createdBy', '==', createdBy)
            .orderBy('updatedAt', 'desc')
            .limit(numberOfResults);
        return this.afsDb.collection('observations', query).get();
    }

    getLatestCommentByObservationId(observationId: string, createdBy: string) {
        const commentsQuery = ref => ref
            .orderBy('updatedAt', 'desc')
            .where('createdBy', '==', createdBy)
            .where('deleted', '==', false)
            .limit(1);
        return this.afsDb.collection(`observations/${observationId}/comments`, commentsQuery).get();
    }

    fetchObservationStats(classRoster: ClassRoster, programId: string, programItem: ProgramItem, createdBy: string) {
        const query = ref => ref
            .where('classId', '==', classRoster.classId)
            .where('programId', '==', programId)
            .where('assessmentId', '==', programItem.id)
            .where('createdBy', '==', createdBy)
            .where('isComplete', '==', true)
            .orderBy('updatedAt', 'desc');
        return this.afsDb.collection('observations', query)
            .get()
            .pipe(map(querySnapshot => querySnapshot.docs.map(document => document.data() as Observation)));
    }

    fetchLatestObservationForAssessment(classId: string, programId: string, assessmentId: string, createdBy: string) {
        const query = ref => ref
            .where('createdBy', '==', createdBy)
            .where('classId', '==', classId)
            .where('programId', '==', programId)
            .where('assessmentId', '==', assessmentId)
            .orderBy('updatedAt', 'desc')
            .limit(1);
        return this.afsDb.collection('observations', query).get();
    }

    fetchObservationsForClass(withComments: boolean, createdBy: string, queryFn: QueryFn): Observable<Observation[]> {
        const observationsRef = this.afsDb.collection('observations', queryFn);
        const response = observationsRef
            .get()
            .pipe(
                map(querySnapshot => querySnapshot.docs.map(doc => doc.data() as Observation)),
            );
        if (!withComments) {
            return response;
        }

        const commentsQuery = ref => ref.where('createdBy', '==', createdBy).where('deleted', '==', false);
        return response.pipe(
            switchMap(observations => observations.length > 0
                ? forkJoin(
                    observations.map(observation => observationsRef
                        .doc(observation.id)
                        .collection('comments', commentsQuery)
                        .get()
                        .pipe(
                            map(snapshot => snapshot.docs.map(commentDoc => commentDoc.data() as Comment)),
                            map(comments => Object.assign(observation, { comments })),
                        )
                    ))
                : of([])
            )
        );
    }

    fetchObservationsWithComments(classId: string, programId: string, assessmentId: string, createdBy: string) {
        const query = ref => ref
            .where('createdBy', '==', createdBy)
            .where('classId', '==', classId)
            .where('programId', '==', programId)
            .where('assessmentId', '==', assessmentId);

        const observationRef = this.afsDb.collection('observations', query);
        const observationResponse = observationRef
            .get()
            .pipe(map(querySnapshot => querySnapshot.docs.map(doc => doc.data() as Observation)));

        const commentsQuery = ref => ref
            .where('createdBy', '==', createdBy)
            .where('deleted', '==', false)
            .orderBy('updatedAt', 'desc');
        return observationResponse.pipe(
            switchMap(observations => observations.length > 0
                ? forkJoin(
                    observations.map(observation => observationRef
                        .doc(observation.id)
                        .collection('comments', commentsQuery)
                        .get()
                        .pipe(
                            map(snapshot => snapshot.docs.map(commentDoc => commentDoc.data() as Comment)),
                            map(comments => Object.assign({}, { observation , comments })),
                        )
                    ))
                : of([])
            )
        );
    }
}
