import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import {
    ActionSendAssessmentOpenedTelemetryEvent,
    AssessmentActionTypes
} from 'src/app/core/assessment/store/assessment.actions';
import { selectSelectedClassRosterId } from 'src/app/core/class-roster/store/class-roster.selectors';
import { AppState } from 'src/app/core/core.state';
import { selectSelectedProgramId } from 'src/app/core/program/store/program.selectors';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';

@Injectable()
export class AssessmentTelemetryEffects {

    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private telemetryService: TelemetryService,
    ) {}

    @Effect({ dispatch: false })
    assessmentOpened = this.actions$.pipe(
        ofType<ActionSendAssessmentOpenedTelemetryEvent>(AssessmentActionTypes.SendAssessmentOpenedTelemetryEvent),
        map(action => action.payload),
        withLatestFrom(
            this.store$.pipe(select(selectSelectedClassRosterId)),
            this.store$.pipe(select(selectSelectedProgramId)),
        ),
        tap(([payload, classId, programId]) => {
            const { assessmentId, navigationType, navigationSubType } = payload;
            let details: any = {
                section: { id: classId },
                program: { rootProgramIdentifier: programId },
                assessment: { id: assessmentId },
                navigationType,
            };
            details = !!navigationSubType ? { ...details, navigationSubType } : details;
            this.telemetryService.sendActivity('opened', 'Assessment', details);
        })
    );
}
