import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Assessment, AssessmentItem, ItemAttachmentResponse } from './store/assessment.model';

@Injectable({ providedIn: 'root' })
export class AssessmentDataService {

    constructor(private http: HttpClient) {}

    fetchAssessmentItems(
        searchTerm: string,
        classId: string,
        userId: string,
        token: string,
    ): Observable<AssessmentItem[]> {
        const apiUrl = environment.api.searchAssessmentItems;
        const url = `${apiUrl}class/${classId}/assessment/search?searchTerm=${encodeURIComponent(searchTerm)}`;
        const headers = {
            Authorization: `Bearer ${token}`,
            userId
        };
        return this.http.get(url, { headers }).pipe(
            map(result => Object.values(result['assessments'])),
        );
    }

    fetchAssessment(
        productId: string,
        rootProgramId: string,
        contentItemId: string,
        userId: string,
        accessToken: string
    ): Observable<Assessment> {
        const apiUrl = environment.api.itemAttachment;
        const url =
            `${apiUrl}product/${productId}/rootProgram/${rootProgramId}/contentItem/${contentItemId}/attachment`;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            userId
        };
        return this.http.get<ItemAttachmentResponse>(url, { headers }).pipe(
            map(itemAttachmentResponse => ({
                    id: itemAttachmentResponse.identifier,
                    type: itemAttachmentResponse.attachment.type,
                    title: itemAttachmentResponse.titleInSequence,
                    keywords: itemAttachmentResponse.attachment.keywords,
                    criteria: itemAttachmentResponse.attachment.criteria
                })
            ),
        );
    }
}
