import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState, State } from './dashboard.model';
import { selectAll } from './dashboard.reducer';

export const selectDashboardState = createFeatureSelector<State, DashboardState>('dashboard');

export const selectIsLoadingDashboardStats = createSelector(
    selectDashboardState,
    state => state.isLoadingAssessmentStats,
);

export const selectIsLoadingRecentActivity = createSelector(
    selectDashboardState,
    state => state.isLoadingRecentActivity,
);

export const selectDashboardAssessmentStats = createSelector(
    selectDashboardState,
    selectAll
);

export const selectIsAllAssessmentsMenuOpen = createSelector(
    selectDashboardState,
    state => state.isAllAssessmentsMenuOpen,
);
