import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/product.reducer';
import { ProductEffects } from './store/product.effects';
import { ProductService } from './product.service';
import { ProductDataService } from './product.data.service';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('product', reducer), EffectsModule.forFeature([ProductEffects])],
    providers: [ProductService, ProductDataService]
})
export class ProductModule {}
