import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { selectSelectedClassRosterId } from 'src/app/core/class-roster/store/class-roster.selectors';
import { AppState } from 'src/app/core/core.state';
import { selectSelectedProgramId } from 'src/app/core/program/store/program.selectors';
import { ActionSendSearchTelemetryEvent, SearchActionTypes } from 'src/app/core/search/store/search.actions';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';

@Injectable()
export class SearchTelemetryEffects {

    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private telemetryService: TelemetryService,
    ) {}

    @Effect({ dispatch: false })
    onSearch = this.actions$.pipe(
        ofType<ActionSendSearchTelemetryEvent>(SearchActionTypes.SendSearchTelemetryEvent),
        map(action => action.payload),
        withLatestFrom(
            this.store$.pipe(select(selectSelectedClassRosterId)),
            this.store$.pipe(select(selectSelectedProgramId)),
        ),
        tap(([payload, classId, programId]) => {
            const { searchTerm, fromSearchHistory } = payload;
            const details = {
                section: { id: classId },
                program: { rootProgramIdentifier: programId },
                searchTerm,
                fromSearchHistory: fromSearchHistory ? 'TRUE' : 'FALSE',
            };
            this.telemetryService.sendActivity('executed', 'Search', details);
        })
    );
}
