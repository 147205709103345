import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../core.state';
import { ActionShowFooter, ActionHideFooter } from './store/layout.actions';

@Injectable({ providedIn: 'root' })
export class LayoutService {
    constructor(private store$: Store<AppState>) {}

    showFooter() {
        this.store$.dispatch(new ActionShowFooter());
    }

    hideFooter() {
        this.store$.dispatch(new ActionHideFooter());
    }
}
