<div class="flex-container-col">

    <ion-toolbar class="page-bar">
        <div slot="start">
            <ion-segment class="segment" (ionChange)="filterChanged($event)" [value]="notesFilter$ | async" mode="ios">
                <ion-segment-button class="segment-button" value="ALL">
                    <ion-label class="segment-button__label">{{ 'notes-page.allNotes' | translate }}</ion-label>
                </ion-segment-button>
                <ion-segment-button class="segment-button" value="TODO">
                    <ion-label class="segment-button__label">{{ 'notes-page.todos' | translate }}</ion-label>
                </ion-segment-button>
            </ion-segment>
        </div>

        <ion-buttons slot="end">
            <ion-button class="page-bar__add-button action-button" [title]="'notes-page.addNote' | translate"
                (click)="presentNotesMenu()" [disabled]="editMode || (isLoadingNotes$ | async)">
                <div class="action-button__icon label-icon icon-addition" slot="start" aria-hidden="true"></div>
                <ion-label class="action-button__label">{{ 'notes-page.addNote' | translate }}</ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

    <!-- view state -->
    <ion-card class="list-card flex-container-col">
        <ion-card-header class="card-header card-header--editable">
            <ion-toolbar class="card-header__toolbar">
                <ion-button *ngIf="!editMode" [disabled]="(allNotes$ | async)?.length === 0 || (isLoadingNotes$ | async)"
                    (click)="enableEditMode()" class="card-header__edit-button action-button"
                    [title]="'notes-page.edit' | translate">
                    <div class="action-button__icon label-icon icon-edit" slot="start" aria-hidden="true"></div>
                    <ion-label class="action-button__label">{{ 'notes-page.edit' | translate }}</ion-label>
                </ion-button>
                <ion-button *ngIf="editMode" [disabled]="isLoadingNotes$ | async" (click)="cancelEditMode()"
                    class="card-header__cancel-button action-button" [title]="'notes-page.cancel' | translate">
                    <ion-label class="action-button__label">{{ 'notes-page.cancel' | translate }}</ion-label>
                </ion-button>
                <ion-button *ngIf="editMode" [disabled]="editSelections.size === 0 || (isLoadingNotes$ | async)" (click)="presentDeleteConfirm()"
                    class="card-header__remove-button action-button" [title]="'notes-page.remove' | translate">
                    <ion-label class="action-button__label">{{ 'notes-page.remove' | translate }}</ion-label>
                </ion-button>
                <ion-button *ngIf="!editMode" class="card-header__add-button action-button"
                    [title]="'notes-page.addNote' | translate" (click)="presentNotesMenu()"
                    [disabled]="editMode || (isLoadingNotes$ | async)">
                    <div class="action-button__icon label-icon icon-addition" slot="start" aria-hidden="true"></div>
                    <ion-label class="action-button__label">{{ 'notes-page.addNote' | translate }}</ion-label>
                </ion-button>
            </ion-toolbar>
        </ion-card-header>

        <ion-content class="card-content">
            <notes-list
                [editMode]="editMode"
                [allNotes]="allNotes$ | async"
                (updateCheckedList)="updateCheckedNotesList($event)"
                zeroStateText="{{ 'notes-page.zero-state.allNotes' | translate }}"
            ></notes-list>
        </ion-content>

    </ion-card>
</div>
