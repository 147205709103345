import { Action } from '@ngrx/store';

export enum OralReadingChartActionTypes {
    OpenOralReadingChartMenu = '[Reports] Open Oral Reading Chart Menu',
    CloseOralReadingChartMenu = '[Reports] Close Oral Reading Chart Menu'
}

export class ActionOpenOralReadingChartMenu implements Action {
    readonly type = OralReadingChartActionTypes.OpenOralReadingChartMenu;
}

export class ActionCloseOralReadingChartMenu implements Action {
    readonly type = OralReadingChartActionTypes.CloseOralReadingChartMenu;
}

export type OralReadingChartActions =
    | ActionOpenOralReadingChartMenu
    | ActionCloseOralReadingChartMenu;
