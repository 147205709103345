import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { selectSelectedAssessmentId } from 'src/app/core/assessment/store/assessment.selectors';
import { selectSelectedClassRosterId } from 'src/app/core/class-roster/store/class-roster.selectors';
import { AppState } from 'src/app/core/core.state';
import {
    ActionAddMediaSuccess,
    ActionRemoveMediaSuccess,
    MediaActionTypes,
    ActionSendFileExceededTelemetry
} from 'src/app/core/media/store/media.actions';
import { selectSelectedProgramId } from 'src/app/core/program/store/program.selectors';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';
import { ActionShowErrorModal } from '../../error/store/error.actions';

@Injectable()
export class MediaTelemetryEffects {

    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private telemetryService: TelemetryService
    ) {}

    @Effect({ dispatch: false })
    sendFileExceededTelemetry$ = this.actions$.pipe(
        ofType<ActionSendFileExceededTelemetry>(MediaActionTypes.SendFileExceededTelemetry),
        map(action => action.payload),
        tap(({ type, size }) => {
            const details: any = {
                media: {
                    type: type.toLowerCase(),
                    size: size / 1024
                },
            };
            this.telemetryService.sendActivity('error', 'Media', details, 'media size too big');
            this.store$.dispatch(new ActionShowErrorModal({
                translatedTitle: 'error-title-media-upload',
                translatedDescription: 'error-description-media-upload'
             }));
        })
    );

    @Effect({ dispatch: false })
    addMedia = this.actions$.pipe(
        ofType<ActionAddMediaSuccess>(MediaActionTypes.AddMediaSuccess),
        map(action => action.payload.media),
        withLatestFrom(
            this.store$.pipe(select(selectSelectedClassRosterId)),
            this.store$.pipe(select(selectSelectedProgramId)),
            this.store$.pipe(select(selectSelectedAssessmentId)),
        ),
        tap(([media, classId, programId, assessmentId]) => {
            let details: any = {
                section: { id: classId },
                program: { rootProgramIdentifier: programId },
                studentIDs: media.taggedStudents,
                media: {
                    type: media.type
                }
            };
            details = !!assessmentId ? { ...details, assessment: { id: assessmentId } } : details;
            this.telemetryService.sendActivity('created', 'Media', details);
        })
    );

    @Effect({ dispatch: false })
    removeMedia = this.actions$.pipe(
        ofType<ActionRemoveMediaSuccess>(MediaActionTypes.RemoveMediaSuccess),
        map(action => action.payload.media),
        withLatestFrom(
            this.store$.pipe(select(selectSelectedClassRosterId)),
            this.store$.pipe(select(selectSelectedProgramId)),
            this.store$.pipe(select(selectSelectedAssessmentId)),
        ),
        tap(([media, classId, programId, assessmentId]) => {
            media.forEach(doc => {
                let details: any = {
                    section: { id: classId },
                    program: { rootProgramIdentifier: programId },
                    studentIDs: doc.taggedStudents,
                };
                details = !!assessmentId ? { ...details, assessment: { id: assessmentId } } : details;
                this.telemetryService.sendActivity('deleted', 'Media', details);
            });
        })
    );
}
