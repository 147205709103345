import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import {
    ActionOpenOralReadingChartMenu,
    OralReadingChartActionTypes,
    ActionCloseOralReadingChartMenu
} from './oral-reading-chart.actions';

@Injectable()
export class OralReadingChartEffects {
    constructor(
        private actions$: Actions<Action>,
        private menuController: MenuController
    ) {}

    @Effect({ dispatch: false })
    openOralReadingChart$ = this.actions$.pipe(
        ofType<ActionOpenOralReadingChartMenu>(
            OralReadingChartActionTypes.OpenOralReadingChartMenu
        ),
        tap(() => {
            this.menuController
                .enable(true, 'oralReadingChartMenu')
                .then(() => {
                    this.menuController.open('oralReadingChartMenu');
                });
        })
    );

    @Effect({ dispatch: false })
    closeOralReadingChart$ = this.actions$.pipe(
        ofType<ActionCloseOralReadingChartMenu>(
            OralReadingChartActionTypes.CloseOralReadingChartMenu
        ),
        tap(() => {
            this.menuController.close('oralReadingChartMenu');
        })
    );
}
