import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { map, tap, withLatestFrom, filter } from 'rxjs/operators';
import { selectSelectedClassRosterId } from 'src/app/core/class-roster/store/class-roster.selectors';
import { AppState } from 'src/app/core/core.state';
import { ActionSetSelectedProgram, ProgramActionTypes } from 'src/app/core/program/store/program.actions';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';

@Injectable()
export class ProgramTelemetryEffects {

    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private telemetryService: TelemetryService,
    ) {}

    @Effect({ dispatch: false })
    selectProgram = this.actions$.pipe(
        ofType<ActionSetSelectedProgram>(ProgramActionTypes.SetSelectedProgram),
        map(action => action.payload.rootProgramIdentifier),
        filter(programId => !!programId),
        withLatestFrom(this.store$.pipe(select(selectSelectedClassRosterId))),
        tap(([programId, classId]) => {
            const details = {
                section: { id: classId },
                program: { rootProgramIdentifier: programId },
            };
            this.telemetryService.sendActivity('selected', 'Program', details);
        })
    );
}
