export enum LogLevel {
    NONE,
    DEBUG,
    INFO,
    WARN,
    ERROR,
    ALL
}

export class LogEntry {
    message: string;
    entryDate: Date = new Date();
    level: LogLevel = LogLevel.INFO;
    params: any[] = [];
    logDate = true;
    stringifyParams = true;

    toString(): string {
        const dateLog = this.logDate ? this.entryDate.toLocaleString() : '';
        const levelLog = LogLevel[this.level];
        const paramsLog = this.stringifyParams ? `\r\n${this.getParamsString()}` : '';

        return `[${levelLog}: ${dateLog}] ${this.message} ${paramsLog}`;
    }

    private getParamsString() {
        if (this.params.some(p => typeof p === 'object')) {
            return this.params.reduce((str, param) => {
                let itemStr = param;
                if (typeof param === 'object') {
                    itemStr = JSON.stringify(param);
                }
                return `${str}, ${itemStr}`;
            }, '');
        }

        return this.params.join(', ');
    }
}
