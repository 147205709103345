import { Action } from '@ngrx/store';

export enum ServiceWorkerActionTypes {
    ShowRefreshModal = '[Service Worker] Show Refresh Modal',
    SetIsServiceWorkerUpdateModalOpen = '[Service Worker] Set Is Service Worker Update Modal Open'
}

export class ActionShowRefreshModal implements Action {
    readonly type = ServiceWorkerActionTypes.ShowRefreshModal;
}

export class ActionSetIsServiceWorkerUpdateModalOpen implements Action {
    readonly type = ServiceWorkerActionTypes.SetIsServiceWorkerUpdateModalOpen;
    constructor(public payload: { isServiceWorkerUpdateModalOpen: boolean }) {}
}

export type ServiceWorkerActions =
    | ActionShowRefreshModal
    | ActionSetIsServiceWorkerUpdateModalOpen;
