import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Note } from './store/notes.model';
import { Update } from '@ngrx/entity';
import { map } from 'rxjs/operators';
import { FirestoreDocumentChangeType } from '../firebase/firebase.model';
import {
    ActionAddNoteToStore,
    ActionUpdateNoteInStore,
    ActionRemoveNoteFromStore
} from './store/notes.actions';
import { Logger } from '../logging/logger';

@Injectable({ providedIn: 'root' })
export class NotesDataService {
    constructor(private afsDb: AngularFirestore, private logger: Logger) {}

    async createNote(note: Partial<Note>): Promise<Partial<Note>> {
        const notesCollection = this.afsDb.collection('notes');
        const noteDoc = await notesCollection.add(note);
        return {
            ...note,
            id: noteDoc.id
        };
    }

    fetchNotes(classId: string, createdBy: string) {
        const collection = this.afsDb.collection('notes', ref =>
            ref
                .where('classId', '==', classId)
                .where('createdBy', '==', createdBy)
                .where('deleted', '==', false)
        );
        return collection
            .auditTrail()
            .pipe(
                map(actions => {
                    this.logger.debug('firebase changes: ', actions);
                    const actionsToDispatch = [];
                    actions.forEach(({ type, payload }) => {
                        const data = payload.doc.data() as Note;
                        const id = payload.doc.id;
                        switch (type) {
                            case FirestoreDocumentChangeType.added:
                                actionsToDispatch.push(new ActionAddNoteToStore({ note: { ...data, id } }));
                                break;
                            case FirestoreDocumentChangeType.modified:
                                actionsToDispatch.push(new ActionUpdateNoteInStore({ note: { ...data, id } }));
                                break;
                            case FirestoreDocumentChangeType.removed:
                                actionsToDispatch.push(new ActionRemoveNoteFromStore({ note: { ...data, id } }));
                                break;
                        }
                    });
                    return actionsToDispatch;
                })
            );
    }

    updateNote(update: Update<Note>): Promise<void> {
        const noteRef = this.afsDb.collection('notes').doc(update.id as string);
        return noteRef.update({
            ...update.changes
        });
    }

    removeNotes(ids: string[]): Promise<void[]> {
        const updates = ids.map(id => {
            const update: Update<Note> = {
                id,
                changes: {
                    deleted: true
                }
            };
            return this.updateNote(update);
        });
        return Promise.all(updates);
    }
}
