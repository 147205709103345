import { Comment } from 'src/app/core/comments/store/comments.model';
import { ProductProgramSkeleton } from 'src/app/core/dashboard/store/dashboard.model';
import { ChecklistObservation } from 'src/app/core/observations/store/observations.model';
import { ProgramItem } from 'src/app/core/program/store/program.model';
import { AppState } from '../../core.state';

export interface ReportsState {
    observationReportFilters: ObservationReportFilters;
    checklistReportStates: ChecklistReportStates;
}

export interface ChecklistReportStates {
    selectedProductProgramSkeleton: ProductProgramSkeleton;
    selectedContentItem: ProgramItem;
    selectedCriteriaTag: CriteriaTag;
    tagsList: CriteriaTag[];
    isTagsListLoading: boolean;
    isChecklistReportDataLoading: boolean;
}

export interface ObservationReportFilters {
    observationTypeFilter: ObservationTypeFilterOptions;
    rangeFilter: RangeFilterOptions;
    fromFilter: number;
    toFilter: number;
    sortByFilter: SortByOptions;
}

export enum ObservationTypeFilterOptions {
    Ongoing = 'reports-observation.form.observation-type-filter-options.ongoing',
    Checklists = 'reports-observation.form.observation-type-filter-options.checklist',
    Fluency = 'reports-observation.form.observation-type-filter-options.fluency'
}

export enum RangeFilterOptions {
    Last7Days = 'reports-observation.form.range-filter-options.last-7-days',
    Last14Days = 'reports-observation.form.range-filter-options.last-14-days',
    Last30Days = 'reports-observation.form.range-filter-options.last-30-days',
    Last60Days = 'reports-observation.form.range-filter-options.last-60-days',
    CustomRange = 'reports-observation.form.range-filter-options.custom-range'
}

export enum SortByOptions {
    Students = 'reports-observation.form.sort-by-filter-options.students',
    Observations = 'reports-observation.form.sort-by-filter-options.observations',
}

export interface FilterChange {
    value: any;
    filterType: FilterType;
}

export enum FilterType {
    Observation = 'observation-type',
    Range = 'range',
    From = 'from',
    To = 'to',
    SortBy = 'sort-by'
}


export enum ReportType {
    Observational = 'observational',
    Fluency = 'fluency',
    Checklist = 'checklist',
}

export interface CriteriaTag {
    title: string;
    description: string;
    assessments: { id: string; criteriaIds: string[] }[];
}

export interface State extends AppState {
    reports: ReportsState;
}

export interface StudentObservationReport {
    studentId: string;
    studentName: string;
    totalObservations: number;
}

export interface ChecklistReportVM {
    programId: string;
    assessmentVM: AssessmentVM;
    observations: { observation: ChecklistObservation, comments: Comment[] }[];
    assessment?: ProgramItem;
}

export interface AssessmentVM {
    id: string;
    criteriaIds: string[];
}
