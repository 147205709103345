import { Component, OnInit, OnDestroy, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { switchMap, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ClassRosterService } from 'src/app/core/class-roster/class-roster.service';
import { UserProfileService } from 'src/app/core/user-profile/user-profile.service';
import { of, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/core.state';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import { selectMediaById, selectSelectedMediaId } from 'src/app/core/media/store/media.selectors';
import { MediaService } from 'src/app/core/media/media.service';
import { Media } from 'src/app/core/media/store/media.model';

interface Student {
    id: string;
    name: string;
    initials: string;
    selected: boolean;
}
interface MediaStudentVM {
    media?: Media;
    students?: Student[];
}
@Component({
    selector: 'media-menu',
    templateUrl: './media-menu.component.html',
    styleUrls: ['./media-menu.component.scss']
})
export class MediaMenuComponent implements OnInit, OnDestroy {
    _editMode: boolean;
    _formDirtyMode: boolean;
    newMediaModel: MediaStudentVM = {};
    prevMediaModel: MediaStudentVM = {};
    routerSubscription: Subscription;

    @ViewChild('videoClip') videoClip: ElementRef;

    constructor(
        private rosterService: ClassRosterService,
        private mediaService: MediaService,
        private ups: UserProfileService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.formDirtyMode = false;

        this.routerSubscription = this.store
            .pipe(
                select(selectSelectedMediaId),
                filter(mediaId => !!mediaId),
                switchMap(mediaId => {
                    this.editMode = false;
                    return this.store.pipe(select(selectMediaById(mediaId)));
                }),
                filter(media => !!media),
                withLatestFrom(
                    this.rosterService.currentRoster$.pipe(
                        filter(roster => !!roster),
                        switchMap(roster => {
                            return this.ups
                                .users$(roster.studentIds)
                                .pipe(switchMap(students => of({ roster, students })));
                        })
                    )
                )
            )
            .subscribe(([media, { roster, students }]) => {
                this.mediaService.fetchSignedDownloadUrlsAndAddToMedia([media]);
                students.sort((a, b) =>
                    a.rumbaUser.fullName.toLowerCase().localeCompare(b.rumbaUser.fullName.toLowerCase())
                );
                const studentsModel = students.map(student => ({
                    id: student.rumbaUser.userId,
                    name: student.rumbaUser.firstAndLastName,
                    initials: student.rumbaUser.firstAndLastName
                        .replace(/[^\w\s]/gi, '')
                        .match(/\b(\w)/g)
                        .join(''),
                    selected: media.taggedStudents.findIndex(id => student.rumbaUser.userId === id) !== -1
                }));
                this.prevMediaModel = { media: cloneDeep(media), students: cloneDeep(studentsModel) };
                this.newMediaModel = { media: cloneDeep(media), students: cloneDeep(studentsModel) };
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }

    set editMode(value: boolean) {
        this._editMode = value;
    }

    get editMode() {
        return this._editMode;
    }

    set formDirtyMode(value: boolean) {
        this._formDirtyMode = value;
    }

    get formDirtyMode() {
        return this._formDirtyMode;
    }

    onMenuDirty() {
        this.formDirtyMode = !isEqual(this.newMediaModel, this.prevMediaModel);
    }

    cancelMenuUpdate() {
        this.newMediaModel = cloneDeep(this.prevMediaModel);
        this.formDirtyMode = false;
    }

    doMenuUpdate() {
        this.formDirtyMode = false;
        this.newMediaModel.media.taggedStudents = this.newMediaModel.students
            .filter(student => student.selected)
            .map(student => student.id);
        this.newMediaModel.media.updatedAt = new Date().getTime();
        this.prevMediaModel = cloneDeep(this.newMediaModel);
        this.mediaService.updateMediaDispatchAction(this.newMediaModel.media);
    }

    onCloseMenu() {
        this.newMediaModel = {};
        if (this.videoClip) {
            this.videoClip.nativeElement.pause();
        }
        this.mediaService.closeMediaMenu();
    }

    trackByStudentId(index, student: any) {
        return student.id;
    }
}
