import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ReportsService } from 'src/app/core/reports/reports.service';
import {
    FilterType,
    FilterChange,
    ObservationReportFilters,
    RangeFilterOptions
} from 'src/app/core/reports/store/reports.model';

@Component({
    selector: 'app-observation-filter-menu',
    templateUrl: './observation-filter-menu.component.html',
    styleUrls: ['./observation-filter-menu.component.scss']
})
export class ObservationFilterMenuComponent implements OnInit, OnDestroy {

    isApplyButtonDisabled: boolean;
    selectedFilters: Partial<ObservationReportFilters>;
    currentFilters: ObservationReportFilters;
    currentFiltersSubscription: Subscription;

    constructor(private reportsService: ReportsService) {}

    ngOnInit() {
        this.currentFiltersSubscription = this.reportsService.selectObservationReportFilters$
            .pipe(filter(filters => !!filters))
            .subscribe(filters => {
                this.currentFilters = filters;
                this.selectedFilters = filters;
            });
    }

    ngOnDestroy() {
        this.currentFiltersSubscription.unsubscribe();
    }

    applyFilters() {
        this.reportsService.setObservationReportFilters(this.selectedFilters);
        this.closeFilterMenu();
    }

    closeFilterMenu() {
        this.selectedFilters = this.currentFilters;
        this.reportsService.closeObservationFilterMenu();
    }

    onFilterChange(filterChange: FilterChange) {
        const { value, filterType } = filterChange;
        switch (filterType) {
            case FilterType.Observation:
                this.selectedFilters = { ...this.selectedFilters, observationTypeFilter: value };
                break;
            case FilterType.Range:
                if (value === RangeFilterOptions.CustomRange) {
                  this.selectedFilters = { ...this.selectedFilters, rangeFilter: value };
                } else {
                  this.selectedFilters = {
                      ...this.selectedFilters,
                      rangeFilter: value,
                      fromFilter: undefined,
                      toFilter: undefined
                  };
                }
                break;
            case FilterType.SortBy:
                this.selectedFilters = { ...this.selectedFilters, sortByFilter: value };
                break;
            case FilterType.From:
                this.selectedFilters = { ...this.selectedFilters, fromFilter: value };
                break;
            case FilterType.To:
                this.selectedFilters = { ...this.selectedFilters, toFilter: value };
                break;
        }
        this.isApplyButtonDisabled =
            this.selectedFilters.rangeFilter === RangeFilterOptions.CustomRange
            && (!this.selectedFilters.fromFilter || !this.selectedFilters.toFilter);
    }
}
