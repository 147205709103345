import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Program, ProgramState } from './program.model';
import { ProgramActions, ProgramActionTypes } from './program.actions';

export const adapter: EntityAdapter<Program> = createEntityAdapter<Program>({});

export const initialState: ProgramState = adapter.getInitialState({
    // additional entity state properties
    currentRootProgramIdentifier: null,
    loadingRootProgramIdentifierIds: []
});

export function reducer(state = initialState, action: ProgramActions): ProgramState {
    switch (action.type) {
        case ProgramActionTypes.SetSelectedProgram: {
            const newProgramId = action.payload.rootProgramIdentifier;
            return newProgramId === state.currentRootProgramIdentifier
                ? state
                : { ...state, currentRootProgramIdentifier: newProgramId };
        }
        case ProgramActionTypes.LoadProgram: {
            const isLoading = state.loadingRootProgramIdentifierIds.includes(action.payload.rootProgramIdentifier);
            return isLoading ? state : {
                ...state,
                loadingRootProgramIdentifierIds: [
                    ...state.loadingRootProgramIdentifierIds,
                    action.payload.rootProgramIdentifier
                ]
            };
        }
        case ProgramActionTypes.LoadProgramSuccess: {
            const program = action.payload.program;
            const loadingRootProgramIdentifierIds = state.loadingRootProgramIdentifierIds.filter(
                (programId) => programId !== action.payload.program.id
            );
            const updatedState = {
                ...state,
                loadingRootProgramIdentifierIds
            };
            return adapter.addOne(program, updatedState);
        }
        case ProgramActionTypes.LoadProgramFailure: {
            const loadingRootProgramIdentifierIds = state.loadingRootProgramIdentifierIds.filter(
                (programId) => programId !== action.payload.programId
            );
            return {
                ...state,
                loadingRootProgramIdentifierIds
            };
        }
        default: {
            return state;
        }
    }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
