import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { of } from 'rxjs';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ClassRosterService } from 'src/app/core/class-roster/class-roster.service';
import { AppState } from 'src/app/core/core.state';
import { Logger } from 'src/app/core/logging/logger';
import { NotesService } from 'src/app/core/notes/notes.service';
import { ActionHideEditorMenu } from 'src/app/core/notes/store/notes.actions';
import { NoteType, Note } from 'src/app/core/notes/store/notes.model';
import { selectEditorNote } from 'src/app/core/notes/store/notes.selectors';
import { UserProfileService } from 'src/app/core/user-profile/user-profile.service';

@Component({
    selector: 'notes-menu',
    templateUrl: './notes-menu.component.html',
    styleUrls: ['./notes-menu.component.scss']
})
export class NotesMenuComponent implements OnInit, OnDestroy {
    newNoteModel;
    noteSubscription;
    minDate = (new Date().getFullYear() - 1).toString();
    maxDate = (new Date().getFullYear() + 1).toString();
    constructor(
        private authService: AuthService,
        private rosterService: ClassRosterService,
        private notesService: NotesService,
        private ups: UserProfileService,
        private menu: MenuController,
        private logger: Logger,
        private store: Store<AppState>,
    ) {}

    ngOnInit() {
        this.newNoteModel = {};
        this.noteSubscription = this.store
            .pipe(
                select(selectEditorNote),
                filter(note => !!note),
                withLatestFrom(
                    this.rosterService.currentRoster$,
                    this.authService.currentUser$
                ),
                filter(
                    ([, currentRoster, currentUser]) =>
                        !!currentRoster && !!currentUser
                ),
                switchMap(([editorNote, currentRoster, currentUser]) => {
                    return this.ups.users$(currentRoster.studentIds).pipe(
                        switchMap(students => {
                            students.sort((a, b) =>
                                a.rumbaUser.fullName.toLowerCase().localeCompare(b.rumbaUser.fullName.toLowerCase())
                            );
                            return of({
                                editorNote,
                                currentRoster,
                                currentUser,
                                students
                            });
                        })
                    );
                }),
                switchMap(
                    ({ editorNote, currentRoster, currentUser, students }) => {
                        const studentsModel = students.map(student => {
                            // this should exist only in "edit" scenario
                            const existing = editorNote.studentIds && editorNote.studentIds.find(
                                id => id === student.rumbaUser.userId
                            );
                            // TODO: modify UPS to return more VM ready student model
                            return {
                                id: student.rumbaUser.userId,
                                name: student.rumbaUser.firstAndLastName,
                                initials:
                                    student.rumbaUser.firstName[0].toUpperCase() +
                                    student.rumbaUser.lastName[0].toUpperCase(),
                                selected: !!existing
                            };
                        });
                        const finalNote = {
                            ...editorNote,
                            classId: currentRoster.classId,
                            createdBy: currentUser.userId,
                            students: studentsModel,
                            isTodo: editorNote.type && editorNote.type === NoteType.todo,
                            dueDateISO: editorNote.dueDate && editorNote.dueDate > 0
                                ? new Date(editorNote.dueDate).toISOString() : null
                        };
                        return of(finalNote);
                    }
                )
            )
            .subscribe(note => {
                this.logger.debug('note changed', note);
                this.newNoteModel = note;
            });
    }

    ngOnDestroy() {
        if (this.noteSubscription) {
            this.noteSubscription.unsubscribe();
        }
    }

    onTodoToggle(event) {
        const isTodo = event.detail.checked;
        this.newNoteModel = {
            ...this.newNoteModel,
            isTodo,
            dueDate: isTodo ? this.newNoteModel.dueDate : null,
            dueDateISO: isTodo ? this.newNoteModel.dueDateISO : null,
        };
    }

    createEditNote() {
        if (this.newNoteModel.id) {
            this.editNote();
        } else {
            this.createNote();
        }
    }

    private createNote() {
        this.logger.debug('******CREATE NOTE*****', this.newNoteModel);
        let dueDate = null;
        if (this.newNoteModel.dueDateISO) {
            dueDate = new Date(this.newNoteModel.dueDateISO).getTime();
        }

        const newNote = {
            classId: this.newNoteModel.classId,
            body: this.newNoteModel.body,
            type: this.newNoteModel.isTodo ? NoteType.todo : NoteType.note,
            dueDate: this.newNoteModel.isTodo ? dueDate : null,
            studentIds: this.newNoteModel.students
                .filter(student => student.selected)
                .map(student => student.id),
            createdBy: this.newNoteModel.createdBy
        };
        this.notesService.createNote(newNote);
        this.store.dispatch(new ActionHideEditorMenu());
    }

    private editNote() {
        this.logger.debug('******EDIT NOTE*****', this.newNoteModel);

        let dueDate = null;
        if (this.newNoteModel.dueDateISO) {
            dueDate = new Date(this.newNoteModel.dueDateISO).getTime();
        }

        const updateNote: Update<Note> = {
            id: this.newNoteModel.id,
            changes: {
                body: this.newNoteModel.body,
                type: this.newNoteModel.isTodo ? NoteType.todo : NoteType.note,
                dueDate: this.newNoteModel.isTodo ? dueDate : null,
                updatedDate: Date.now(),
                studentIds: this.newNoteModel.students
                    .filter(student => student.selected)
                    .map(student => student.id),
            }
        };
        this.notesService.updateNote(updateNote);
        this.store.dispatch(new ActionHideEditorMenu());
    }

    trackByStudentId(index, student: any) {
        return student.id;
    }
}
