import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProgramService } from '../../program/program.service';
import { DashboardService } from '../../dashboard/dashboard.service';

@Component({
    selector: 'app-dashboard-menu',
    templateUrl: './dashboard-menu.component.html',
    styleUrls: ['./dashboard-menu.component.scss']
})
export class DashboardMenuComponent implements OnInit, OnDestroy {
    selectedTab = 'byTOC';
    programHeader$: Observable<string>;
    isAllAssessmentsMenuOpenSubscription: Subscription;

    constructor(private programService: ProgramService, private dashboardService: DashboardService) {}

    ngOnInit() {
        this.programHeader$ = this.programService.currentProgramName$.pipe(
            map(programName => programName || '')
        );

        this.isAllAssessmentsMenuOpenSubscription = this.dashboardService.isAllAssessmentsMenuOpen$.subscribe(
            isAllAssessmentsMenuOpen => {
                if (!isAllAssessmentsMenuOpen) {
                    this.selectedTab = 'byTOC';
                }
            }
        );
    }

    ngOnDestroy() {
        this.isAllAssessmentsMenuOpenSubscription.unsubscribe();
    }

    onTabChange(evt) {
        this.selectedTab = evt.detail.value;
    }
}
