import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectSelectedProductProgramSkeleton } from 'src/app/core/reports/store/reports.selectors';
import { selectRbsCredentials } from '../../auth/store/auth.selectors';
import { AppState } from '../../core.state';
import {
    ActionCloseObservationFilterMenu,
    ActionLoadTagListForSelectedProgramFailed,
    ActionLoadTagListForSelectedProgram,
    ActionOpenObservationFilterMenu,
    ActionSetSelectedContentItem,
    ReportsActionTypes
} from './reports.actions';
import { ReportsService } from '../reports.service';
import { ActionSendTelemetryEventForError, ActionShowErrorModal } from '../../error/store/error.actions';

@Injectable()
export class ReportsEffects {

    constructor(
        private actions$: Actions<Action>,
        private menuController: MenuController,
        private store$: Store<AppState>,
        private reportsService: ReportsService
    ) {}

    @Effect({ dispatch: false })
    openObservationFilterMenu$ = this.actions$.pipe(
        ofType<ActionOpenObservationFilterMenu>(ReportsActionTypes.OpenObservationFilterMenu),
        tap(() => {
            this.menuController.enable(true, 'observationReportFilterMenu').then(() => {
                this.menuController.open('observationReportFilterMenu');
            });
        })
    );

    @Effect({ dispatch: false })
    closeObservationFilterMenu$ = this.actions$.pipe(
        ofType<ActionCloseObservationFilterMenu>(ReportsActionTypes.CloseObservationFilterMenu),
        tap(() => {
            this.menuController.close('observationReportFilterMenu');
        })
    );

    @Effect()
    loadContentItemTags$ = this.actions$.pipe(
        ofType<ActionSetSelectedContentItem>(ReportsActionTypes.SetSelectedContentItem),
        map(action => action.payload.selectedContentItem),
        filter(contentItem => !!contentItem),
        withLatestFrom(
            this.store$.pipe(select(selectRbsCredentials)),
            this.store$.pipe(select(selectSelectedProductProgramSkeleton))
        ),
        filter(([, creds, programItem]) => !!creds && !!programItem),
        switchMap(([{ id }, { userId, token }, { productId, rootProgramIdentifier }]) =>
            this.reportsService.getCriteriaTagsForProgram(productId, rootProgramIdentifier, id, userId, token)),
        map((tagsList) => {
            return new ActionLoadTagListForSelectedProgram({ tagsList });
        }),
        catchError(error => {
            const description = {
                origin: 'Load Content Item Tags',
                message: error
            };
            return [
                new ActionLoadTagListForSelectedProgramFailed({ error }),
                new ActionSendTelemetryEventForError({ description }),
                new ActionShowErrorModal(),
            ];
        })
    );
}
