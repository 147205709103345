import { EntityState } from '@ngrx/entity';
import { CommentSnapshot, Comment } from '../../comments/store/comments.model';
import { AppState } from '../../core.state';
import { UserProfile } from '../../user-profile/store/user-profile.model';
import { ContentCriterion } from '../../assessment/store/assessment.model';

export interface Observation {
    id: string;
    type: ObservationType;
    classId: string;
    programId: string;
    assessmentId: string;
    studentId: string;
    createdBy: string;
    createdAt: number;
    updatedAt: number;
    isComplete?: boolean;
    isObservationValid?: boolean;
}

export interface OngoingObservation extends Observation {
    criterionId: string;
}

export interface ChecklistObservation extends Observation {
    criterionId: string;
    criteriaIsChecked: CriteriaIsChecked;
}

export interface CriteriaIsChecked {
    [key: string]: boolean;
}

export interface FluencyObservation extends Observation {
    readingLevel?: FluencyReadingLevel;
    wordsRead?: string;
    errors?: string;
}

export enum ObservationType {
    Ongoing = 'ongoing',
    Checklist = 'checklist',
    Fluency = 'fluency'
}

export enum FluencyReadingLevel {
    OnLevel = 'On-Level',
    Developing = 'Developing',
    Advanced = 'Advanced',
    ChooseAReadingLevel = 'ChooseAReadingLevel'
}

export interface ObservationSnapshot {
    id?: string;
    type: ObservationType;
    classId?: string;
    programId?: string;
    assessmentId: string;
    studentId: string;
    createdBy?: string;
    createdAt?: number;
    updatedAt?: number;
    isComplete?: boolean;
    isObservationValid?: boolean;
}

export interface OngoingObservationSnapshot extends ObservationSnapshot {
    criterionId?: string;
    commentSnapshots: CommentSnapshot[];
}

export interface ChecklistObservationSnapshot extends ObservationSnapshot {
    criteriaIsChecked?: CriteriaIsChecked;
    commentSnapshots?: CommentSnapshot[];
}

export interface FluencyObservationSnapshot extends ObservationSnapshot {
    readingLevel?: FluencyReadingLevel;
    wordsRead?: string;
    errors?: string;
    commentSnapshots?: CommentSnapshot[];
}

export type ObservationsSnapshot =
    | OngoingObservationSnapshot
    | ChecklistObservationSnapshot
    | FluencyObservationSnapshot;

export interface ObservationsState extends EntityState<Observation> {
    isLoading: boolean;
    selectedObservationId: string;
}

export interface State extends AppState {
    observations: ObservationsState;
}

export interface StudentObservationVM {
    student: UserProfile;
    isStudentObservationComplete: boolean;
}

export interface FluencyClassReportStudentObservationVM extends FluencyObservation {
    lastComment?: Comment;
}

export interface FluencyClassReportStudentDataVM {
    observation?: FluencyClassReportStudentObservationVM;
    student: UserProfile;
}

export interface ChecklistStudentViewVM {
    criteria: ContentCriterion;
    comments: any[];
    isChecked: boolean;
    lastComment: any;
    textCommentsCount: number;
    mediaCommentsCount: number;
}
