import { Component, OnInit, OnDestroy } from '@angular/core';
import { LayoutService } from '../core/layout/layout.service';
import { ErrorService } from '../core/error/error.service';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ErrorType } from '../core/error/store/error.model';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
    errorMessageObservable: Observable<string>;
    constructor(
        private layoutService: LayoutService,
        private errorService: ErrorService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.layoutService.hideFooter();
        this.errorMessageObservable = this.errorService.errorType$.pipe(
            switchMap(errorType => {
                let errorMessage: string;
                switch (errorType) {
                    case ErrorType.TIMEOUT:
                        errorMessage = 'error.timeoutMessage';
                        break;
                    default:
                        errorMessage = 'error.message';
                        break;
                }

                return this.translate.get(errorMessage);
            })
        );
    }

    ngOnDestroy() {
        this.layoutService.showFooter();
    }
}
