import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ObservationsActions, ObservationsActionTypes } from './observations.actions';
import { Observation, ObservationsState } from './observations.model';

export const adapter: EntityAdapter<Observation> = createEntityAdapter<Observation>();
export const initialState: ObservationsState = adapter.getInitialState({
    isLoading: false,
    selectedObservationId: undefined
});
export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

export function reducer(state = initialState, action: ObservationsActions) {
    switch (action.type) {
        case ObservationsActionTypes.SetSelectedObservationId:
            return { ...state, selectedObservationId: action.payload.observationId };
        case ObservationsActionTypes.LoadObservationsStart:
            return { ...state, isLoading: true };
        case ObservationsActionTypes.LoadObservations:
            return adapter.addAll(action.payload.observations, { ...state, isLoading: false });
        default:
            return state;
    }
}
