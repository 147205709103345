export const ApplyCustomStyle = dropdown => {
    const styles = document.createElement('style');
    styles.textContent = `
            * {
                box-sizing: border-box !important;
            }

            div[data-e2e-id="drop-down"] {
                position: relative !important;
              }

            .dropdown__menu{
                position: absolute !important;
                width: 100% !important;
            }

            .placeHolder__title,
            .dropdown__title {
                font-style: normal !important;
                border: 1px solid #CACACA !important;
                border-radius: 4px !important;
                background-color: #FFFFFF !important;
                width: 100% !important;
                height: 34px !important;
                padding: 8px 10px !important;
            }

            .dropdown__value {
                color: #2C2C2C !important;
                font-family: Cabin !important;
                font-size: 14px !important;
                width: 80% !important;
            }

            .dropdown__list {
                max-height: 100% !important;
                min-height: 0 !important;
            }

            .dropdown__list.dropdown-active {
                width: 100% !important;
                border: 1px solid #CACACA !important;
                border-radius: 0 0 4px 4px !important;
                background-color: #FFFFFF !important;
                box-shadow: 2px 6px 8px 0 rgba(0,0,0,0.3) !important;
                margin: 0 !important;
            }

            .item__list {
                padding: 7px 14px !important;
                height: auto !important;
            }

            .item__value {
                color: #000000 !important;	font-family: Cabin !important;	font-size: 14px !important;
                height: auto !important;
            }
        `;

    // bad code but seems that for first rendering, style not able to add inside shadowroot element
    // [TODO] if better solution is available should be used
    setTimeout(() => {
        if (dropdown.nativeElement.shadowRoot) {
            dropdown.nativeElement.shadowRoot.appendChild(styles);
        }
    });
};
