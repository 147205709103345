import { Component, OnInit, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { selectIsAuthenticating } from './core/auth/store/auth.selectors';
import { ClassRoster } from './core/class-roster/store/class-roster.model';
import { ClassRosterService } from './core/class-roster/class-roster.service';
import { AppState } from './core/core.state';
import { Logger } from './core/logging/logger';
import { selectHideFooter } from './core/layout/store/layout.selectors';
import { SearchService } from './core/search/search.service';
import { ErrorService } from './core/error/error.service';
import { DashboardService } from './core/dashboard/dashboard.service';
import { ServiceWorkerService } from './core/service-worker/service-worker.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    classList$: Observable<ClassRoster[]> = this.rosterService.classList$;
    classRoasterIsLoading$: Observable<boolean> = this.rosterService.isLoading$;
    selectedClassId$: Observable<string> = this.rosterService.selectedClassId$;
    classListSubscription: Subscription;
    isAuthenticating: boolean;
    isAuthenticatingSubscription: Subscription;
    hideFooter$: Observable<boolean>;
    isUnrecoverableErrorMode$: Observable<boolean>;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private rosterService: ClassRosterService,
        private translateService: TranslateService,
        private logger: Logger,
        private store: Store<AppState>,
        private searchService: SearchService,
        private errorService: ErrorService,
        private dashboardService: DashboardService,
        private swUpdate: SwUpdate,
        private serviceWorkerService: ServiceWorkerService
    ) {
        this.initializeApp();
        this.classListSubscription = this.classList$.subscribe();
    }

    ngOnInit() {
        this.isAuthenticatingSubscription = this.store
            .pipe(select(selectIsAuthenticating))
            .subscribe(isAuthenticating => {
                this.isAuthenticating = isAuthenticating;
            });

        this.hideFooter$ = this.store.pipe(
            select(selectHideFooter),
            delay(0)
        );
        this.isUnrecoverableErrorMode$ = this.errorService.isUnrecoverableErrorMode$;
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                this.serviceWorkerService.showRefreshModal();
            });
        }
    }

    ngOnDestroy() {
        this.classListSubscription.unsubscribe();
        this.isAuthenticatingSubscription.unsubscribe();
    }

    initializeApp() {
        this.translateService.setDefaultLang('en');

        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    onClassSelectionChange(classId: string) {
        this.logger.info('onClassSelectionChange', classId);
        this.rosterService.setSelectedClassRosterId(classId);
    }

    onSearchMenuOpen() {
        this.searchService.setIsSearchMenuOpen(true);
    }

    onSearchMenuClose() {
        this.searchService.setIsSearchMenuOpen(false);
    }

    onAllAssessmentsMenuOpen() {
        this.dashboardService.setIsAllAssessmentsMenuOpen(true);
    }

    onAllAssessmentsMenuClose() {
        this.dashboardService.setIsAllAssessmentsMenuOpen(false);
    }
}
