import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { selectSelectedAssessmentId } from 'src/app/core/assessment/store/assessment.selectors';
import { selectSelectedClassRosterId } from 'src/app/core/class-roster/store/class-roster.selectors';
import { CommentType } from 'src/app/core/comments/store/comments.model';
import { AppState } from 'src/app/core/core.state';
import {
    ActionSendStartedTimerTelemetryEvent,
    ActionUpsertObservationSuccess,
    ObservationsActionTypes
} from 'src/app/core/observations/store/observations.actions';
import { FluencyObservation, ObservationType } from 'src/app/core/observations/store/observations.model';
import { selectSelectedProgramId } from 'src/app/core/program/store/program.selectors';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';

@Injectable()
export class ObservationsTelemetryEffects {

    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private telemetryService: TelemetryService,
    ) {}

    @Effect({ dispatch: false })
    createObservation = this.actions$.pipe(
        ofType<ActionUpsertObservationSuccess>(ObservationsActionTypes.UpsertObservationSuccess),
        map(action => action.payload),
        tap(({ observation, comment }) => {
            if (observation.type === ObservationType.Fluency) {
                const fluencyObservation = observation as FluencyObservation;
                const isObservationComplete = !!fluencyObservation.readingLevel
                    && !!fluencyObservation.wordsRead
                    && !!fluencyObservation.errors;
                if (isObservationComplete) {
                    const details = {
                        section: { id: observation.classId },
                        program: { rootProgramIdentifier: observation.programId },
                        assessment: { id: observation.assessmentId },
                        studentIDs: [observation.studentId],
                    };
                    this.telemetryService.sendActivity('modified', 'Fluency', details);
                }
            }

            if (!!comment && comment.createdAt === comment.updatedAt) {
                const details = {
                    section: { id: observation.classId },
                    program: { rootProgramIdentifier: observation.programId },
                    assessment: { id: observation.assessmentId },
                    studentIDs: [observation.studentId],
                };
                switch (comment.type) {
                    case CommentType.Media:
                        this.telemetryService.sendActivity('created', 'Media', details);
                        break;
                    case CommentType.Text:
                        this.telemetryService.sendActivity('created', 'Comments', details);
                        break;
                }
            }
        })
    );

    @Effect({ dispatch: false })
    startedTimer = this.actions$.pipe(
        ofType<ActionSendStartedTimerTelemetryEvent>(ObservationsActionTypes.SendStartedTimerTelemetryEvent),
        withLatestFrom(
            this.store$.pipe(select(selectSelectedClassRosterId)),
            this.store$.pipe(select(selectSelectedProgramId)),
            this.store$.pipe(select(selectSelectedAssessmentId)),
        ),
        tap(([, classId, programId, assessmentId]) => {
            const details = {
                section: { id: classId },
                program: { rootProgramIdentifier: programId },
                assessment: { id: assessmentId },
            };
            this.telemetryService.sendActivity('executed', 'Timer', details);
        })
    );
}
