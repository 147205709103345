import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MenuController } from '@ionic/angular';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { from } from 'rxjs';
import {
    catchError,
    filter,
    map,
    switchMap,
    tap,
    mergeMap
} from 'rxjs/operators';
import {
    ActionSendTelemetryEventForError,
    ActionShowErrorModal
} from 'src/app/core/error/store/error.actions';
import { ErrorService } from 'src/app/core/error/error.service';
import { selectSelectedClassRosterId } from '../../class-roster/store/class-roster.selectors';
import { AppState } from '../../core.state';
import { NotesDataService } from '../notes.data.service';
import {
    ActionCreateNote,
    ActionCreateNoteSuccess,
    ActionHideEditorMenu,
    ActionLoadNotesEnd,
    ActionLoadNotesStart,
    ActionRemoveNotes,
    ActionRemoveNotesFailed,
    ActionRemoveNoteSuccess,
    ActionShowEditorMenu,
    ActionUpdateNote,
    ActionUpdateNoteFailed,
    ActionUpdateNoteSuccess,
    NotesActionTypes} from './notes.actions';
import { Note } from './notes.model';

@Injectable()
export class NotesEffects {
    constructor(
        private actions$: Actions<Action>,
        private notesDataService: NotesDataService,
        private menuController: MenuController,
        private firestoreAuth: AngularFireAuth,
        private store: Store<AppState>,
        private errorService: ErrorService
    ) {}

    @Effect({ dispatch: false })
    loadNotesForClass$ = this.store.pipe(
        select(selectSelectedClassRosterId),
        filter(rosterId => !!rosterId),
        tap(() => this.store.dispatch(new ActionLoadNotesStart())),
        switchMap(rosterId => {
            return this.firestoreAuth.authState.pipe(
                filter(user => !!user),
                switchMap(user =>
                    this.notesDataService.fetchNotes(rosterId, user.uid).pipe(
                        tap(actionsToDispatch => {
                            actionsToDispatch.forEach((action) => {
                                this.store.dispatch(action);
                            });
                        })
                    )
                ),
                tap(() => this.store.dispatch(new ActionLoadNotesEnd())),
                catchError(error => {
                    const description = {
                        origin: 'Load Notes For Class',
                        payload: { rosterId },
                        message: error
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionShowErrorModal()
                    ];
                })
            );
        })
    );

    @Effect({ dispatch: false })
    createNote$ = this.actions$.pipe(
        ofType<ActionCreateNote>(NotesActionTypes.CreateNote),
        map(action => action.payload),
        tap(({ note }) => {
            this.notesDataService
                .createNote(note)
                .then(newNote =>
                    this.store.dispatch(
                        new ActionCreateNoteSuccess({ note: newNote as Note })
                    )
                )
                .catch(error => {
                    const description = {
                        origin: 'Create Note',
                        payload: { note },
                        message: error
                    };
                    this.errorService.processError(description);
                });
        })
    );

    @Effect()
    updateNote$ = this.actions$.pipe(
        ofType<ActionUpdateNote>(NotesActionTypes.UpdateNote),
        map(action => action.payload),
        mergeMap(({ update }) =>
            from(this.notesDataService.updateNote(update)).pipe(
                map(() => new ActionUpdateNoteSuccess({ update })),
                catchError(err => {
                    const description = {
                        origin: 'Update Note',
                        payload: { update },
                        message: err
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionUpdateNoteFailed({ error: err }),
                        new ActionShowErrorModal()
                    ];
                })
            )
        )
    );

    @Effect()
    deleteNotes$ = this.actions$.pipe(
        ofType<ActionRemoveNotes>(NotesActionTypes.RemoveNotes),
        map(action => action.payload),
        mergeMap(({ ids }) =>
            from(this.notesDataService.removeNotes(ids)).pipe(
                map(() => new ActionRemoveNoteSuccess({ ids })),
                catchError(error => {
                    const description = {
                        origin: 'Delete Notes',
                        payload: { ids },
                        message: error
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionRemoveNotesFailed({ error }),
                        new ActionShowErrorModal()
                    ];
                })
            )
        )
    );

    @Effect({ dispatch: false })
    showEditor = this.actions$.pipe(
        ofType<ActionShowEditorMenu>(NotesActionTypes.ShowEditorMenu),
        tap(() => {
            this.menuController.enable(true, 'notesMenu').then(() => {
                this.menuController.open('notesMenu');
            });
        })
    );

    @Effect({ dispatch: false })
    hideEditor = this.actions$.pipe(
        ofType<ActionHideEditorMenu>(NotesActionTypes.HideEditorMenu),
        tap(() => {
            this.menuController.close();
        })
    );
}
