import { EntityState } from '@ngrx/entity';
import { AppState } from '../../core.state';
import { ProgramItem } from '../../program/store/program.model';
import { ProgramSkeleton } from '../../product/store/product.model';
import { Observation } from '../../observations/store/observations.model';
import { Note } from '../../notes/store/notes.model';
import { Media } from '../../media/store/media.model';

export interface AssessmentStat extends ProgramItem {
    programId: string;
    percentObserved: number;
    updatedAt: number;
}

export enum RecentActivityCategory {
    Note = 'note',
    Media = 'media',
    Assessment = 'assessment',
}

export interface RecentActivityResult {
    id: string;
    category: RecentActivityCategory;
    title: string;
    updatedAt: number;
    object: Media | Note | ProgramItem;
    observation?: Observation;
}

export interface ProductProgramSkeleton extends ProgramSkeleton {
    productId: string;
}

export interface DashboardState extends EntityState<AssessmentStat> {
    isLoadingAssessmentStats: boolean;
    isLoadingRecentActivity: boolean;
    isAllAssessmentsMenuOpen: boolean;
}

export interface State extends AppState {
    dashboard: DashboardState;
}
