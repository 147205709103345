import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SettingsEffects } from './store/settings.effects';
import { reducer as settingsReducer } from './store/settings.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('settings', settingsReducer),
        EffectsModule.forFeature([SettingsEffects])
    ]
})
export class SettingsModule {}
