import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../core.state';
import { Logger } from '../logging/logger';
import { selectIsAuthenticated, selectIsAuthenticating } from './store/auth.selectors';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private store: Store<AppState>,
        private router: Router,
        private logger: Logger
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.store.pipe(
            withLatestFrom(this.store.pipe(select(selectIsAuthenticating))),
            filter(([, isAuthenticating]) => !isAuthenticating),
            switchMap(([appState]) => {
                this.logger.info('auth guard done authenticating', {
                    appState
                });
                return this.store.pipe(select(selectIsAuthenticated));
            })
        );
    }
}
