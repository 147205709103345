import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ErrorState, State } from './error.model';

export const selectErrorState = createFeatureSelector<State, ErrorState>('error');

export const selectIsUnrecoverableErrorMode = createSelector(
    selectErrorState,
    state => state.isUnrecoverableErrorMode
);

export const selectIsErrorModalOpen = createSelector(
    selectErrorState,
    state => state.isErrorModalOpen
);

export const selectErrorType = createSelector(
    selectErrorState,
    ({ errorType }) => errorType
);
