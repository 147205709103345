import { Action } from '@ngrx/store';
import { Media, MediaFilter, MediaType } from './media.model';

export enum MediaActionTypes {
    SetMediaFilter = '[Media] Filter Media',
    SetSelectedMediaId = '[Media] Set Selected Media Id',

    LoadMediaStart = '[Media] Start Loading Media',
    LoadMediaEnd = '[Media] End Loading Media',

    CreateMediaDocument = '[Media] Create Media Document',

    AddMedia = '[Media] Add Media',
    AddMediaSuccess = '[Media] Add Media Success',
    AddMediaFailed = '[Media] Add Media Failed',
    AddMediaToStore = '[Media] Add Media To Store',

    RemoveMedia = '[Media] Remove Media',
    RemoveMediaSuccess = '[Media] Remove Media Success',
    RemoveMediaFailed = '[Media] Remove Media Failed',
    RemoveMediaFromStore = '[Media] Remove Media From Store',

    UpdateMedia = '[Media] Update Media',
    UpdateMediaSuccess = '[Media] Update Media Success',
    UpdateMediaFailed = '[Media] Update Media Failed',
    UpdateMediaInStore = '[Media] Update Media Document In Store',

    UploadMedia = '[Media] Upload Media',
    UploadMediaFailed = '[Media] Upload Media Failed',

    OpenMediaMenu = '[Media] Open Media Menu',
    CloseMediaMenu = '[Media] Close Media Menu',

    FetchSignedThumbnailUrls = '[Media] Fetch Signed Thumbnail',
    FetchSignedThumbnailUrlsSuccess = '[Media] Fetch Signed Thumbnail Success',
    FetchSignedThumbnailUrlsFailed = '[Media] Fetch Signed Thumbnail Fail',

    FetchSignedDownloadUrls = '[Media] Fetch Download Url',
    FetchSignedDownloadUrlsSuccess = '[Media] Fetch Download Url Success',
    FetchSignedDownloadUrlsFailed = '[Media] Fetch Download Url Fail',

    SendFileExceededTelemetry = '[Media] Send File Exceeded Telemetry'
}

export class ActionSetMediaFilter implements Action {
    readonly type = MediaActionTypes.SetMediaFilter;
    constructor(public payload: { filter: MediaFilter }) {}
}

export class ActionLoadMediaStart implements Action {
    readonly type = MediaActionTypes.LoadMediaStart;
}

export class ActionLoadMediaEnd implements Action {
    readonly type = MediaActionTypes.LoadMediaEnd;
}

export class ActionCreateMediaDocument implements Action {
    readonly type = MediaActionTypes.CreateMediaDocument;
    constructor(public payload: { file: File }) {}
}

export class ActionAddMedia implements Action {
    readonly type = MediaActionTypes.AddMedia;
    constructor(public payload: { media: Media }) {}
}

export class ActionAddMediaSuccess implements Action {
    readonly type = MediaActionTypes.AddMediaSuccess;
    constructor(public payload: { media: Media }) {}
}

export class ActionAddMediaFailed implements Action {
    readonly type = MediaActionTypes.AddMediaFailed;
    constructor(public payload: { error: any; media: Media }) {}
}

export class ActionAddMediaToStore implements Action {
    readonly type = MediaActionTypes.AddMediaToStore;
    constructor(public payload: { media: Media }) {}
}

export class ActionRemoveMedia implements Action {
    readonly type = MediaActionTypes.RemoveMedia;
    constructor(public payload: { media: Media[] }) {}
}

export class ActionRemoveMediaSuccess implements Action {
    readonly type = MediaActionTypes.RemoveMediaSuccess;
    constructor(public payload: { media: Media[] }) {}
}

export class ActionRemoveMediaFailed implements Action {
    readonly type = MediaActionTypes.RemoveMediaFailed;
    constructor(public payload: { error: any }) {}
}

export class ActionRemoveMediaFromStore implements Action {
    readonly type = MediaActionTypes.RemoveMediaFromStore;
    constructor(public payload: { media: Media[] }) {}
}

export class ActionUpdateMedia implements Action {
    readonly type = MediaActionTypes.UpdateMedia;
    constructor(public payload: { media: Media }) {}
}

export class ActionUpdateMediaSuccess implements Action {
    readonly type = MediaActionTypes.UpdateMediaSuccess;
}

export class ActionUpdateMediaFailed implements Action {
    readonly type = MediaActionTypes.UpdateMediaFailed;
    constructor(public payload: { error: any }) {}
}

export class ActionUpdateMediaInStore implements Action {
    readonly type = MediaActionTypes.UpdateMediaInStore;
    constructor(public payload: { media: Media }) {}
}

export class ActionUploadMedia implements Action {
    readonly type = MediaActionTypes.UploadMedia;
    constructor(public payload: { file: File; media: Media }) {}
}

export class ActionUploadMediaFailed implements Action {
    readonly type = MediaActionTypes.UploadMediaFailed;
    constructor(public payload: { error: any; media: Media }) {}
}

export class ActionOpenMediaMenu implements Action {
    readonly type = MediaActionTypes.OpenMediaMenu;
    constructor(public payload: { mediaId: string }) {}
}

export class ActionCloseMediaMenu implements Action {
    readonly type = MediaActionTypes.CloseMediaMenu;
}

export class ActionSetSelectedMediaId implements Action {
    readonly type = MediaActionTypes.SetSelectedMediaId;
    constructor(public payload: { mediaId: string }) {}
}

export class ActionFetchSignedThumbnailUrls implements Action {
    readonly type = MediaActionTypes.FetchSignedThumbnailUrls;
    constructor(public payload: { medias: Media[] }) {}
}
export class ActionFetchSignedThumbnailUrlsSuccess implements Action {
    readonly type = MediaActionTypes.FetchSignedThumbnailUrlsSuccess;
    constructor(public payload: { medias: Media[] }) {}
}

export class ActionFetchSignedThumbnailUrlsFailed implements Action {
    readonly type = MediaActionTypes.FetchSignedThumbnailUrlsFailed;
    constructor(public payload: { error: any }) {}
}

export class ActionFetchSignedDownloadUrls implements Action {
    readonly type = MediaActionTypes.FetchSignedDownloadUrls;
    constructor(public payload: { medias: Media[] }) {}
}

export class ActionFetchSignedDownloadUrlsSuccess implements Action {
    readonly type = MediaActionTypes.FetchSignedDownloadUrlsSuccess;
    constructor(public payload: { medias: Media[] }) {}
}

export class ActionFetchSignedDownloadUrlsFailed implements Action {
    readonly type = MediaActionTypes.FetchSignedDownloadUrlsFailed;
    constructor(public payload: { error: any }) {}
}

export class ActionSendFileExceededTelemetry implements Action {
    readonly type = MediaActionTypes.SendFileExceededTelemetry;
    constructor(public payload: { type: MediaType, size: number }) {}
}

export type MediaActions =
    | ActionSetMediaFilter
    | ActionLoadMediaStart
    | ActionLoadMediaEnd
    | ActionCreateMediaDocument
    | ActionAddMedia
    | ActionAddMediaSuccess
    | ActionAddMediaFailed
    | ActionAddMediaToStore
    | ActionRemoveMedia
    | ActionRemoveMediaSuccess
    | ActionRemoveMediaFailed
    | ActionRemoveMediaFromStore
    | ActionUpdateMedia
    | ActionUpdateMediaSuccess
    | ActionUpdateMediaFailed
    | ActionUpdateMediaInStore
    | ActionUploadMedia
    | ActionUploadMediaFailed
    | ActionOpenMediaMenu
    | ActionCloseMediaMenu
    | ActionSetSelectedMediaId
    | ActionFetchSignedThumbnailUrls
    | ActionFetchSignedThumbnailUrlsSuccess
    | ActionFetchSignedThumbnailUrlsFailed
    | ActionFetchSignedDownloadUrls
    | ActionFetchSignedDownloadUrlsSuccess
    | ActionFetchSignedDownloadUrlsFailed
    | ActionSendFileExceededTelemetry;
