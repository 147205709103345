import { Component, Output, EventEmitter, Input, HostListener, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Media, MediaType } from 'src/app/core/media/store/media.model';
import { UserProfile } from 'src/app/core/user-profile/store/user-profile.model';
import { first } from 'rxjs/operators';
import { MediaService } from '../../../core/media/media.service';
import { AppState } from 'src/app/core/core.state';
import { Store, select } from '@ngrx/store';
import { selectUsersByIds } from 'src/app/core/user-profile/store/user-profile.selectors';

@Component({
    selector: 'media-grid',
    templateUrl: './media-grid.component.html',
    styleUrls: ['./media-grid.component.scss']
})
export class MediaGridComponent implements OnInit, OnDestroy, OnChanges {
    @Input() allMedia: Media[];
    @Input() editMode = false;
    @Input() checkedSelection = new Set<Media>();
    @Output() mediaClick = new EventEmitter<Media>();
    @Output() mediaEdit = new EventEmitter<any>();
    currentMedia: Media;
    studentList: UserProfile[];
    showStudentList = false;
    constructor(private store$: Store<AppState>, private mediaService: MediaService) {}

    ngOnInit() {}

    ngOnDestroy() {}

    ngOnChanges(changes: any) {
        const { allMedia } = changes;
        if (allMedia) {
            // filter out media that does not have thumbnails that could have been updated
            const imageMediaToRequest = allMedia.currentValue.filter((media: Media) => {
                return media.type === MediaType.Image;
            });
            this.mediaService.fetchSignedThumbnailUrlsAndAddToMedia(imageMediaToRequest);
        }
    }

    onMediaClick(media: Media) {
        this.mediaClick.emit(media);
    }

    onMediaEdit(event: any, media: Media) {
        this.mediaEdit.emit({ event, media });
    }

    trackByMediaId(index, media: Media) {
        return media.id;
    }

    presentStudentList(media: Media) {
        if (media.taggedStudents.length === 0) {
            this.currentMedia = null;
            return;
        }
        this.showStudentList = true;
        this.currentMedia = media;
        this.store$
            .pipe(
                select(selectUsersByIds(media.taggedStudents)),
                first()
            )
            .subscribe(students => {
                this.studentList = students;
            });
    }

    getStudentInitials(student: UserProfile) {
        return student.rumbaUser.firstName.charAt(0).toUpperCase() + student.rumbaUser.lastName.charAt(0).toUpperCase();
    }

    @HostListener('document:click', ['$event'])
    onClick() {
        if (this.currentMedia && !this.showStudentList) {
            this.currentMedia = null;
        } else {
            this.showStudentList = false;
        }
    }

    isMediaThumbnailLoaded(media: Media): boolean {
        switch (media.type) {
            case MediaType.Video:
                return !!media.metadata.duration;
            case MediaType.Image:
                return !!media.signedThumbnailUrl && !!media.signedThumbnailUrl.url;
            default:
                throw new Error(`unknown media type: ${media.type}`);
        }
    }
}
