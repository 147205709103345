import { LayoutActionTypes, LayoutActions } from './layout.actions';
import { LayoutState } from './layout.model';

export const initialState: LayoutState = {
    hideFooter: false
};

export function reducer(state = initialState, action: LayoutActions): LayoutState {
    switch (action.type) {
        case LayoutActionTypes.ShowFooter: {
            return { hideFooter: false };
        }
        case LayoutActionTypes.HideFooter: {
            return { hideFooter: true };
        }
        default:
            return state;
    }
}
