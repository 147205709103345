import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom, first, tap, catchError } from 'rxjs/operators';
import { ClassRosterService } from 'src/app/core/class-roster/class-roster.service';
import { ProductProgramSkeleton } from 'src/app/core/dashboard/store/dashboard.model';
import { ObservationsService } from 'src/app/core/observations/observations.service';
import { ProgramService } from 'src/app/core/program/program.service';
import { selectCurrentUser } from '../auth/store/auth.selectors';
import { selectCurrentClassRoster, selectSelectedClassRosterId } from '../class-roster/store/class-roster.selectors';
import { AppState } from '../core.state';
import { UserProfile } from '../user-profile/store/user-profile.model';
import { UserProfileService } from '../user-profile/user-profile.service';
import { ReportsDataService } from './reports.data.service';
import {
    ActionCloseObservationFilterMenu,
    ActionLoadTagListForSelectedProgram,
    ActionSetSelectedContentItem,
    ActionSetSelectedCriteriaTag,
    ActionSetSelectedProductProgramSkeleton,
    ActionSetIsChecklistReportLoading,
    ActionOpenObservationFilterMenu,
    ActionSendReportOpenedTelemetryEvent,
    ActionSetObservationReportFilters,
} from './store/reports.actions';
import {
    ObservationReportFilters,
    ObservationTypeFilterOptions,
    ReportType,
    SortByOptions,
    StudentObservationReport,
    ChecklistReportVM,
    CriteriaTag,
    AssessmentVM
} from './store/reports.model';
import {
    selectObservationReportFilters,
    selectSelectedContentItem,
    selectSelectedCriteriaTag,
    selectSelectedProductProgramSkeleton,
    selectTagsList,
    selectIsTagsListLoading,
    selectIsChecklistReportDataLoading
} from './store/reports.selectors';
import { CommentType, Comment } from '../comments/store/comments.model';
import { selectMediaById } from '../media/store/media.selectors';
import { ProgramItem } from '../program/store/program.model';
import { ActionNavigateToAssessmentView } from '../assessment/store/assessment.actions';
import { MediaService } from '../media/media.service';
import { ObservationType, CriteriaIsChecked } from '../observations/store/observations.model';
import { ActionSendTelemetryEventForError, ActionShowErrorModal } from '../error/store/error.actions';

@Injectable({ providedIn: 'root' })
export class ReportsService {

    constructor(
        private classRosterService: ClassRosterService,
        private mediaService: MediaService,
        private store$: Store<AppState>,
        private reportsDataService: ReportsDataService,
        private programService: ProgramService,
        private ups: UserProfileService,
        private observationsService: ObservationsService
    ) {}

    selectObservationReportFilters$ = this.store$.pipe(select(selectObservationReportFilters));

    selectedProductProgramSkeleton$ = this.store$.pipe(select(selectSelectedProductProgramSkeleton));
    selectedContentItem$ = this.store$.pipe(select(selectSelectedContentItem));
    selectedCriteriaTag$ = this.store$.pipe(select(selectSelectedCriteriaTag));
    tagsList$ = this.store$.pipe(select(selectTagsList));
    isTagsListLoading$ = this.store$.pipe(select(selectIsTagsListLoading));
    isChecklistReportDataLoading$ = this.store$.pipe(select(selectIsChecklistReportDataLoading));

    contentItemList$ = this.store$.pipe(
        select(selectSelectedProductProgramSkeleton),
        filter(program => !!program),
        map(program => program.rootProgramIdentifier),
        switchMap(programId => this.programService.getProgram(programId).pipe(
            map(program => program.hierarchy ? program.hierarchy : [])
        ))
    );

    observationReportData$ = combineLatest(
        this.store$.pipe(select(selectCurrentClassRoster)),
        this.store$.pipe(select(selectCurrentUser)),
        this.store$.pipe(select(selectObservationReportFilters)),
    ).pipe(
        filter(([classRoster, currentUser, filters]) =>
            !!classRoster && !!currentUser && !!filters && !!filters.observationTypeFilter),
        switchMap(([{ classId, studentIds }, currentUser, observationReportFilters]) => {
            const createdBy = currentUser.userId;
            return this.ups.users$(studentIds)
                .pipe(switchMap(students => of({ classId, students, createdBy, observationReportFilters })));
        }),
        switchMap(({ classId, students, createdBy, observationReportFilters }) => {
            return this.fetchObservationsReportData(classId, createdBy, students, observationReportFilters);
        })
    );

    checklistReportData$ = combineLatest(
        this.store$.pipe(select(selectSelectedClassRosterId)),
        this.store$.pipe(select(selectSelectedProductProgramSkeleton)),
        this.store$.pipe(select(selectSelectedContentItem)),
        this.store$.pipe(select(selectSelectedCriteriaTag)),
    ).pipe(
        withLatestFrom(this.store$.pipe(select(selectCurrentUser))),
        filter(([[classId, programItem, contentItem, criteriaTag], currentUser]) =>
            !!classId && !!programItem && !!contentItem && !!criteriaTag && !!currentUser),
        tap(() => this.store$.dispatch(new ActionSetIsChecklistReportLoading({isChecklistReportDataLoading: true}))),
        switchMap(([[classId, programItem, contentItem, criteriaTag], currentUser]) => {
            const { assessments } = criteriaTag;
            const { rootProgramIdentifier } = programItem;
            const { userId } = currentUser;
            return forkJoin(
                assessments.map(assessment => this.observationsService
                    .fetchObservationsWithComments(classId, rootProgramIdentifier, assessment, userId))
            );
        }),
        switchMap((reportData: ChecklistReportVM[]) => {
            return forkJoin(
                reportData.map(data => this.fetchProgramItemForAssessment(data)),
            );
        }),
        switchMap((checklistReportVMs: ChecklistReportVM[]) => {
            return this.classRosterService.currentRosterStudents$.pipe(
                switchMap(students => {
                    return forkJoin(
                        students.map(student => this.createStudentChecklistReportData(student, checklistReportVMs))
                    );
                })
            );
        }),
        map((checklistReportDataRecords: any[]) => {
            return checklistReportDataRecords.filter((checklistReportDataRecord: any) => {
                return checklistReportDataRecord.assessmentData.length > 0;
            });
        }),
        tap(() => this.store$.dispatch(new ActionSetIsChecklistReportLoading({isChecklistReportDataLoading: false}))),
    );

    resetStaleCheckListReportData$ = combineLatest(
        this.store$.pipe(select(selectSelectedProductProgramSkeleton)),
        this.store$.pipe(select(selectSelectedContentItem))
    ).pipe(
        switchMap(([productProgramSkeleton, contentItem]) => {
            if (!!productProgramSkeleton || !!contentItem) {
                return of(true);
            }
            return of(false);
        })
    );

    private fetchProgramItemForAssessment(data: ChecklistReportVM): Observable<ChecklistReportVM> {
        return this.programService.getProgram(data.programId).pipe(
            map(program => {
                const programItem = this.programService.getProgramItemForAssessment(program, data.assessmentVM.id);
                return { ...data, assessment: programItem };
            }),
            first()
        );
    }

    private createStudentChecklistReportData(student: UserProfile, checklistReportVMs: ChecklistReportVM[]) {
        const studentReportData = { student: student, assessmentData: [] };
        checklistReportVMs.forEach(checklistReportVM => {
            const observations = checklistReportVM.observations
                .filter(observation =>
                    observation.observation.studentId === student.rumbaUser.userId
                    && observation.observation.isComplete
                );
            if (observations.length > 0) {
                const currentStudentReportData = {
                    assessment: checklistReportVM.assessment,
                    comments: this.getCommentsFilteredByCriteriaIds(
                        observations[0].comments,
                        checklistReportVM.assessmentVM
                    )
                };
                if (currentStudentReportData.comments.length === 0) {
                    // check if criteria is selected for current observation
                    const isCriteriaChecked = this.isCriteriaChecked (
                            checklistReportVM.assessmentVM.criteriaIds, observations[0].observation.criteriaIsChecked);
                    if (isCriteriaChecked) {
                        studentReportData.assessmentData.push(currentStudentReportData);
                    }
                } else {
                    studentReportData.assessmentData.push(currentStudentReportData);
                }
            }
        });
        return of(studentReportData);
    }

    private isCriteriaChecked(criteriaIds: string[], observationCriteriaFlags: CriteriaIsChecked): boolean {
        let criteriaChecked = false;
        if (observationCriteriaFlags) {
            criteriaIds.forEach(criteriaId => {
                if (observationCriteriaFlags[criteriaId]) {
                    criteriaChecked = true;
                }
            });
        }
        return criteriaChecked;
    }

    private getCommentsFilteredByCriteriaIds(comments: Comment[], assessmentVM: AssessmentVM) {
        const filteredComments = comments.reduce((acc, comment) => {
            if (assessmentVM.criteriaIds.includes(comment.criterionId)) {
                if (comment.type === CommentType.Media) {
                    acc.push({
                        comment,
                        media$: this.store$.pipe(select(selectMediaById(comment.mediaId)),
                        tap((media) => {
                            if (media) {
                                this.mediaService.fetchSignedThumbnailUrlsAndAddToMedia([media]);
                            }
                        }))
                    });
                } else {
                    acc.push({ comment });
                }
            }
            return acc;
        }, []);
        return filteredComments.sort((a, b) => a.comment.updatedAt - b.comment.updatedAt);
    }

    getCriteriaTagsForProgram(productId, programId, contentItemId, userId, token): Observable<CriteriaTag[]> {
        return this.reportsDataService.fetchTagsList(productId, programId, contentItemId, userId, token).pipe(
            map((tagsList: CriteriaTag[]) => tagsList),
            catchError((error) => {
                const description = {
                    origin: 'Fetch Tags',
                    payload: { productId, programId, contentItemId },
                    message: error,
                };
                this.store$.dispatch(new ActionSendTelemetryEventForError({ description }));
                this.store$.dispatch(new ActionShowErrorModal());
                return of([]);
            })
        );
    }

    setObservationReportFilters(filters: Partial<ObservationReportFilters>) {
        this.store$.dispatch(new ActionSetObservationReportFilters({ filters }));
    }

    openObservationFilterMenu() {
        this.store$.dispatch(new ActionOpenObservationFilterMenu());
    }

    closeObservationFilterMenu() {
        this.store$.dispatch(new ActionCloseObservationFilterMenu());
    }

    setSelectedProductProgramSkeleton(selectedProductProgramSkeleton: ProductProgramSkeleton) {
        this.store$.dispatch(new ActionSetSelectedProductProgramSkeleton({ selectedProductProgramSkeleton }));
    }

    setSelectedContentItem(selectedContentItem: ProgramItem) {
        this.store$.dispatch(new ActionSetSelectedContentItem({ selectedContentItem }));
    }

    setSelectedCriteriaTag(selectedCriteriaTag: CriteriaTag) {
        this.store$.dispatch(new ActionSetSelectedCriteriaTag({ selectedCriteriaTag }));
    }

    setTagListEmpty() {
        this.store$.dispatch(
            new ActionLoadTagListForSelectedProgram({ tagsList: [] })
        );
    }

    resetCheckListReportStateToInitialState() {
        this.setSelectedProductProgramSkeleton(undefined);
        this.setSelectedContentItem(undefined);
        this.setSelectedCriteriaTag(undefined);
        this.setTagListEmpty();
    }

    sendReportOpenedTelemetryEvent(reportType: ReportType) {
        this.store$.dispatch(new ActionSendReportOpenedTelemetryEvent({ reportType }));
    }

    fetchObservationsReportData(
        classId: string,
        createdBy: string,
        students: UserProfile[],
        observationReportFilters: ObservationReportFilters
    ) {
        const { observationTypeFilter, sortByFilter } = observationReportFilters;
        const studentObservationsCount = students.reduce((acc, val) => {
            if (!acc[val.rumbaUser.userId]) {
                acc[val.rumbaUser.userId] = {
                    studentId: val.rumbaUser.userId,
                    studentName: val.rumbaUser.fullName,
                    totalObservations: 0
                };
            }
            return acc;
        }, {});

        switch (observationTypeFilter) {
            case ObservationTypeFilterOptions.Ongoing: {
                return this.observationsService
                    .fetchObservationsForClassByFilter(classId, createdBy, true, observationReportFilters)
                    .pipe(
                        // individual comments account for 1 observation count
                        map(observationsWithComments => observationsWithComments.reduce((acc, observation) => {
                            // only count observations for non-deleted students
                            if (acc[observation.studentId]) {
                                acc[observation.studentId].totalObservations += observation.comments.length;
                            }
                            return acc;
                        }, studentObservationsCount)),
                        map(() => Object.values(studentObservationsCount)),
                        switchMap(result =>
                            sortByFilter
                            ? of(this.applySortByFilter(sortByFilter, result as StudentObservationReport[]))
                            : of(result)),
                    );
            }
            case ObservationTypeFilterOptions.Checklists: {
                return this.observationsService
                    .fetchObservationsForClassByFilter(classId, createdBy, true, observationReportFilters)
                    .pipe(
                        // both comments and criteria check mark accounts for 1 observation count
                        map(observationsWithComments => observationsWithComments.reduce((acc, observation) => {
                            // only count observations for non-deleted students
                            if (acc[observation.studentId]) {
                                acc[observation.studentId].totalObservations += observation.comments.length;
                                acc[observation.studentId].totalObservations += !!observation.criteriaIsChecked
                                    ? Object.values(observation.criteriaIsChecked)
                                        .filter(isChecked => isChecked).length
                                    : 0;
                            }
                            return acc;
                        }, studentObservationsCount)),
                        map(() => Object.values(studentObservationsCount)),
                        switchMap(result =>
                            sortByFilter
                            ? of(this.applySortByFilter(sortByFilter, result as StudentObservationReport[]))
                            : of(result)));
            }
            case ObservationTypeFilterOptions.Fluency: {
                return this.observationsService
                    .fetchObservationsForClassByFilter(classId, createdBy, true, observationReportFilters)
                    .pipe(
                        // both comments and valid wcpm value accounts for 1 observation count
                        map(observationsWithComments => observationsWithComments.reduce((acc, observation) => {
                            // only count observations for non-deleted students
                            if (acc[observation.studentId]) {
                                acc[observation.studentId].totalObservations += observation.comments.length;
                                acc[observation.studentId].totalObservations +=
                                    !!observation.isObservationValid ? 1 : 0;
                            }
                            return acc;
                        }, studentObservationsCount)),
                        map(() => Object.values(studentObservationsCount)),
                        switchMap(result =>
                            sortByFilter
                            ? of(this.applySortByFilter(sortByFilter, result as StudentObservationReport[]))
                            : of(result)),
                    );
            }
            default:
                throw new Error('invalid observation filter type');
        }
    }

    private applySortByFilter(sortBy: SortByOptions, results: StudentObservationReport[]) {
        switch (sortBy) {
            case SortByOptions.Observations:
                return results.sort((a, b) => {
                    const diff = a.totalObservations - b.totalObservations;
                    return diff === 0 ? a.studentName.localeCompare(b.studentName) : diff;
                });
            case SortByOptions.Students:
                return results.sort((a, b) => a.studentName.localeCompare(b.studentName));
            default:
                throw new Error(`unknown sort by filter: ${sortBy}`);
        }
    }

    navigateToChecklistObservation(observation) {
        const assessment = {
            type: ObservationType.Checklist,
            programId: observation.programId,
            assessmentId: observation.assessmentId,
            studentId: observation.studentId,
            criterionId: observation.criterionId
        };
        this.store$.dispatch(new ActionNavigateToAssessmentView({ assessment }));
    }
}
