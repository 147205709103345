import { NavigationActions, NavigationActionTypes } from './navigation.actions';
import { NavigationState } from './navigation.model';

export const initialState: NavigationState = {
    previousURL: ''
};

export function reducer(state = initialState, action: NavigationActions): NavigationState {
    if (action.type === NavigationActionTypes.SetPreviousURL) {
        return { ...state, previousURL: action.payload.previousURL };
    } else {
        return state;
    }
}
