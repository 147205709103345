import { createEntityAdapter } from '@ngrx/entity';
import { AssessmentActions, AssessmentActionTypes } from './assessment.actions';
import { Assessment, AssessmentState } from './assessment.model';

const adapter = createEntityAdapter<Assessment>();

export const initialState: AssessmentState = adapter.getInitialState({
    selectedAssessmentId: ''
});

export function assessmentReducer(state = initialState, action: AssessmentActions): AssessmentState {
    switch (action.type) {
        case AssessmentActionTypes.SetSelectedAssessmentId:
            return { ...state, selectedAssessmentId: action.payload.assessmentId };
        case AssessmentActionTypes.SetSelectedCriterionId:
            return { ...state, selectedCriterionId: action.payload.criterionId };
        case AssessmentActionTypes.LoadAssessmentSuccess:
            return adapter.addOne(action.payload.assessment, state);
        default:
            return state;
    }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
