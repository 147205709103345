import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import sha256 from 'crypto-js/sha256';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ActionSendTelemetryEventForError, ActionShowErrorModal } from 'src/app/core/error/store/error.actions';
import { selectCurrentUser } from '../../auth/store/auth.selectors';
import { selectSelectedClassRosterId, selectSelectedStudentId } from '../../class-roster/store/class-roster.selectors';
import {
    ActionAddCommentToStore,
    ActionDeleteCommentFromStore,
    ActionLoadCommentsStart
} from '../../comments/store/comments.actions';
import { Comment } from '../../comments/store/comments.model';
import { AppState } from '../../core.state';
import {
    ActionAddMediaFailed,
    ActionAddMediaToStore,
    ActionUploadMediaFailed,
    ActionUpdateMedia
} from '../../media/store/media.actions';
import { selectSelectedProgramId } from '../../program/store/program.selectors';
import { ObservationsDataService } from '../observations.data.service';
import {
    ActionAddCommentMedia,
    ActionCreateObservationDocument,
    ActionDeleteObservationComments,
    ActionDeleteObservationCommentsFailed,
    ActionDeleteObservationCommentsSuccess,
    ActionLoadObservationsStart,
    ActionSetSelectedObservationId,
    ActionUploadCommentMedia,
    ActionUpsertObservation,
    ActionUpsertObservationFailed,
    ActionUpsertObservationSuccess,
    ObservationsActionTypes
} from './observations.actions';
import { Observation, ObservationType } from './observations.model';
import {
    selectObservation,
    selectSelectedObservationId
} from './observations.selectors';
import cloneDeep from 'lodash.clonedeep';
import { selectSelectedAssessmentId } from '../../assessment/store/assessment.selectors';
import { MediaService } from '../../media/media.service';
import { Media } from '../../media/store/media.model';

@Injectable()
export class ObservationsEffects {

    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private afsDb: AngularFirestore,
        private afsAuth: AngularFireAuth,
        private observationsDataService: ObservationsDataService,
        private mediaService: MediaService,
    ) {}

    @Effect()
    setSelectedObservationId$ = combineLatest(
        this.store$.pipe(select(selectSelectedClassRosterId)),
        this.store$.pipe(select(selectSelectedStudentId)),
        this.store$.pipe(select(selectSelectedAssessmentId)),
    ).pipe(
        withLatestFrom(
            this.store$.pipe(select(selectSelectedProgramId)),
            this.store$.pipe(select(selectCurrentUser)),
        ),
        filter(([[, , assessmentId], programId, currentUser]) => !!programId && !!currentUser && !!assessmentId),
        switchMap(([[classId, studentId, assessmentId], programId, currentUser]) =>
            this.store$.pipe(
                select(selectObservation(classId, programId, assessmentId, studentId, currentUser.userId)),
                map(observation => observation ? observation.id : undefined),
                switchMap(observationId => of(new ActionSetSelectedObservationId({ observationId })))
            )
        )
    );

    @Effect()
    loadObservationComments$ = this.store$.pipe(
        select(selectSelectedObservationId),
        filter(observationId => !!observationId),
        tap(() => this.store$.dispatch(new ActionLoadCommentsStart())),
        switchMap(observationId => this.afsAuth.authState.pipe(
            filter(user => !!user),
            switchMap(user => this.observationsDataService
                .fetchObservationComments(observationId, user.uid)),
            catchError(error => {
                const description = {
                    origin: 'Load Observations Comments',
                    payload: { observationId },
                    message: error,
                };
                return [
                    new ActionSendTelemetryEventForError({ description }),
                    new ActionShowErrorModal(),
                ];
            })
        ))
    );

    @Effect()
    loadObservations$ = combineLatest(
        this.store$.pipe(select(selectSelectedClassRosterId)),
        this.store$.pipe(select(selectSelectedProgramId)),
        this.store$.pipe(select(selectSelectedAssessmentId))
    ).pipe(
        filter(([classId, programId, assessmentId]) => !!classId && !!programId && !!assessmentId),
        tap(() => this.store$.dispatch(new ActionLoadObservationsStart())),
        switchMap(([classId, programId, assessmentId]) =>
            this.afsAuth.authState.pipe(
                filter(user => !!user),
                switchMap(user => this.observationsDataService
                    .fetchObservations(classId, programId, assessmentId, user.uid)),
                catchError(error => {
                    const description = {
                        origin: 'Load Observations',
                        payload: { classId, programId, assessmentId },
                        message: error,
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionShowErrorModal(),
                    ];
                })
            )
        )
    );

    @Effect()
    createObservation$ = this.actions$.pipe(
        ofType<ActionCreateObservationDocument>(ObservationsActionTypes.CreateObservationDocument),
        map(action => action.payload),
        withLatestFrom(
            this.store$.pipe(select(selectSelectedClassRosterId)),
            this.store$.pipe(select(selectSelectedProgramId)),
            this.store$.pipe(
                select(selectCurrentUser),
                filter(currentUser => !!currentUser),
                map(currentUser => currentUser.userId)
            )
        ),
        switchMap(([payload, classId, programId, userId]) => {
            const timestamp = Date.now();
            const { commentSnapshots, ...snapshot } = payload.snapshot;

            // Observation document ...
            const observation: Observation =
                snapshot.id
                    ? { ...snapshot as Observation, updatedAt: timestamp }
                    : {
                        ...snapshot,
                        id: sha256(classId + programId + snapshot.assessmentId + snapshot.studentId).toString(),
                        classId,
                        programId,
                        createdBy: userId,
                        createdAt: timestamp,
                        updatedAt: timestamp,
                        isObservationValid: snapshot.type !== ObservationType.Fluency
                    };

            const actions = [];

            // Optional Comment document ...
            if (commentSnapshots && commentSnapshots.length > 0) {
                commentSnapshots.forEach(commentSnapshot => {
                    const observationComment = commentSnapshot.id
                        ? { ...commentSnapshot, updatedAt: timestamp }
                        : {
                            ...commentSnapshot,
                            id: this.afsDb.createId(),
                            observationId: observation.id,
                            createdAt: timestamp,
                            updatedAt: timestamp,
                            createdBy: userId,
                            deleted: false
                        };

                    if (observationComment.file) {
                        const { file, ...comment } = observationComment;
                        const mediaId = this.afsDb.createId();
                        const doc = { ...comment, mediaId } as Comment;
                        const media = this.mediaService
                            .createMediaDocument(userId, classId, file, mediaId);
                        if (media.taggedStudents.indexOf(observation.studentId) === -1) {
                            media.taggedStudents.push(observation.studentId);
                        }

                        actions.push(new ActionAddMediaToStore({ media }));
                        actions.push(new ActionAddCommentToStore({ comment: doc }));
                        actions.push(new ActionUploadCommentMedia({
                            observation,
                            media,
                            file,
                            comment: doc,
                        }));
                    } else {
                        if (observationComment.media) {
                            const { media, ...comment } = observationComment;
                            const doc = { ...comment, mediaId: media.id } as Comment;
                            const taggedMedia = cloneDeep(media);
                            if (taggedMedia.taggedStudents.indexOf(observation.studentId) === -1) {
                                taggedMedia.taggedStudents.push(observation.studentId);
                            }
                            delete taggedMedia.signedDownloadUrl;
                            delete taggedMedia.signedThumbnailUrl;
                            actions.push(new ActionUpdateMedia({ media: taggedMedia }));
                            actions.push(new ActionUpsertObservation({
                                observation,
                                comment: doc
                            }));
                        } else {
                            actions.push(new ActionUpsertObservation({
                                observation,
                                comment: observationComment as Comment
                            }));
                        }
                    }
                });
            } else {
                actions.push(new ActionUpsertObservation({ observation }));
            }

            return actions;
        })
    );

    @Effect()
    upsertObservation$ = this.actions$.pipe(
        ofType<ActionUpsertObservation>(ObservationsActionTypes.UpsertObservation),
        map(action => action.payload),
        switchMap(({ observation, comment }) =>
            this.observationsDataService.upsertObservation(observation, comment)
                .pipe(
                    map(() => new ActionUpsertObservationSuccess({ observation, comment })),
                    catchError(error => {
                        const description = {
                            origin: 'Upsert Observations',
                            payload: { observation, comment },
                            message: error,
                        };
                        return [
                            new ActionSendTelemetryEventForError({ description }),
                            new ActionUpsertObservationFailed({ error, observation, comment }),
                            new ActionShowErrorModal(),
                        ];
                    })
                )
        )
    );

    @Effect()
    deleteObservationComments$ = this.actions$.pipe(
        ofType<ActionDeleteObservationComments>(ObservationsActionTypes.DeleteObservationComments),
        map(action => action.payload.comments),
        switchMap(comments => this.observationsDataService.deleteObservationComments(comments)
            .pipe(
                map(() => new ActionDeleteObservationCommentsSuccess()),
                catchError(error => {
                    const description = {
                        origin: 'Delete Observations Comments',
                        payload: { comments },
                        message: error,
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionDeleteObservationCommentsFailed({ error }),
                        new ActionShowErrorModal(),
                    ];
                })
            )
        )
    );

    @Effect()
    uploadMediaComment$ = this.actions$.pipe(
        ofType<ActionUploadCommentMedia>(ObservationsActionTypes.UploadCommentMedia),
        map(action => action.payload),
        switchMap(({ observation, comment, media, file }) => this.mediaService.uploadMedia(file, media)
            .pipe(
                map(() => new ActionAddCommentMedia({ observation, comment, media })),
                catchError(error => {
                    const description = {
                        origin: 'Upload Media Observation',
                        payload: { observation, comment, media, file },
                        message: error,
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionUploadMediaFailed({ error, media }),
                        new ActionDeleteCommentFromStore({ comment }),
                        new ActionShowErrorModal(),
                    ];
                })
            )
        )
    );

    @Effect()
    addCommentMediaDocument = this.actions$.pipe(
        ofType<ActionAddCommentMedia>(ObservationsActionTypes.AddCommentMedia),
        map(action => action.payload),
        switchMap(({ observation, comment, media }) => this.mediaService.addMedia(media)
            .pipe(
                map(() => new ActionUpsertObservation({ observation, comment })),
                catchError(error => {
                    const description = {
                        origin: 'Add Media Observation Comment',
                        payload: { observation, comment, media },
                        message: error,
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionAddMediaFailed({ error, media }),
                        new ActionDeleteCommentFromStore({ comment }),
                        new ActionShowErrorModal(),
                    ];
                })
            ))
    );
}
