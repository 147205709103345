import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NotesMenuComponent } from './components/notes-menu/notes-menu.component';
import { NotesComponent } from './pages/notes.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { NotesListComponent } from './components/notes-list/notes-list.component';

@NgModule({
    declarations: [NotesComponent, NotesMenuComponent, NotesListComponent],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule,
        SharedModule,
    ],
    providers: [],
    exports: [NotesMenuComponent, NotesListComponent]
})
export class NotesModule {}
