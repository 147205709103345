import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ReportsTelemetryEffects } from './store/reports-telemetry.effects';
import { reducer } from './store/reports.reducer';
import { ReportsEffects } from './store/reports.effects';
import { ReportsService } from './reports.service';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('reports', reducer),
        EffectsModule.forFeature([ReportsEffects, ReportsTelemetryEffects])
    ],
    providers: [ReportsService]
})
export class ReportsModule {}
