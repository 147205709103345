import { ActionReducer } from '@ngrx/store';

import { AppState } from '../core.state';

export function debug(
    reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
    return function logReduceWrapper(state, action) {
        const newState = reducer(state, action);
        // TODO: make the logger static? this is outside of DI
        // tslint:disable-next-line:no-console
        console.info(`[DEBUG] action: ${action.type}`, {
            payload: (<any>action).payload,
            oldState: state,
            newState
        });
        return newState;
    };
}
