import { commonConfig } from './common';

export const environment = {
    ...commonConfig,
    envName: 'nightly',
    production: false,
    firebase: {
        // the uat in the config represents the nightly envionment
        apiKey: 'AIzaSyAAU5Bw4OaOqvv_BNI1nlQoMPaKct0_4Jg',
        authDomain: 'lst-scout-uat.firebaseapp.com',
        databaseURL: 'https://lst-scout-uat.firebaseio.com',
        projectId: 'lst-scout-uat',
        storageBucket: 'lst-scout-uat.appspot.com',
        messagingSenderId: '518171650289'
    },
    api: {
        rosterSections:
            'https://roster-service.nightly.savvasrealizedev.com/roster-service/v1/sections',
        userProfile:
            'https://user-profile-service.nightly.savvasrealizedev.com/ups/api/v1/user/bulk/userids',
        programHierarchy:
            'https://lst-scout-uat.appspot.com/api/v1/',
        jwt:
            'https://lst-scout-uat.appspot.com/api/jwt',
        rootPrograms:
            'https://lst-scout-uat.appspot.com/api/v1/rootPrograms',
        itemAttachment:
            'https://lst-scout-uat.appspot.com/api/v1/',
        searchAssessmentItems:
            'https://lst-scout-uat.appspot.com/api/v1/',
        signedMediaUrl:
            'https://us-central1-lst-scout-uat.cloudfunctions.net/getSignedUrl'
    }
};
