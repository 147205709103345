import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { MediaActions, MediaActionTypes } from './media.actions';
import { Media, MediaFilter, MediaState } from './media.model';

export const adapter: EntityAdapter<Media> = createEntityAdapter<Media>();
export const initialState: MediaState = adapter.getInitialState({
    filter: MediaFilter.AllMedia,
    isLoading: false,
    isMediaMenuOpen: false,
    selectedMediaId: ''
});

export function reducer(state = initialState, action: MediaActions): MediaState {
    switch (action.type) {
        case MediaActionTypes.SetMediaFilter: {
            return { ...state, filter: action.payload.filter };
        }
        case MediaActionTypes.LoadMediaStart:
            return { ...state, isLoading: true };
        case MediaActionTypes.LoadMediaEnd:
            return { ...state, isLoading: false };
        case MediaActionTypes.UploadMediaFailed: {
            return adapter.removeOne(action.payload.media.id, state);
        }
        case MediaActionTypes.AddMediaToStore: {
            return adapter.addOne(action.payload.media, state);
        }
        case MediaActionTypes.AddMediaFailed: {
            return adapter.removeOne(action.payload.media.id, state);
        }
        case MediaActionTypes.RemoveMediaFromStore: {
            const ids = action.payload.media.map(media => media.id);
            return adapter.removeMany(ids, state);
        }
        case MediaActionTypes.FetchSignedThumbnailUrls: {
            const { medias } = action.payload;
            const newMedia = medias.map(media => {
                return {
                    id: media.id,
                    changes: {
                        ...media,
                        thumbnailLoading: true
                    }
                };
            });
            return adapter.updateMany(newMedia, state);
        }
        case MediaActionTypes.FetchSignedDownloadUrls: {
            const { medias } = action.payload;
            const newMedia = medias.map(media => {
                return {
                    id: media.id,
                    changes: {
                        ...media,
                        mediaLoading: true
                    }
                };
            });
            return adapter.updateMany(newMedia, state);
        }
        case MediaActionTypes.FetchSignedThumbnailUrlsSuccess:
        case MediaActionTypes.FetchSignedDownloadUrlsSuccess: {
            const { medias } = action.payload;
            const newMedia = medias.map(media => {
                return {
                    id: media.id,
                    changes: {
                        ...media
                    }
                };
            });
            return adapter.updateMany(newMedia, state);
        }
        case MediaActionTypes.UpdateMediaInStore: {
            const id = action.payload.media.id;
            const changes = action.payload.media;
            return adapter.updateOne({ id, changes }, state);
        }
        case MediaActionTypes.OpenMediaMenu: {
            return { ...state, isMediaMenuOpen: true };
        }
        case MediaActionTypes.CloseMediaMenu: {
            return { ...state, isMediaMenuOpen: false };
        }
        case MediaActionTypes.SetSelectedMediaId: {
            const mediaId = action.payload.mediaId;
            return { ...state, selectedMediaId: mediaId };
        }
        case MediaActionTypes.FetchSignedThumbnailUrlsFailed:
        case MediaActionTypes.FetchSignedDownloadUrlsFailed:
        case MediaActionTypes.AddMedia:
        case MediaActionTypes.RemoveMedia:
        case MediaActionTypes.RemoveMediaFailed:
        case MediaActionTypes.UpdateMedia:
        case MediaActionTypes.UpdateMediaFailed:
        case MediaActionTypes.CreateMediaDocument:
        case MediaActionTypes.UploadMedia:
        default:
            return state;
    }
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
