import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core/core.state';
import { ActionNavigateToErrorPage } from 'src/app/core/error/store/error.actions';
import { UserProfileService } from 'src/app/core/user-profile/user-profile.service';
import {
    UserProfileActionTypes,
    ActionUserProfileLoad,
    ActionUserProfileLoadSuccess,
    ActionUserProfileBulkLoad,
    ActionUserProfileBulkLoadSuccess
} from './user-profile.actions';
import { map, switchMap, withLatestFrom, catchError, tap } from 'rxjs/operators';
import { selectRbs, selectCurrentUser } from 'src/app/core/auth/store/auth.selectors';
import { of } from 'rxjs';
import { selectUserProfilesState } from './user-profile.selectors';
import { SessionStorageService } from 'src/app/core/session-storage/session-storage.service';

const STATE_STORAGE_KEY = 'USERS';

@Injectable()
export class UserProfileEffects {
    constructor(
        private actions$: Actions<Action>,
        private store: Store<AppState>,
        private userProfileService: UserProfileService,
        private sessionStorageService: SessionStorageService
    ) {}

    @Effect()
    loadUser$ = this.actions$.pipe(
        ofType<ActionUserProfileLoad>(UserProfileActionTypes.LOAD_PROFILE),
        map(action => action.payload),
        withLatestFrom(
            this.store.pipe(select(selectRbs)),
            this.store.pipe(select(selectCurrentUser))
        ),
        switchMap(([{ userId }, { token }, { userId: currentUserId }]) => {
            return this.userProfileService.getProfiles([userId], token, currentUserId).pipe(
                map(
                    response =>
                        new ActionUserProfileLoadSuccess({
                            profile: response.users[0]
                        })
                ),
                catchError(err => {
                    const description = {
                        origin: 'Load User Profile',
                        payload: { userId },
                        message: err,
                    };
                    return of(new ActionNavigateToErrorPage({ description }));
                })
            );
        })
    );

    @Effect()
    bulkLoadUsers$ = this.actions$.pipe(
        ofType<ActionUserProfileBulkLoad>(UserProfileActionTypes.BULK_LOAD),
        map(action => action.payload),
        withLatestFrom(
            this.store.pipe(select(selectRbs)),
            this.store.pipe(select(selectCurrentUser))
        ),
        switchMap(([{ userIds }, { token }, { userId: currentUserId }]) => {
            return this.userProfileService.getProfiles([currentUserId, ...userIds], token, currentUserId).pipe(
                map(
                    response =>
                        new ActionUserProfileBulkLoadSuccess({
                            profiles: response.users
                        })
                ),
                catchError(err => {
                    const description = {
                        origin: 'Load Bulk User Profile',
                        payload: { userIds },
                        message: err,
                    };
                    return of(new ActionNavigateToErrorPage({ description }));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    persistState = this.actions$.pipe(
        ofType(
            UserProfileActionTypes.LOAD_PROFILE_SUCCESS,
            UserProfileActionTypes.BULK_LOAD_SUCCESS
        ),
        withLatestFrom(this.store.pipe(select(selectUserProfilesState))),
        tap(([, state]) => {
            this.sessionStorageService.setItem(STATE_STORAGE_KEY, state);
        })
    );
}
