import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ObservationsTelemetryEffects } from './store/observations-telemetry.effects';
import { ObservationsEffects } from './store/observations.effects';
import { reducer } from './store/observations.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('observations', reducer),
        EffectsModule.forFeature([ObservationsEffects, ObservationsTelemetryEffects])
    ]
})
export class ObservationsModule {}
