import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { SearchActions, SearchActionTypes } from './search.actions';
import { SearchRecord, SearchState } from './search.model';

export const adapter: EntityAdapter<SearchRecord> = createEntityAdapter<SearchRecord>();
export const initialState: SearchState = adapter.getInitialState({
    isLoadingRecentSearches: false,
    isLoadingSearchResults: false,
    isSearchMenuOpen: false
});

export function reducer(state = initialState, action: SearchActions): SearchState {
    switch (action.type) {
        case SearchActionTypes.LoadRecentSearchesStart:
            return { ...state, isLoadingRecentSearches: true };
        case SearchActionTypes.LoadRecentSearches:
            return adapter.addAll(action.payload.searchRecords, { ...state, isLoadingRecentSearches: false });
        case SearchActionTypes.SetIsLoadingSearchResults:
            return { ...state, isLoadingSearchResults: action.payload.isLoadingSearchResults };
        case SearchActionTypes.UpsertSearchRecordSuccess:
            return adapter.upsertOne(action.payload.searchRecord, state);
        case SearchActionTypes.SetIsSearchMenuOpen:
            return { ...state, isSearchMenuOpen: action.payload.isSearchMenuOpen };
        default:
            return state;
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
} = adapter.getSelectors();
