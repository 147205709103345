import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState, selectRouterState } from '../../core.state';
import { ActionLoadProducts } from '../../product/store/product.actions';
import { ActionSetSelectedProgram } from '../../program/store/program.actions';
import { SessionStorageService } from '../../session-storage/session-storage.service';
import { UserProfileService } from '../../user-profile/user-profile.service';
import {
    ActionPopulateStudentsForRoster,
    ActionSetSelectedClassRoster,
    ClassRosterActionTypes,
    ActionSetSelectedStudentId,
} from './class-roster.actions';
import { selectClassRosterById, selectClassRosterState } from './class-roster.selectors';
import { AuthActionTypes } from '../../auth/store/auth.actions';
import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';

const STATE_STORAGE_KEY = 'CLASS-ROSTER';

@Injectable()
export class ClassRosterEffects {
    constructor(
        private actions$: Actions<Action>,
        private store: Store<AppState>,
        private sessionStorageService: SessionStorageService,
        private ups: UserProfileService
    ) {}

    @Effect({ dispatch: false })
    loadStudentsOnSelectRoster$ = this.actions$.pipe(
        ofType<ActionSetSelectedClassRoster>(ClassRosterActionTypes.SetSelectedClassRoster),
        map(action => action.payload),
        switchMap(({ classId }) => {
            return this.store.pipe(select(selectClassRosterById(classId))).pipe(
                filter(roster => !!roster),
                switchMap(roster => {
                    return this.ups.users$(roster.studentIds).pipe(
                        tap(students => {
                            this.store.dispatch(
                                new ActionPopulateStudentsForRoster({
                                    students
                                })
                            );
                        })
                    );
                })
            );
        })
    );

    @Effect()
    loadProductsOnSelectRoster$ = this.actions$.pipe(
        ofType<ActionSetSelectedClassRoster>(ClassRosterActionTypes.SetSelectedClassRoster),
        map(action => action.payload),
        tap(() => {
            // reset selected program on class change
            this.store.dispatch(new ActionSetSelectedProgram({rootProgramIdentifier: undefined, productId: undefined}));
        }),
        switchMap(({ classId }) =>  this.store.pipe(
            select(selectClassRosterById(classId)),
            filter(classRoster => !!classRoster),
            map(({ productIds }) => new ActionLoadProducts({ productIds }))
        ))
    );

    @Effect()
    setSelectedStudentsId$ = this.actions$.pipe(
        ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
        withLatestFrom(this.store.pipe(select(selectRouterState))),
        map(([, router]) => new ActionSetSelectedStudentId({ studentId: router.state.params.studentId })),
    );

    @Effect({ dispatch: false })
    persistState = this.actions$.pipe(
        ofType(ClassRosterActionTypes.SetSelectedClassRoster, ClassRosterActionTypes.LoadClassRosters),
        withLatestFrom(this.store.pipe(select(selectClassRosterState))),
        tap(([, state]) => {
            this.sessionStorageService.setItem(STATE_STORAGE_KEY, state);
        })
    );

    @Effect({ dispatch: false })
    clearPersistedState = this.actions$.pipe(
        ofType(AuthActionTypes.LOGOUT),
        tap(() => {
            this.sessionStorageService.removeItem(STATE_STORAGE_KEY);
        })
    );
}
