import { ErrorState } from './error.model';
import { ErrorActions, ErrorActionTypes } from './error.actions';

export const initialState: ErrorState = {
    isUnrecoverableErrorMode: false,
    isErrorModalOpen: false,
    errorType: undefined
};

export function reducer(state = initialState, action: ErrorActions) {
    switch (action.type) {
        case ErrorActionTypes.SetIsUnrecoverableErrorMode:
            return { ...state, isUnrecoverableErrorMode: action.payload.isUnrecoverableErrorMode };
        case ErrorActionTypes.SetIsErrorModalOpen:
            return { ...state, isErrorModalOpen: action.payload.isErrorModalOpen };
        case ErrorActionTypes.SetErrorType:
            return {...state, errorType: action.payload.errorType};
        default:
            return state;
    }
}
