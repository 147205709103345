import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { NotesComponent } from './notes/pages/notes.component';
import { MediaRootComponent } from './media/pages/media-root/media-root.component';
import { MediaListComponent } from './media/pages/media-list/media-list.component';
import { MediaViewComponent } from './media/pages/media-view/media-view.component';
import { ClassExistsGuard } from './core/auth/classExists.guard';
import { ErrorComponent } from './error/error.component';
import { ErrorGuard } from './core/auth/error.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
    },
    {
        path: 'init',
        canActivate: [AuthGuard, ErrorGuard],
        loadChildren: './init/init.module#InitModule'
    },
    {
        path: 'assess',
        canActivate: [AuthGuard, ClassExistsGuard, ErrorGuard],
        loadChildren: './assessment/assessment.module#AssessmentModule',
        data: {
            title: 'app-header.title.assessments'
        }
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard, ClassExistsGuard, ErrorGuard],
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        data: {
            title: 'app-header.title.dashboard'
        }
    },
    {
        path: 'reports',
        canActivate: [AuthGuard, ClassExistsGuard, ErrorGuard],
        loadChildren: './reports/reports.module#ReportsModule',
        data: {
            title: 'app-header.title.reports'
        }
    },
    {
        path: 'notes',
        canActivate: [AuthGuard, ClassExistsGuard, ErrorGuard],
        component: NotesComponent,
        data: {
            title: 'app-header.title.notes'
        }
    },
    {
        path: 'media',
        canActivate: [AuthGuard, ClassExistsGuard, ErrorGuard],
        component: MediaRootComponent,
        children: [
            {
                path: ':mediaId',
                component: MediaViewComponent
            },
            {
                path: '',
                component: MediaListComponent
            }
        ],
        data: {
            title: 'app-header.title.media'
        }
    },
    {
        path: 'students',
        canActivate: [AuthGuard, ClassExistsGuard, ErrorGuard],
        loadChildren: './students/students.module#StudentsModule',
        data: {
            title: 'app-header.title.students'
        }
    },
    {
        path: 'error',
        component: ErrorComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // enableTracing: !environment.production,
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
