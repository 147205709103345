import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppState } from '../core.state';
import { Logger } from '../logging/logger';
import { UserDetails, AuthenticationResponse, RbsTokenResponse, JwtResponse } from './store/auth.model';
import { selectCurrentUser } from './store/auth.selectors';
import { AuthDataService } from './auth.data.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    currentUser$: Observable<UserDetails>;

    constructor(
        @Inject('LOCATION') private location: Location,
        @Inject('WINDOW') private window: Window,
        private store: Store<AppState>,
        private authDataService: AuthDataService,
        private logger: Logger
    ) {
        this.currentUser$ = this.store.pipe(select(selectCurrentUser));
    }

    getReturnServiceUrl(returnPath: string = ''): string {
        const { protocol, hostname, port } = this.location;
        const safePort = port ? `:${port}` : '';

        // const [noQsPath] = returnPath.split('?'); // need to pass query string for redirection after class selection
        let finalReturnPath = returnPath;
        if (finalReturnPath[0] !== '/') {
            finalReturnPath = `/${finalReturnPath}`;
        }

        const serviceUrl = `${protocol}//${hostname}${safePort}${finalReturnPath}`;
        return serviceUrl;
    }

    getSsoRedirectUrl(returnPath: string = ''): string {
        const ssoBaseUrl = environment.ssoUrl;
        const ssoLoginUrl = `${ssoBaseUrl}/login`;
        const serviceUrl = this.getReturnServiceUrl(returnPath);
        const appProfile = environment.ssoProfile || 'realize';
        const rumbaQuery = 'additionalAttributes=Domains&k12int=true';

        const finalUrl = `${ssoLoginUrl}?profile=${appProfile}&service=${serviceUrl}&${rumbaQuery}`;
        return finalUrl;
    }

    ssoLogout(returnUrl = '') {
        const url = `${
            environment.ssoUrl
        }/logout?url=${this.getReturnServiceUrl(returnUrl)}`;
        this.logger.info('SSO LOGOUT', { url });
        this.window.open(url, '_self');
    }

    firebaseLogout(): Promise<void> {
        return this.authDataService.firebaseLogout();
    }

    authenticateTicket(ticket: string, serviceUrl: string): Observable<AuthenticationResponse> {
        return this.authDataService.authenticateTicket(ticket, serviceUrl);
    }

    getRbsToken(userId: string, castgc: string): Observable<RbsTokenResponse> {
        return this.authDataService.getRbsToken(userId, castgc);
    }

    resetRbsTtl(rbsToken: string) {
        return this.authDataService.resetRbsTtl(rbsToken);
    }

    getJwt(userId: string, rbsToken: string): Observable<JwtResponse> {
        return this.authDataService.getJwt(userId, rbsToken);
    }

    firebaseLogin(jwt: string): Observable<firebase.auth.UserCredential> {
        return this.authDataService.firebaseLogin(jwt);
    }
}
