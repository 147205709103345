import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthActionTypes } from '../../auth/store/auth.actions';
import { selectRbsCredentials, selectCurrentUser } from '../../auth/store/auth.selectors';
import { selectCurrentClassRoster } from '../../class-roster/store/class-roster.selectors';
import { AppState } from '../../core.state';
import { getProductFromProgramId, getProgramSkeleton } from '../../product/store/product.selectors';
import { SessionStorageService } from '../../session-storage/session-storage.service';
import { ProgramDataService } from '../program.data.service';
import { ProgramService } from '../program.service';
import {
    ActionLoadProgram,
    ActionLoadProgramFailure,
    ActionLoadProgramSuccess,
    ProgramActionTypes,
    ActionSetSelectedProgram,
    ActionStartSetSelectProgram,
    ActionCheckAndDispatchLoadProgram,
} from './program.actions';
import { selectProgramById, selectProgramState, selectLoadingRootProgramIdentifierIds } from './program.selectors';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActionNavigateToErrorPage, ActionSetErrorType } from '../../error/store/error.actions';
import { ErrorType } from '../../error/store/error.model';

const STATE_STORAGE_KEY = 'PROGRAM';

@Injectable()
export class ProgramEffects {
    constructor(
        private actions$: Actions<Action>,
        private store: Store<AppState>,
        private programService: ProgramService,
        private programDataService: ProgramDataService,
        private sessionStorageService: SessionStorageService,
        private afsAuth: AngularFireAuth,
    ) {}

    @Effect()
    setSelectedProgram$ = this.actions$.pipe(
        ofType<ActionSetSelectedProgram>(ProgramActionTypes.SetSelectedProgram),
        map(action => action.payload),
        filter(({rootProgramIdentifier}) => !!rootProgramIdentifier),
        withLatestFrom(
            this.store.pipe(select(selectRbsCredentials)),
            this.store.pipe(select(selectCurrentClassRoster))
        ),
        tap(([{ rootProgramIdentifier }, { userId }, currentClass]) => {
            this.programDataService.updateRecentProgramForClass(currentClass.classId, rootProgramIdentifier, userId);
        }),
        switchMap(([{ rootProgramIdentifier, productId }]) => this.store.pipe(
            select(selectProgramById(rootProgramIdentifier)),
            map(programFromStore => ({ programFromStore, rootProgramIdentifier, productId }))
        )),
        filter(({ programFromStore }) => !programFromStore),
        map(({ rootProgramIdentifier, productId }) =>
            new ActionCheckAndDispatchLoadProgram({ rootProgramIdentifier, productId }))
    );

    @Effect({ dispatch: true })
    loadProgram = this.actions$.pipe(
        ofType<ActionLoadProgram>(ProgramActionTypes.LoadProgram),
        map(action => action.payload),
        withLatestFrom(this.store.pipe(select(selectRbsCredentials))),
        filter(([, creds]) => !!creds),
        mergeMap(([{ rootProgramIdentifier, productId }, { userId, token }]) => this.store.pipe(
            select(getProgramSkeleton(rootProgramIdentifier)),
            map(({ programName }) => ({ rootProgramIdentifier, productId, userId, token, programName }))
        )),
        mergeMap(({ rootProgramIdentifier, productId, userId, token, programName }) =>
            this.programService.loadProgram(productId, rootProgramIdentifier, userId, token, programName).pipe(
                map(program => new ActionLoadProgramSuccess({ program })),
                catchError(error => {
                    const description = {
                        origin: 'Load Program Hierarchy',
                        payload: { rootProgramIdentifier, productId },
                        message: error,
                    };
                    const actionArray: Action[] = [
                        new ActionLoadProgramFailure({ error, programId: rootProgramIdentifier }),
                        new ActionNavigateToErrorPage({ description })];
                    if (description.message.status && description.message.status === 504) {
                        actionArray.push(new ActionSetErrorType({ errorType: ErrorType.TIMEOUT }));
                    }
                    return actionArray;
                })
            )
        )
    );

    @Effect()
    checkAndDispatchLoadProgram$ = this.actions$.pipe(
        ofType<ActionCheckAndDispatchLoadProgram>(ProgramActionTypes.CheckAndDispatchLoadProgram),
        map(action => action.payload),
        withLatestFrom(this.store.pipe(select(selectLoadingRootProgramIdentifierIds))),
        filter(([{ rootProgramIdentifier }, loadingRootProgramIdentifierIds]) =>
            !loadingRootProgramIdentifierIds.includes(rootProgramIdentifier)
        ),
        map(([{ rootProgramIdentifier, productId }]) => new ActionLoadProgram({ rootProgramIdentifier, productId })),
    );

    @Effect()
    startSetSelectProgram$ = this.actions$.pipe(
        ofType<ActionStartSetSelectProgram>(ProgramActionTypes.StartSetSelectProgram),
        switchMap(() => {
            return this.store.pipe(select(selectCurrentClassRoster), first());
        }),
        // select current user
        switchMap(classRoster => {
            return this.store.pipe(
                select(selectCurrentUser),
                map(({ userId }) => {
                    return { classRoster, userId };
                }),
                first(),
            );
        }),
        // get most recent program in class
        switchMap(({ classRoster, userId }) => {
            return this.afsAuth.authState.pipe(
                filter(user => !!user),
                switchMap(() => {
                    return this.programDataService.getRecentProgramForClass(classRoster.classId, userId).pipe(
                        map(recentProgramDoc => {
                            return { recentProgramDoc, classRoster };
                        })
                    );
                })
            );
        }),
        // get product if observation document exists
        switchMap(({ recentProgramDoc, classRoster }) => {
            if (recentProgramDoc) {
                return this.store.pipe(
                    select(getProductFromProgramId(recentProgramDoc.programId)),
                    map(product => {
                        return { recentProgramDoc, classRoster, product };
                    })
                );
            } else {
                return of({ recentProgramDoc, classRoster, product: undefined });
            }
        }),
        // select recent program if observation exist
        tap(({ recentProgramDoc, product }) => {
            if (recentProgramDoc && product) {
                return this.store.dispatch(
                    new ActionSetSelectedProgram({
                        rootProgramIdentifier: recentProgramDoc.programId,
                        productId: product.id
                    })
                );
            }
        }),
        // stop if observation exist
        filter(({ recentProgramDoc, product }) => !(recentProgramDoc && product)),
        // sort and select first program alphabetically
        switchMap(({ classRoster }) => {
            const { productIds } = classRoster;
            return this.programService.sortAndSelectPrograms(productIds).pipe(first());
        })
    );

    @Effect({ dispatch: false })
    persistState = this.actions$.pipe(
        ofType(ProgramActionTypes.LoadProgramSuccess, ProgramActionTypes.SetSelectedProgram),
        withLatestFrom(this.store.pipe(select(selectProgramState))),
        tap(([, state]) => {
            this.sessionStorageService.setItem(STATE_STORAGE_KEY, state);
        })
    );

    @Effect({ dispatch: false })
    clearPersistedState = this.actions$.pipe(
        ofType(AuthActionTypes.LOGOUT),
        tap(() => {
            this.sessionStorageService.removeItem(STATE_STORAGE_KEY);
        })
    );
}
