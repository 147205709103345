import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { finalize, first, map } from 'rxjs/operators';
import { AssessmentService } from '../assessment/assessment.service';
import { AppState } from '../core.state';
import { selectMediaForSearchTerm } from '../media/store/media.selectors';
import { selectNotesForSearchTerm } from '../notes/store/notes.selectors';
import {
    ActionCloseSearchMenu,
    ActionOpenSearchMenu,
    ActionSendSearchTelemetryEvent,
    ActionSetIsLoadingSearchResults,
    ActionSetIsSearchMenuOpen,
    ActionUpsertSearchRecord
} from './store/search.actions';
import { SearchResultCategory, SearchVM } from './store/search.model';
import {
    selectIsLoadingRecentSearches,
    selectIsLoadingSearchResults,
    selectIsSearchMenuOpen,
    selectRecentSearchesOrderedByDesc
} from './store/search.selectors';

@Injectable({ providedIn: 'root' })
export class SearchService {

    isSearchMenuOpen$ = this.store$.pipe(select(selectIsSearchMenuOpen));
    isLoadingRecentSearches$ = this.store$.pipe(select(selectIsLoadingRecentSearches));
    isLoadingSearchResults$ = this.store$.pipe(select(selectIsLoadingSearchResults));

    constructor(
        private store$: Store<AppState>,
        private assessmentService: AssessmentService,
    ) {}

    upsertSearchRecord(searchTerm: string) {
        this.store$.dispatch(new ActionUpsertSearchRecord({ searchTerm }));
    }

    getSearchResultsForSearchTerm(searchTerm: string) {
        this.store$.dispatch(new ActionSetIsLoadingSearchResults({ isLoadingSearchResults: true }));
        return combineLatest(
            this.store$.pipe(select(selectMediaForSearchTerm(searchTerm))),
            this.store$.pipe(select(selectNotesForSearchTerm(searchTerm))),
            this.assessmentService.getAssessmentsForSearchTerm(searchTerm)
        ).pipe(
            map(([mediaResult, notesResult, assessmentsResult]) => {
                const mediaResults = mediaResult.map(media => ({
                    category: SearchResultCategory.Media,
                    title: media.title,
                    updatedAt: media.updatedAt,
                    id: media.id,
                    object: media,
                }));
                const notesResults = notesResult.map(note => ({
                    category: SearchResultCategory.Note,
                    title: note.body,
                    updatedAt: note.updatedDate,
                    id: note.id,
                    object: note,
                }));
                const assessmentResults = assessmentsResult.map(assessment => ({
                    category: SearchResultCategory.Assessment,
                    title: assessment.titleInSequence,
                    updatedAt: assessment.updatedAt,
                    id: assessment.identifier,
                    object: assessment,
                }));
                return [
                    ...mediaResults,
                    ...notesResults,
                    ...assessmentResults
                ].sort((a, b) => b.updatedAt - a.updatedAt);
            }),
            first(),
            finalize(() => this.store$.dispatch(
                new ActionSetIsLoadingSearchResults({ isLoadingSearchResults: false })
            ))
        );
    }

    getRecentSearches() {
        const currentDateTime = Date.parse(new Date().toDateString());
        return this.store$.pipe(
            select(selectRecentSearchesOrderedByDesc),
            map(recentSearches => {
                const searchRecords: SearchVM = { today: [], thisWeek: [] };
                const reducer = (accumulator, recentSearch) => {
                    (currentDateTime <= recentSearch.updatedAt)
                        ? accumulator.today.push(recentSearch)
                        : accumulator.thisWeek.push(recentSearch);
                    return accumulator;
                };
                return recentSearches.reduce(reducer, searchRecords);
            })
        );
    }

    openSearchMenu() {
        this.store$.dispatch(new ActionOpenSearchMenu());
    }

    closeSearchMenu() {
        this.store$.dispatch(new ActionCloseSearchMenu());
    }

    setIsSearchMenuOpen(isSearchMenuOpen: boolean) {
        this.store$.dispatch(new ActionSetIsSearchMenuOpen({ isSearchMenuOpen }));
    }

    sendSearchTelemetryEvent(searchTerm: string, fromSearchHistory: boolean) {
        this.store$.dispatch(new ActionSendSearchTelemetryEvent({ searchTerm, fromSearchHistory }));
    }
}
