<ion-content>
    <div class="flex-container-col">
        <div class="card-header card-header--secondary">
            <h4 class="card-header__title">{{ programHeader$ | async }}</h4>
            <ion-menu-toggle>
                <button class="menu-header__close-button" [title]="'global.menu.close-menu' | translate">
                    <span class="icon-exit" aria-hidden="true"></span>
                </button>
            </ion-menu-toggle>
        </div>
        <div class="page-bar">
            <ion-segment class="segment" (ionChange)="onTabChange($event)" [value]="selectedTab" mode="ios">
                <ion-segment-button class="segment-button" value="byTOC">
                    <ion-label class="segment-button__label">
                        {{ 'dashboard-all-assessments-menu.viewByTOC' | translate }}
                    </ion-label>
                </ion-segment-button>
                <ion-segment-button class="segment-button" value="all">
                    <ion-label class="segment-button__label">
                        {{ 'dashboard-all-assessments-menu.viewAll' | translate }}
                    </ion-label>
                </ion-segment-button>
            </ion-segment>
        </div>
        <div class="assessment-hierarchy">
            <app-dashboard-assessment-hierarchy *ngIf="selectedTab === 'byTOC'"></app-dashboard-assessment-hierarchy>
            <app-dashboard-assessment-list *ngIf="selectedTab === 'all'"></app-dashboard-assessment-list>
        </div>
    </div>
</ion-content>
