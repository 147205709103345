import { Action } from '@ngrx/store';
import { Program } from './program.model';

export enum ProgramActionTypes {
    StartSetSelectProgram = '[Program] Start Set selected program',
    SetSelectedProgram = '[Program] Set selected program',
    CheckAndDispatchLoadProgram = '[Program] Check and Dispatch LoadProgram',
    LoadProgram = '[Program] Start Loading Program',
    LoadProgramSuccess = '[Program] Program Loaded',
    LoadProgramFailure = '[Program] Program Loading Failed',
}

export class ActionStartSetSelectProgram implements Action {
    readonly type = ProgramActionTypes.StartSetSelectProgram;
}

export class ActionSetSelectedProgram implements Action {
    readonly type = ProgramActionTypes.SetSelectedProgram;
    constructor(public payload: { rootProgramIdentifier: string; productId: string }) {}
}

export class ActionLoadProgram implements Action {
    readonly type = ProgramActionTypes.LoadProgram;
    constructor(public payload: { rootProgramIdentifier: string, productId: string }) {}
}

export class ActionCheckAndDispatchLoadProgram implements Action {
    readonly type = ProgramActionTypes.CheckAndDispatchLoadProgram;
    constructor(public payload: { rootProgramIdentifier: string, productId: string }) {}
}

export class ActionLoadProgramSuccess implements Action {
    readonly type = ProgramActionTypes.LoadProgramSuccess;
    constructor(public payload: { program: Program }) {}
}

export class ActionLoadProgramFailure implements Action {
    readonly type = ProgramActionTypes.LoadProgramFailure;
    constructor(public payload: { error?: any, programId: string }) {}
}

export type ProgramActions =
    | ActionSetSelectedProgram
    | ActionLoadProgram
    | ActionCheckAndDispatchLoadProgram
    | ActionLoadProgramSuccess
    | ActionLoadProgramFailure;
