import { Pipe, PipeTransform } from '@angular/core';
import { ObservationType } from '../../core/observations/store/observations.model';

@Pipe({ name: 'observationIcon' })
export class ObservationIconPipe implements PipeTransform {
    transform(observationType: ObservationType) {
        switch (observationType) {
            case ObservationType.Checklist:
                return 'icon-observational_assessment-inverted';
            case ObservationType.Fluency:
                return 'icon-timer';
            case ObservationType.Ongoing:
                return 'icon-assessment';
            default:
                return '';
        }
    }
}
