import { EntityState } from '@ngrx/entity';
import { AppState } from 'src/app/core/core.state';

export enum NoteType {
    note = 'note',
    todo = 'todo'
}

export interface StudentVM {
    id: string;
    name: string;
    initials: string;
}

export interface Note {
    id: string;
    body: string;
    createdDate: number;
    updatedDate: number;
    createdBy: string;
    type: NoteType;
    classId: string;
    studentIds: string[];
    completed: boolean;
    dueDate: number;
    completedDate: number;
    deleted: boolean;
}

export interface NoteVM {
    id: string;
    body: string;
    students?: StudentVM[];
    type: NoteType;
    completed: boolean;
    updatedDate: number;
    dueDateISO?: string;
    isTodo: boolean;
    dueDate?: number;
    sortDueDateOrder: number;
    sortUpdateDateOrder: number;
}

export type NotesFilter = 'ALL' | 'TODO' | 'ACTIVE';

export interface NotesState extends EntityState<Note> {
    filter: NotesFilter;
    editorNote: Note;
    isLoadingNotes: boolean;
}

export interface State extends AppState {
    notes: NotesState;
}
