import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { MenuController } from '@ionic/angular';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, tap, withLatestFrom, mergeMap } from 'rxjs/operators';
import { selectCurrentUser } from '../../auth/store/auth.selectors';
import { selectSelectedClassRosterId } from '../../class-roster/store/class-roster.selectors';
import { AppState, selectRouterState } from '../../core.state';
import {
    ActionAddMedia,
    ActionAddMediaFailed,
    ActionAddMediaToStore,
    ActionCreateMediaDocument,
    ActionLoadMediaStart,
    ActionRemoveMedia,
    ActionRemoveMediaFailed,
    ActionRemoveMediaSuccess,
    ActionUpdateMedia,
    ActionUpdateMediaFailed,
    ActionUpdateMediaSuccess,
    ActionUploadMedia,
    ActionUploadMediaFailed,
    MediaActionTypes,
    ActionOpenMediaMenu,
    ActionCloseMediaMenu,
    ActionSetSelectedMediaId,
    ActionUpdateMediaInStore,
    ActionRemoveMediaFromStore
} from './media.actions';
import { selectMediaState, selectMediaIdFromRouterState } from './media.selectors';
import {
    ActionFetchSignedThumbnailUrls,
    ActionFetchSignedDownloadUrls,
    ActionFetchSignedThumbnailUrlsSuccess,
    ActionFetchSignedDownloadUrlsSuccess,
    ActionFetchSignedThumbnailUrlsFailed,
    ActionFetchSignedDownloadUrlsFailed
} from './media.actions';
import { Media } from './media.model';
import { MediaService } from '../media.service';
import { ActionAddMediaSuccess, ActionLoadMediaEnd } from './media.actions';
import { ActionSendTelemetryEventForError, ActionShowErrorModal } from 'src/app/core/error/store/error.actions';
import { FirestoreDocumentChangeType } from '../../firebase/firebase.model';

@Injectable()
export class MediaEffects {
    constructor(
        private actions$: Actions<Action>,
        private store$: Store<AppState>,
        private mediaService: MediaService,
        private afsAuth: AngularFireAuth,
        private menuController: MenuController,
        private router: Router
    ) {}

    @Effect({ dispatch: false })
    loadAllMedia$ = this.store$.pipe(
        select(selectSelectedClassRosterId),
        filter(classId => !!classId),
        tap(() => this.store$.dispatch(new ActionLoadMediaStart())),
        switchMap(classId =>
            this.afsAuth.authState.pipe(
                filter(user => !!user),
                switchMap(user => this.mediaService.fetchMedia(classId, user.uid).pipe(
                    tap(actionsToDispatch => {
                        actionsToDispatch.forEach((action) => {
                            this.store$.dispatch(action);
                        });
                    })
                )),
                tap(() => this.store$.dispatch(new ActionLoadMediaEnd())),
                catchError(error => {
                    const description = {
                        origin: 'Load Media',
                        payload: { classId },
                        message: error
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionShowErrorModal(),
                    ];
                })
            )
        )
    );

    @Effect()
    removeMedia$ = this.actions$.pipe(
        ofType<ActionRemoveMedia>(MediaActionTypes.RemoveMedia),
        map(action => action.payload.media),
        mergeMap(media =>
            this.mediaService.removeMedia(media).pipe(
                map(() => new ActionRemoveMediaSuccess({ media })),
                catchError(error => {
                    const description = {
                        origin: 'Remove Media',
                        payload: { media },
                        message: error
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionRemoveMediaFailed({ error }),
                        new ActionShowErrorModal(),
                    ];
                })
            )
        )
    );

    @Effect()
    getThumbnailUrls$ = this.actions$.pipe(
        ofType<ActionFetchSignedThumbnailUrls>(MediaActionTypes.FetchSignedThumbnailUrls),
        map(action => action.payload.medias),
        mergeMap((medias: Media[]) => {
            const mediaMap: Map<string, Media> = new Map();
            const mediaUrls = medias.map((media: Media) => {
                const thumbnailPathArray = media.metadata.ref.split('/');
                thumbnailPathArray[thumbnailPathArray.length - 1] = `thumb_${
                    thumbnailPathArray[thumbnailPathArray.length - 1]
                }`;
                const mediaRef = thumbnailPathArray.join('/');
                mediaMap.set(mediaRef, media);
                return mediaRef;
            });
            return this.mediaService.fetchSignedUrls(mediaUrls).pipe(
                map(result => {
                    const changedMedia = result.map(signedUrlResult => {
                        const media = mediaMap.get(signedUrlResult.mediaRef);
                        return {
                            ...media,
                            thumbnailLoading: false,
                            signedThumbnailUrl: { url: signedUrlResult.signedUrl, expireAt: signedUrlResult.expiresIn }
                        };
                    });
                    return new ActionFetchSignedThumbnailUrlsSuccess({ medias: changedMedia });
                }),
                catchError(error => {
                    const description = {
                        origin: 'Fetch Signed Thumbnail Urls',
                        payload: { medias },
                        message: error
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionFetchSignedThumbnailUrlsFailed({ error }),
                        new ActionShowErrorModal(),
                    ];
                })
            );
        })
    );

    @Effect()
    getMediaUrl$ = this.actions$.pipe(
        ofType<ActionFetchSignedDownloadUrls>(MediaActionTypes.FetchSignedDownloadUrls),
        map(action => action.payload.medias),
        mergeMap((medias: Media[]) => {
            const mediaMap: Map<string, Media> = new Map();
            const mediaUrls = medias.map((media: Media) => {
                const mediaRef = media.metadata.ref;
                mediaMap.set(mediaRef, media);
                return mediaRef;
            });
            return this.mediaService.fetchSignedUrls(mediaUrls).pipe(
                map(result => {
                    const changedMedia = result.map(signedUrlResult => {
                        const media = mediaMap.get(signedUrlResult.mediaRef);
                        return {
                            ...media,
                            mediaLoading: false,
                            signedDownloadUrl: { url: signedUrlResult.signedUrl, expireAt: signedUrlResult.expiresIn }
                        };
                    });
                    return new ActionFetchSignedDownloadUrlsSuccess({ medias: changedMedia });
                }),
                catchError(error => {
                    const description = {
                        origin: 'Fetch Signed Download Urls',
                        payload: { medias },
                        message: error
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionFetchSignedDownloadUrlsFailed({ error }),
                        new ActionShowErrorModal(),
                    ];
                })
            );
        })
    );

    @Effect()
    updateMedia$ = this.actions$.pipe(
        ofType<ActionUpdateMedia>(MediaActionTypes.UpdateMedia),
        map(action => action.payload.media),
        switchMap(media =>
            this.mediaService.updateMedia(media).pipe(
                map(() => new ActionUpdateMediaSuccess()),
                catchError(error => {
                    const description = {
                        origin: 'Update Media',
                        payload: { media },
                        message: error
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionUpdateMediaFailed({ error }),
                        new ActionShowErrorModal(),
                    ];
                })
            )
        )
    );

    @Effect()
    createMediaDocument$ = this.actions$.pipe(
        ofType<ActionCreateMediaDocument>(MediaActionTypes.CreateMediaDocument),
        map(action => action.payload.file),
        withLatestFrom(
            this.store$.pipe(select(selectCurrentUser)),
            this.store$.pipe(select(selectSelectedClassRosterId))
        ),
        mergeMap(([file, currentUser, classId]) => {
            const media = this.mediaService.createMediaDocument(currentUser.userId, classId, file);
            return [
                new ActionAddMediaToStore({ media }),
                new ActionUploadMedia({ file, media })
            ];
        })
    );

    @Effect()
    uploadMedia$ = this.actions$.pipe(
        ofType<ActionUploadMedia>(MediaActionTypes.UploadMedia),
        map(action => action.payload),
        mergeMap(({ file, media }) =>
            this.mediaService.uploadMedia(file, media).pipe(
                map(() => new ActionAddMedia({ media })),
                catchError(error => {
                    const description = {
                        origin: 'Upload Media',
                        payload: { media },
                        message: error
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionUploadMediaFailed({ error, media }),
                        new ActionShowErrorModal(),
                    ];
                })
            )
        )
    );

    @Effect()
    addMedia$ = this.actions$.pipe(
        ofType<ActionAddMedia>(MediaActionTypes.AddMedia),
        map(action => action.payload.media),
        mergeMap(media =>
            this.mediaService.addMedia(media).pipe(
                map(() => new ActionAddMediaSuccess({ media })),
                catchError(error => {
                    const description = {
                        origin: 'Add Media',
                        payload: { media },
                        message: error
                    };
                    return [
                        new ActionSendTelemetryEventForError({ description }),
                        new ActionAddMediaFailed({ error, media }),
                        new ActionShowErrorModal(),
                    ];
                })
            )
        )
    );

    @Effect({ dispatch: false })
    openMediaMenu$ = this.actions$.pipe(
        ofType<ActionOpenMediaMenu>(MediaActionTypes.OpenMediaMenu),
        map(action => action.payload.mediaId),
        tap(mediaId => {
            this.menuController.enable(true, 'mediaMenu').then(() => {
                if (mediaId) {
                    this.store$.dispatch(new ActionSetSelectedMediaId({ mediaId }));
                }
                this.menuController.open('mediaMenu');
            });
        })
    );

    @Effect({ dispatch: false })
    closeMediaMenu$ = this.actions$.pipe(
        ofType<ActionCloseMediaMenu>(MediaActionTypes.CloseMediaMenu),
        withLatestFrom(this.store$.pipe(select(selectMediaIdFromRouterState))),
        tap(([, mediaId]) => {
            if (mediaId) {
                this.router.navigateByUrl('/media');
            }
            this.store$.dispatch(new ActionSetSelectedMediaId({ mediaId: '' }));
            this.menuController.close('mediaMenu');
        })
    );

    @Effect({ dispatch: false })
    closeMediaMenuOnRouteChange$ = this.store$.pipe(
        select(selectRouterState),
        withLatestFrom(this.store$.select(selectMediaState)),
        filter(([router, media]) => !!router && !!media),
        map(([router, media]) => {
            if (!router.state.params.mediaId && media.isMediaMenuOpen) {
                this.store$.dispatch(new ActionCloseMediaMenu());
            }
        })
    );

    @Effect({ dispatch: false })
    setSelectedMediaId$ = this.store$.pipe(
        select(selectMediaIdFromRouterState),
        filter(mediaId => !!mediaId),
        tap(mediaId => {
            this.store$.dispatch(new ActionSetSelectedMediaId({ mediaId }));
        })
    );
}
