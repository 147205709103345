import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { AppState } from '../core.state';
import { ActionSetPreviousURL } from './store/navigation.actions';
import { selectPreviousURL } from './store/navigation.selectors';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    selectPreviousURL$ = this.store$.pipe(select(selectPreviousURL));

    constructor(
        private store$: Store<AppState>,
        private router: Router
    ) {}

    setPreviousURL(previousURL = '') {
        this.store$.dispatch(new ActionSetPreviousURL({ previousURL }));
    }

    goToPreviousURL(alternateURL = '/dashboard') {
        this.selectPreviousURL$.pipe(first()).subscribe((previousURL) => {
            const toNavigateURL = previousURL || alternateURL;
            this.router.navigateByUrl(toNavigateURL);
        });
    }
}
