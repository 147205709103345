import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { ProgramTelemetryEffects } from './store/program-telemetry.effects';
import { ProgramEffects } from './store/program.effects';
import { ProgramService } from './program.service';
import { reducer } from './store/program.reducer';
import { StoreModule } from '@ngrx/store';
import { ProgramDataService } from './program.data.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('program', reducer),
        EffectsModule.forFeature([ProgramEffects, ProgramTelemetryEffects])
    ],
    providers: [ProgramService, ProgramDataService]
})
export class ProgramModule {}
