<ion-content>
    <div class="flex-container-col">
        <div class="card-header">
            <div>
                <span class="icon-search search-icon" aria-hidden="true"></span>
                <input
                    class="search-box-input"
                    (keyup)="onKeyUpHandler($event)"
                    [(ngModel)]="searchInput"
                    [placeholder]="'search-menu.search-box.placeholder' | translate"/>
            </div>
            <div class="close-btn-container">
                <ion-button
                    fill="clear"
                    mode="ios"
                    *ngIf="(!!searchTerm || searchResults?.length > 0) && !(isLoadingSearchResults$ | async)"
                    class="btn btn-clear"
                    (click)="clearSearch()">
                    {{'search-menu.clear-button' | translate}}
                </ion-button>
                <ion-button
                    fill="clear"
                    mode="ios"
                    class="btn btn-menu-header-close"
                    [title]="'global.menu.close-menu' | translate"
                    (click)="closeSearchMenu()">
                    <span class="icon-exit" aria-hidden="true"></span>
                </ion-button>
            </div>
        </div>

        <div class="search-results-container">
            <ng-container *ngIf="showRecentSearches">
                <ng-container *ngIf="!(isLoadingRecentSearches$ | async); else showLoadingSkeleton">
                    <ion-list class="list recent-searches" lines="none">
                        <ion-item class="list-item">
                            <ion-label class="recent-searches-time">
                                {{'search-menu.recentSearch-today' | translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item
                            class="list-item"
                            *ngFor="let recentSearch of recentSearches?.today; trackBy: trackByRecentSearchRecordId"
                            (click)="onClickRecentSearch(recentSearch)">
                            <ion-label class="recent-searches-data">{{ recentSearch.searchTerm }}</ion-label>
                        </ion-item>
                    </ion-list>
                    <ion-list class="list recent-searches" lines="none" *ngIf="recentSearches?.today.length < 25">
                        <ion-item class="list-item">
                            <ion-label class="recent-searches-time">
                                {{'search-menu.recentSearch-this-week' | translate}}
                            </ion-label>
                        </ion-item>
                        <ion-item
                            class="list-item "
                            *ngFor="let recentSearch of recentSearches?.thisWeek; trackBy: trackByRecentSearchRecordId"
                            (click)="onClickRecentSearch(recentSearch)">
                            <ion-label class="recent-searches-data">{{ recentSearch.searchTerm }}</ion-label>
                        </ion-item>
                    </ion-list>
                </ng-container>
                <ng-template #showLoadingSkeleton>
                    <ion-item class="list-item__title list-item__title--ellipsis" *ngFor="let item of [0, 1, 3]">
                        <ion-skeleton-text animated style="width: 100%;"></ion-skeleton-text>
                    </ion-item>
                </ng-template>
            </ng-container>
            <div
                class="zero-state-container"
                *ngIf="searchResults?.length === 0 && searchTerm && !showRecentSearches && !(isLoadingSearchResults$ | async); else showResultData">
                <span
                    class="zero-state-label"
                    [innerHTML]="('search-menu.no-search-result-text' | translate) + searchTerm + ('search-menu.no-search-result-suggestion-text' | translate) ">
                </span>
            </div>
            <ng-template #showResultData>
                <div
                    *ngIf="!!searchResults"
                    class="card-content display-results">
                    <ion-list class="list list-search-results">
                        <ion-item
                            *ngFor="let searchResult of searchResults; trackBy: trackBySearchResultRecordId"
                            class="list-item search-item-list"
                            tabindex="0"
                            ngClass="list-item--{{ searchResult.category }}-item"
                            lines="full"
                            (click)="navigateToSearchResult(searchResult)">
                            <span
                                class="list-item__icon label-icon"
                                ngClass="{{ searchResult.category === assessmentType ? (searchResult.object.type | observationIcon) : (searchResult.category | searchResultIcon) }}"
                                aria-hidden="true"
                                slot="start"
                            ></span>
                            <ion-label>
                                <h3 class="list-item__title list-item__title--ellipsis"
                                    ngClass="list-item--{{ searchResult.category }}-text">
                                    {{ searchResult.title }}
                                </h3>
                                <h4 class="list-item__subtitle"
                                    *ngIf="searchResult.category === 'assessment'">
                                    {{ searchResult.object.path }}
                                </h4>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </div>
                <ion-spinner *ngIf="isLoadingSearchResults$ | async" class="search-results-loader"></ion-spinner>
            </ng-template>
        </div>
    </div>
</ion-content>
