import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProgramHierarchyResponse } from './store/program.model';

interface IRecentProgram {
    programId: string;
    classId: string;
    createdBy: string;
}
@Injectable({ providedIn: 'root' })
export class ProgramDataService {
    constructor(private http: HttpClient, private afsDb: AngularFirestore) {}

    getRecentProgramForClass(classId: string, createdBy: string) {
        const query = ref => ref.where('classId', '==', classId).where('createdBy', '==', createdBy);
        return this.afsDb
            .collection<IRecentProgram>('recentProgram', query)
            .get()
            .pipe(
                map(querySnapshot => {
                    let document: IRecentProgram;
                    querySnapshot.docs.forEach(documentSnapshot => {
                        document = documentSnapshot.data() as IRecentProgram;
                    });
                    return document;
                })
            );
    }

    updateRecentProgramForClass(classId: string, programId: string, createdBy: string) {
        const docId = `${classId}${createdBy}`;
        return this.afsDb
            .collection('recentProgram')
            .doc(docId)
            .set({ programId, classId, createdBy }, { merge: true });
    }

    loadProgram(productId: string, programId: string, userId: string, accessToken: string, programName: string) {
        const url = environment.api.programHierarchy + `product/${productId}/rootProgram/${programId}/hierarchy`;

        const headers = {
            Authorization: `Bearer ${accessToken}`,
            userId
        };
        return this.http.get<ProgramHierarchyResponse>(url, { headers });
    }
}
