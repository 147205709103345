import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ProductDataService } from './product.data.service';
import { Product, ProductEntity, ProgramSkeleton } from './store/product.model';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    constructor(private productDataService: ProductDataService) {}

    loadProducts(productIds: string[], userId: string, accessToken: string, throwErrorOnProductLoadFailure = true):
        Observable<Product[]> {
        return this.productDataService.loadProducts(productIds, userId, accessToken).pipe(
            map((productEntities: ProductEntity[]) => {
                const productMap: Map<string, ProgramSkeleton[]> = new Map();
                productEntities.forEach((product: ProductEntity) => {
                    const programSkeleton: ProgramSkeleton = {
                        programName: product.programName,
                        rootProgramIdentifier: product.rootProgramIdentifier
                    };

                    product.productIds.forEach((productId: string) => {
                        const programsForProduct = productMap.get(productId) || [];
                        programsForProduct.push(programSkeleton);
                        productMap.set(productId, programsForProduct);
                    });
                });

                const products: Product[] = [];
                productIds.forEach((id) => {
                    const programs = productMap.get(id) || [];
                    if (programs.length > 0) {
                        products.push({ id, programs });
                    }
                });
                return products;
            }),
            catchError(error => throwErrorOnProductLoadFailure ? throwError(error) : of([]))
        );
    }
}
