import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { from, throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Media, MediaType, SignedUrlVM } from './store/media.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FirestoreDocumentChangeType } from '../firebase/firebase.model';
import { ActionAddMediaToStore, ActionUpdateMediaInStore, ActionRemoveMediaFromStore } from './store/media.actions';

@Injectable({ providedIn: 'root' })
export class MediaDataService {
    constructor(private afsDb: AngularFirestore, private afsStorage: AngularFireStorage, private http: HttpClient) {}

    static mediaTypeFromMime(type: string) {
        let mediaType: MediaType;
        if (type.startsWith('image/')) {
            mediaType = MediaType.Image;
        } else if (type.startsWith('video/')) {
            mediaType = MediaType.Video;
        } else {
            throw new Error(`unknown MIME type: ${type}`);
        }
        return mediaType;
    }

    createMediaDocument(userId: string, classId: string, file: File, mediaId?: string): Media {
        const id = mediaId ? mediaId : this.afsDb.createId();
        const path = `/user/${userId}/class/${classId}/media/${id}/${file.name}`;
        const timestamp = new Date().getTime();
        return {
            id: id,
            type: MediaDataService.mediaTypeFromMime(file.type),
            title: 'Untitled',
            description: '',
            downloadUrl: '',
            thumbnails: { h128: '', h256: '' },
            createdBy: userId,
            classId: classId,
            createdAt: timestamp,
            updatedAt: timestamp,
            taggedStudents: [],
            metadata: {
                name: file.name,
                contentType: file.type,
                ref: path
            },
            deleted: false,

            thumbnailLoading: false,
            mediaLoading: false
        };
    }

    addMedia(media: Media) {
        return from(
            this.afsDb
                .collection<Media>('media')
                .doc(media.id)
                .set(media)
        );
    }

    uploadMedia(file: File, media: Media) {
        return from(this.afsStorage.ref(media.metadata.ref).put(file));
    }

    removeMedia(mediaDocument: Media[]) {
        const media = mediaDocument.map(document => {
            const doc = { ...document };
            delete doc.signedDownloadUrl;
            delete doc.signedThumbnailUrl;
            return doc;
        });
        const batch = this.afsDb.firestore.batch();
        media.forEach(doc => {
            const changes = { ...doc, deleted: true };
            const docRef = this.afsDb.firestore.collection('media').doc(doc.id);
            batch.update(docRef, changes);
        });
        return from(batch.commit());
    }

    updateMedia(changes: Media) {
        return from(
            this.afsDb
                .collection('media')
                .doc(changes.id)
                .update(changes)
        );
    }

    fetchMedia(classId: string, createdBy: string) {
        const query = ref =>
            ref
                .where('classId', '==', classId)
                .where('createdBy', '==', createdBy)
                .where('deleted', '==', false);
        return this.afsDb
            .collection<Media>('media', query)
            .auditTrail()
            .pipe(
                map(data => {
                    const actionsToDispatch = [];
                    data.forEach(({ type, payload }) => {
                        const doc = payload.doc.data() as Media;
                        const id = payload.doc.id;
                        switch (type) {
                            case FirestoreDocumentChangeType.added:
                                actionsToDispatch.push(new ActionAddMediaToStore({ media: { ...doc, id } }));
                                break;
                            case FirestoreDocumentChangeType.modified:
                                actionsToDispatch.push(new ActionUpdateMediaInStore({ media: { ...doc, id } }));
                                break;
                            case FirestoreDocumentChangeType.removed:
                                actionsToDispatch.push(new ActionRemoveMediaFromStore({ media: [{ ...doc, id }] }));
                                break;
                        }
                    });
                    return actionsToDispatch;
                })
            );
    }

    fetchSignedUrls(mediaRefs: string[], token: string): Observable<SignedUrlVM[]> {
        const signedUrl = environment.api.signedMediaUrl;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        const requestParams = JSON.stringify({
            mediaRefs
        });
        const request = this.http.post<SignedUrlVM[]>(signedUrl, requestParams, { headers }).pipe(
            map(data => data),
            catchError(err => throwError(err))
        );

        return request;
    }
}
