import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NotesService } from 'src/app/core/notes/notes.service';
import { Logger } from 'src/app/core/logging/logger';
import { Subscription } from 'rxjs';
import { ActionShowEditorMenu } from 'src/app/core/notes/store/notes.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/core.state';

@Component({
    selector: 'notes-list',
    templateUrl: './notes-list.component.html',
    styleUrls: ['./notes-list.component.scss']
})
export class NotesListComponent implements OnInit, OnDestroy {
    @Input() allNotes = [];
    @Input() editMode = false;
    @Input() zeroStateText = '';

    @Output() updateCheckedList = new EventEmitter<any>();

    notesCount$ = this.notesService.currentClassNotesCount$;
    isLoadingNotes$ = this.notesService.isLoadingNotes$;

    count: number;
    isLoadingNotes: boolean;
    isExpanded = false;
    private countSub: Subscription;
    private isLoadingNotesSub: Subscription;

    constructor(
        private notesService: NotesService,
        private logger: Logger,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.isLoadingNotesSub = this.isLoadingNotes$.subscribe(isLoadingNotes => {
            this.isLoadingNotes = isLoadingNotes;
        });

        this.countSub = this.notesCount$.subscribe(count => {
            this.count = count;
        });
    }

    ngOnDestroy() {
        this.countSub.unsubscribe();
        this.isLoadingNotesSub.unsubscribe();
    }

    toggleSelected(event, note) {
        this.logger.info('item checked: ', event, note);
        const { checked } = event.detail;
        this.updateCheckedList.emit({checked, note});
    }

    presentNotesMenuForEdit(noteId: string): void {
        if (!this.editMode) {
            this.store.dispatch(new ActionShowEditorMenu({ noteId }));
        }
    }

    showStudents() {
        this.logger.info('Xpander');
        this.isExpanded = true;
    }

    trackByNoteId(index, note) {
        return note.id;
    }
}
