import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ErrorEffects } from 'src/app/core/error/store/error.effects';
import { ErrorService } from 'src/app/core/error/error.service';
import { reducer } from 'src/app/core/error/store/error.reducers';
import { StoreModule } from '@ngrx/store';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('error', reducer),
        EffectsModule.forFeature([ErrorEffects])
    ],
    providers: [ErrorService]
})
export class ErrorModule {}
