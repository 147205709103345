import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import {
    ActionSetSelectedClassRoster,
    ClassRosterActionTypes
} from 'src/app/core/class-roster/store/class-roster.actions';
import { TelemetryService } from 'src/app/core/telemetry/telemetry.service';

@Injectable()
export class ClassRosterTelemetryEffects {

    constructor(
        private actions$: Actions<Action>,
        private telemetryService: TelemetryService,
    ) {}

    @Effect({ dispatch: false })
    selectClassRoster = this.actions$.pipe(
        ofType<ActionSetSelectedClassRoster>(ClassRosterActionTypes.SetSelectedClassRoster),
        map(action => action.payload.classId),
        tap(classId => {
            const details = {
                section: { id: classId },
            };
            this.telemetryService.sendActivity('selected', 'Class', details);
        })
    );
}
