import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserProfileDataService } from './user-profile.data.service';
import { AppState } from '../core.state';
import { UserProfile } from './store/user-profile.model';
import { selectIsLoadingUsers, selectUsersByIds } from './store/user-profile.selectors';
import { tap, map } from 'rxjs/operators';
import { ActionUserProfileBulkLoad } from './store/user-profile.actions';

export interface BulkProfilesResponse {
    users: UserProfile[];
}

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {

    isLoading$ = this.store.pipe(select(selectIsLoadingUsers));

    constructor(
        private store: Store<AppState>,
        private userProfileDataService: UserProfileDataService,
    ) {}

    getProfiles(userIds: string[], accessToken: string, currentUserId: string): Observable<BulkProfilesResponse> {
        return this.userProfileDataService.getProfiles(userIds, accessToken, currentUserId).pipe(
            map((response) => ({
                users: Object.keys(response).map((userId) => ({
                    rumbaUser: response[userId].data,
                    attributes: {}
                }))
            })));
    }

    users$(userIds: string[]) {
        return this.store.pipe(
            select(selectUsersByIds(userIds)),
            tap(users => {
                const idsFound = users.map(u => u.rumbaUser.userId);
                const notFound = userIds.filter(id => !idsFound.includes(id));
                if (notFound.length) {
                    this.store.dispatch(new ActionUserProfileBulkLoad({ userIds: notFound }));
                }
            })
        );
    }
}
