<ul class="assessment-hierarchy">
    <li class="assessment-hierarchy__item" *ngFor="let program of programHierarchy; trackBy: trackByProgramId">
        <ng-container *ngIf="(selectedProgramId == null || selectedProgramId === program.id) && program.children?.length">
            <button (click)="toggleChildrenPrograms(program)" class="item__button">
                <span [ngStyle]="{ 'padding-left': hierarchyPadding }" class='title'> {{ program.name }}</span>
                <span
                    class="label-icon icon-chevron"
                    aria-hidden="true"
                    *ngIf="!(hideArrow && program.children?.length)"
                    [ngClass]="selectedProgramId === program.id ? 'arrow-down' : ''"
                ></span>
            </button>
            <ng-container *ngIf="program.children?.length">
                <app-dashboard-assessment-hierarchy-group
                    [programHierarchy]="program.children"
                    (currentStateEvent)="toggleArrowStatus()"
                    *ngIf="selectedProgramId === program.id"
                    [hierarchyLevel]="hierarchyLevel"
                >
                </app-dashboard-assessment-hierarchy-group>
            </ng-container>
        </ng-container>
        <app-dashboard-assessment-hierarchy-node
                    [assessment]="program"
                    *ngIf="!program.children?.length"
                    [hierarchyLevel]="hierarchyLevel"
                >
        </app-dashboard-assessment-hierarchy-node>
    </li>
</ul>
