import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ProgramItem } from '../../../../program/store/program.model';
import { HIERARCHY_PADDING } from '../../common/constants';
import { DashboardService } from 'src/app/core/dashboard/dashboard.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dashboard-assessment-hierarchy-group',
    templateUrl: './dashboard-assessment-hierarchy-group.component.html',
    styleUrls: ['./dashboard-assessment-hierarchy-group.component.scss']
})
export class DashboardAssessmentHierarchyGroupComponent implements OnInit, OnDestroy {
    @Input() programHierarchy: ProgramItem;
    @Input() hierarchyLevel: number;

    @Output() currentStateEvent = new EventEmitter();

    hideArrow: boolean;
    selectedProgramId: string;
    isAllAssessmentsMenuOpenSubscription: Subscription;

    constructor(private dashboardService: DashboardService) {}

    ngOnInit() {
        this.hideArrow = false;
        this.hierarchyLevel += 1; // increasing the hierarchy level so that the padding value is applied correctly
        this.isAllAssessmentsMenuOpenSubscription = this.dashboardService.isAllAssessmentsMenuOpen$.subscribe(
            isAllAssessementsMenuOpen => {
                if (!isAllAssessementsMenuOpen) {
                    this.selectedProgramId = null;
                }
            }
        );
    }

    ngOnDestroy() {
        this.isAllAssessmentsMenuOpenSubscription.unsubscribe();
    }

    toggleArrowStatus() {
        this.hideArrow = !this.hideArrow;
    }

    get hierarchyPadding() {
        return this.hierarchyLevel * HIERARCHY_PADDING + 'em'; // having a constant value of 10px for padding
    }

    toggleChildrenPrograms(program: any) {
        if (this.selectedProgramId === program.id) {
            this.selectedProgramId = null;
            this.hideArrow = false;
        } else {
            this.selectedProgramId = program.id;
            this.hideArrow = false;
        }
        this.currentStateEvent.emit();
    }

    trackByProgramId(index, program: ProgramItem) {
        return program.id;
    }
}
