export interface AuthenticationResponse {
    token: string;
    castgc: string;
    loggedInSince: number;
    identityId: string;
    userName: string;
    firstName: string;
    lastName: string;
    idpResponse: IDPResponse;
    permissions: Product[];
    name: string;
    title: string;
}

export interface AuthState {
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    isValidating: boolean;
    currentUser: UserDetails;
    rbs: RbsToken;
    jwt: string;
    authorizedScoutProducts: string[];
}

export interface IDPResponse {
    data: {
        assertion: {
            attributes: { [index: string]: string };
        };
        authorizedResource: AuthorizedResource[]
    };
}

interface AuthorizedResource {
    productId: string;
}

export interface JwtResponse {
    jwt: string;
}

export interface Product {
    UserOrganizationId: string;
    HasAccess: string;
    ProductId: string;
    ProductDisplayName: string;
}

export interface RbsToken {
    token: string;
    expires: number;
}

export interface RbsTokenResponse {
    access_token: string;
    expires_in: string;
    createdDate: string;
    userId: string;
}

export enum UserRole {
    TEACHER = 'Teacher',
    STUDENT = 'Student'
}
export interface UserDetails {
    userId: string;
    firstName: string;
    lastName: string;
    name: string;
    title: string;
    userName: string;
    role: UserRole;
}
