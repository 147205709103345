import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommentsState, State } from './comments.model';
import { selectAll } from './comments.reducer';

export const selectCommentsState = createFeatureSelector<State, CommentsState>('comments');

export const selectAllComments = createSelector(
    selectCommentsState,
    selectAll,
);

export const selectObservationComments = (observationId: string) => createSelector(
    selectAllComments,
    comments => comments
        .filter(comment => !comment.deleted && comment.observationId === observationId)
        .sort((a, b) => a.updatedAt - b.updatedAt)
);

export const selectIsLoadingComments = createSelector(
    selectCommentsState,
    state => state.isLoading,
);
