import { ReportsActions, ReportsActionTypes } from './reports.actions';
import { ReportsState } from './reports.model';

export const initialState: ReportsState = {
    observationReportFilters: undefined,
    checklistReportStates: {
        selectedProductProgramSkeleton: undefined,
        selectedContentItem: undefined,
        selectedCriteriaTag: undefined,
        tagsList: [],
        isTagsListLoading: false,
        isChecklistReportDataLoading: false
    }
};

export function reducer(state = initialState, action: ReportsActions) {
    switch (action.type) {
        case ReportsActionTypes.SetObservationReportFilters:
            return {
                ...state,
                observationReportFilters: {
                    ...state.observationReportFilters,
                    ...action.payload.filters,
                }
            };
        case ReportsActionTypes.SetSelectedProductProgramSkeleton:
            return {
                ...state,
                checklistReportStates: {
                    ...state.checklistReportStates,
                    selectedProductProgramSkeleton: action.payload.selectedProductProgramSkeleton,
                }
            };
        case ReportsActionTypes.SetSelectedContentItem:
            return {
                ...state,
                checklistReportStates: {
                    ...state.checklistReportStates,
                    selectedContentItem: action.payload.selectedContentItem,
                    isTagsListLoading: true
                }
            };
        case ReportsActionTypes.SetSelectedCriteriaTag:
            return {
                ...state,
                checklistReportStates: {
                    ...state.checklistReportStates,
                    selectedCriteriaTag: action.payload.selectedCriteriaTag,
                }
            };
        case ReportsActionTypes.LoadTagListForSelectedProgram:
            return {
                ...state,
                checklistReportStates: {
                    ...state.checklistReportStates,
                    tagsList: action.payload.tagsList,
                    isTagsListLoading: false
                }
            };
        case ReportsActionTypes.SetIsChecklistReportLoading:
            return {
                ...state,
                checklistReportStates: {
                    ...state.checklistReportStates,
                    isChecklistReportDataLoading: action.payload.isChecklistReportDataLoading,
                }
            };
        default:
            return state;
    }
}
