import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { TelemetryService } from '../../telemetry/telemetry.service';
import {
    ActionCreateNoteSuccess,
    ActionFilterNotes,
    ActionRemoveNoteSuccess,
    ActionUpdateNoteSuccess,
    NotesActionTypes
} from './notes.actions';

@Injectable()
export class NotesTelemetryEffects {
    constructor(
        private actions$: Actions<Action>,
        private telemetryService: TelemetryService
    ) {}

    @Effect({ dispatch: false })
    createNote = this.actions$.pipe(
        ofType<ActionCreateNoteSuccess>(NotesActionTypes.CreateNoteSuccess),
        tap(action => {
            const detail = {
                note: {
                    ...action.payload.note
                }
            };
            this.addISOVersionsOfNumericDates(detail.note);
            this.telemetryService.sendActivity('created', 'Notes', detail);
        })
    );

    @Effect({ dispatch: false })
    editNote = this.actions$.pipe(
        ofType<ActionUpdateNoteSuccess>(NotesActionTypes.UpdateNoteSuccess),
        tap(action => {
            const detail = {
                note: {
                    id: action.payload.update.id,
                    changes: {
                        ...action.payload.update.changes
                    }
                }
            };

            this.addISOVersionsOfNumericDates(detail.note.changes);
            this.telemetryService.sendActivity('modified', 'Notes', detail);
        })
    );

    @Effect({ dispatch: false })
    deleteNotes = this.actions$.pipe(
        ofType<ActionRemoveNoteSuccess>(NotesActionTypes.RemoveNoteSuccess),
        tap(action => {
            const noteIds = action.payload.ids;
            noteIds.forEach(id => {
                const details = {
                    note: { id }
                };
                this.telemetryService.sendActivity('deleted', 'Notes', details);
            });
        })
    );

    @Effect({ dispatch: false })
    filterNotes = this.actions$.pipe(
        ofType<ActionFilterNotes>(NotesActionTypes.FilterNotes),
        tap(action => {
            const details = {
                filterType: action.payload.filter
            };
            this.telemetryService.sendActivity('filtered', 'Notes', details);
        })
    );

    private addISOVersionsOfNumericDates(objectToChange: any) {
        Object.keys(objectToChange).forEach(key => {
            if (key.search(/date/gi) >= 0) {
                if (objectToChange[key]) {
                    objectToChange[key + 'ISO'] = new Date(
                        objectToChange[key]
                    ).toISOString();
                }
            }
        });
    }
}
