<div class="flex-container-col">
    <ion-toolbar class="page-bar">
        <div slot="start">
            <ion-segment class="segment" mode="ios"
                (ionChange)="filterChanged($event)"
                [value]="filteredBy">
                <ion-segment-button class="segment-button" value="All Media">
                    <ion-label class="segment-button__label">
                        {{ 'media-list.allMedia' | translate }}
                    </ion-label>
                </ion-segment-button>
                <ion-segment-button class="segment-button" value="Images">
                    <ion-label class="segment-button__label">
                        {{ 'media-list.pictures' | translate }}
                    </ion-label>
                </ion-segment-button>
                <ion-segment-button class="segment-button" value="Videos">
                    <ion-label class="segment-button__label">
                        {{ 'media-list.videos' | translate }}
                    </ion-label>
                </ion-segment-button>
            </ion-segment>
        </div>
        <ion-buttons slot="end">
            <input class="hidden" type="file" accept="image/*, video/*" #captureImage (change)="onMediaCaptured($event)">
            <ion-button
                class="page-bar__add-button action-button"
                [disabled]="_editMode"
                (click)="captureImage.click()">
                <div class="action-button__icon label-icon icon-addition" aria-hidden="true"></div>
                <ion-label class="action-button__label">{{ 'media-list.addMedia' | translate }}</ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

    <ion-card class="list-card">
        <ion-card-header class="card-header card-header--editable">
            <ion-toolbar class="card-header__toolbar">
                <ion-buttons class="buttons-container" *ngIf="!editMode">
                    <ion-button
                        class="card-header__add-button action-button"
                        [disabled]="_editMode"
                        (click)="captureImage.click()">
                        <div class="action-button__icon label-icon icon-addition" aria-hidden="true"></div>
                        <ion-label class="action-button__label">{{ 'media-list.addMedia' | translate }}</ion-label>
                    </ion-button>
                    <ion-button
                        [disabled]="(allMedia$ | async)?.length == 0"
                        (click)="editMode=true"
                        class="card-header__edit-button action-button">
                        <div class="action-button__icon label-icon icon-edit" aria-hidden="true"></div>
                        <ion-label class="action-button__label">{{ 'media-list.edit' | translate }}</ion-label>
                    </ion-button>
                </ion-buttons>
                <ion-buttons class="buttons-container" *ngIf="editMode">
                    <ion-button
                        (click)="editMode=false"
                        class="card-header__cancel-button action-button">
                        <ion-label class="action-button__label">{{ 'media-list.cancel' | translate }}</ion-label>
                    </ion-button>
                    <ion-button
                        (click)="presentDeleteConfirm()"
                        class="card-header__delete-button action-button"
                        [disabled]="checkedSelection.size === 0">
                        <ion-label class="action-button__label">{{ 'media-list.delete' | translate }}</ion-label>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-card-header>

        <div class="card-content" *ngIf="!(isLoading$ | async); else loadingThumbnailSkeleton">
            <media-grid
                [editMode]="editMode"
                [allMedia]="(allMedia$ | async)"
                [checkedSelection]="checkedSelection"
                *ngIf="(allMedia$ | async)?.length > 0; else emptyMediaTemplate"
                (mediaEdit)="toggleSelected($event)"
                (mediaClick)="onImageClick($event)"
            ></media-grid>
            <ng-template #emptyMediaTemplate>
                <div class="emptylist-content">
                    <span *ngIf="filteredBy === 'All Media'" [innerHtml]="'media-list.allMedia.zeroState' | translate"></span>
                    <span *ngIf="filteredBy === 'Images'" [innerHtml]="'media-list.pictures.zeroState' | translate"></span>
                    <span *ngIf="filteredBy === 'Videos'" [innerHtml]="'media-list.videos.zeroState' | translate"></span>
                </div>
            </ng-template>
        </div>

        <ng-template #loadingThumbnailSkeleton>
            <div class="card-content">
                <ion-grid class="media-grid">
                    <ion-row>
                        <ion-col size="auto" class="media-col" *ngFor="let item of [0,1,2]">
                            <div class="media-container">
                                <ng-container *ngTemplateOutlet="loadingMediaSkeleton"></ng-container>
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
        </ng-template>

        <ng-template #loadingMediaSkeleton>
            <div class="media-content">
                <ion-spinner class="media-loader"></ion-spinner>
            </div>
            <div class="media-name"> <ion-skeleton-text animated style="width: 50%;"></ion-skeleton-text> </div>
            <div class="media-creationdate"> <ion-skeleton-text animated style="width: 50%;"></ion-skeleton-text> </div>
        </ng-template>
    </ion-card>
</div>
