<ion-content>
    <div class="flex-container-col">
        <ion-card-header class="card-header card-header--editable">
            <ion-toolbar class="menu-header__toolbar">
                <ion-menu-toggle>
                    <ion-button class="menu-header__close-button action-button"
                    >
                        <ion-label class="action-button__label">{{ 'notes-menu.cancel' | translate }}</ion-label>
                    </ion-button>
                </ion-menu-toggle>
                <ion-buttons slot="end">
                    <ion-button
                        (click)="createEditNote()"
                        class="menu-header__done-button action-button"
                    >
                        <ion-label class="action-button__label">{{ 'notes-menu.save' | translate }}</ion-label>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-card-header>

        <ion-list class="menu-content">
            <ion-item-group class="form-group">
                <ion-item lines="none">
                    <ion-textarea
                        [(ngModel)]="newNoteModel.body"
                        class="textarea"
                        [attr.aria-label]="'notes-menu.textarea.label' | translate"
                        [placeholder]="'notes-menu.textarea.placeholder' | translate"
                    >
                    </ion-textarea>
                </ion-item>
            </ion-item-group>
            <ion-item-group class="form-group">
                <ion-item lines="none">
                    <span class="form-label">{{ 'notes-menu.todo.label' | translate }}</span>
                    <ion-toggle class="toggle-switch" [checked]="newNoteModel.isTodo" (ionChange)="onTodoToggle($event)" mode="ios"></ion-toggle>
                </ion-item>
                <ion-item lines="none" *ngIf="newNoteModel.isTodo">
                    <span class="form-label">{{ 'notes-menu.due-date.label' | translate }}</span>
                    <ion-datetime
                        class="date-picker"
                        [(ngModel)]="newNoteModel.dueDateISO"
                        display-format="MM/DD/YYYY"
                        picker-format="DDDD, MMM DD, YYYY"
                        min="{{ minDate }}"
                        max="{{ maxDate }}"
                        [placeholder]="'notes-menu.datetime.placeholder' | translate"
                        #datepicker
                    >
                    </ion-datetime>
                    <span (click)="datepicker.open()" class="icon-calendar" slot="end" aria-hidden="true"></span>
                </ion-item>
            </ion-item-group>
            <ion-item-group class="form-group">
                <ion-item lines="none">
                    <ion-label class="form-label">
                            {{ 'notes-menu.tag-students.label' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item lines="none" *ngFor="let student of newNoteModel.students; trackBy: trackByStudentId">
                    <ion-checkbox [(ngModel)]="student.selected" class="checkbox" slot="start"></ion-checkbox>
                    <ion-chip class="inline-avatar">
                        <ion-avatar class="avatar">
                            <span class="avatar__initials">{{ student.initials }}</span>
                        </ion-avatar>
                        <ion-label class="avatar__label">{{ student.name }}</ion-label>
                    </ion-chip>
                </ion-item>
            </ion-item-group>
        </ion-list>
    </div>
</ion-content>
