// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

// Ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// app
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { environment } from '../environments/environment';
import { NotesModule } from './notes/notes.module';
import { MediaModule } from './media/media.module';
import { SharedModule } from './shared/shared.module';
import { ErrorModule } from './error/error.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        }),
        HttpClientModule,
        CoreModule,
        SharedModule,
        NotesModule,
        MediaModule,
        ErrorModule,
        AppRoutingModule, // must come after feature modules
    ],
    providers: [
        StatusBar,
        SplashScreen
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}
