import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MediaService } from './media.service';
import { MediaTelemetryEffects } from './store/media-telemetry.effects';
import { MediaEffects } from './store/media.effects';
import { reducer } from './store/media.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('media', reducer),
        EffectsModule.forFeature([MediaEffects, MediaTelemetryEffects])
    ],
    providers: [MediaService, AngularFireStorage]
})
export class MediaModule {}
