<div class="error-modal">
    <ion-toolbar class="alert__header" *ngIf="showHeader">
        <ion-title class="alert__text">{{ translatedTitle | translate }}</ion-title>
        <ion-button
            slot="end"
            fill="clear"
            class="alert__exit"
            [title]="'alert-header.exit' | translate"
        >
            <span class="icon-exit" aria-hidden="true" (click)="closeModal()"></span>
        </ion-button>
    </ion-toolbar>
    <div class="modal-content">
        <ion-label text-wrap class="alert__content">
            {{ translatedDescription | translate: { maxImageSize: imageMaxUploadSize, maxVideoSize: videoMaxUploadSize } }}
        </ion-label>
        <ion-button class="alert__button alert__button-ok" (click)="closeModal()">
            {{ translatedButtonText | translate }}
        </ion-button>
    </div>
</div>
