import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { DashboardActions, DashBoardActionTypes } from './dashboard.actions';
import { AssessmentStat, DashboardState } from './dashboard.model';
import sha256 from 'crypto-js/sha256';

export const adapter: EntityAdapter<AssessmentStat> = createEntityAdapter<AssessmentStat>({
    selectId: programItem => sha256(programItem.id + programItem.path.join() + programItem.name).toString()
});

export const initialState: DashboardState = adapter.getInitialState({
    isLoadingAssessmentStats: false,
    isLoadingRecentActivity: false,
    isAllAssessmentsMenuOpen: false
});

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal
} = adapter.getSelectors();

export function reducer(state = initialState, action: DashboardActions) {
    switch (action.type) {
        case DashBoardActionTypes.SetRecentActivityLoading:
            return { ...state, isLoadingRecentActivity: action.payload.loading };
        case DashBoardActionTypes.LoadAssessmentStatsStart:
            return { ...state, isLoadingAssessmentStats: true };
        case DashBoardActionTypes.LoadAssessmentStats:
            return adapter.addAll(action.payload.assessmentStats, { ...state, isLoadingAssessmentStats: false });
        case DashBoardActionTypes.SetIsAllAssessmentsMenuOpen:
            return { ...state, isAllAssessmentsMenuOpen: action.payload.isAllAssessmentsMenuOpen };
        default:
            return state;
    }
}
