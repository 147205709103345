import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { LoggingEffects } from './logging.effects';
import { Logger } from './logger';

@NgModule({
    imports: [CommonModule, EffectsModule.forFeature([LoggingEffects])],
    providers: [Logger]
})
export class LoggingModule {}
