import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'minutesToSeconds'
})

export class MinutesToSecondsPipe implements PipeTransform {
    transform(value: number): string {
       const minutes: number = Math.floor(value / 60);
       return minutes + ':' +
       Math.floor((value - minutes * 60)).toString().padStart(2, '0');
    }
}
